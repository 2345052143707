import { Component, HostBinding, HostListener, OnInit, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { TranslationLanguageModel } from '../../../../core/models/translation-language.model';
import { AuthService } from '../../../../core/services/auth.service';
import { LayoutService } from '../../../../core/services/layout.service';
import { SidebarService } from '../../../../core/services/sidebar.service';
import { SettingsStorage } from '../../../../core/services/storages/settings.storage';
import { TranslationService } from '../../../../core/services/translation.service';
import { BaseComponent } from '../../../abstractions/base.component';
import { RouteService } from '../../../../core/services/route.service';
import { MenuItemModel } from '../../../../core/models/menu-item.model';
import { environment } from '../../../../environments/environment';
import { CustomerInteractionDialogComponent } from '../../../dialogs/customer-interaction-dialog/customer-interaction-dialog.component';

@Component({
    selector: 'arc-top-bar',
    templateUrl: './top-bar.component.html',
    styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent extends BaseComponent implements OnInit {
    @HostBinding('class') classes = 'sticky top-0 z-10';

    readonly mainNavigation: MenuItemModel[] = [];
    activeMainMenu?: string;

    isSearchbarCollapsed = true;
    isDarkMode = false;

    currentRotation = 0;

    selectedLanguage: TranslationLanguageModel;
    allowedLanguages: TranslationLanguageModel[] = [];

    private readonly router = inject(Router);
    private readonly sidebarService = inject(SidebarService);
    private readonly layoutService = inject(LayoutService);
    private readonly settingsStorage = inject(SettingsStorage);
    private readonly authService = inject(AuthService);
    private readonly translationService = inject(TranslationService);
    private readonly routeService = inject(RouteService);
    private readonly matDialog = inject(MatDialog);

    constructor() {
        super();
        this.selectedLanguage = this.translationService.current;
        this.mainNavigation = this.routeService.generateMenu();

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                const data = this.routeService.getRouteDataFromUri(event.url);
                this.activeMainMenu = data?.subMenu;
            }
        });
    }

    @HostListener('window:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent): void {
        if (event.ctrlKey && event.key === 'n') {
            event.preventDefault();
            this.showCustomerInteractionDialog();
        }
    }

    ngOnInit(): void {
        this.allowedLanguages = [...this.translationService.allowedLanguages].sort((a, b) => a.name.localeCompare(b.name));

        this.isDarkMode = this.settingsStorage.getDarkMode() ?? false;
        const darkModeChangedSubscription = this.layoutService.getThemeChangedObservable().subscribe(isDarkMode => {
            this.isDarkMode = isDarkMode;
        });
        this.addSubscriptions(darkModeChangedSubscription);
    }

    toggleSidebar(): void {
        const isSidebarOpen = this.sidebarService.toggleLeft();

        let rotationDegree;
        if (isSidebarOpen === false) {
            rotationDegree = this.currentRotation - 180;
        } else if (isSidebarOpen === true) {
            rotationDegree = this.currentRotation + 180;
        }

        if (rotationDegree !== undefined) {
            this.currentRotation = rotationDegree;
        }
    }

    logout(): void {
        this.authService.logout();
    }

    switchLanguage(code: string): void {
        this.translationService.switch(code);
        this.selectedLanguage = this.translationService.current;
    }

    toggleTheme(): void {
        this.layoutService.setDarkMode(!this.isDarkMode);
    }

    navigateToFirstAllowedChild(menu: MenuItemModel): void {
        const firstAllowedChild = this.routeService.getFirstAllowedSubmenu(menu.path);
        if (firstAllowedChild) {
            this.router.navigate([firstAllowedChild.path], { queryParams: firstAllowedChild.startingQueryParams });
        }
    }

    showCustomerInteractionDialog(): void {
        this.matDialog.open(CustomerInteractionDialogComponent, {
            width: '600px',
            maxHeight: '98svh',
            maxWidth: '98vw'
        });
    }

    get logo(): string {
        return environment.isMity ? 'logo-mity' : 'logo';
    }
}
