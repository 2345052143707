import { Component, EventEmitter, Input, Output } from '@angular/core';

import { BaseComponent } from '../../../../../../components/abstractions/base.component';
import { TicketListModel } from '../../../../../models/responses/ticket-list.model';

@Component({
    selector: 'arc-ticket-list-item',
    templateUrl: './ticket-list-item.component.html',
    styleUrl: './ticket-list-item.component.scss'
})
export class TicketListItemComponent extends BaseComponent {
    @Input({required: true}) ticket!: TicketListModel;
    @Output() readonly ticketSelected = new EventEmitter<number>();

    handleSelectTicket(ticketId: number): void {
        this.ticketSelected.emit(ticketId);
    }
}
