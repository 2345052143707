import { BaseEntityModel } from '../../../core/abstractions/base-entity.model';

export class NewsletterCreateModel extends BaseEntityModel {
    messageType!: string;
    title!: string;

    constructor(init?: Partial<NewsletterCreateModel>) {
        super();

        if (!!init) {
            Object.assign(this, init);
        }
    }
}
