<div class="w-full h-full flex flex-col">
    @if(isLoading()){
    <div class="w-full h-full flex-1 grid place-items-center">
        <mat-spinner [diameter]="32"></mat-spinner>
    </div>
    }
    @if(!!tableConfig && !isLoading()){
        <arc-static-basic-table-list-view [config]="tableConfig" [shouldUseCompactStyle]="true" class="flex-1">
        </arc-static-basic-table-list-view>
    }
    <div class="sticky bottom-0">
        <button mat-icon-button class="float-right" (click)="addLicenseTransaction()">
            <mat-icon>add</mat-icon>
        </button>
    </div>
</div>