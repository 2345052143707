import { Component } from '@angular/core';

import { BaseCustomColumnComponent } from '../../../../../../components/dynamic-table/column-components/custom-column/base-custom-column.component';
import { BenefitListModel } from '../../../../../models/responses/benefit-list.model';
import { PaymentPeriodsEnum } from '../../../../../models/enums/payment-periods.enum';

@Component({
    selector: 'arc-benefit-payment-period',
    templateUrl: './benefit-payment-period.component.html',
    styleUrl: './benefit-payment-period.component.scss'
})
export class BenefitPaymentPeriodComponent extends BaseCustomColumnComponent<BenefitListModel>{
    PaymentPeriodsEnum = PaymentPeriodsEnum;
    isNaN = isNaN;
}
