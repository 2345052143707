import { Component, HostBinding } from '@angular/core';

import { BaseCardComponent } from '../../../../../components/abstractions/base-card.component';
import { PosReportTemplateListModel } from '../../../../models/responses/pos-report-template-list.model';

@Component({
    selector: 'arc-pos-report-card',
    templateUrl: './pos-report-template-card.component.html',
    styleUrls: ['./pos-report-template-card.component.scss']
})
export class PosReportTemplateCardComponent extends BaseCardComponent<PosReportTemplateListModel> {
    @HostBinding('class') classList = 'h-full flex flex-col';
}
