import { Component, HostBinding, Input } from '@angular/core';

import { BaseControlComponent } from '../base-control/base-control.component';

@Component({
    selector: 'arc-textarea',
    templateUrl: './textarea.component.html',
    styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent extends BaseControlComponent {
    @HostBinding('class') classes = 'grow';

    @Input() minLength?: number;
    @Input() maxLength?: number;
}
