import { Component, ViewChild, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { DynamicFormField } from '../../dynamic-form/models/dynamic-form-field';
import { BaseComponent } from '../../abstractions/base.component';
import { DynamicFormComponent } from '../../dynamic-form/dynamic-form.component';
import { PosReportTemplateParametersDialogData } from './pos-report-template-parameters-dialog-data';
import { PosReportTemplatesStore } from '../../../app/services/stores/pos-report-templates.store';

@Component({
    selector: 'arc-pos-report-parameters-dialog',
    templateUrl: './pos-report-template-parameters-dialog.component.html',
    styleUrls: ['./pos-report-template-parameters-dialog.component.scss']
})
export class PosReportTemplateParametersDialogComponent extends BaseComponent {
    @ViewChild('dynamicForm') dynamicForm!: DynamicFormComponent;

    formFields: DynamicFormField[] = [];
    isLoading = true;

    private readonly data: PosReportTemplateParametersDialogData = inject(MAT_DIALOG_DATA);
    private readonly dialogRef = inject(MatDialogRef);
    private readonly posReportsStore = inject(PosReportTemplatesStore);

    constructor() {
        super();
        this.getReportParameters(this.data);
    }

    getReportParameters(data: PosReportTemplateParametersDialogData): void {
        this.posReportsStore.getReportParameters(data.id).subscribe(response => {
            if (response.value) {
                response.value.forEach(formField => {
                    const presetParam = this.data.parameters?.get(formField.key);

                    if (!!presetParam) {
                        // reports are sending all the values in an array
                        if (Array.isArray(presetParam) && presetParam.length > 0) {
                            formField.value = presetParam[0];
                        } else {
                            formField.value = presetParam;
                        }
                    }
                });

                this.formFields = response.value!;
                this.isLoading = false;
            }
        });
    }

    onFormSubmitted(data: any): void {
        this.data.parameters = { ...this.data.parameters, ...data };
        this.dialogRef.close({ parameters: this.data.parameters, openReport: true });
    }

    onOpenReport(): void {
        if (!!this.dynamicForm) {
            this.dynamicForm.submitForm();
        } else {
            this.dialogRef.close({ parameters: undefined, openReport: true });
        }
    }
}
