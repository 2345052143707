import { Component, HostBinding, OnInit, computed, effect, inject, signal } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { map, Observable, of, tap } from 'rxjs';

import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { Utils } from '../../../../../../core/utils/tools/utils.tools';
import { TicketModel } from '../../../../../models/ticket.model';
import { TicketsStore } from '../../../../../services/stores/tickets.store';
import { BlobModel } from '../../../../../models/blob.model';
import { SidebarService } from '../../../../../../core/services/sidebar.service';
import { BaseSidebarComponent } from '../../../../../../components/sidebar-components/base-sidebar/base-sidebar.component';
import { TicketSidebarService } from '../../../../../../core/services/ticket-sidebar.service';
import { ActionButtonsService } from '../../../../../../core/services/action-buttons.service';
import { ActionButtonModel } from '../../../../../../core/models/action-button.model';
import { ModalVisibilityEnum } from '../../../../../../core/models/enums/modal-visibility.enum';
import { TranslationService } from '../../../../../../core/services/translation.service';
import { TicketMessageOrEventModel } from '../message-or-event.model';
import { TicketHistoryModel } from '../../../../../models/ticket-history.model';
import { TicketMessageModel } from '../../../../../models/ticket-message.model';
import { TicketMessageTypeEnum } from '../message-or-event.enum';

@Component({
    selector: 'arc-ticket-messages',
    templateUrl: './ticket-messages.component.html',
    styleUrl: './ticket-messages.component.scss'
})
export class TicketMessagesComponent extends BaseSidebarComponent implements OnInit {
    @HostBinding('class') classes = 'h-full flex flex-col px-8 pt-8 smd:px-2 smd:pt-2';

    isAddingMessage = signal(false);

    messagesOrEvents = computed(() => this.getMessagesOrEvents(this.ticketSidebarService.ticket()));
    ticket = computed(() => this.ticketSidebarService.ticket());

    readonly sendMessageButtonKey = Utils.newGuid();

    visiblePrimaryAction = this.getSendMessageButton();
    visibleSecondaryAction?: ActionButtonModel;
    collapsedActions: ActionButtonModel[] = [];

    formGroup = new FormGroup({
        text: new ArcFormControl(''),
        private: new ArcFormControl(false),
        escalated: new ArcFormControl(false),
        attachments: new ArcFormControl<OptionalType<BlobModel[]>>(undefined)
    });

    TicketMessageTypeEnum = TicketMessageTypeEnum;

    fileControl = new ArcFormControl<OptionalType<File>>(undefined);

    private readonly ticketStore = inject(TicketsStore);
    private readonly sidebarService = inject(SidebarService);
    private readonly ticketSidebarService = inject(TicketSidebarService);
    private readonly actionButtonsService = inject(ActionButtonsService);
    private readonly translationService = inject(TranslationService);

    constructor() {
        super();

        effect(() => {
            const ticket = this.ticketSidebarService.ticket();
            if (!ticket) {
                return;
            }
            if (ticket?.escalated) {
                this.formGroup.controls.escalated.setValue(true);
            }
        });

        effect(() => {
            const actionButtons = this.actionButtonsService.buttons();
            this.setupActionButtons(actionButtons);
        });
    }

    ngOnInit(): void {
        this.fileControl.valueChanges.subscribe(async file => {
            if (!file) {
                return;
            }
            const newBlob = {
                id: 0,
                blobDataId: Utils.newGuid(),
                blobData: Array.from(new Uint8Array(await file.arrayBuffer())),
                fileMimeType: file.type,
                fileName: file.name
            };
            this.formGroup.controls.attachments.patchValue([...(this.formGroup.value.attachments || []), newBlob]);
            this.fileControl.reset();
        });
    }

    addMessage(): Observable<boolean> {
        if (!this.formGroup.valid) {
            return of(false);
        }

        this.isAddingMessage.set(true);
        const ticketId = this.ticket()!.id;
        const ticketEditModel = {
            id: ticketId,
            private: this.formGroup.value.private || false,
            escalated: this.formGroup.value.escalated || false,
            text: this.formGroup.value.text || '',
            attachments: this.formGroup.value.attachments || []
        };
        return this.ticketStore.edit(ticketEditModel).pipe(
            tap(() => {
                this.formGroup.reset();
                this.formGroup.markAsPristine();
                this.isAddingMessage.set(false);
            }),
            map(() => true)
        );
    }

    removeFile(blobDataId?: string): void {
        if (!blobDataId) {
            return;
        }

        const filteredAttachments = (this.formGroup.value.attachments || []).filter(x => x.blobDataId !== blobDataId);
        this.formGroup.controls.attachments.patchValue(filteredAttachments);
    }

    cancel(): void {
        this.sidebarService.closeRight();
    }

    getButtonText(btn: ActionButtonModel): string {
        return btn.key === this.sendMessageButtonKey
            ? this.translationService.getText('Tickets.Edit.AddMessage')
            : /([a-zA-Z]+\.[a-zA-Z]+)/g.test(btn.text)
                ? this.translationService.getText(btn.text)
                : btn.text;
    }

    isButtonDisabled(btn: ActionButtonModel): boolean {
        return btn.isLoading || this.isAddingMessage();
    }

    asMessage(item: TicketMessageModel | TicketHistoryModel): TicketMessageModel {
        return item as TicketMessageModel;
    }
    asEvent(item: TicketMessageModel | TicketHistoryModel): TicketHistoryModel {
        return item as TicketHistoryModel;
    }

    onContextActionClick(evt: MouseEvent, btn: ActionButtonModel): void {
        evt.stopPropagation();

        const shouldClose =
            btn.modalVisibility === ModalVisibilityEnum.PrimaryCloseAfter ||
            btn.modalVisibility === ModalVisibilityEnum.SecondaryCloseAfter;

        if (!!btn.clickFn) {
            btn.clickFn(btn, this.ticket());
        } else if (!!btn.key) {
            this.addMessage().subscribe({
                complete: () => {
                    this.actionButtonsService.handleClick(btn.key, this.ticket());
                    if (shouldClose) {
                        this.sidebarService.closeRight();
                    }
                }
            });
        }
    }

    private setupActionButtons(actionButtons: ActionButtonModel[]): void {
        const buttons = actionButtons.filter(
            ab =>
                ab.min === 1 &&
                !this.actionButtonsService.isHidden(ab.key, this.ticket()) &&
                ab.modalVisibility !== ModalVisibilityEnum.None
        );

        if (buttons.length < 1) {
            return;
        }

        const newPrimary = buttons.find(
            b => b.modalVisibility === ModalVisibilityEnum.PrimaryCloseAfter || b.modalVisibility === ModalVisibilityEnum.Primary
        );
        let secondary = buttons.find(
            b => b.modalVisibility === ModalVisibilityEnum.SecondaryCloseAfter || b.modalVisibility === ModalVisibilityEnum.Secondary
        );

        if (!!newPrimary) {
            secondary = undefined; // We reset it here because all the secondary actions will be in the dropdown in this case.
            this.visiblePrimaryAction = newPrimary;
            this.visibleSecondaryAction = this.getSendMessageButton();
        } else {
            this.visiblePrimaryAction = this.getSendMessageButton();

            if (!!secondary) {
                this.visibleSecondaryAction = secondary;
            }
        }

        this.collapsedActions = buttons.filter(b => (!newPrimary || b.key !== newPrimary.key) && (!secondary || b.key !== secondary.key));
    }

    private getSendMessageButton(): ActionButtonModel {
        return new ActionButtonModel({
            key: this.sendMessageButtonKey,
            text: '',
            modalVisibility: ModalVisibilityEnum.Primary,
            clickFn: () => this.addMessage().subscribe(() => this.sidebarService.closeRight())
        });
    }

    private getMessagesOrEvents(ticket?: TicketModel): TicketMessageOrEventModel[] {
        if (!ticket) {
            return [];
        }

        const messages = ticket.messages.map(m => ({
            id: m.id.toString(),
            date: m.createdDate,
            item: m,
            type: TicketMessageTypeEnum.Message
        }));

        const events = ticket.history.map(h => ({
            id: Utils.newGuid(),
            date: h.date,
            item: h,
            type: TicketMessageTypeEnum.Event
        }));

        return [...messages, ...events].sort((a, b) => a.date.getTime() - b.date.getTime());
    }
}
