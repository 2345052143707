<div class="flex flex-col gap-2" [formGroup]="form">
    <div class="flex items-center hover:bg-opacity-5 h-[48px]" [class.cursor-pointer]="!isRequired"
        [class.font-bold]="isRequired" [class.hover:bg-black]="!isRequired" (click)="isOpen = !isOpen">
        <div class="basis-0 grow flex items-center gap-2">
            <arc-icon *ngIf="!isRequired" icon="chevron_right" class="transition-transform"
                [class.rotate-90]="isOpen"></arc-icon>
            <span>{{ titleKey | transloco }}</span>
        </div>
        <div class="mx-8 grid place-items-center">
            <mat-icon class="invisible">arrow_right_alt</mat-icon>
        </div>
        <div class="basis-0 grow">{{ 'Components.ImportDialog.YourData' | transloco }}</div>
    </div>
    <div *ngFor="let column of columns" class="flex items-center h-[48px]" [class.hidden]="!isOpen && !isRequired">
        <div class="basis-0 grow h-full">
            <div
                class="w-full h-full flex gap-2 items-center px-[16px] border border-on-app-light border-solid rounded-[4px]">
                <span>{{ column.displayName }}</span>
                <arc-icon *ngIf="!!column.tooltip" icon="info_outline" class="text-on-app-light"
                    [matTooltip]="column.tooltip"></arc-icon>
                <div class="grow"></div>
                <span class="text-on-app-light">{{ column.infoText }}</span>
            </div>
        </div>
        <div class="mx-8 grid place-items-center">
            <mat-icon class="rotate-180">arrow_right_alt</mat-icon>
        </div>
        <div class="basis-0 grow">
            <mat-form-field class="w-full" color="primary" subscriptSizing="dynamic">
                <mat-select [formControlName]="column.id" [required]="column.isRequired">
                    <mat-option *ngIf="!column.isRequired">--</mat-option>
                    <mat-option *ngFor="let column of importFileColumns" [value]="column">{{ column }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</div>