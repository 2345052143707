import { Component, inject } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { NewsletterSubscriberEditModel } from '../../../../../models/requests/newsletter-subscriber-edit.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { SelectOptionModel } from '../../../../../../core/models/select-option.model';
import { TranslationService } from '../../../../../../core/services/translation.service';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { ComplexDataTypesEnum } from '../../../../../../core/models/complex-data-types.enum';
import { NewsletterSubscriberModel } from '../../../../../models/newsletter-subscriber.model';

@Component({
    selector: 'arc-subscribers-edit',
    templateUrl: './subscribers-edit.component.html',
    styleUrl: './subscribers-edit.component.scss'
})
export class SubscribersEditComponent extends BaseEditSidebarItemComponent<NewsletterSubscriberModel, NewsletterSubscriberEditModel> {
    override formGroup = new FormGroup({
        subscriptionType: new ArcFormControl('', Validators.required),
        languageIsoCode: new ArcFormControl('', Validators.required),
        email: new ArcFormControl('', [Validators.required, Validators.email]),
        name: new ArcFormControl<OptionalType<string>>(undefined),
        licenseId: new ArcFormControl<OptionalType<number>>(undefined),
        hiddenSubscriptionType: new ArcFormControl(false)
    });

    ComplexDataTypesEnum = ComplexDataTypesEnum;

    allowedLanguages: SelectOptionModel<string>[] = [];
    private readonly translationService = inject(TranslationService);

    constructor() {
        super();
        this.allowedLanguages = this.translationService.allowedLanguages.map(l => ({ label: l.name, value: l.code }));
    }
    onItemSet(): void {
        this.formGroup.patchValue(this.item);
    }

    override prepareSaveModel(itemCopy: NewsletterSubscriberEditModel): NewsletterSubscriberEditModel {
        return new NewsletterSubscriberEditModel({ ...itemCopy, ...this.formGroup.value });
    }
}
