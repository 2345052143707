<div class="flex flex-col gap-4">
    <arc-input labelKey="Contracts.Edit.BaseData.Key" [control]="this.formGroup.controls.key"></arc-input>
    <div class="flex justify-between">
        <div class="flex items-center gap-6">
            <p>{{ 'Contracts.Edit.BaseData.Language' | transloco }}</p>
            <arc-button-toggle-group [control]="language" [items]="languageItems" toggleButtonClasses="w-[80px]"></arc-button-toggle-group>
        </div>
        <button mat-icon-button (click)="translate()">
            <mat-icon>translate</mat-icon>
        </button>
    </div>
    <arc-input labelKey="Contracts.Edit.BaseData.Title" [control]="title"></arc-input>
    <arc-input labelKey="Contracts.Edit.BaseData.LastChangesSummary" [control]="lastChangesSummary"></arc-input>
    <arc-markdown-editor styleClass="mde-help" [formControl]="text"> </arc-markdown-editor>
</div>
