<div class="flex flex-col gap-4 overflow-y-auto grow">
    <mat-chip-listbox (change)="onFilterChanged($event)" required #chipList>
        <mat-chip-option [value]="ImportExecutionRowStatusEnum.All" selected color="accent">
            <span class="font-bold">{{ 'Components.ImportDialog.ImportStatusTypes.All' | transloco }}</span>
            <span class="ml-2">{{ importResultAmountTotal }}</span>
        </mat-chip-option>
        <mat-chip-option [value]="ImportExecutionRowStatusEnum.New">
            <span class="font-bold">{{ 'Components.ImportDialog.ImportStatusTypes.New' | transloco }}</span>
            <span class="ml-2">{{ importResultAmounts[ImportExecutionRowStatusEnum.New] }}</span>
        </mat-chip-option>
        <mat-chip-option [value]="ImportExecutionRowStatusEnum.Unchanged">
            <span class="font-bold">{{ 'Components.ImportDialog.ImportStatusTypes.Unchanged' | transloco }}</span>
            <span class="ml-2">{{ importResultAmounts[ImportExecutionRowStatusEnum.Unchanged] }}</span>
        </mat-chip-option>
        <mat-chip-option [value]="ImportExecutionRowStatusEnum.Changed">
            <span class="font-bold">{{ 'Components.ImportDialog.ImportStatusTypes.Changed' | transloco }}</span>
            <span class="ml-2">{{ importResultAmounts[ImportExecutionRowStatusEnum.Changed] }}</span>
        </mat-chip-option>
        <mat-chip-option [value]="ImportExecutionRowStatusEnum.Invalid" color="warn"
            [class.!bg-error-light]="chipList.value !== ImportExecutionRowStatusEnum.Invalid">
            <span class="font-bold">{{ 'Components.ImportDialog.ImportStatusTypes.Invalid' | transloco }}</span>
            <span class="ml-2">{{ importResultAmounts[ImportExecutionRowStatusEnum.Invalid] }}</span>
        </mat-chip-option>
    </mat-chip-listbox>
    <div class="grow flex flex-col overflow-y-auto">
        <div class="grow overflow-auto">
            <table mat-table [dataSource]="datasource" multiTemplateDataRows matSort>
                <ng-container matColumnDef="importStatus">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'Components.ImportDialog.ImportStatus' | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let element" class="!border-b-0" [ngSwitch]="element.importStatus">
                        <ng-container *ngSwitchCase="ImportExecutionRowStatusEnum.New">
                            {{ 'Components.ImportDialog.ImportStatusTypes.New' | transloco }}
                        </ng-container>
                        <ng-container *ngSwitchCase="ImportExecutionRowStatusEnum.Unchanged">
                            {{ 'Components.ImportDialog.ImportStatusTypes.Unchanged' | transloco }}
                        </ng-container>
                        <ng-container *ngSwitchCase="ImportExecutionRowStatusEnum.Changed">
                            {{ 'Components.ImportDialog.ImportStatusTypes.Changed' | transloco }}
                        </ng-container>
                        <ng-container *ngSwitchCase="ImportExecutionRowStatusEnum.Invalid">
                            {{ 'Components.ImportDialog.ImportStatusTypes.Invalid' | transloco }}
                        </ng-container>
                        <ng-container *ngSwitchCase="ImportExecutionRowStatusEnum.Ignored">
                            {{ 'Components.ImportDialog.ImportStatusTypes.Ignored' | transloco }}
                        </ng-container>
                    </td>
                </ng-container>

                <ng-container [matColumnDef]="column.id" *ngFor="let column of displayedEntityColumns">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ column.displayName }}</th>
                    <td mat-cell *matCellDef="let element" class="!border-b-0 max-w-[200px]"
                        [class.error]="!!element.importColumnErrorsDict[column.displayName]"
                        [matTooltip]="element.importColumnErrorsDict[column.displayName] ?? ''">
                        <p class="line-clamp-2 break-words">
                            {{ element[column.id] }}
                        </p>
                    </td>
                </ng-container>

                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="allDisplayedColumns.length">
                        <div class="flex flex-col overflow-hidden text-error"
                            [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
                            <div class="flex flex-col gap-2 mt-2 mb-4">
                                <div *ngFor="let error of element.importColumnErrors">
                                    {{ error.fieldName }}: {{ error.errorMessage }}
                                </div>
                                <div *ngFor="let error of element.importGeneralErrors">{{ error }}</div>
                            </div>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="allDisplayedColumns; sticky: true"
                    class="border-b-2 border-on-app border-solid">
                </tr>
                <tr mat-row *matRowDef="let element; columns: allDisplayedColumns" class="!border-b-0"
                    [class.example-expanded-row]="expandedElement === element"
                    [class.!bg-error-light]="element.hasErrors" [class.!text-on-error-light]="element.hasErrors"
                    [class.cursor-pointer]="element.hasErrors" (click)="expand(element)">
                </tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="!h-0 !bg-error-light"></tr>

                <tr *matNoDataRow>
                    <td [attr.colspan]="allDisplayedColumns.length" class="p-4 text-center">
                        {{ 'General.NoRecords' | transloco }}
                    </td>
                </tr>
            </table>
        </div>

        <mat-paginator [pageSizeOptions]="[10, 15, 20]" [pageSize]="15" [showFirstLastButtons]="true"> </mat-paginator>
    </div>
</div>