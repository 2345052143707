<div class='max-w-[360px] flex flex-col gap-12'>
    <div>
        <h1 class='text-3xl mb-4 break-words' *ngIf='!wasRequestSent else requestSentTitle'>
            {{ 'RequestPasswordReset.Title' | transloco }}
        </h1>
        <ng-template #requestSentTitle>
            <h1>
                {{ 'RequestPasswordReset.RequestSentTitle' | transloco }}
            </h1>
        </ng-template>
        <p *ngIf='wasRequestSent'>
            {{'RequestPasswordReset.RequestSent' | transloco}}
        </p>
    </div>
    <mat-card-content class="!flex flex-col gap-4">
        <div [formGroup]="formGroup" *ngIf="!wasRequestSent">
            <arc-input [control]="formGroup.controls['email']" labelKey="General.Email"
                       placeholder="you@arcavis.ch">
            </arc-input>
        </div>
    </mat-card-content>
    <mat-card-actions>
        <button *ngIf="!wasRequestSent else requestSentButton" mat-flat-button color='accent' (click)="requestResetEmail()">
            {{ 'RequestPasswordReset.SendRequest' | transloco }}
        </button>
        <ng-template #requestSentButton>
            <button mat-flat-button color='accent' (click)="returnToLogin()">
                {{ 'RequestPasswordReset.ReturnToLogin' | transloco }}
            </button>
        </ng-template>
    </mat-card-actions>
</div>
