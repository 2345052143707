import { Component, inject } from '@angular/core';

import { HelpTooltipListModel } from '../../../models/responses/help-tooltip-list.model';
import { HelpTooltipModel } from '../../../models/responses/help-tooltip.model';
import { HelpTooltipEditModel } from '../../../models/requests/help-tooltip-edit.model';
import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { HelpTooltipsStore } from '../../../services/stores/help-tooltips.store';
import { TableListViewConfigModel } from '../../../../components/list-views/table-list-view/models/table-list-view-config.model';
import { StringColumnModel } from '../../../../components/dynamic-table/models/column-types/string-column.model';
import { ColumnSortModel } from '../../../../core/models/column-sort.model';
import { SortDirectionEnum } from '../../../../core/models/enums/sort-direction.enum';
import { HelpTooltipEditComponent } from './help-tooltips-edit-items/help-tooltip-edit/help-tooltip-edit.component';

@Component({
    selector: 'arc-help-tooltips',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrl: './help-tooltips.component.scss'
})
export class HelpTooltipsComponent extends PageListViewComponent<HelpTooltipListModel, HelpTooltipModel, HelpTooltipEditModel> {
    private readonly helpTooltipsStore = inject(HelpTooltipsStore);
    constructor() {
        super('HelpTooltip');
        this.config = new TableListViewConfigModel<HelpTooltipListModel, HelpTooltipModel, HelpTooltipEditModel>({
            entityName: 'HelpTooltips',
            availableColumns: {
                key: new StringColumnModel({
                    columnTitleKey: 'HelpTooltips.List.Key',
                    propertyName: 'key',
                    widthPixels: 400
                }),
                text: new StringColumnModel({
                    columnTitleKey: 'HelpTooltips.List.Text',
                    propertyName: 'text'
                })
            },
            defaultColumnOrder: [
                'key',
                'text'
            ],
            defaultSort: new ColumnSortModel({
                column: 'id',
                direction: SortDirectionEnum.Desc
            }),
            availableDetailWidgets: {
            },
            defaultDetailWidgetOrder: [],
            editSidebarConfig: {
                editComponents: [
                    {
                        titleKey: 'HelpTooltips.Edit.BaseData.Name',
                        component: HelpTooltipEditComponent
                    }
                ]
            },

            store: this.helpTooltipsStore
        });
    }
}
