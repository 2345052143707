import { Component, ElementRef, Input, ViewChild, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';

import { AddressModel } from '../../../core/models/address.model';
import { BingApiService } from '../../../core/services/bing-api.service';
import { OptionalType } from '../../../core/models/types/optional.type';
import { PlacesStore } from '../../../app/services/stores/places.store';

@Component({
    selector: 'arc-address-picker',
    templateUrl: './address-picker.component.html',
    styleUrls: ['./address-picker.component.scss']
})
export class AddressPickerComponent {
    @ViewChild('streetInput', { read: ElementRef }) streetInput!: ElementRef;

    // TODO: change this to either use ControlValueAccessor or to pass a whole FormGroup to the component
    @Input() streetControl!: FormControl;
    @Input() streetNumberControl!: FormControl;
    @Input() zipControl!: FormControl;
    @Input() cityControl!: FormControl;
    @Input() countryIsoCodeControl!: FormControl<OptionalType<string>>;

    private readonly bingApiService = inject(BingApiService);
    private readonly placesStore = inject(PlacesStore);

    searchFn(query: string): Observable<AddressModel[]> {
        if (!!query) {
            return this.bingApiService.getSuggestions(query, this.countryIsoCodeControl.value?.toUpperCase());
        }
        return of([]);
    }

    optionDisplayFn(address: AddressModel): string {
        if (!address) {
            return '';
        }

        let str = address.street;
        if (!!address.streetNumber) {
            str += ` ${address.streetNumber}`;
        }

        str += ', ';

        if (address.zip) {
            str += `${address.zip} `;
        }

        str += `${address.city}`;

        return str;
    }

    onStreetControlBlur(): void {
        // check if street contains a street number
        const street = this.streetControl.value as string;
        if (!street) {
            return;
        }

        const matches = street.match(/^(.+?)(?:\s+([0-9]+[A-z]*))?$/);
        if (matches?.length === 3 && !!matches[2]) {
            this.streetControl.setValue(matches[1]);
            this.streetNumberControl.setValue(matches[2]);
        } else if (matches?.length === 3 && !!matches[1]) {
            this.streetControl.setValue(matches[1]);
        }
    }

    handleAddressSelected(address: OptionalType<AddressModel>): void {
        this.streetNumberControl.setValue(address?.streetNumber ?? '');

        if (!!address) {
            this.zipControl.setValue(address.zip);
            this.countryIsoCodeControl.setValue(address.countryIsoCode);

            this.placesStore.search(address.countryIsoCode, address.zip).subscribe(result => {
                let city = address.city;

                const places = result.value ?? [];
                if (places.length > 0) {
                    const bestMatch = places.find(p => p.city.toLowerCase() === address.city.toLowerCase());
                    city = bestMatch?.city ?? places[0].city;
                }

                this.cityControl.setValue(city);
            });
        }
    }
}
