import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';

import { BaseStore } from '../../../core/abstractions/base.store';
import { StoreConfig } from '../../../core/models/store.config';
import { ApiResponseModel } from '../../models/responses/api-response.model';
import { ImportEntityResponseModel } from '../../models/responses/import-entity-response.model';
import { ImportModel } from '../../models/import.model';
import { ImportExecutionColumnModel } from '../../models/import-execution-column.model';
import { UploadFileRequestModel } from '../../models/requests/upload-file-request.model';
import { ImportExecutionResultResponseModel } from '../../models/responses/import-execution-result-response.model';
import { ExecuteImportRequestModel } from '../../models/requests/execute-import-request.model';
import { ResponseTypeEnum } from '../../../core/models/enums/response-type.enum';

@Injectable({
    providedIn: 'root'
})
export class ImportStore extends BaseStore {
    constructor() {
        super(new StoreConfig({ baseController: 'import' }));
    }

    getImportEntites(): Observable<ApiResponseModel<ImportEntityResponseModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<ImportEntityResponseModel[]>>(this.getUrl('import-entities'));
    }

    getExistingImportByEntityName(entityName: string): Observable<ApiResponseModel<ImportEntityResponseModel>> {
        return this._requestService.makeGet<ApiResponseModel<ImportEntityResponseModel>>(this.getUrl(entityName));
    }

    uploadFile(entityName: string, uploadFileRequest: UploadFileRequestModel): Observable<ApiResponseModel<ImportModel>> {
        return this._requestService.makePost<ApiResponseModel<ImportModel>>(this.getUrl(entityName), uploadFileRequest);
    }

    delete(importEntity: string): Observable<ApiResponseModel<boolean>> {
        return this._requestService.makeDelete<ApiResponseModel<boolean>>(this.getUrl(''), importEntity);
    }

    preview(
        importEntity: string,
        overwriteExistingData: boolean,
        importExecutionColumns: ImportExecutionColumnModel[]
    ): Observable<ApiResponseModel<ImportExecutionResultResponseModel>> {
        return this.callExecute(importEntity, true, overwriteExistingData, importExecutionColumns);
    }

    execute(
        importEntity: string,
        overwriteExistingData: boolean,
        importExecutionColumns: ImportExecutionColumnModel[],
        keysToIgnore: string[] = []
    ): Observable<ApiResponseModel<ImportExecutionResultResponseModel>> {
        return this.callExecute(importEntity, false, overwriteExistingData, importExecutionColumns, keysToIgnore);
    }

    getImportErrorFile(importEntity: string): Observable<HttpResponse<Blob>> {
        return this._requestService.makeGet<HttpResponse<Blob>>(
            this.getUrl(`import-errors/${importEntity}`),
            undefined,
            undefined,
            undefined,
            undefined,
            ResponseTypeEnum.Blob
        );
    }

    private callExecute(
        importEntity: string,
        isTest: boolean,
        overwriteExistingData: boolean,
        importExecutionColumns: ImportExecutionColumnModel[],
        keysToIgnore: string[] = []
    ): Observable<ApiResponseModel<ImportExecutionResultResponseModel>> {
        return this._requestService.makePut<ApiResponseModel<ImportExecutionResultResponseModel>>(
            this.getUrl(`${importEntity}/execute`),
            new ExecuteImportRequestModel({
                isTest,
                overwriteExistingData,
                importExecutionColumns,
                keysToIgnore
            })
        );
    }
}
