import { Injectable, inject } from '@angular/core';
import { formatDate } from '@angular/common';

import { TranslationService } from './translation.service';
import { DateDiffTypeEnum } from '../models/enums/date-diff-type.enum';

@Injectable({
    providedIn: 'root'
})
export class DateService {
    private readonly translationService = inject(TranslationService);

    format(value?: any, format = 'shortDate'): string {
        if (!value) {
            return '';
        }

        const localeCode = this.translationService.current.code;

        if (format === 'shortDate' && localeCode === 'de') {
            format = 'dd.MM.yyyy';
        }

        if (format === 'mediumCustom') {
            // this custom format returns a localized version of GMT+01 or GMT+02
            // For 'de' locale this would be MEZ or MESZ
            // if the zone timezone can't be mapped it fallsback to 'long'
            const zone = this.getZoneString(value.toString(), localeCode);

            if (zone === '') {
                // time was neither GMT+01 or GMT+02
                // or there is no abbreviation found in the mapping
                // return built in format with time zone
                return formatDate(value, 'long', localeCode);
            }

            // there is no built in format displaying medium date and short time
            // we need to build the string
            const date = formatDate(value, 'mediumDate', localeCode);
            const time = formatDate(value, 'shortTime', localeCode);

            return `${date}, ${time} ${zone}`;
        }

        if (format === 'longCustom') {
            // this custom format returns a similar version to the 'long' built-in version
            // but without seconds and with the timezone as CET/CEST
            // if the zone timezone can't be mapped it fallback to 'long'
            const zone = this.getZoneString(value.toString(), localeCode);

            if (zone === '') {
                return formatDate(value, 'long', localeCode);
            }

            const date = formatDate(value, 'fullDate', localeCode);
            const time = formatDate(value, 'shortTime', localeCode);

            return `${date}, ${time} ${zone}`;
        }

        return formatDate(value, format, this.translationService.current.code);
    }

    diff(first: Date, second: Date, type: DateDiffTypeEnum = DateDiffTypeEnum.Days): number {
        let timeFrame = 1000; // Seconds.

        switch (type) {
            case DateDiffTypeEnum.Days:
                timeFrame *= 60 * 60 * 24;
                break;
            case DateDiffTypeEnum.Hours:
                timeFrame *= 60 * 60;
                break;
            case DateDiffTypeEnum.Minutes:
                timeFrame *= 60;
                break;
        }

        return Math.round((second.getTime() - first.getTime()) / timeFrame);
    }

    private getZoneString(dateString: string, localeCode: string): string {
        let zone = '';

        if (dateString.includes('GMT+01')) {
            zone = this.getTimeZoneAbbreviations()[localeCode + '_1'];
        } else if (dateString.includes('GMT+02')) {
            zone = this.getTimeZoneAbbreviations()[localeCode + '_2'];
        }

        return zone;
    }

    // Quick and dirty mapping from the few locales currently implemented
    // MEZ = Mitteleuropäische Zeit
    // CET = Central European Time
    // MESZ = Mitteleuropäische Sommerzeit
    // CEST = Central European Summer Time
    private getTimeZoneAbbreviations(): { [p: string]: string } {
        return {
            de_1: 'MEZ',
            de_2: 'MESZ',
            en_1: 'CET',
            en_2: 'CEST',
            fr_1: 'HNEC',
            fr_2: 'HEEC',
            it_1: 'CET',
            it_2: 'CEST'
        };
    }
}
