<div *ngIf="isLoading; else contentTpl" class="w-full h-full grid place-items-center">
    <mat-spinner [diameter]="32"></mat-spinner>
</div>
<ng-template #contentTpl>
    <div class="mb-10">
        <input class="w-full h-16 border-solid border border-on-app-light rounded-md p-5" type="text"
            [matAutocomplete]="searchAutocomplete" (input)="onSearch($event)"
            placeholder="{{'Help.SearchPlaceholder' | transloco}}" />
        <mat-autocomplete #searchAutocomplete="matAutocomplete" class="!p-0" hideSingleSelectionIndicator="true"
            (optionSelected)="onSearchResultSelected($event)">
            <mat-option *ngFor="let item of searchResult" [value]="item" class="!p-0">
                <div class="flex flex-row p-3 group hover:bg-element-hover hover:cursor-pointer">
                    <div>
                        <arc-icon [icon]="item.helpSubTopicIcon || item.helpTopicIcon || 'help'" [size]="40"
                            class="flex-none"></arc-icon>
                    </div>
                    <div class="flex-1 flex flex-col">
                        <div class="font-bold">
                            {{item.title}}
                        </div>
                        <div>
                            {{item.summary}}
                        </div>
                    </div>
                </div>
            </mat-option>
        </mat-autocomplete>
    </div>

    <ng-container *ngIf="!selectedArticle; else helpArticleViewTpl">
        <ng-container *ngIf="recommendedArticles.length > 0">
            <h2 class="text-accent font-bold">{{'Help.RecommendedArticles' | transloco}}</h2>
            <div class="flex flex-col gap-4 mt-8 mb-10">
                <ng-container *ngFor="let article of recommendedArticles">
                    <ng-container *ngTemplateOutlet="helpArticleListTpl; context: {$implicit: article}"></ng-container>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="otherArticles.length > 0">
            <h2 class="text-accent font-bold">{{'Help.MoreArticles' | transloco}}</h2>
            <div class="flex flex-col gap-4 mt-8 mb-10">
                <ng-container *ngFor="let article of otherArticles">
                    <ng-container *ngTemplateOutlet="helpArticleListTpl; context: {$implicit: article}"></ng-container>
                </ng-container>
            </div>
        </ng-container>
    </ng-container>
</ng-template>

<!-- Template for the articles list -->
<ng-template #helpArticleListTpl let-article>
    <div (click)="selectArticle(article.id)"
        class="border border-on-app-light border-solid rounded-xl p-5 flex flex-row group hover:bg-element-hover hover:cursor-pointer">
        <arc-icon [icon]="article.helpSubTopicIcon || article.helpTopicIcon || 'help'" [size]="40"
            class="flex-none"></arc-icon>
        <div class="ml-5 grow">
            <h3 class="font-bold">{{article.title}}</h3>
            <span>{{article.summary}}</span>
        </div>
        <arc-icon icon="chevron_right" [size]="30"
            class="opacity-0 group-hover:opacity-100 duration-300 text-icon-light"></arc-icon>
    </div>
</ng-template>

<!-- Template to view a single article -->
<ng-template #helpArticleViewTpl>
    <ng-container *ngIf="selectedArticle">
        <ng-container>
            <div>
                <button (click)="backToStart()" mat-button>
                    <mat-icon class="mr-5">arrow_back</mat-icon><span>{{'Help.BackToStart' | transloco}}</span>
                </button>
            </div>
            <div class="border border-solid border-on-app-light rounded-xl p-5 mt-8 mb-8">
                <h2 class="text-3xl font-bold text-accent">{{selectedArticle.title}}</h2>
                <div class="arc-markdown" markdown [innerHTML]="selectedArticle.text">
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="selectedArticle.relatedArticles.length > 0">
            <h2 class="text-accent font-bold">{{'Help.RelatedArticles' | transloco}}</h2>
            <div class="flex flex-col gap-4 mt-8 mb-10">
                <ng-container *ngFor="let article of selectedArticle.relatedArticles">
                    <ng-container *ngTemplateOutlet="helpArticleListTpl; context: {$implicit: article}"></ng-container>
                </ng-container>
            </div>
        </ng-container>
    </ng-container>
</ng-template>