import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewChildren,
    QueryList,
    Output,
    EventEmitter,
    inject
} from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort, Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { Observable, Subject } from 'rxjs';

import { TableListViewConfigModel } from './models/table-list-view-config.model';
import { DictionaryType } from '../../../core/models/types/dictionary.type';
import { BaseColumnModel } from '../../dynamic-table/models/column-types/base-column.model';
import { ColumnSortModel } from '../../../core/models/column-sort.model';
import { SortDirectionEnum } from '../../../core/models/enums/sort-direction.enum';
import { BaseListViewComponent } from '../../../core/abstractions/base-list-view-component';
import { TableSettingsDialogComponent } from './table-settings-dialog/table-settings-dialog.component';
import { TableSettingsDialogModel } from './table-settings-dialog/models/table-settings-dialog.model';
import { TableSettingsModel } from '../../../core/models/table-settings.model';
import { TableListDetailWidgetModel } from './models/table-list-detail-widget.model';
import { Utils } from '../../../core/utils/tools/utils.tools';
import { OptionalType } from '../../../core/models/types/optional.type';
import { FilterService } from '../../../core/services/filter.service';
import { Identifyable } from '../../../core/abstractions/identifyable';

@Component({
    selector: 'arc-table-list-view',
    templateUrl: './table-list-view.component.html',
    styleUrls: ['./table-list-view.component.scss']
})
export class TableListViewComponent<
    T extends Identifyable<TId>,
    TList extends Identifyable<TId>,
    TCreate extends Identifyable<TId> = T,
    TUpdate extends Identifyable<TId> = TCreate,
    TId = number
>
    extends BaseListViewComponent<T, TList, TCreate, TUpdate, TId>
    implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('tableHeader') tableHeader?: ElementRef;
    @ViewChildren('accordion') accordion?: QueryList<ElementRef>;
    @ViewChild(MatSort) matSort!: MatSort;

    @Input() config!: TableListViewConfigModel<TList, T, TCreate, TUpdate, TId>;
    @Output() readonly selectionChanged = new EventEmitter<TList[]>();

    SortDirectionEnum = SortDirectionEnum;

    readonly accordionPaddings = 48;
    readonly columnGap = 4;
    readonly checkboxWidth = 40;
    readonly editButtonWidth = 48;
    readonly panelOpenedSub = new Subject<TId>();

    isInitializing = true;
    isFromPoll = false;
    data: TList[] = [];
    tableSettings = new TableSettingsModel();
    entityModels: DictionaryType<T> = {};
    resizeObserver!: ResizeObserver;
    visibleColumns: BaseColumnModel[] = [];
    selectedItems = new SelectionModel<TList>(true, []);
    openedPanels: DictionaryType<OptionalType<boolean>> = {};
    columnPermissions: DictionaryType<boolean> = {};
    columnPermissionTooltips: DictionaryType<Observable<string>> = {};

    private readonly zone = inject(NgZone);
    private readonly changeDetectorRef = inject(ChangeDetectorRef);
    private readonly filterService = inject(FilterService);

    ngOnInit(): void {
        this.tableSettings = new TableSettingsModel({
            columnOrder: this.config.defaultColumnOrder,
            detailWidgetOrder: this.config.defaultDetailWidgetOrder
        });

        this.applyTableSettings();

        // apply default sort
        if (!!this.config.defaultSort) {
            this.currentSorting = Utils.deepCopy(this.config.defaultSort);
            this._searchRequestService.setSortings([this.currentSorting]);
        }

        if ((this.config.defaultFilters?.length || 0) > 0) {
            const filters = this.config.defaultFilters!;
            this.filterService.initialized$.subscribe(() => {
                let hasPendingFilters = false;

                filters.forEach(f => {
                    const existingFilter = this.filterService.getFilterByName(f.column);

                    if (!existingFilter) {
                        hasPendingFilters = true;
                        this.filterService.addFilter(f);
                    }
                });

                if (hasPendingFilters) {
                    this.filterService.apply();
                }
            });
        }

        this.resizeObserver = new ResizeObserver(this.calculateWidth.bind(this));

        for (const [, column] of Object.entries(this.config.availableColumns)) {
            this.columnPermissions[column.identifier] = true;
        }

        super.onInit();
    }

    ngAfterViewInit(): void {
        this.resizeObserver.observe(this.tableHeader?.nativeElement);
        this.accordion?.changes.subscribe(this.calculateWidth.bind(this));
        if (!!this.currentSorting) {
            this.matSort.active = this.currentSorting.column;
            this.matSort.direction = this.currentSorting.direction === SortDirectionEnum.Desc ? 'desc' : 'asc';
        }

        this.calculateWidth();
    }

    applyTableSettings(): void {
        this._layoutService.getTableSettings(this.config.entityName).subscribe(result => {
            const settings = result ?? new TableSettingsModel({ columnOrder: [], detailWidgetOrder: [] });
            let isColumnOrderInvalid = settings.columnOrder?.some(e => !Object.keys(this.config.availableColumns).includes(e)) ?? false;
            isColumnOrderInvalid ||= (settings.columnOrder?.length ?? 0) === 0;

            if (isColumnOrderInvalid) {
                settings.columnOrder = this.config.defaultColumnOrder;
            }

            let isDetailWidgetOrderInvalid =
                settings.detailWidgetOrder?.some(e => !Object.keys(this.config.availableDetailWidgets).includes(e)) ?? false;
            isDetailWidgetOrderInvalid ||= (settings.detailWidgetOrder?.length ?? 0) === 0;

            if (isDetailWidgetOrderInvalid) {
                settings.detailWidgetOrder = this.config.defaultDetailWidgetOrder;
            }

            if (isColumnOrderInvalid || isDetailWidgetOrderInvalid) {
                this._layoutService.saveTableSettings(this.config.entityName, settings);
            }

            this.tableSettings = settings;
            this.isInitializing = false;

            this.calculateWidth();
        });
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
        this.resizeObserver.unobserve(this.tableHeader?.nativeElement);
    }

    override loadingCallback(): void {
        super.loadingCallback();
        this.entityModels = {};
        // reload open panels
        Object.entries(this.openedPanels).forEach(([id, isOpen]) => {
            if (isOpen) {
                this.loadDetailModel(id);
            }
        });
    }

    override afterLoadedCallback(isFromPoll: boolean): void {
        this.isFromPoll = isFromPoll;
        const openedPanels = Utils.deepCopy(this.openedPanels);
        this.openedPanels = { ...openedPanels };
        if (this.data.length === 1) {
            this.handlePanelOpened(this.data[0]);
        }
    }

    override handlePageEvent(event: PageEvent): void {
        super.handlePageEvent(event);
        // Currently we can't keep the selection over pages, so clear on pagination
        this.selectedItems.clear();
        this.selectionChanged.emit(this.selectedItems.selected);
    }

    sortData(sort: Sort): void {
        if (!!sort.direction) {
            this._searchRequestService.setSortings([
                new ColumnSortModel({
                    column: sort.active,
                    direction: sort.direction === 'asc' ? SortDirectionEnum.Asc : SortDirectionEnum.Desc
                })
            ]);
        } else {
            this._searchRequestService.setSortings([]);
        }
    }

    handlePanelOpened(item: TList): void {
        const id = this.getItemId(item.id);
        this.openedPanels[id] = true;
        this.loadDetailModel(id);
        this.panelOpenedSub.next(item.id);
    }

    loadDetailModel(id: string): void {
        this.config.store.get(id as TId).subscribe(details => {
            if (!!details.value && !this.entityModels[id]) {
                this.entityModels[id] = details.value;
            }
        });
    }

    handlePanelClosed(item: TList): void {
        this.openedPanels[this.getItemId(item.id)] = undefined;
    }

    isEverythingSelected(): boolean {
        const numSelected = this.selectedItems.selected.length;
        const numRows = this.data.length;
        return numSelected === numRows;
    }

    toggleAllRows(): void {
        if (this.isEverythingSelected()) {
            this.selectedItems.clear();
        } else {
            this.selectedItems.select(...this.data);
        }

        this.selectionChanged.emit(this.selectedItems.selected);
    }

    itemSelectionChanged(event: any, item: TList): void {
        if (!!event) {
            this.selectedItems.toggle(item);
            this.selectionChanged.emit(this.selectedItems.selected);
        }
    }

    openTableSettingsDialog(openTab?: number): void {
        const dialogRef = this._matDialog.open(TableSettingsDialogComponent, {
            height: '90%',
            width: '600px',
            disableClose: true,
            data: new TableSettingsDialogModel({
                entityName: this.config.entityName,
                availableColumns: this.config.availableColumns,
                currentColumnOrder: Utils.deepCopy(this.tableSettings.columnOrder),
                defaultColumnOrder: Utils.deepCopy(this.config.defaultColumnOrder),
                availableDetailWidgets: this.config.availableDetailWidgets,
                currentDetailWidgetOrder: Utils.deepCopy(this.tableSettings.detailWidgetOrder),
                defaultDetailWidgetOrder: Utils.deepCopy(this.config.defaultDetailWidgetOrder),
                currentTab: openTab
            })
        });
        dialogRef.afterClosed().subscribe(value => {
            if (!!value && value instanceof TableSettingsModel) {
                this.tableSettings = value;

                this._layoutService.saveTableSettings(this.config.entityName, value);
                this.calculateWidth();
            }
        });
    }

    getDetailWidgetByName(detailWidgetName: string): TableListDetailWidgetModel<TList, T, TId> {
        return this.config.availableDetailWidgets[detailWidgetName];
    }

    shouldDisplayHeader(detailWidgetName: string): boolean {
        return !this.getDetailWidgetByName(detailWidgetName).shouldHideHeader;
    }

    shouldRemovePadding(detailWidgetName: string): boolean {
        return this.getDetailWidgetByName(detailWidgetName).shouldRemovePadding;
    }

    getSubtitleKey(detailWidgetName: string): string {
        return this.getDetailWidgetByName(detailWidgetName).name || '';
    }

    getSizeClasses(detailWidgetName: string): string {
        return Object.keys(this.config.availableDetailWidgets).length === 1 &&
            this.getDetailWidgetByName(detailWidgetName).takeFullSizeIfOnly
            ? 'w-full h-full'
            : 'w-[450px] h-[300px]';
    }

    override refresh(): void {
        this.selectedItems.clear();
        this.selectionChanged.emit(this.selectedItems.selected);
        super.refresh();
    }

    private calculateWidth(): void {
        this.zone.run(() => {
            const accordionWidth = this.accordion?.first?.nativeElement?.clientWidth;
            if (!accordionWidth) {
                return;
            }

            const availableWidth = accordionWidth - this.accordionPaddings;

            this.visibleColumns = [];
            let totalWidth = this.editButtonWidth;

            if (this.config.showCheckboxes) {
                totalWidth += this.checkboxWidth;
            }

            for (const columnName of this.tableSettings?.columnOrder ?? []) {
                const column = this.config.availableColumns[columnName];
                totalWidth += column.widthPixels + this.columnGap;

                // always show min. 1 column on very small devices
                if (this.visibleColumns.length > 0 && totalWidth >= availableWidth) {
                    return;
                }

                this.visibleColumns.push(column);
                this.changeDetectorRef.detectChanges();
            }
        });
    }
}
