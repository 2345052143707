import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Observable, map, catchError, of } from 'rxjs';

import { AuthStore } from './stores/auth.store';
import { SecurityStorage } from './storages/security.storage';
import { SettingsStorage } from './storages/settings.storage';
import { AuthRequestModel } from '../../app/models/requests/auth-request.model';
import { AuthenticatedUserModel } from '../../app/models/authenticated-user.model';
import { UserRoles } from '../../app/models/enums/user-roles.enum';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    readonly onLoggedIn = new Subject();

    private readonly router = inject(Router);
    private readonly securityStorage = inject(SecurityStorage);
    private readonly settingsStorage = inject(SettingsStorage);
    private readonly authStore = inject(AuthStore);

    login(data: AuthRequestModel): Observable<void> {
        return this.authStore.login(data).pipe(
            map(response => {
                if (!response.value) {
                    return;
                }
                this.settingsStorage.saveLastLoginTimeStamp(Date.now());
                this.loadInitialData(response.value!);
                this.redirectFromLogin();
            })
        );
    }

    logout(): void {
        this.securityStorage.eraseSecurityData();
        this.router.navigate(['login']).then();
    }

    goToLoginPage(): void {
        this.logout();
    }

    isLoggedIn(): Observable<boolean> {
        return this.authStore.authCheck().pipe(
            map(() => true),
            catchError(() => of(false))
        );
    }

    hasRole(requiredRole: UserRoles): boolean {
        const user = this.securityStorage.getUserInfo();
        if (!user) {
            return false;
        }

        return user.roles === UserRoles.Admin || (user.roles & requiredRole) !== 0;
    }
    redirectFromLogin(): void {
        let redirectRoute = this.settingsStorage.getNotLoggedRedirectUrl() || 'home';
        redirectRoute = redirectRoute?.endsWith('login') ? 'home' : redirectRoute;

        if (redirectRoute !== 'home') {
            this.settingsStorage.saveNotLoggedRedirectUrl(undefined);
        }

        if (redirectRoute.includes('http')) {
            redirectRoute = decodeURI(redirectRoute);
            window.location.href = redirectRoute;

            return;
        }

        this.router.navigate([redirectRoute]).then(() => {
            window.location.reload();
        });
    }

    private loadInitialData(authData: AuthenticatedUserModel): void {
        this.securityStorage.saveUserInfo(authData);
        this.onLoggedIn.next(undefined); // Fire logged in event.
    }
}
