<form [formGroup]="formGroup" class="flex flex-col gap-4">
    <div class="flex items-center gap-2">
        <p class="min-w-[80px]">{{'License.Edit.Payment.Period' | transloco}}</p>
        <arc-button-toggle-group [control]="formGroup.controls.paymentPeriod" [items]="paymentPeriods"></arc-button-toggle-group>
    </div>
    <div class="flex items-center gap-2">
        <p class="min-w-[80px]">{{'License.Edit.Payment.Type' | transloco}}</p>
        <arc-button-toggle-group [control]="formGroup.controls.paymentType" [items]="paymentTypes"></arc-button-toggle-group>
    </div>
    <arc-checkbox labelKey="License.Edit.Payment.Demo" [control]="formGroup.controls.isDemo"></arc-checkbox>
    <arc-input labelKey="License.Edit.Payment.BenefitCode" [control]="formGroup.controls.benefitCode"></arc-input>
</form>
