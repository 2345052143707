import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { RightSidebarConfigModel } from '../models/right-sidebar-config.model';
import { OptionalType } from '../models/types/optional.type';
import { SidebarHeaderService } from './sidebar-header.service';
import { EditSidebarDataModel } from '../../components/sidebar-components/edit-sidebar/models/edit-sidebar-data.model';
import { EditSidebarComponent } from '../../components/sidebar-components/edit-sidebar/edit-sidebar.component';
import { TranslationService } from './translation.service';
import { Identifyable } from '../abstractions/identifyable';

@Injectable({
    providedIn: 'root'
})
export class SidebarService {
    isLeftOpen = false;
    leftOpenSubject = new BehaviorSubject<boolean>(this.isLeftOpen);
    isLeftDisabled = false;

    isRightOpen = false;
    rightOpenSubject = new BehaviorSubject<boolean>(this.isRightOpen);
    rightConfig?: RightSidebarConfigModel;

    private rightCloseSubject?: Subject<any>;

    private readonly translationService = inject(TranslationService);
    private readonly headerService = inject(SidebarHeaderService);

    toggleLeft(): OptionalType<boolean> {
        if (!this.isLeftDisabled) {
            this.isLeftOpen = !this.isLeftOpen;
            this.updateLeft();

            return this.isLeftOpen;
        }

        return undefined;
    }

    openLeft(): void {
        if (!this.isLeftDisabled) {
            this.isLeftOpen = true;
            this.updateLeft();
        }
    }

    closeLeft(): void {
        if (!this.isLeftDisabled) {
            this.isLeftOpen = false;
            this.updateLeft();
        }
    }

    openRight<TReturnType>(config: RightSidebarConfigModel): Observable<OptionalType<TReturnType>> {
        this.isRightOpen = true;
        this.rightConfig = config;
        this.updateRight();
        this.rightCloseSubject = new Subject<OptionalType<TReturnType>>();
        return this.rightCloseSubject.asObservable();
    }

    openEdit<
        TList extends Identifyable<TId>,
        T extends Identifyable<TId>,
        TCreate extends Identifyable<TId> = T,
        TUpdate extends Identifyable<TId> = TCreate,
        TId = number
    >(config: EditSidebarDataModel<TList, T, TCreate, TUpdate, TId>, maxWidth = '800px'): Observable<OptionalType<boolean>> {
        return this.openRight<boolean>({
            component: EditSidebarComponent,
            data: config,
            shouldCloseOnBackdropClick: false,
            width: 'full',
            maxWidth
        });
    }

    closeRight<TReturnType>(data?: TReturnType): void {
        this.isRightOpen = false;
        this.updateRight();
        this.rightConfig = undefined;
        this.rightCloseSubject?.next(data);
        this.rightCloseSubject?.complete();
        this.rightCloseSubject = undefined;
        this.headerService.reset();
    }

    getDefaultRightSidebarHeaderTitleFn<TId = number>(entityName: string): (currentItem: Identifyable<TId>) => Observable<string> {
        return currentItem => {
            if (!!currentItem?.id) {
                return this.translationService.getTextObservable(`${entityName}.EntityName`);
            }

            return this.translationService.getTextObservable(`${entityName}.NewEntity`);
        };
    }

    private updateLeft(): void {
        this.leftOpenSubject.next(this.isLeftOpen);
    }

    private updateRight(): void {
        this.rightOpenSubject.next(this.isRightOpen);
    }
}
