import { Pipe, PipeTransform, inject } from '@angular/core';

import { DateService } from '../services/date.service';

@Pipe({ name: 'arcDate' })
export class DatePipe implements PipeTransform {
    private readonly dateService = inject(DateService);

    transform(value?: Date, format?: string): string {
        return this.dateService.format(value, format);
    }
}
