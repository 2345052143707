import { Component } from '@angular/core';

import { BaseCustomColumnComponent } from '../../../../../../components/dynamic-table/column-components/custom-column/base-custom-column.component';
import { BenefitListModel } from '../../../../../models/responses/benefit-list.model';
import { PaymentTypesEnum } from '../../../../../models/enums/payment-types.enum';

@Component({
    selector: 'arc-benefit-payment-type',
    templateUrl: './benefit-payment-type.component.html',
    styleUrl: './benefit-payment-type.component.scss'
})
export class BenefitPaymentTypeComponent extends BaseCustomColumnComponent<BenefitListModel> {
    PaymentTypesEnum = PaymentTypesEnum;
}
