import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { StoreConfig } from '../../../core/models/store.config';
import { HelpTopicListModel } from '../../models/responses/help-topic-list.model';
import { HelpTopicModel } from '../../models/help-topic.model';
import { HelpTopicEditModel } from '../../models/requests/help-topic-edit.model';
import { ApiResponseModel } from '../../models/responses/api-response.model';

@Injectable({
    providedIn: 'root'
})
export class HelpTopicsStore extends BaseCrudStore<HelpTopicModel, HelpTopicListModel, HelpTopicEditModel, HelpTopicEditModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'help-topics' }));
    }

    getHelpTopics(): Observable<ApiResponseModel<HelpTopicListModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<HelpTopicListModel[]>>(this.getUrl(''));
    }
}
