import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseStore } from '../../../core/abstractions/base.store';
import { StoreConfig } from '../../../core/models/store.config';
import { ApiResponseModel } from '../../models/responses/api-response.model';
import { PlaceModel } from '../../models/place.model';

@Injectable({
    providedIn: 'root'
})
export class PlacesStore extends BaseStore {
    constructor() {
        super(new StoreConfig({ baseController: 'places' }));
    }

    search(countryIsoCode: string, zip?: string, city?: string): Observable<ApiResponseModel<PlaceModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<PlaceModel[]>>(
            this.getUrl('search'),
            undefined,
            this.isAbsoluteUrl,
            undefined,
            undefined,
            undefined,
            { key: 'countryIsoCode', value: countryIsoCode },
            { key: 'zip', value: zip ?? '' },
            { key: 'city', value: city ?? '' }
        );
    }
}
