import { Injectable, inject } from '@angular/core';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { ContentTypeEnum } from '../models/enums/content-type.enum';
import { KeyValueModel } from '../models/key-value.model';
import { EnvironmentService } from './environment.service';
import { ResponseTypeEnum } from '../models/enums/response-type.enum';
import { Tools } from '../utils/tools/index';

/**
 * Manages server-side communication.
 */
@Injectable({
    providedIn: 'root'
})
export class RequestService {
    private readonly http = inject(HttpClient);
    private readonly environmentService = inject(EnvironmentService);

    /**
     * Creates an HTTP request of type GET.
     * @param url URL
     * @param [id] Record's ID, if any.
     * @param [isAbsolute] Whether the URL is absolute (full URL).
     * @param contentType
     * @param responseType
     * @param contentType
     * @param responseType
     * @param [params] URL parameters, if any.
     * @param loadRelatedData Whether related data should be requested.
     */
    makeGet<T>(
        url: string,
        id?: string,
        isAbsolute = false,
        contentType = ContentTypeEnum.Empty,
        loadRelatedData = false,
        responseType?: ResponseTypeEnum,
        ...params: KeyValueModel[]
    ): Observable<T> {
        return this.makeRequest<T>(
            'get',
            isAbsolute ? url : Tools.Url.getUrl(url, this.environmentService.baseUrl, id),
            undefined,
            contentType,
            Tools.Url.getParams(params),
            undefined,
            loadRelatedData,
            responseType
        );
    }

    /**
     * Creates an HTTP request of type POST.
     * @param url URL
     * @param [data] Object to be sent in body.
     * @param [isAbsolute] Whether the URL is absolute (full URL).
     * @param [isFileAttached] Whether there's a file attached as response.
     * @param loadRelatedData Whether related data should be requested.
     * @param responseType
     * @param params
     * @param responseType
     * @param params
     */
    makePost<T>(
        url: string,
        data?: any,
        isAbsolute = false,
        isFileAttached = false,
        loadRelatedData = false,
        responseType?: ResponseTypeEnum,
        ...params: KeyValueModel[]
    ): Observable<T> {
        return this.makeRequest<T>(
            'post',
            isAbsolute ? url : Tools.Url.getUrl(url, this.environmentService.baseUrl),
            data,
            undefined,
            Tools.Url.getParams(params),
            isFileAttached,
            loadRelatedData,
            responseType
        );
    }

    /**
     * Creates an HTTP request of type PUT.
     * @param url URL
     * @param [data] Object to be sent in body.
     * @param [isAbsolute] Whether the URL is absolute (full URL).
     * @param loadRelatedData Whether related data should be requested.
     * @param responseType
     */
    makePut<T>(url: string, data?: any, isAbsolute = false, loadRelatedData = false, responseType?: ResponseTypeEnum): Observable<T> {
        return this.makeRequest<T>(
            'put',
            isAbsolute ? url : Tools.Url.getUrl(url, this.environmentService.baseUrl),
            data,
            undefined,
            undefined,
            undefined,
            loadRelatedData,
            responseType
        );
    }

    /**
     * Creates an HTTP request of type DELETE.
     * @param url URL
     * @param id ID of record to be removed.
     * @param [isAbsolute] Whether the URL is absolute (full URL).
     * @param loadRelatedData Whether related data should be requested.
     * @param responseType
     * @param data
     */
    makeDelete<T>(
        url: string,
        id?: string,
        isAbsolute = false,
        loadRelatedData = false,
        responseType?: ResponseTypeEnum,
        data?: any
    ): Observable<T> {
        return this.makeRequest<T>(
            'delete',
            isAbsolute ? url : Tools.Url.getUrl(url, this.environmentService.baseUrl, id),
            data,
            undefined,
            undefined,
            undefined,
            loadRelatedData,
            responseType
        );
    }

    /**
     * Creates an HTTP request of type DELETE.
     * @param url URL
     * @param keys Keys of the items to be removed.
     * @param [isAbsolute] Whether the URL is absolute (full URL).
     * @param loadRelatedData Whether related data should be requested.
     * @param responseType
     */
    makeDeleteMany<T>(
        url: string,
        keys: KeyValueModel[],
        isAbsolute = false,
        loadRelatedData = false,
        responseType?: ResponseTypeEnum
    ): Observable<T> {
        return this.makeRequest<T>(
            'delete',
            isAbsolute ? url : Tools.Url.getUrl(url, this.environmentService.baseUrl),
            undefined,
            undefined,
            Tools.Url.getParams(keys),
            undefined,
            loadRelatedData,
            responseType
        );
    }

    /**
     * Creates an HTTP request of type POST with file.
     * @param url URL
     * @param [data] File to be sent.
     * @param [isAbsolute] Whether the URL is absolute (full URL).
     * @param loadRelatedData Whether related data should be requested.
     * @param responseType
     */
    makeFilePost<T>(url: string, data: any, isAbsolute = false, loadRelatedData = false, responseType?: ResponseTypeEnum): Observable<T> {
        return this.makeRequest<T>(
            'post',
            isAbsolute ? url : Tools.Url.getUrl(url, this.environmentService.baseUrl),
            data,
            ContentTypeEnum.Empty,
            undefined,
            undefined,
            loadRelatedData,
            responseType
        );
    }

    /**
     * Creates an HTTP request.
     * @param type Request type.
     * @param url URL
     * @param [data] Request's body.
     * @param [contentType] Content's type.
     * @param [httpParams] URL parameters.
     * @param isFileAttached
     * @param loadRelatedData Whether related data should be requested.
     * @param responseType
     */
    private makeRequest<T>(
        type: string,
        url: string,
        data?: any,
        contentType: ContentTypeEnum = ContentTypeEnum.ApplicationJson,
        httpParams: HttpParams = new HttpParams(),
        isFileAttached = false,
        loadRelatedData = false,
        responseType?: ResponseTypeEnum
    ): Observable<T> {
        let request: Observable<T> = of();
        const headerBody = {};

        if (contentType !== ContentTypeEnum.Empty) {
            // @ts-ignore
            headerBody['Content-Type'] = contentType;
        }

        if (loadRelatedData) {
            // eslint-disable-next-line dot-notation, @typescript-eslint/dot-notation
            // @ts-ignore
            headerBody['related'] = 'true';
        }

        const headers = headerBody ? new HttpHeaders(headerBody) : new HttpHeaders();
        const httpOptions = { headers, params: httpParams, responseType: undefined, body: data };

        if (isFileAttached) {
            // @ts-ignore
            httpOptions.responseType = 'blob';
        }

        if (!!responseType) {
            // @ts-ignore
            httpOptions.responseType = responseType;
        }

        // eslint-disable-next-line eqeqeq
        if (httpOptions.responseType === ResponseTypeEnum.Blob) {
            (httpOptions as any)['observe'] = 'response';
        }

        const body = data && contentType !== ContentTypeEnum.Empty ? JSON.stringify(data) : data ?? '';

        switch (type) {
            case 'get':
                request = this.http.get<T>(url, httpOptions);
                break;
            case 'post':
                request = this.http.post<T>(url, body, httpOptions);
                break;
            case 'put':
                request = this.http.put<T>(url, body, httpOptions);
                break;
            case 'delete':
                request = this.http.delete<T>(url, httpOptions);
                break;
        }

        return request;
    }
}
