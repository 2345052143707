<div class="grid grid-cols-2 gap-4">
    <arc-quick-search class="col-span-2" [config]="ComplexDataTypesEnum.License | arcQuickSearchConfig"
        [formControl]="formGroup.controls.licenseId" label="LicenseHardwareOrders.Edit.BaseData.License"></arc-quick-search>
    <arc-select class="col-span-2" labelKey="LicenseHardwareOrders.Edit.BaseData.HardwareBundle" [options]="licenseHardwareBundles()"
            [control]="formGroup.controls.hardwareBundleId"></arc-select>
    <arc-input [control]="formGroup.controls.serialNumber" labelKey="LicenseHardwareOrders.Edit.BaseData.SerialNumber">
    </arc-input>
    <arc-input [control]="formGroup.controls.trackingNumber" labelKey="LicenseHardwareOrders.Edit.BaseData.TrackingNumber">
    </arc-input>
    <arc-number-input [formControl]="formGroup.controls.quantity" label="LicenseHardwareOrders.Edit.BaseData.Quantity">
    </arc-number-input>
    <arc-number-input [formControl]="formGroup.controls.price" label="LicenseHardwareOrders.Edit.BaseData.Price">
    </arc-number-input>
    <arc-datepicker [control]="formGroup.controls.orderedDate" labelKey="LicenseHardwareOrders.Edit.BaseData.OrderedDate">
    </arc-datepicker>
    <arc-datepicker [control]="formGroup.controls.sentDate" labelKey="LicenseHardwareOrders.Edit.BaseData.SentDate">
    </arc-datepicker>
    <arc-select [options]="hardwareOrderOptions" [control]="formGroup.controls.orderState"
        labelKey="LicenseHardwareOrders.Edit.BaseData.OrderState">
    </arc-select>
    <arc-quick-search [config]=" ComplexDataTypesEnum.User | arcQuickSearchConfig" [formControl]="formGroup.controls.sentUserId"
        label="LicenseHardwareOrders.Edit.BaseData.UserSent"></arc-quick-search>
    <arc-input class="col-span-2" [control]="formGroup.controls.deliveryAddressName" labelKey="LicenseHardwareOrders.Edit.BaseData.DeliveryAddressName">
    </arc-input>
    <arc-address-picker class="col-span-2" [streetControl]="formGroup.controls.street"
        [streetNumberControl]="formGroup.controls.streetNumber" [zipControl]="formGroup.controls.zip"
        [cityControl]="formGroup.controls.city" [countryIsoCodeControl]="formGroup.controls.countryIsoCode">
    </arc-address-picker>
</div>