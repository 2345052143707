import { MatDialog } from '@angular/material/dialog';
import { Component, inject, OnInit, signal } from '@angular/core';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { LicenseModel } from '../../../../../models/license.model';
import { LicenseListModel } from '../../../../../models/responses/license-list.model';
import { TransactionListModel } from '../../../../../models/responses/transaction-list.model';
import { StaticBasicTableListViewConfigModel } from '../../../../../../components/list-views/static-basic-table-list-view/models/static-table-list-view-config.model';
import { TransactionsStore } from '../../../../../services/stores/transactions.store';
import { DateColumnModel } from '../../../../../../components/dynamic-table/models/column-types/date-column.model';
import { StringColumnModel } from '../../../../../../components/dynamic-table/models/column-types/string-column.model';
import { TransactionTypeEnum } from '../../../../../models/enums/transaction-type.enum';
import { CurrencyColumnModel } from '../../../../../../components/dynamic-table/models/column-types/currency-column.model';
import { LicenseNewTransactionDialogComponent } from '../../components/license-new-transaction-dialog/license-new-transaction-dialog.component';

@Component({
    selector: 'arc-license-transactions-widget',
    templateUrl: './license-transactions-widget.component.html',
    styleUrl: './license-transactions-widget.component.scss'
})
export class LicenseTransactionsWidgetComponent extends BaseDetailWidget<LicenseListModel, LicenseModel> implements OnInit {
    tableConfig?: StaticBasicTableListViewConfigModel<TransactionListModel>;
    isLoading = signal(false);
    items = signal<TransactionListModel[]>([]);

    private readonly transactionsStore = inject(TransactionsStore);
    private readonly matDialog = inject(MatDialog);

    ngOnInit(): void {
        this.setupTransactions();
    }
    setupTransactions(): void {
        this.isLoading.set(true);
        this.items.set([]);
        this.transactionsStore.getTransactionsByLicenseId(this.entityModel.id).subscribe(response => {
            this.items.set(response?.value?.sort((a, b) => new Date(b.valueDate).getTime() - new Date(a.valueDate).getTime()) || []);
            this.setupTable();
            this.isLoading.set(false);
        });
    }
    setupTable(): void {
        this.tableConfig = new StaticBasicTableListViewConfigModel<TransactionListModel>({
            data: this.items(),
            defaultPageSize: 4,
            availableColumns: {
                createdDate: new DateColumnModel({
                    columnTitleKey: 'License.Details.TransactionsWidget.ValueDate',
                    propertyName: 'valueDate',
                    format: 'short',
                    widthPixels: 145
                }),
                createdByEmail: new StringColumnModel({
                    columnTitleKey: 'License.Details.TransactionsWidget.Type',
                    propertyName: 'type',
                    customFormatter: (record, value) => TransactionTypeEnum[value],
                    widthPixels: 100
                }),
                price: new CurrencyColumnModel({
                    columnTitleKey: 'License.Details.TransactionsWidget.Amount',
                    propertyName: 'amount',
                    currencyIsoCode: item => item.currencyIsoCode,
                    widthPixels: 80
                })
            }
        });
    }

    addLicenseTransaction(): void {
        this.matDialog.open(LicenseNewTransactionDialogComponent, {
            width: '600px',
            maxHeight: '98svh',
            maxWidth: '98vw',
            data: {
                licenseId: this.entityModel.id
            }
        });
    }
}
