<div class='bg-app text-on-app flex h-[100svh] w-full overflow-hidden flex-col md:flex-row p-4 md:p-0'>
    <div class="h-full w-1/2 grow-1 shrink-0 basis-1/2 hidden md:block">
        <div class='flex items-center justify-center relative w-full h-full'>
            <ng-container>
                <div class="w-3/4 h-3/4 max-w-[360px] max-h-[360px] pointer-events-none transition-all duration-[500ms] absolute opacity-0 mix-blend-multiply" [class.opacity-100]='didViewLoad'>
                    <img class='w-full h-full object-contain' [src]="baseUrl + '/auth/logo'" alt="">
                </div>
            </ng-container>
        </div>
    </div>
    <div class="h-full w-full grow-1 shrink-0 basis-[100%] p-12 md:basis-1/2 flex flex-col items-center justify-between bg-element rounded-[2rem] md:w-1/2 md:rounded-l-[3rem] md:rounded-r-0">
        <div class="transition-opacity self-start" [class.opacity-0]='!didViewLoad'>
            <svg class='h-full w-auto' width="168" height="30" viewBox="0 0 168 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M167.408 23.0825C167.305 15.8819 156.85 18.0629 156.85 14.9818C156.85 14.0471 157.611 13.4932 158.996 13.4932C160.289 13.4932 161.183 14.0168 161.598 14.8684C161.81 15.3022 162.196 15.6742 162.678 15.6742H166.242C166.811 15.6742 167.261 15.1805 167.133 14.6261C166.382 11.3685 163.684 9.09668 159.169 9.09668C154.115 9.09668 151.311 11.7969 151.311 15.0857C151.311 22.1824 161.835 19.9322 161.835 23.0479C161.835 23.9134 161.039 24.6057 159.515 24.6057C158.25 24.6057 157.235 24.061 156.782 23.1834C156.556 22.7453 156.163 22.3555 155.67 22.3555H151.723C151.164 22.3555 150.716 22.8342 150.847 23.3777C151.625 26.6054 154.908 28.9677 159.585 28.9677C164.431 28.9677 167.408 26.4405 167.408 23.0825Z"
                    fill="#004346" />
                <path
                    d="M141.515 27.7294C141.515 28.2605 141.945 28.691 142.476 28.691H146.473C147.004 28.691 147.435 28.2605 147.435 27.7294V10.3355C147.435 9.80444 147.004 9.37391 146.473 9.37391H142.476C141.945 9.37391 141.515 9.80444 141.515 10.3355V27.7294ZM144.492 6.40441C146.569 6.40441 147.988 4.98506 147.988 3.21952C147.988 1.41936 146.569 0 144.492 0C142.38 0 140.961 1.41936 140.961 3.21952C140.961 4.98506 142.38 6.40441 144.492 6.40441Z"
                    fill="#004346" />
                <path
                    d="M119.373 9.37402C118.708 9.37402 118.244 10.0313 118.466 10.6572L124.638 28.0511C124.774 28.4347 125.137 28.6911 125.545 28.6911H131.491C131.898 28.6911 132.261 28.4347 132.397 28.0511L138.569 10.6572C138.791 10.0313 138.327 9.37402 137.663 9.37402H133.437C133.013 9.37402 132.64 9.65089 132.517 10.0559L128.811 22.2443C128.728 22.517 128.342 22.517 128.259 22.2443L124.553 10.0559C124.43 9.65089 124.057 9.37402 123.633 9.37402H119.373Z"
                    fill="#004346" />
                <path
                    d="M95.1719 18.9976C95.1719 25.0904 98.9453 28.9677 103.653 28.9677C106.06 28.9677 107.883 28.0452 109.043 26.749C109.235 26.5336 109.642 26.6624 109.642 26.9514V27.7291C109.642 28.2602 110.073 28.6907 110.604 28.6907H114.601C115.132 28.6907 115.562 28.2602 115.562 27.7291V10.3353C115.562 9.80416 115.132 9.37363 114.601 9.37363H110.604C110.073 9.37363 109.642 9.80416 109.642 10.3353V11.0848C109.642 11.376 109.226 11.5055 109.033 11.288C107.892 10.0062 106.077 9.09668 103.688 9.09668C98.9453 9.09668 95.1719 12.9047 95.1719 18.9976ZM109.642 19.0322C109.642 22.1132 107.669 23.8095 105.419 23.8095C103.203 23.8095 101.195 22.0786 101.195 18.9976C101.195 15.9165 103.203 14.2548 105.419 14.2548C107.669 14.2548 109.642 15.9511 109.642 19.0322Z"
                    fill="#004346" />
                <path
                    d="M74.2109 19.0322C74.2109 25.1596 78.2267 28.9677 83.9041 28.9677C88.3176 28.9677 91.5541 26.6423 92.8554 22.8542C93.0556 22.2715 92.5962 21.6978 91.98 21.6978H87.5534C87.1498 21.6978 86.7997 21.956 86.6091 22.3118C86.0559 23.3447 85.1753 23.9134 83.8002 23.9134C81.6885 23.9134 80.2345 22.2517 80.2345 19.0322C80.2345 15.8127 81.6885 14.151 83.8002 14.151C85.1632 14.151 86.0646 14.7583 86.6063 15.7491C86.8009 16.1052 87.152 16.3666 87.5578 16.3666H91.9879C92.6016 16.3666 93.0607 15.7971 92.8672 15.2147C91.5738 11.3207 88.3311 9.09668 83.9041 9.09668C78.2267 9.09668 74.2109 12.9393 74.2109 19.0322Z"
                    fill="#004346" />
                <path
                    d="M66.5018 19.7244C66.5018 16.401 68.1289 15.4317 70.9329 15.4317H71.5984C72.1295 15.4317 72.56 15.0012 72.56 14.4701V10.1274C72.56 9.59633 72.1274 9.15847 71.6012 9.23043C69.7679 9.48115 68.2209 10.4444 67.0735 11.8228C66.8905 12.0426 66.5018 11.9194 66.5018 11.6334V10.3351C66.5018 9.80404 66.0713 9.3735 65.5402 9.3735H61.5437C61.0126 9.3735 60.582 9.80404 60.582 10.3351V27.729C60.582 28.2601 61.0126 28.6906 61.5437 28.6906H65.5402C66.0712 28.6906 66.5018 28.2601 66.5018 27.729V19.7244Z"
                    fill="#004346" />
                <path
                    d="M35.9082 18.9976C35.9082 25.0904 39.6816 28.9677 44.3897 28.9677C46.7959 28.9677 48.6194 28.0452 49.779 26.749C49.9717 26.5336 50.3787 26.6624 50.3787 26.9514V27.7291C50.3787 28.2602 50.8092 28.6907 51.3403 28.6907H55.3368C55.8679 28.6907 56.2985 28.2602 56.2985 27.7291V10.3353C56.2985 9.80416 55.8679 9.37363 55.3368 9.37363H51.3403C50.8092 9.37363 50.3787 9.80416 50.3787 10.3353V11.0848C50.3787 11.376 49.9627 11.5055 49.7691 11.288C48.6283 10.0062 46.8135 9.09668 44.4243 9.09668C39.6816 9.09668 35.9082 12.9047 35.9082 18.9976ZM50.3787 19.0322C50.3787 22.1132 48.4055 23.8095 46.1553 23.8095C43.9397 23.8095 41.9318 22.0786 41.9318 18.9976C41.9318 15.9165 43.9397 14.2548 46.1553 14.2548C48.4055 14.2548 50.3787 15.9511 50.3787 19.0322Z"
                    fill="#004346" />
                <path
                    d="M0.796352 12.7676C0.356945 12.3282 0.356945 11.6157 0.796351 11.1763L3.50544 8.46725C3.94485 8.02784 4.65727 8.02784 5.09668 8.46725L9.15698 12.5276C9.33729 12.7079 9.4386 12.9524 9.4386 13.2074V16.5981C9.4386 16.8805 9.20962 17.1095 8.92716 17.1095L5.53654 17.1095C5.28154 17.1095 5.03697 17.0082 4.85665 16.8279L0.796352 12.7676Z"
                    fill="#004346" />
                <path
                    d="M17.2314 8.46725C17.6709 8.02784 18.3833 8.02784 18.8227 8.46725L21.5318 11.1763C21.9712 11.6157 21.9712 12.3282 21.5318 12.7676L17.4715 16.8279C17.2912 17.0082 17.0466 17.1095 16.7916 17.1095L13.401 17.1095C13.1185 17.1095 12.8895 16.8805 12.8895 16.5981L12.8895 13.2074C12.8895 12.9524 12.9908 12.7079 13.1711 12.5276L17.2314 8.46725Z"
                    fill="#004346" />
                <path
                    d="M21.5318 24.9043C21.9712 25.3437 21.9712 26.0561 21.5318 26.4955L18.8227 29.2046C18.3833 29.644 17.6709 29.644 17.2314 29.2046L13.1711 25.1443C12.9908 24.964 12.8895 24.7194 12.8895 24.4644L12.8895 21.0738C12.8895 20.7914 13.1185 20.5624 13.401 20.5624L16.7916 20.5624C17.0466 20.5624 17.2912 20.6637 17.4715 20.844L21.5318 24.9043Z"
                    fill="#004346" />
                <path
                    d="M5.09668 29.2046C4.65727 29.644 3.94485 29.644 3.50544 29.2046L0.796352 26.4955C0.356946 26.0561 0.356945 25.3437 0.796351 24.9043L4.85665 20.844C5.03697 20.6637 5.28154 20.5624 5.53654 20.5624L8.92716 20.5624C9.20962 20.5624 9.4386 20.7914 9.4386 21.0738L9.4386 24.4644C9.4386 24.7194 9.3373 24.964 9.15698 25.1443L5.09668 29.2046Z"
                    fill="#004346" />
            </svg>

        </div>
        <div class='transition-opacity' [class.opacity-0]='!didViewLoad'>
            <router-outlet></router-outlet>
        </div>
        <div class="self-end text-on-app transition-all duration-300" [class.opacity-0]='!didViewLoad'>
        </div>
    </div>
</div>
