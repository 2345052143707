import { debounceTime, switchMap, skip, takeUntil } from 'rxjs/operators';

export const autocomplete = (time: number, selector: any) => (source: any): any =>
    source.pipe(
        debounceTime(time),
        switchMap((...args: any[]) =>
            selector(...args)
                .pipe(
                    takeUntil(source.pipe(skip(1)))
                )
        )
    );
