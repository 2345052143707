import { HttpParams } from '@angular/common/http';

import { KeyValueModel } from '../../models/key-value.model';

/**
 * URL tools.
 */
export class Url {
    /**
     * Generates a URL.
     * @param url URL's Controller/Action.
     * @param baseUrl URL's base.
     * @param [id] Record's ID.
     */
    static getUrl(url: string, baseUrl: string, id?: string): string {
        const currentUrl = Url.trimTrailingSlash(baseUrl) + `/${Url.trimLeadingAndTrailingSlash(url)}`;
        return id ? `${currentUrl}/${id}` : currentUrl;
    }

    /**
     * Generates a HttpParams based on provided values.
     * @param params URL parameters.
     */
    static getParams(params: KeyValueModel[]): HttpParams {
        let httpParams = new HttpParams();

        for (const param of params) {
            if (httpParams.has(param.key!)) {
                httpParams = httpParams.append(param.key!, param.value!);
            } else {
                httpParams = httpParams.set(param.key!, param.value!);
            }
        }

        return httpParams;
    }

    /**
     * Transforms an object into an array of UrlParameterModel.
     * @param obj Object to be transformed
     * @param prefix Prefix of the key
     */
    static transformToUrlParameterModel(obj: any, onlyWithValue = false, prefix?: string): KeyValueModel[] {
        const params: KeyValueModel[] = [];

        Object.keys(obj).forEach(k => {
            let value = obj[k];
            if (!onlyWithValue || !!value) {
                if (typeof(value) === 'object') {
                    value = JSON.stringify(value);
                }
                params.push(new KeyValueModel({ key: `${(prefix ? prefix : '')}${k}`, value: encodeURIComponent(value) }));
            }
        });

        return params;
    }

    /**
     * Removes the last (trailing) slash (/) from URL, if any.
     * @param url URL.
     */
    static trimTrailingSlash(url: string): string {
        return url.replace(new RegExp('/$'), '');
    }

    /**
     * Removes the first (leading) and last (trailing) slash (/) from URL, if any.
     * @param url URL.
     */
    static trimLeadingAndTrailingSlash(url: string): string {
        return url.replace(new RegExp('^/|/$', 'g'), '');
    }
}
