import { ComponentType } from '@angular/cdk/portal';

import { BaseCardComponent } from '../../../abstractions/base-card.component';
import { PaginatorConfigModel } from '../../../../core/models/paginator-config.model';
import { BaseListViewConfigModel } from '../../../../core/abstractions/base-list-view-config.model';
import { OptionalType } from '../../../../core/models/types/optional.type';
import { BaseCrudStore } from '../../../../core/abstractions/base-crud.store';
import { MainCardButtonModel } from '../../../../core/models/main-card-button.model';
import { Identifyable } from '../../../../core/abstractions/identifyable';

export class CardListViewConfigModel<
    T extends Identifyable<TId>,
    TList extends Identifyable<TId>,
    TCreate extends Identifyable<TId> = T,
    TUpdate extends Identifyable<TId> = TCreate,
    TId = number
> extends BaseListViewConfigModel<T, TList, TCreate, TUpdate, TId> {
    entityName!: string;
    store!: BaseCrudStore<T, TList, TCreate, TUpdate, TId>;
    cardComponent!: ComponentType<BaseCardComponent<TList, TId>>;
    cardWidth!: string;
    cardHeight!: string;
    isCustomCard = false;
    paginatorConfig = new PaginatorConfigModel();
    cardImageCustomClasses?: string;
    actionButtonsCustomClasses?: string;
    mainButton?: MainCardButtonModel;

    constructor(init?: Partial<CardListViewConfigModel<T, TList, TCreate, TUpdate, TId>>) {
        super(init);

        if (!!init) {
            Object.assign(this, init);
        }
    }

    cardImage: (currentItem: TList) => OptionalType<string> = () => '';
    cardBackgroundColor: (currentItem: TList) => OptionalType<string> = () => '';
    cardTitle: (currentItem: TList) => OptionalType<string> = () => '';
    cardSubtitle: (currentItem: TList) => OptionalType<string> = () => '';
    cardTitleClasses: (currentItem: TList) => string[] = () => [];
}
