import { Component, inject, input, OnInit, signal } from '@angular/core';

import { LicenseModuleItemModel } from '../../../../../models/license-module-item.model';
import { LicenseModel } from '../../../../../models/license.model';
import { LicensesStore } from '../../../../../services/stores/licenses.store';
import { BaseComponent } from '../../../../../../components/abstractions/base.component';

@Component({
    selector: 'arc-license-module-item-list',
    templateUrl: './license-module-item-list.component.html',
    styleUrl: './license-module-item-list.component.scss'
})
export class LicenseModuleItemListComponent extends BaseComponent implements OnInit {
    license = input.required<LicenseModel>();
    isLoading = signal(false);
    licenseModules = signal<LicenseModuleItemModel[]>([]);

    private readonly licenseStore = inject(LicensesStore);

    ngOnInit(): void {
        this.isLoading.set(true);
        this.licenseStore.getAllLicenseModuleBundles(this.license().id).subscribe(x => {
            this.licenseModules.set(x.value || []);
            this.isLoading.set(false);
        });
    }
}
