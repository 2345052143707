export enum ProductAreasEnum {
    General,
    ImportExport,
    Transactions,
    Dashboard,
    Customers,
    Articles,
    Help,
    Invoices,
    Vouchers,
    Warehouse,
    Configuration,
    Extensions,
    Reporting,
    PointOfSale
}
