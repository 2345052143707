import { Component, HostBinding, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { Subject, debounceTime, distinctUntilChanged, forkJoin } from 'rxjs';
import { Router } from '@angular/router';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

import { BaseSidebarComponent } from '../sidebar-components/base-sidebar/base-sidebar.component';
import { HelpStore } from '../../app/services/stores/help.store';
import { HelpArticleListModel } from '../../app/models/responses/help-article-list.model';
import { HelpArticleModel } from '../../app/models/responses/help-article.model';
import { SettingsSessionStorage } from '../../core/services/storages/settings.session-storage';

@Component({
    selector: 'arc-help',
    templateUrl: './help.component.html',
    styleUrls: ['./help.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HelpComponent extends BaseSidebarComponent implements OnInit {
    @HostBinding('class') classes = 'flex flex-col';

    isLoading = true;
    recommendedArticles: HelpArticleListModel[] = [];
    otherArticles: HelpArticleListModel[] = [];
    currentContext?: string;
    selectedArticle?: HelpArticleModel;
    searchResult: HelpArticleListModel[] = [];

    private readonly helpStore = inject(HelpStore);
    private readonly router = inject(Router);
    private readonly settingsSessionStorage = inject(SettingsSessionStorage);

    private searchInputSubject = new Subject<string>();

    constructor() {
        super();

        this.searchInputSubject.pipe(debounceTime(300), distinctUntilChanged()).subscribe(searchString => {
            this.helpStore.getHelpArticles(searchString).subscribe(r => {
                this.searchResult = r.value || [];
            });
        });
    }

    ngOnInit(): void {
        if (this.data) {
            this.selectArticle(this.data);
        } else {
            this.load();
        }
    }

    selectArticle(articleId: string): void {
        this.clearSelectedArticle();
        this.isLoading = true;
        this.helpStore.getHelpArticle(articleId).subscribe(r => {
            this.selectedArticle = r.value;
            this.settingsSessionStorage.saveCurrentHelpArticle(this.selectedArticle);
            this.isLoading = false;
        });
    }

    backToStart(): void {
        this.clearSelectedArticle();
        this.load();
    }

    onSearch(e: Event): void {
        if (e?.target instanceof HTMLInputElement) {
            const searchValue = e.target.value;
            this.searchInputSubject.next(searchValue);
        }
    }

    onSearchResultSelected(e: MatAutocompleteSelectedEvent): void {
        const selectedOption = e.option.value;
        this.selectArticle(selectedOption?.id);
    }

    private clearSelectedArticle(): void {
        this.selectedArticle = undefined;
        this.settingsSessionStorage.saveCurrentHelpArticle(this.selectedArticle);
    }

    private load(): void {
        this.isLoading = true;
        this.currentContext = this.settingsSessionStorage.getCurrentHelpContext();
        this.selectedArticle = this.settingsSessionStorage.getCurrentHelpArticle();
        const context = this.router.url.slice(1);

        if (this.currentContext !== context) {
            this.currentContext = context;
            this.settingsSessionStorage.saveCurrentHelpContext(this.currentContext);
            this.clearSelectedArticle();
        }

        if (!this.selectedArticle) {
            const recomendedArticlesObservable = this.helpStore.getHelpArticles('', context);

            // featured articles have the context === 'featured'
            const otherArticlesObservable = this.helpStore.getHelpArticles('', 'featured');

            forkJoin([recomendedArticlesObservable, otherArticlesObservable]).subscribe(results => {
                const recommendedArticlesResult = results[0];
                const otherArticlesResult = results[1];

                this.recommendedArticles = recommendedArticlesResult.value || [];
                this.otherArticles = otherArticlesResult.value || [];

                this.isLoading = false;
            });
        } else {
            this.isLoading = false;
        }
    }
}
