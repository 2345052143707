<div cdkOverlayOrigin #searchbarContainer
    class="searchbar-container bg-transparent grow-0 flex justify-end items-center"
    [ngClass]="{ '!bg-power-search !grow': !isSearchbarCollapsed }">
    <button mat-icon-button (click)="toggleSearchbarCollapse()">
        <arc-icon class="text-on-top-bar" [icon]="isSearchbarCollapsed ? 'search' : 'close'" [size]="21"></arc-icon>
    </button>
    <input
        #searchbar
        class="bg-power-search text-on-power-search placeholder:text-on-power-search placeholder:opacity-50 w-0 grow outline-none h-12"
        type="text"
        placeholder="{{ 'Layout.TopBar.SearchBar.GlobalSearch' | transloco }}..."
        [(ngModel)]="searchString"
        (input)="handleGlobalSearch()"
        (keyup.enter)="handleGlobalSearch()" />
</div>

<!-- Search results -->
<ng-template cdkConnectedOverlay #overlay
    [cdkConnectedOverlayHasBackdrop]="true"
    cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
    [cdkConnectedOverlayOrigin]="searchbarContainer"
    [cdkConnectedOverlayOpen]="shouldShowResults"
    (detach)="closeOverlay()"
    (backdropClick)="closeOverlay()">
    <div class="bg-power-search text-on-power-search px-4 py-2 max-h-[75vh] overflow-y-auto" [ngStyle]="{ 'width.px': searchbarContainer.clientWidth }">
        <div *ngIf="isLoading; else searchResults" class="flex justify-center py-4">
            <mat-spinner [diameter]="32"></mat-spinner>
        </div>
        <ng-template #searchResults>
            <div *ngIf="!!results && results.length > 0; else noResults">
                <div *ngFor="let result of results" (click)="goToResult(result)"
                    class="block py-2 px-2 hocus:bg-power-search hocus:text-on-power-search cursor-pointer">
                    <div class="flex gap-4 items-center">
                        <mat-icon>{{ resultTypeIconMap[result.type] }}</mat-icon>
                        <div class="flex flex-col">
                            <span class="text line-clamp-1 leading-tight" [innerHTML]="result.label | bold:searchString"></span>
                            <span class="text-xs line-clamp-1 leading-tight" [innerHTML]="result.description | bold:searchString"></span>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template #noResults>
            <div class="py-4">
                {{ 'General.NoRecords' | transloco }}
            </div>
        </ng-template>
    </div>
</ng-template>
