import { Component, EventEmitter, input, Output } from '@angular/core';

import { HelpArticleListModel } from '../../../../../../models/responses/help-article-list.model';

@Component({
    selector: 'arc-article-list-item',
    templateUrl: './help-article-list-item.component.html',
    styleUrl: './help-article-list-item.component.scss'
})
export class HelpArticleListItemComponent {
    @Output() readonly articleSelected = new EventEmitter<number>();
    article = input.required<HelpArticleListModel>();
    selectArticle(articleId: number): void {
        this.articleSelected.emit(articleId);
    }
}
