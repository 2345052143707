<h1 mat-dialog-title>{{ _dialogData.selectionDialogTitleKey | transloco }}</h1>

<mat-dialog-content class="grow !max-h-[unset] overflow-y-auto">
    <arc-search-filter [store]="_dialogData.store"></arc-search-filter>

    <div class="relative min-h-[128px]">
        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
                <button mat-icon-button disabled></button>
                <button *ngIf="canNodeBeSelected(node); else txtTpl"
                    class="w-full h-full rounded-[4px] cursor-pointer hover:bg-element-hover text-start pl-2" (click)="selectItem(node)">
                    {{node.text}}
                </button>
                <ng-template #txtTpl>
                    {{node.text}}
                </ng-template>
            </mat-tree-node>
            <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
                <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.text">
                    <mat-icon *ngIf="!node.isLoading" class="mat-icon-rtl-mirror">
                        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                    </mat-icon>
                    <mat-spinner *ngIf="node.isLoading" [diameter]="24"></mat-spinner>
                </button>
                <button *ngIf="canNodeBeSelected(node); else defaultTpl"
                    class="w-full h-full rounded-[4px] cursor-pointer hover:bg-element-hover text-start pl-2" (click)="selectItem(node)">
                    {{node.text}}
                </button>
                <ng-template #defaultTpl>
                    {{node.text}}
                </ng-template>
            </mat-tree-node>
            <mat-tree-node *matTreeNodeDef="let node; when: isNewButton" matTreeNodePadding>
                <button mat-icon-button disabled></button>
                <button *ngIf="!node.isInEditMode; else newItemTpl" mat-button class="!text-element-disabled"
                    (click)="toggleEditMode(node)">+ {{getAddNewItemKeyForLevel(node) | transloco}}
                </button>
                <ng-template #newItemTpl>
                    <div class="h-full relative border-b-on-app border-b-2 border-solid input-accent-outline">
                        <input #newRecordInput class="outline-0 pl-2 h-full pr-[80px] w-[220px]">
                        <div class="absolute right-0 top-1/2 -translate-y-1/2 flex">
                            <button [disabled]="node.isSaving" mat-icon-button (click)="toggleEditMode(node)">
                                <mat-icon>close</mat-icon>
                            </button>
                            <button [disabled]="node.isSaving" mat-icon-button (click)="saveNode(node)">
                                <mat-icon>save</mat-icon>
                            </button>
                        </div>
                    </div>
                </ng-template>
            </mat-tree-node>
        </mat-tree>
        <div *ngIf="isLoading" class="absolute inset-0 grid place-items-center h-100">
            <mat-spinner [diameter]="64"></mat-spinner>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-stroked-button [mat-dialog-close]="undefined">{{ 'General.Actions.Cancel' | transloco }}</button>
</mat-dialog-actions>
