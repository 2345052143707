import { Component } from '@angular/core';

import { StackedColumnModel } from '../../models/column-types/stacked-column.model';
import { BaseColumnComponent } from '../base-column.component';

@Component({
    selector: 'arc-stacked-column',
    templateUrl: './stacked-column.component.html',
    styleUrls: ['./stacked-column.component.scss']
})
export class StackedColumnComponent<T extends object> extends BaseColumnComponent<T> {
    get castedColumnModel(): StackedColumnModel {
        return this.columnModel as StackedColumnModel;
    }
}
