import { Component, OnInit, inject } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ApiIntegrationModel } from '../../../../../models/api-intagration.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { ApiIntegrationEditModel } from '../../../../../models/requests/api-integration-edit.model';
import { ComplexDataTypesEnum } from '../../../../../../core/models/complex-data-types.enum';
import { UserRoles } from '../../../../../models/enums/user-roles.enum';
import { AuthService } from '../../../../../../core/services/auth.service';
import { TranslationService } from '../../../../../../core/services/translation.service';

@Component({
    selector: 'arc-api-integration-edit-base-data',
    templateUrl: './api-integration-edit-base-data.component.html',
    styleUrl: './api-integration-edit-base-data.component.scss'
})
export class ApiIntegrationEditBaseDataComponent
    extends BaseEditSidebarItemComponent<ApiIntegrationModel, ApiIntegrationEditModel>
    implements OnInit {
    ComplexDataTypesEnum = ComplexDataTypesEnum;

    override formGroup = new FormGroup({
        title: new ArcFormControl('', [Validators.required]),
        userId: new ArcFormControl(0, [Validators.required]),
        maxRequestsPer15Mins: new ArcFormControl(0, [Validators.required, Validators.min(0)]),
        approvedDate: new ArcFormControl<OptionalType<Date>>(undefined),
        helpText: new ArcFormControl<OptionalType<string>>(undefined, [Validators.required])
    });

    isApiIntegrationAdmin = false;
    private readonly authService = inject(AuthService);
    private readonly translationService = inject(TranslationService);

    ngOnInit(): void {
        this.isApiIntegrationAdmin = this.authService.hasRole(UserRoles.ApiIntegrationAdmin);

        if (this.isCreate) {
            const defaulHelpText = this.translationService.getText('ApiIntegrations.Edit.DefaultHelpText');
            this.formGroup.controls.helpText.setValue(defaulHelpText);
        }
    }

    override onItemSet(): void {
        this.formGroup.patchValue(this.item);
    }

    override prepareSaveModel(itemCopy: ApiIntegrationEditModel): ApiIntegrationEditModel {
        return { ...itemCopy, ...this.formGroup.value };
    }
}
