/* eslint-disable @typescript-eslint/naming-convention */
import { BaseEntityModel } from '../../../core/abstractions/base-entity.model';

export class NewsletterSubscriberEditModel extends BaseEntityModel {
    subscriptionType!: string;
    languageIsoCode!: string;
    name?: string;
    email?: string;
    licenseId?: number;
    hiddenSubscriptionType!: boolean;
    constructor(init?: Partial<NewsletterSubscriberEditModel>) {
        super();

        if (!!init) {
            Object.assign(this, init);
        }
    }
}
