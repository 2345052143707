import { Injectable } from '@angular/core';

import { StoreConfig } from '../../../core/models/store.config';
import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { LicenseHardwareOrderModel } from '../../models/license-hardware-orders.model';
import { LicenseHardwareOrderListModel } from '../../models/responses/license-hardware-order-list.model';
import { LicenseHardwareOrderEditModel } from '../../models/requests/license-hardware-order-edit.model';

@Injectable({
    providedIn: 'root'
})
// eslint-disable-next-line max-len
export class LicenseHardwareOrdersStore extends BaseCrudStore<LicenseHardwareOrderModel, LicenseHardwareOrderListModel, LicenseHardwareOrderEditModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'license-hardware-orders' }));
    }
}
