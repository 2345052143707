<mat-form-field class="w-full" [floatLabel]="hasFloatingLabel ? 'always' : 'auto'" class="w-full" [color]="color"
    [arcErrorTooltip]="_formControl" [subscriptSizing]="hasFixedSubscript ? 'fixed' : 'dynamic'">
    @if (!!label) {
        <mat-label>{{ isLabelTranslated ? label : (label | transloco) }}</mat-label>
    }

    @if (!!currentParentPath()) {
        <div class="!w-full text-xs h-[15px] mb-2">{{ currentParentPath() }}</div>
    }

    <input matInput #searchInput #inputTrigger="matAutocompleteTrigger" [formControl]="internalControl"
        [placeholder]="isPlaceholderTranslated ? placeholder : (placeholder | transloco)" [matAutocomplete]="auto"
        (blur)="checkContent()" />

    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)" [displayWith]="displayWithFn.bind(this)"
        hideSingleSelectionIndicator autoActiveFirstOption>
        @if (!isRequired) {
            <!-- prevent default on click so blur doesn't fire -->
            <mat-option [value]="null" (mousedown)="$event.preventDefault()">-</mat-option>
        }
        @for (option of searchResults(); track option.id) {
            <!-- prevent default on click so blur doesn't fire -->
            <mat-option [value]="option" (mousedown)="$event.preventDefault()">
                {{ option | transform : getNodePath.bind(this) }}
            </mat-option>
        }
    </mat-autocomplete>
    <div matIconSuffix></div>
    <div matSuffix>
        @if (isSearching()) {
            <mat-spinner diameter="22"></mat-spinner>
        }
        @else {
            <button type="button" mat-icon-button (click)="openDialog(inputTrigger)">
                <mat-icon>account_tree</mat-icon>
            </button>
        }
    </div>
</mat-form-field>
