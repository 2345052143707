<div class='max-w-[360px] flex flex-col gap-12'>
    <div>
        <h1 class='text-3xl mb-4 break-words'>
            {{ 'Login.Title' | transloco }}
        </h1>
        <p>
            {{ 'Login.Description' | transloco }}
        </p>
    </div>
    <form [formGroup]="formGroup" (submit)="login()">
        <mat-card-content class="!flex flex-col gap-4">
            <ng-container *ngIf="!isCheckingAuth; else authCheckTpl">
                <arc-input [control]="formGroup.controls['email']" labelKey="Login.Username">
                </arc-input>
                <div class='relative'>
                    <arc-input [control]="formGroup.controls['password']" [type]="showPasswordClearText ? 'text' : 'password'" labelKey="Login.Password"
                               placeholder="********">
                    </arc-input>
                    <arc-icon class="absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer" [icon]="showPasswordClearText ? 'visibility_off' : 'visibility'" (click)='showPasswordClearText = !showPasswordClearText'></arc-icon>
                </div>
                <button type="button" class="text-right text-accent -ml-2 -mt-1 mr-5 mb-4 hover:underline" (click)="redirectToPasswordRecover()">
                    {{ 'Login.RecoverPassword' | transloco }}
                </button>
                <button type="button" class="text-right text-accent -ml-2 -mt-1 mr-5 mb-10 hover:underline" (click)="redirectToRegister()">
                    {{ 'Login.Register' | transloco }}
                </button>
            </ng-container>
            <ng-template #authCheckTpl>
                <div class="flex flex-col items-center gap-4">
                    <mat-spinner [diameter]="48"></mat-spinner>
                    <span>{{ 'Login.CheckingAuthentication' | transloco }}...</span>
                </div>
            </ng-template>
        </mat-card-content>
        <mat-card-actions *ngIf="!isCheckingAuth">
            <button type="submit" mat-flat-button color="accent">{{ 'Login.SignIn' | transloco }}</button>
        </mat-card-actions>
    </form>
</div>


