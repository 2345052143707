import { Pipe, PipeTransform } from '@angular/core';

import { Tools } from '../utils/tools';

@Pipe({ name: 'bold' })
export class BoldPipe implements PipeTransform {
    transform(value: string, boldText: string): string {
        const escapedText = Tools.Utils.regexEscape(boldText);
        const regex = new RegExp(escapedText, 'ig');
        return value.replaceAll(regex, '<strong>$&</strong>');
    }
}
