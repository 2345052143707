<div class="px-8 py-12 h-full">
    <header class="absolute top-0 left-8 right-12 h-12 flex items-center z-10">
        <ng-container *ngIf="!isDashboardEditable; else editTitle">
            <h2 class="font-semibold">{{ widget.title }}</h2>
        </ng-container>
        <ng-template #editTitle>
            <input class="title font-semibold w-full" matInput [(ngModel)]="widget.title"
                (touchstart)="handleClick($event)" [placeholder]="'Widgets.WidgetTitle' | transloco"
                (mousedown)="handleClick($event)" />
        </ng-template>
    </header>
    <div class="h-full w-full">
        <arc-chart *ngIf="widget.parameters.chartType !== StatisticChartTypesEnum.Number; else numberTemplate"
            [data]="chartData" [type]="getChartTypeString(widget.parameters.chartType)"></arc-chart>
        <ng-template #numberTemplate>
            <div class="flex flex-col justify-center items-center h-full">
                <div>
                    <div class="text-5xl">{{ chartTypeNumberSum | arcNumber : 2 : undefined : undefined : true }}</div>
                    <div *ngIf="hasComparisonValues" class="text-2xl"
                        [ngClass]="isChartTypeNumberComparisonPositive ? 'text-accent' : 'text-error'">
                        <span *ngIf="isChartTypeNumberComparisonPositive">+</span>
                        {{ chartTypeNumberChange | arcNumber : 2 : '' : '%' }}
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
    <footer class="absolute bottom-0 left-8 right-8 h-12 items-center flex justify-between">
        <div class="w-40">
            <mat-select (selectionChange)="onPeriodSelected($event.value)" [(value)]="widget.parameters.period">
                <mat-option *ngFor="let period of periods" [value]="period.value">
                    {{ period.label }}
                </mat-option>
            </mat-select>
        </div>
        <div class="flex gap-4">
            <button mat-icon-button [matMenuTriggerFor]="chartTypeMenu"
                matTooltip="{{ 'Widgets.Statistics.StatisticChartType' | transloco }}">
                <mat-icon *ngIf="widget.parameters.chartType"> {{ chartTypeIconMapper[widget.parameters.chartType]
                    }}</mat-icon>
            </button>
            <mat-menu #chartTypeMenu="matMenu" [class]="'chart-types'">
                <button mat-menu-item *ngFor="let chartType of chartTypes"
                    [ngClass]="{ '!bg-element-selected': widget.parameters.chartType === chartType.value }"
                    (click)="onChartTypeSelected(chartType.value)" [matTooltip]="chartType.label"
                    matTooltipPosition="after">
                    <mat-icon class="!mr-0"> {{ chartType.icon }}</mat-icon>
                </button>
            </mat-menu>
            <div class="inline-block">
                <button mat-icon-button (click)="openOptionsDialog()"
                    matTooltip="{{ 'Widgets.Statistics.Filter.Filter' | transloco }}">
                    <mat-icon>filter_alt</mat-icon>
                </button>
            </div>
        </div>
    </footer>
</div>