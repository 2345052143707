<div class="h-full flex flex-col w-full">
    <arc-search-filter [store]="helpTopicsStore"></arc-search-filter>
    <div class="mt-4 relative min-h-[128px]">
        @if(!!isLoading) {
            <div class="absolute inset-0 grid place-items-center h-100">
                <mat-spinner [diameter]="64"></mat-spinner>
            </div>
        } @else {
            <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="p-8">
                <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding draggable="true" (drop)="handleDrop($event, node)"
                    (dragstart)="handleDragStart($event, node)" (dragover)="handleDragOver($event, node)" (dragend)="handleDragEnd()">
                    <button mat-icon-button disabled></button>
                    <mat-icon *ngIf="node.level > 0" class="cursor-pointer mr-2">drag_indicator</mat-icon>
                    {{ node.text }}
                    <button mat-icon-button class="hidden-button" (click)="openEdit(node)">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button class="hidden-button" (click)="remove(node)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </mat-tree-node>
                <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding draggable="true" (drop)="handleDrop($event, node)"
                    (dragstart)="handleDragStart($event, node)" (dragover)="handleDragOver($event, node)" (dragend)="handleDragEnd()">
                    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.text">
                        <mat-icon *ngIf="!node.isLoading" class="mat-icon-rtl-mirror">
                            {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                        </mat-icon>
                        <mat-spinner *ngIf="node.isLoading" [diameter]="24"></mat-spinner>
                    </button>
                    <mat-icon *ngIf="node.level > 0" class="cursor-pointer mr-2">drag_indicator</mat-icon>
                    {{ node.text }}
                    <button mat-icon-button class="hidden-button" (click)="openEdit(node)">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button class="hidden-button" (click)="remove(node)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </mat-tree-node>
                <mat-tree-node *matTreeNodeDef="let node; when: isNewButton" matTreeNodePadding>
                    <button mat-icon-button disabled></button>
                    <button *ngIf="!node.isInEditMode; else newItemTpl" mat-button class="!text-element-disabled"
                        (click)="toggleEditMode(node)">+ {{ getAddNewItemKeyForLevel(node) | transloco }}
                    </button>
                    <ng-template #newItemTpl>
                        <div class="h-full relative border-b-on-app border-b-2 border-solid input-accent-outline">
                            <input #newRecordInput class="outline-0 pl-2 h-full pr-[80px] w-[220px]">
                            <div class="absolute right-0 top-1/2 -translate-y-1/2 flex">
                                <button [disabled]="node.isSaving" mat-icon-button (click)="toggleEditMode(node)">
                                    <mat-icon>close</mat-icon>
                                </button>
                                <button [disabled]="node.isSaving" mat-icon-button (click)="saveNode(node)">
                                    <mat-icon>save</mat-icon>
                                </button>
                            </div>
                        </div>
                    </ng-template>
                </mat-tree-node>
            </mat-tree>
        }
    </div>
</div>
