import { Injectable } from '@angular/core';

import { StoreConfig } from '../../../core/models/store.config';
import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { HelpTooltipModel } from '../../models/responses/help-tooltip.model';
import { HelpTooltipListModel } from '../../models/responses/help-tooltip-list.model';
import { HelpTooltipEditModel } from '../../models/requests/help-tooltip-edit.model';

@Injectable({
    providedIn: 'root'
})
export class HelpTooltipsStore extends BaseCrudStore<HelpTooltipModel, HelpTooltipListModel, HelpTooltipEditModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'help-tooltips' }));
    }
}
