import { Component, Input, OnInit, inject } from '@angular/core';

import { LicenseModel } from '../../../../../models/license.model';
import { LicensesStore } from '../../../../../services/stores/licenses.store';
import { LicenseConnectionModel } from '../../../../../models/license-connection.model';
import { ActionButtonsService } from '../../../../../../core/services/action-buttons.service';
import { BaseComponent } from '../../../../../../components/abstractions/base.component';

@Component({
    selector: 'arc-license-db-info',
    templateUrl: './license-db-info.component.html',
    styleUrl: './license-db-info.component.scss'
})
export class LicenseDbInfoComponent extends BaseComponent implements OnInit {
    @Input({ required: true }) license!: LicenseModel;
    @Input() isDarkTheme = false;
    connection?: LicenseConnectionModel;
    isLoading = false;

    private readonly licenseStore = inject(LicensesStore);
    private readonly actionButtonsService = inject(ActionButtonsService);

    ngOnInit(): void {
        this.licenseStore.getConnectionById(this.license.id).subscribe(x => {
            this.connection = x.value;
            this.isLoading = false;
        });
    }

    copyToClipboard(value?: string): void {
        if (value) {
            navigator.clipboard.writeText(value);
        }
    }
    handleDelete(): void {
        this.actionButtonsService.handleClick('LicenseTestDeactivateContextAction', this.license);
    }
    handleDownload(): void {
        this.actionButtonsService.handleClick('LicenseTestDownloadPosConfigContextAction', this.license);
    }
}
