<div class="w-full h-[100svh] grid place-items-center">

    <div class="p-8 bg-app rounded-xl max-w-[90vw]">
        <h1 class="w-full text-center text-3xl text-primary mb-4">Arcavis Demo</h1>
        <p>Das erstellen einer Demo ist online aktuell nicht möglich.</p>
        <p>Bitte melden Sie sich mit Ihrer Anfrage direkt bei uns:</p>
        <div class="w-full flex justify-center mt-4">
            <div class="text-info grid grid-cols-[auto,1fr] gap-x-4 gap-y-2">
                <mat-icon>email</mat-icon>
                <a href="mailto:sales@arcavis.ch">sales&#64;arcavis.ch</a>
                <mat-icon>phone</mat-icon>
                <a href="tel:031 333 09 00">031 333 09 00</a>
            </div>
        </div>
    </div>

</div>
