import { BaseApiModel } from '../../core/abstractions/base-api.model';

export class PlaceModel extends BaseApiModel {
    zip!: string;
    city!: string;
    countryIsoCode!: string;

    constructor(init?: Partial<PlaceModel>) {
        super();
        Object.assign(this, init);
    }
}
