import { BaseListViewModel } from '../../../../core/abstractions/base-list-view.model';
import { BaseSearchStore } from '../../../../core/abstractions/base-search.store';
import { DataSelectionDialogDataModel } from '../../../dialogs/data-selection-dialog/models/data-selection-dialog-data.model';
import { DataSelectionDialogCustomDataModel } from '../../../dialogs/data-selection-dialog/models/data-selection-dialog-custom-data.model';

export class QuickSearchConfigModel<TList extends BaseListViewModel> {
    store!: BaseSearchStore<TList>;
    displayFn!: (data?: TList) => string;
    shouldHideDataSelectDialogButton?: boolean;
    dataSelectionDialogConfig?: DataSelectionDialogDataModel | DataSelectionDialogCustomDataModel;

    constructor(init: QuickSearchConfigModel<TList>) {
        Object.assign(this, init);
    }
}
