import { Component, inject } from '@angular/core';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { ContractListModel } from '../../../models/responses/contract-list.model';
import { ContractModel } from '../../../models/contract.model';
import { ContractsStore } from '../../../services/stores/contracts.store';
import { TableListViewConfigModel } from '../../../../components/list-views/table-list-view/models/table-list-view-config.model';
import { StringColumnModel } from '../../../../components/dynamic-table/models/column-types/string-column.model';
import { ColumnSortModel } from '../../../../core/models/column-sort.model';
import { SortDirectionEnum } from '../../../../core/models/enums/sort-direction.enum';
import { DateColumnModel } from '../../../../components/dynamic-table/models/column-types/date-column.model';
import { ContractEditModel } from '../../../models/requests/contract-edit.model';
import { ContractEditBaseDataComponent } from './contract-edit-items/contract-edit-base-data/contract-edit-base-data.component';

@Component({
    selector: 'arc-contracts',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrl: './contracts.component.scss'
})
export class ContractsComponent extends PageListViewComponent<ContractListModel, ContractModel, ContractEditModel> {
    private readonly contractsStore = inject(ContractsStore);
    constructor() {
        super('Contracts');
        this.config = new TableListViewConfigModel<ContractListModel, ContractModel, ContractEditModel>({
            entityName: 'Contracts',
            availableColumns: {
                key: new StringColumnModel({
                    columnTitleKey: 'Contracts.List.Key',
                    propertyName: 'key',
                    widthPixels: 200
                }),
                title: new StringColumnModel({
                    columnTitleKey: 'Contracts.List.Title',
                    propertyName: 'title',
                    widthPixels: 300
                }),
                lastUpdated: new DateColumnModel({
                    columnTitleKey: 'Contracts.List.LastUpdated',
                    propertyName: 'lastUpdated',
                    widthPixels: 150
                }),
                lastUpdatedByUser: new StringColumnModel({
                    columnTitleKey: 'Contracts.List.LastUpdatedByUser',
                    propertyName: 'lastUpdatedByUser',
                    widthPixels: 200
                })
            },
            defaultColumnOrder: ['key', 'title', 'lastUpdated', 'lastUpdatedByUser'],
            defaultSort: new ColumnSortModel({
                column: 'title',
                direction: SortDirectionEnum.Desc
            }),
            availableDetailWidgets: {},
            defaultDetailWidgetOrder: [],
            editSidebarConfig: {
                editComponents: [
                    {
                        titleKey: 'Contracts.Edit.BaseData.Name',
                        component: ContractEditBaseDataComponent
                    }
                ]
            },
            store: this.contractsStore
        });
    }
}
