<form [formGroup]="formGroup">
    <div class="flex flex-col gap-4">
        <arc-translation-input translationDialogTitleKey="General.Actions.TranslateTitle" labelKey="HelpTopics.Edit.BaseData.Title"
            [controlDe]="formGroup.controls.title_de" [controlEn]="formGroup.controls.title_en"
            [controlFr]="formGroup.controls.title_fr" [controlIt]="formGroup.controls.title_it">
        </arc-translation-input>
        <arc-input labelKey="HelpTopics.Edit.BaseData.Icon" [control]="formGroup.controls.icon" [icon]="formGroup.value.icon"></arc-input>
        <arc-select labelKey="HelpTopics.Edit.BaseData.RequiredRole" [control]="formGroup.controls.requiredRole" [options]="roles"></arc-select>
    </div>
</form>
