<form [formGroup]="form">
    <div class="flex flex-col gap-4">
        <arc-step2-mapping-group titleKey="Components.ImportDialog.MappingRequired"
            [importFileColumns]="importFileColumns" [columns]="requiredColumns" [form]="form" [isRequired]="true">
        </arc-step2-mapping-group>
        <arc-step2-mapping-group titleKey="Components.ImportDialog.MappingAdditional"
            [importFileColumns]="importFileColumns" [columns]="additionalColumns" [form]="form" [isOpen]="hasAdditionalMappingSuggestion">
        </arc-step2-mapping-group>
        <arc-step2-mapping-group titleKey="Components.ImportDialog.MappingTranslation"
            [importFileColumns]="importFileColumns" [columns]="translationColumns" [form]="form" [isOpen]="hasTranslationMappingSuggestion">
        </arc-step2-mapping-group>
    </div>
</form>
