import { Component, inject } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs';

import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { TransactionEditModel } from '../../../../../models/requests/transaction-edit.model';
import { TransactionTypeEnum } from '../../../../../models/enums/transaction-type.enum';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { SelectOptionModel } from '../../../../../../core/models/select-option.model';
import { GeneralDataStore } from '../../../../../services/stores/general-data.store';
import { CustomValidators } from '../../../../../../core/utils/custom-validators';
import { ComplexDataTypesEnum } from '../../../../../../core/models/complex-data-types.enum';
import { TransactionModel } from '../../../../../models/transaction.model';
import { TranslationService } from '../../../../../../core/services/translation.service';

@Component({
    selector: 'arc-transaction-base-data',
    templateUrl: './transaction-base-data.component.html',
    styleUrl: './transaction-base-data.component.scss'
})
export class TransactionBaseDataComponent extends BaseEditSidebarItemComponent<TransactionModel, TransactionEditModel> {
    override formGroup = new FormGroup({
        valueDate: new ArcFormControl(new Date(), Validators.required),
        licenseId: new ArcFormControl(0, Validators.required),
        type: new ArcFormControl(TransactionTypeEnum.Individual, Validators.required),
        poolTime: new ArcFormControl<OptionalType<number>>(undefined, CustomValidators.number({ min: 0 })),
        originalAmount: new ArcFormControl<OptionalType<number>>(undefined, CustomValidators.number()),
        publicRemark: new ArcFormControl<OptionalType<string>>(undefined),
        internalRemark: new ArcFormControl<OptionalType<string>>(undefined),
        discountGiven: new ArcFormControl<OptionalType<number>>(undefined),
        licenseModuleBundleId: new ArcFormControl<OptionalType<number>>(undefined),
        licenseHardwareOrderId: new ArcFormControl<OptionalType<number>>(undefined),
        amount: new ArcFormControl<OptionalType<number>>(undefined)
    });

    TransactionTypeEnum = TransactionTypeEnum;
    ComplexDataTypesEnum = ComplexDataTypesEnum;
    licenseModuleBundles: SelectOptionModel<string>[] = [];
    licenseHardwareOrders: SelectOptionModel<string>[] = [];
    showServiceFee = false;

    readonly translationService = inject(TranslationService);

    transactionTypes = [
        {
            value: TransactionTypeEnum.Individual,
            label: this.translationService.getText('TransactionType.Individual')
        },
        {
            value: TransactionTypeEnum.Hardware,
            label: this.translationService.getText('TransactionType.Hardware')
        },
        {
            value: TransactionTypeEnum.License,
            label: this.translationService.getText('TransactionType.License')
        },
        {
            value: TransactionTypeEnum.Payment,
            label: this.translationService.getText('TransactionType.Payment')
        }
    ];

    override skipPropertiesOnUnsavedChangesCheck = ['originalAmount'];

    private readonly generalDataStore = inject(GeneralDataStore);

    constructor() {
        super();

        this.formGroup.controls.licenseId.valueChanges.subscribe(licenseId => {
            this.handleLicenseUpdate(licenseId);
        });
    }

    onItemSet(): void {
        this.formGroup.patchValue(this.item);
        if (!!this.item.licenseId) {
            this.handleLicenseUpdate(this.item.licenseId);
        }
        this.setOriginalAmount();
        this.showServiceFee = this.item.type === TransactionTypeEnum.ServiceFee;
    }

    override prepareSaveModel(itemCopy: TransactionEditModel): TransactionEditModel {
        return new TransactionEditModel({ ...itemCopy, ...this.formGroup.value });
    }
    setTotalAmount(): void {
        const amount = this.formGroup.value.originalAmount ?? 0;
        const discount = this.formGroup.value.discountGiven ?? 0;
        this.formGroup.controls.amount.setValue(amount - discount);
    }

    setOriginalAmount(): void {
        const originalAmount = this.formGroup.value.amount ?? 0;
        const discount = this.formGroup.value.discountGiven ?? 0;
        this.formGroup.controls.originalAmount.setValue(originalAmount + discount);
    }

    handleLicenseUpdate(licenseId: number): void {
        this.generalDataStore
            .getLicenseModuleBundles(licenseId)
            .pipe(first())
            .subscribe(result => {
                this.licenseModuleBundles = result.value?.map(x => ({ label: x.value!, value: x.key! })) ?? [];
            });
        this.generalDataStore
            .getLicenseHardwareOrders(licenseId)
            .pipe(first())
            .subscribe(result => {
                this.licenseHardwareOrders = result.value?.map(x => ({ label: x.value!, value: x.key! })) ?? [];
            });
    }
}
