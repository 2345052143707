import { ChangeDetectorRef, Component, HostBinding, HostListener, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription, finalize } from 'rxjs';

import { ImportDialogDataModel } from './import-dialog-data.model';
import { BaseComponent } from '../../abstractions/base.component';
import { GeneralPromptDialogComponent } from '../general-prompt-dialog/general-prompt-dialog.component';
import { ImportService } from '../../../core/services/import.service';
import { ImportEntityColumnModel } from '../../../app/models/import-entity-column.model';
import { ImportDialogStepsEnum } from './models/enums/import-dialog-steps.enum';
import { ImportStepBase } from './models/import-step-base';
import { ErrorsService } from '../../../core/services/errors.service';
import { Tools } from '../../../core/utils/tools';

@Component({
    selector: 'arc-import-dialog',
    templateUrl: './import-dialog.component.html',
    styleUrls: ['./import-dialog.component.scss']
})
export class ImportDialogComponent extends BaseComponent implements OnInit, OnDestroy {
    @HostBinding('class') classes = 'flex flex-col h-full';

    @ViewChild('step1') private step1!: ImportStepBase;
    @ViewChild('step2') private step2!: ImportStepBase;
    @ViewChild('step3') private step3!: ImportStepBase;
    @ViewChild('step4') private step4!: ImportStepBase;

    ImportDialogStepsEnum = ImportDialogStepsEnum;

    isLoading = false;
    hasError = false;
    apiErrorMessage?: string;
    isDownloadingErrorFile = false;

    allowNext = false;

    currentStep = ImportDialogStepsEnum.Step1UploadFile;
    currentStepComponent!: ImportStepBase;
    currentStepChangedSub?: Subscription;

    closeDialogRef?: MatDialogRef<GeneralPromptDialogComponent, any>;

    get fileColumns(): string[] {
        return this.importService.fileColumns ?? [];
    }

    get entityImportColumns(): ImportEntityColumnModel[] {
        return this.importService.entityImportColumns ?? [];
    }

    private get stepComponents(): ImportStepBase[] {
        return [this.step1, this.step2, this.step3, this.step4];
    }

    protected readonly _data: ImportDialogDataModel = inject(MAT_DIALOG_DATA);
    private readonly errorsService = inject(ErrorsService);
    private readonly dialogRef = inject(MatDialogRef<ImportDialogComponent>);
    private readonly matDialog = inject(MatDialog);
    private readonly importService = inject(ImportService);
    private readonly changeDetectorRef = inject(ChangeDetectorRef);

    constructor() {
        super();
    }

    @HostListener('document:keydown', ['$event'])
    onKeyDown(event: KeyboardEvent): void {
        if (event.key === 'Escape' && !event.repeat && this.currentStepComponent.allowCancel()) {
            this.cancel();
        }
    }

    ngOnInit(): void {
        this.isLoading = true;
        this.importService.init(this._data.entityName).subscribe({
            complete: () => {
                this.isLoading = false;
                this.initCurrentStep();
            }
        });

        const errorSub = this.errorsService.onBusinessException.subscribe(response => {
            this.hasError = true;
            this.apiErrorMessage = response.brokenRules?.at(0)?.message ?? response.message;
        });
        this.addSubscriptions(errorSub);
    }

    cancel(): void {
        if (!!this.closeDialogRef) {
            return;
        }

        this.closeDialogRef = this.matDialog.open(GeneralPromptDialogComponent, {
            data: { promptKey: 'Components.ImportDialog.CancelPrompt' }
        });
        this.closeDialogRef.afterClosed().subscribe(result => {
            this.closeDialogRef = undefined;
            if (result) {
                this.dialogRef.close(false);
            }
        });
    }

    previous(): void {
        if (!this.currentStepComponent.allowPrevious() || this.currentStep === 0) {
            return;
        }

        this.currentStep--;
        this.initCurrentStep();
    }

    next(): void {
        if (!this.currentStepComponent.allowNext() || this.currentStep + 1 === this.stepComponents.length) {
            this.allowNext = false;
            return;
        }

        this.isLoading = true;
        this.currentStepComponent
            .next()
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(() => {
                this.currentStep++;
                this.initCurrentStep();
            });
    }

    downloadErrorFile(): void {
        this.isDownloadingErrorFile = true;
        this.importService.getImportErrorFile().subscribe({
            next: response => {
                if (!response.body) {
                    return;
                }
                const blob = new Blob(['', response.body], { type: response.body.type });
                Tools.Utils.saveFile(blob, Tools.Utils.getFileNameFromResponse(response));
            },
            complete: () => this.isDownloadingErrorFile = false
        });
    }

    private initCurrentStep(): void {
        this.currentStepComponent = this.stepComponents[this.currentStep];
        this.setAllowedActions();
        this.currentStepChangedSub = this.currentStepComponent.changes.subscribe(() => {
            this.setAllowedActions();
        });
        this.addSubscriptions(this.currentStepChangedSub);
        this.currentStepComponent.init();
    }

    private setAllowedActions(): void {
        this.allowNext = this.currentStepComponent.allowNext() ?? false;
        this.changeDetectorRef.detectChanges();
    }
}
