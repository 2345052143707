import { Injectable } from '@angular/core';

import { StoreConfig } from '../../../core/models/store.config';
import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { ApiIntegrationModel } from '../../models/api-intagration.model';
import { ApiIntegrationListModel } from '../../models/responses/api-integration-list.model';

@Injectable({
    providedIn: 'root'
})
export class ApiIntegrationsStore extends BaseCrudStore<ApiIntegrationModel, ApiIntegrationListModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'api-integrations' }));
    }
}
