<ng-container [ngSwitch]="item.type">
    <ng-container *ngSwitchCase="TransactionTypeEnum.Payment">
            <span>
                {{ 'TransactionType.Payment' | transloco}}
            </span>
    </ng-container>
    <ng-container *ngSwitchCase="TransactionTypeEnum.Individual">
            <span>
                {{ 'TransactionType.Individual' | transloco}}
            </span>
    </ng-container>
    <ng-container *ngSwitchCase="TransactionTypeEnum.License">
            <span>
                {{ 'TransactionType.License' | transloco}}
            </span>
    </ng-container>
    <ng-container *ngSwitchCase="TransactionTypeEnum.ServiceFee">
            <span>
                {{ 'TransactionType.ServiceFee' | transloco}}
            </span>
    </ng-container>
    <ng-container *ngSwitchCase="TransactionTypeEnum.Hardware">
            <span>
                {{ 'TransactionType.Hardware' | transloco }}
            </span>
    </ng-container>
</ng-container>