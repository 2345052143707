import { Component, Input } from '@angular/core';

import { TicketStatusEnum, TicketStatusEnumExtensions } from '../../../../../models/enums/ticket-status.enum';
import { TicketStatusModel } from '../../../../../models/responses/ticket-status.model';

@Component({
    selector: 'arc-ticket-status',
    templateUrl: './ticket-status.component.html',
    styleUrl: './ticket-status.component.scss'
})
export class TicketStatusComponent {
    @Input({ required: true }) ticket!: TicketStatusModel;

    getStatusColorClasses(ticketStatus: TicketStatusEnum): string {
        return TicketStatusEnumExtensions.getColor(ticketStatus);
    }
}
