import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { translate } from '@ngneat/transloco';
import { Subject } from 'rxjs';

@Injectable()
export class MatPaginatorIntlTranslated implements MatPaginatorIntl {
    changes = new Subject<void>();

    get firstPageLabel(): string {
        return translate('Components.Paginator.FirstPage');
    }

    get itemsPerPageLabel(): string {
        return translate('Components.Paginator.ItemsPerPage') + ':';
    }

    get lastPageLabel(): string {
        return translate('Components.Paginator.LastPage');
    }

    get nextPageLabel(): string {
        return translate('Components.Paginator.NextPage');
    }

    get previousPageLabel(): string {
        return translate('Components.Paginator.PreviousPage');
    }

    getRangeLabel(page: number, pageSize: number, length: number): string {
        if (length === 0) {
            return translate('Components.Paginator.PageXofY', { page: 1, amountPages: 1 });
        }
        const amountPages = Math.ceil(length / pageSize);
        return translate('Components.Paginator.PageXofY', { page: page + 1, amountPages });
    }
}
