import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

import { BaseControlComponent } from '../base-control/base-control.component';

@Component({
    selector: 'arc-date-range-picker',
    templateUrl: './date-range-picker.component.html',
    styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent extends BaseControlComponent implements OnInit {
    @Input() controlEnd!: AbstractControl;
    @Input() min?: Date;
    @Input() max?: Date;
    @Input() showHint = true;

    dateFormat = '';

    get castedControlEnd(): FormControl {
        return this.controlEnd as FormControl;
    }

    override ngOnInit(): void {
        super.ngOnInit();
        this.dateFormat = this._translationService.current.dateFormat;
    }
}
