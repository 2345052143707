<h1 mat-dialog-title class="!px-12 !font-bold">
    <span class="inline-block">
        {{ 'Components.ImportDialog.Title' | transloco : { entityName: _data.entityName + '.Title' | transloco } }}
    </span>
</h1>

<mat-dialog-content class="grow !max-h-full !px-12 scrollbar-gutter-stable">
    <!-- step 1: upload file -->
    <arc-step1-upload-file class="block w-full h-full" #step1
        [ngClass]="{ 'hidden': isLoading || hasError || currentStep !== ImportDialogStepsEnum.Step1UploadFile }">
    </arc-step1-upload-file>
    <!-- step 2: mapping -->
    <arc-step2-mapping class="block w-full h-full" #step2
        [ngClass]="{ 'hidden': isLoading || hasError || currentStep !== ImportDialogStepsEnum.Step2Mapping }">
    </arc-step2-mapping>
    <!-- step 3: execute -->
    <arc-step3-preview-execute class="block w-full h-full" #step3
        [ngClass]="{ 'hidden': isLoading || hasError || currentStep !== ImportDialogStepsEnum.Step3PreviewExecute }">
    </arc-step3-preview-execute>
    <!-- step 4: result -->
    <arc-step4-result class="block w-full h-full" #step4
        [ngClass]="{ 'hidden': isLoading || hasError || currentStep !== ImportDialogStepsEnum.Step4Result }">
    </arc-step4-result>

    <div class="w-full h-full grid place-items-center" *ngIf="isLoading">
        <mat-spinner [diameter]="64"></mat-spinner>
    </div>

    <div class="w-full h-full flex flex-col items-center justify-center gap-4 text-error"
        *ngIf="!isLoading && hasError">
        <div>{{ 'General.Alert.UnexpectedError' | transloco }}</div>
        <div>{{ apiErrorMessage }}</div>
    </div>
</mat-dialog-content>

<mat-dialog-actions [ngSwitch]="currentStep" *ngIf="!isLoading && !hasError; else actionsLoadingOrError"
    class="arc-dialog-actions-start-left !px-12 !pb-4">
    <ng-container *ngSwitchCase="ImportDialogStepsEnum.Step1UploadFile">
        <button mat-stroked-button disabled>
            {{ 'Components.ImportDialog.Actions.InfoAndInstructions' | transloco }}
        </button>
        <div class="grow"></div>
        <button mat-stroked-button color="warn" (click)="cancel()">{{ 'General.Actions.Cancel' | transloco }}</button>
        <button mat-flat-button color="accent" [disabled]="!allowNext" (click)="next()">
            {{ 'General.Actions.Next' | transloco }}
        </button>
    </ng-container>

    <ng-container *ngSwitchCase="ImportDialogStepsEnum.Step2Mapping">
        <div class="grow"></div>
        <button mat-stroked-button color="warn" (click)="cancel()">{{ 'General.Actions.Cancel' | transloco }}</button>
        <button mat-stroked-button (click)="previous()">{{ 'General.Actions.Back' | transloco }}</button>
        <button mat-flat-button color="accent" [disabled]="!allowNext" (click)="next()">
            {{ 'Components.ImportDialog.Actions.ShowPreview' | transloco }}
        </button>
    </ng-container>

    <ng-container *ngSwitchCase="ImportDialogStepsEnum.Step3PreviewExecute">
        <div class="grow"></div>
        <button mat-stroked-button color="warn" (click)="cancel()">{{ 'General.Actions.Cancel' | transloco }}</button>
        <button mat-stroked-button (click)="previous()">{{ 'General.Actions.Back' | transloco }}</button>
        <button mat-flat-button color="accent" [disabled]="!allowNext" (click)="next()">
            {{ 'Components.ImportDialog.Actions.Execute' | transloco }}
        </button>
    </ng-container>

    <ng-container *ngSwitchCase="ImportDialogStepsEnum.Step4Result">
        <arc-button type="stroked" (click)="downloadErrorFile()" [isLoading]="isDownloadingErrorFile">
            {{ 'Components.ImportDialog.Actions.DownloadErrorFile' | transloco }}
        </arc-button>
        <div class="grow"></div>
        <button mat-flat-button color="accent" mat-dialog-close>{{ 'General.Actions.Close' | transloco }}</button>
    </ng-container>
</mat-dialog-actions>

<ng-template #actionsLoadingOrError>
    <mat-dialog-actions class="!px-12 !pb-4">
        <button mat-flat-button color="warn" mat-dialog-close>{{ 'General.Actions.Cancel' | transloco }}</button>
    </mat-dialog-actions>
</ng-template>
