import { Component, HostBinding, OnInit } from '@angular/core';

import { BaseColumnComponent } from '../base-column.component';
import { IconColumnModel } from '../../models/column-types/icon-column.model';
import { ColumnTitleAlignment } from '../../../../core/models/enums/column-title-alignment-enum';
import { IconModel } from '../../../../core/models/icon.model';
import { OptionalType } from '../../../../core/models/types/optional.type';

@Component({
    selector: 'arc-icon-column',
    templateUrl: './icon-column.component.html',
    styleUrls: ['./icon-column.component.scss']
})
export class IconColumnComponent<T extends object> extends BaseColumnComponent<T> implements OnInit {
    @HostBinding('class') override classes = 'w-full h-full grid';
    icon?: string;
    color?: string;
    tooltip = '';

    get iconColumnModel(): IconColumnModel {
        return new IconColumnModel(this.columnModel);
    }

    override ngOnInit(): void {
        super.ngOnInit();
        this.icon = this.getModel()?.name ?? undefined;
        this.color = this.getModel()?.color ?? '';
        this.color = `var(--${this.color})`;
        this.tooltip = this.getModel()?.tooltip ?? '';

        // setTimeout prevents expression changed after checked error
        setTimeout(() => this.columnModel.columnTitleAlignment = ColumnTitleAlignment.Center);
    }

    getModel(): OptionalType<IconModel> {
        return this.iconColumnModel.iconMapping?.get(this.getItemValue(this.columnModel.propertyName));
    }
}
