import { Component } from '@angular/core';

import { BaseColumnComponent } from '../base-column.component';
import { ColoredStackedColumnModel } from '../../models/column-types/colored-stacked-column.model';

@Component({
    selector: 'arc-colored-stacked-column',
    templateUrl: './colored-stacked-column.component.html',
    styleUrls: ['./colored-stacked-column.component.scss']
})
export class ColoredStackedColumnComponent<T extends object> extends BaseColumnComponent<T> {
    get coloredStackedModel(): ColoredStackedColumnModel {
        return new ColoredStackedColumnModel(this.columnModel);
    }

    getColor(): string {
        if (this.coloredStackedModel.color !== undefined) {
            const colorValue = this.getItemValue(this.coloredStackedModel.color);
            if(this.coloredStackedModel.isColorInHex) {
                return colorValue;
            }
            return colorValue.match(/(?<=\[).+?(?=])/)![0].toLowerCase() ?? '';
        }
        return '';
    }
}
