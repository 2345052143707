<div class="h-full flex flex-col w-full">
    <div *ngIf="shouldDisplayHeader" class="relative pl-2 my-1 flex items-center overflow-hidden">
        <span class="flex-none flex justify-{{column.columnTitleAlignment}}"
            *ngFor="let column of visibleColumns; let i = index" [attr.data-index]="i"
            [ngStyle]="{ 'width.px': column.widthPixels }">
            <span class="arc-mat-badge-icon cursor-pointer text-sm" (click)="sortColumn(column)"
                [matBadge]="currentSorting?.direction === SortDirectionEnum.Desc ? 'arrow_downward' : 'arrow_upward'"
                matBadgeSize="small"
                [matBadgeHidden]="!currentSorting || currentSorting.column !== column.propertyName">
                {{ column.shouldHideColumnTitle ? '' : (column.columnTitleKey | transloco) }}
                {{ !!column.columnTitleSuffix ? ' ' + column.columnTitleSuffix : '' }}
            </span>
        </span>
    </div>
    <table mat-table [dataSource]="dataSource" [class.compact-style]="shouldUseCompactStyle"
        [class.single-line-row]="isSingleLineRow">
        <ng-container *ngFor="let key of keys" [matColumnDef]="key">
            <ng-container *ngIf="!config.availableColumns[key].shouldHideColumnTitle">
                <th mat-header-cell *matHeaderCellDef class="text-xs"
                    [style.min-width.px]="config.availableColumns[key].widthPixels"
                    [style.max-width.px]="config.availableColumns[key].widthPixels"
                    [style.width.px]="config.availableColumns[key].widthPixels">
                    {{ config.availableColumns[key].columnTitleKey | transloco }}
                    {{ !!config.availableColumns[key].columnTitleSuffix ? ' ' + config.availableColumns[key].columnTitleSuffix : '' }}
                </th>
            </ng-container>
            <td mat-cell *matCellDef="let row">
                <ng-container *ngIf="config.availableColumns[key] as column">
                    <!-- dynamic columns -->
                    <div class="h-full flex items-center pr-[4px]" [style.padding-right.px]="columnGap"
                        [style.min-width.px]="column.widthPixels" [style.max-width.px]="column.widthPixels">
                        <ng-container arcDynamicColumn [columnModel]="column" [item]="row"></ng-container>
                    </div>
                </ng-container>

            </td>
        </ng-container>

        <ng-container *ngIf="visibleColumnNames.length>0">
            <tr mat-header-row *matHeaderRowDef="visibleColumnNames"></tr>
        </ng-container>
        <tr mat-row *matRowDef="let row; columns: keys;"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4"></td>
        </tr>
    </table>
    <mat-paginator class="bottom-0.5 right-0 bg-transparent" [class.w-full]="shouldUseCompactStyle"
        [hidePageSize]="shouldUseCompactStyle" [length]="totalRecords" [pageSize]="pageSize"
        [showFirstLastButtons]="!shouldUseCompactStyle">
    </mat-paginator>
</div>
