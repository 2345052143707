import { Component } from '@angular/core';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { HelpArticleListModel } from '../../../../../models/responses/help-article-list.model';
import { HelpArticleModel } from '../../../../../models/responses/help-article.model';

@Component({
    selector: 'arc-help-description',
    templateUrl: './help-description.component.html',
    styleUrl: './help-description.component.scss'
})
export class HelpDescriptionComponent extends BaseDetailWidget<HelpArticleListModel, HelpArticleModel> {}
