import { Injectable, inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { TranslationService } from './translation.service';
import { OptionalType } from '../models/types/optional.type';
import { GeneralPromptDialogComponent } from '../../components/dialogs/general-prompt-dialog/general-prompt-dialog.component';
import {
    DialogButtonConfigurationEnum
} from '../../components/dialogs/general-prompt-dialog/models/enums/dialog-button-configuration.enum';

@Injectable({
    providedIn: 'root'
})
export class DialogService {
    private readonly translationService = inject(TranslationService);
    private readonly matDialog = inject(MatDialog);

    openDeleteDialog(
        entityName: string,
        itemsCount: number,
        customDeleteConfirmationMessage: OptionalType<string> = undefined
    ): MatDialogRef<GeneralPromptDialogComponent> {
        const isMultiple = itemsCount > 1;
        const prefix = 'General.Prompts.';
        const entityNameLocal = this.translationService.getText(`${entityName}.EntityName`);
        const entityNamePlural = this.translationService.getText(`${entityName}.EntityNamePlural`);

        return this.matDialog.open(GeneralPromptDialogComponent, {
            data: {
                promptKey: `${prefix}${isMultiple ? 'DeleteXEntries' : 'DeleteEntry'}`,
                messageKey: `${prefix}${isMultiple ? 'DeleteEntriesDefaultDescription' : 'DeleteEntryDefaultDescription'}`,
                customDeleteConfirmationMessage: customDeleteConfirmationMessage,
                promptParams: { value: itemsCount, entity: entityNameLocal, entityPlural: entityNamePlural },
                buttonConfiguration: DialogButtonConfigurationEnum.DeleteCancel
            }
        });
    }
}
