import { BaseEntityModel } from '../../../core/abstractions/base-entity.model';

export class ContractEditModel extends BaseEntityModel {
    key!: string;
    title_de!: string;
    title_en?: string;
    title_fr?: string;
    title_it?: string;
    text_de!: string;
    text_en?: string;
    text_fr?: string;
    text_it?: string;
    lastChangesSummary_de!: string;
    lastChangesSummary_en?: string;
    lastChangesSummary_fr?: string;
    lastChangesSummary_it?: string;

    constructor(init?: Partial<ContractEditModel>) {
        super();
        if (!!init) {
            Object.assign(this, init);
        }
    }
}
