import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseStore } from './base.store';
import { ApiResponseModel } from '../../app/models/responses/api-response.model';
import { SearchRequestModel } from '../../app/models/requests/search-request.model';
import { FilterItemModel } from '../models/filter-item.model';
import { SearchResponseModel } from '../../app/models/responses/search-response.model';
import { ContextActionModel } from '../models/context-action.model';
import { ExecuteActionModel } from '../models/execute-action.model';
import { ResponseTypeEnum } from '../models/enums/response-type.enum';
import { SavedFilterModel } from '../models/saved-filter.model';
import { ContextActionParametersDialogModel } from '../../app/models/responses/context-action-parameters-dialog.model';
import { Tools } from '../utils/tools';
import { Identifyable } from './identifyable';

/**
 * Store base class for searches.
 */
@Injectable()
// TODO change BaseApiModel to BaseListViewModel
export abstract class BaseSearchStore<TSearch extends Identifyable<TId>, TId = number> extends BaseStore {
    /**
     * Retrieves an items list model.
     * @param id Item's ID.
     */
    getListModel(id: any): Observable<ApiResponseModel<TSearch>> {
        return this._requestService.makeGet<ApiResponseModel<TSearch>>(
            this.getUrl(this._config.actions.getList),
            Tools.Utils.getId(id)
        );
    }

    /**
     * Searches the records and returns the result count.
     * @param searchRequest Search request.
     */
    count(searchRequest: SearchRequestModel): Observable<ApiResponseModel<number>> {
        return this._requestService.makePost<ApiResponseModel<number>>(
            this.getUrl('count'),
            searchRequest,
            this.isAbsoluteUrl
        );
    }

    /**
     * Searches the records.
     * @param searchRequest Search request.
     */
    search(searchRequest: SearchRequestModel): Observable<ApiResponseModel<SearchResponseModel<TSearch, TId>>> {
        return this._requestService.makePost<ApiResponseModel<SearchResponseModel<TSearch, TId>>>(
            this.getUrl('search'),
            searchRequest,
            this.isAbsoluteUrl
        );
    }

    /**
     * Filters the records and returns a CSV file.
     * @param searchRequest Search request.
     */
    downloadCsv(searchRequest: SearchRequestModel): Observable<any> {
        searchRequest.pageSize = 9999999;
        return this._requestService.makePost<any>(
            this.getUrl('search/csv'),
            searchRequest,
            this.isAbsoluteUrl,
            undefined,
            undefined,
            ResponseTypeEnum.ArrayBuffer
        );
    }

    /**
     * Saves the filters for a page.
     * @param request A search request containing the filters to save.
     */
    saveFilters(request: SavedFilterModel): Observable<ApiResponseModel<string>> {
        return this._requestService.makePost<ApiResponseModel<string>>(
            this.getUrl('save-filters'),
            request,
            this.isAbsoluteUrl
        );
    }

    /**
     * Deletes a set of filters for a page.
     * @param id The id of the filters to delete.
     */
    deleteFilters(id: string): Observable<ApiResponseModel<boolean>> {
        return this._requestService.makeDelete<ApiResponseModel<boolean>>(
            this.getUrl('delete-filters'),
            id,
            this.isAbsoluteUrl
        );
    }

    /**
     * Gets the saved filters and sortings for a page.
     */
    getSavedFilters(): Observable<ApiResponseModel<SavedFilterModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<SavedFilterModel[]>>(
            this.getUrl('list-saved-filters')
        );
    }

    /**
     * Gets the allowed filters for a page.
     */
    getAllowedFilters(): Observable<ApiResponseModel<FilterItemModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<FilterItemModel[]>>(
            this.getUrl('allowed-filters')
        );
    }

    /**
     * Retrieves the context actions assigned to the entity.
     */
    getContextActions(): Observable<ApiResponseModel<ContextActionModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<ContextActionModel[]>>(
            this.getUrl('context-actions')
        );
    }

    /**
     * Retrieves the parameters for the specified context action.
     */
    getContextActionParameters(
        contextActionKey: string,
        data: ExecuteActionModel<TId>
    ): Observable<ApiResponseModel<ContextActionParametersDialogModel>> {
        return this._requestService.makePost<ApiResponseModel<ContextActionParametersDialogModel>>(
            this.getUrl(`context-action/${contextActionKey}/parameters`),
            {
                ...data,
                selectedIds: data.selectedIds.map(id => Tools.Utils.getId(id))
            }
        );
    }

    /**
     * Executes the action with the provided parameters.
     */
    executeAction(
        contextActionKey: string,
        data: ExecuteActionModel<TId>,
        expectsFileResult: boolean
    ): Observable<ApiResponseModel<boolean>> {
        return this._requestService.makePost<ApiResponseModel<boolean>>(
            this.getUrl(`context-action/${contextActionKey}/execute`),
            {
                ...data,
                selectedIds: data.selectedIds.map(id => Tools.Utils.getId(id))
            },
            undefined,
            expectsFileResult
        );
    }
}
