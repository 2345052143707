<div class="grid items-center grid-cols-12 grid-flow-row auto-cols-auto gap-4">
    <arc-autocomplete class="col-span-6 ssm:col-span-9" [formControl]="streetControl" label="General.Address.Street"
        [searchFn]="searchFn.bind(this)" [optionDisplayAccessor]="optionDisplayFn.bind(this)" valueAccessor="street"
        [allowArbitraryValues]="true" (optionSelected)="handleAddressSelected($event)"
        (blurred)="onStreetControlBlur()">
    </arc-autocomplete>

    <arc-input class="col-span-6 ssm:col-span-3" labelKey="General.Address.StreetNumber"
        [control]="streetNumberControl"></arc-input>
    <div class="col-span-1 ssm:col-span-2"></div>
    <arc-place-select class="col-span-12" [zipFormControl]="zipControl" [cityFormControl]="cityControl"
        [countryIsoCodeFormControl]="countryIsoCodeControl"></arc-place-select>
</div>