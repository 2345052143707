import { Component } from '@angular/core';

import { fabAnimations } from './animations';
import { BaseButtonsBarComponent } from '../abstractions/base-buttons-bar.component';
import { Identifyable } from '../../core/abstractions/identifyable';

@Component({
    selector: 'arc-fab',
    templateUrl: './fab.component.html',
    styleUrls: ['./fab.component.scss'],
    animations: fabAnimations
})
export class FabComponent<T extends Identifyable<TId>, TId> extends BaseButtonsBarComponent<T, TId> {
    fabTogglerState = 'inactive';

    toggle(): void {
        if (this.fabTogglerState === 'inactive') {
            this.showMenu();
        } else {
            this.hideMenu();
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected override setupItems(data?: any, evaluateMultipleSelection = false): void {
        super.setupItems(data, true);
    }

    private showMenu(): void {
        this.fabTogglerState = 'active';
    }

    private hideMenu(): void {
        this.fabTogglerState = 'inactive';
    }
}
