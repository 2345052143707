<div class="!h-[70px] !w-[90px] mb-4">
    <img [src]="image" width="90" height="70" class="object-contain h-full" />
</div>
<arc-file-upload
    class="block w-full !h-[60px]"
    fileTypes=".png, .jpg, .jpeg"
    [shouldForceMinHeight]="false"
    [formControl]="fileControl"
    [allowUrlUpload]="false"
    [maxFileSizeInKb]="5120">
</arc-file-upload>
