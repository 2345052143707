import { BaseCrudStore } from '../../../../core/abstractions/base-crud.store';
import { BaseColumnModel } from '../../../dynamic-table/models/column-types/base-column.model';
import { TableListDetailWidgetModel } from './table-list-detail-widget.model';
import { PaginatorConfigModel } from '../../../../core/models/paginator-config.model';
import { BaseListViewConfigModel } from '../../../../core/abstractions/base-list-view-config.model';
import { DictionaryType } from '../../../../core/models/types/dictionary.type';
import { ColumnFilterModel } from '../../../../core/models/column-filter.model';
import { Identifyable } from '../../../../core/abstractions/identifyable';

export class TableListViewConfigModel<
    TList extends Identifyable<TId>,
    T extends Identifyable<TId>,
    TCreate extends Identifyable<TId> = T,
    TUpdate extends Identifyable<TId> = TCreate,
    TId = number
> extends BaseListViewConfigModel<T, TList, TCreate, TUpdate, TId> {
    entityName!: string;
    availableColumns!: DictionaryType<BaseColumnModel>;
    defaultColumnOrder!: string[];
    defaultFilters?: ColumnFilterModel[];
    availableDetailWidgets!: DictionaryType<TableListDetailWidgetModel<TList, T, TId>>;
    defaultDetailWidgetOrder!: string[];
    store!: BaseCrudStore<T, TList, TCreate, TUpdate, TId>;
    showCheckboxes = true;
    paginatorConfig = new PaginatorConfigModel();

    constructor(init?: Partial<TableListViewConfigModel<TList, T, TCreate, TUpdate, TId>>) {
        super(init);

        if (!!init) {
            Object.assign(this, init);
        }
    }
}
