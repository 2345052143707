import { Pipe, PipeTransform, inject } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { Observable, map, of } from 'rxjs';

import { FormControlErrorsService } from '../services/form-control-errors.service';

@Pipe({ name: 'errorTranslator', pure: false })
export class ErrorTranslatorPipe implements PipeTransform {
    private lastInput: ValidationErrors | undefined | null;
    private lastResult = of('');
    private readonly formControlErrorsService = inject(FormControlErrorsService);

    transform(errors: ValidationErrors | undefined | null): Observable<string> {
        if (errors === this.lastInput) {
            return this.lastResult;
        }
        this.lastInput = errors;
        this.lastResult = this.formControlErrorsService.getErrorMessageObs(errors).pipe(map(s => s ?? ''));
        return this.lastResult;
    }
}
