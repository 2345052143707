import { FeedbackListModel } from '../../../../app/models/responses/feedback-list.model';
import { CustomColumnModel } from './custom-column.model';

export class FeedbackVoteTextColumnModel extends CustomColumnModel<FeedbackListModel> {
    refreshFn?: () => void;
    constructor(init?: Partial<FeedbackVoteTextColumnModel>) {
        super();

        if (!!init) {
            Object.assign(this, init);
        }
    }
}
