export enum UserRoles {
    Anonymous = 1,
    Basic = 2,
    Feedback = 4,
    Tickets = 8,
    Leads = 16,
    Support = 32,
    Features = 64,
    Invoices = 128,
    Users = 256,
    Newsletter = 512,
    ReportTemplates = 1024,
    HelpArticles = 2048,
    Dashboard = 4096,
    Licenses = 8192,
    Benefits = 16384,
    Transactions = 32768,
    ModuleBundles = 65536,
    Contracts = 131072,
    Developer = 262144,
    HardwareBundles = 524288,
    ApiIntegrations = 1048576,
    ApiIntegrationAdmin = 2097152,
    ContentArticles = 4194304,
    HelpTopics = 8388608,
    ToolTips = 16777216,
    HelpArticlesAdmin = 33554432,
    AllData = 67108864,
    BlogArticles = 134217728,
    BlogArticlesAdmin = 268435456,
    CustomerInteractions = 536870912,
    /* Admin 2^30 */
    Admin = 1073741824
}
