import { Component } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { LicenseEditModel } from '../../../../../models/requests/license-edit.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { LicenseModel } from '../../../../../models/license.model';
import { ComplexDataTypesEnum } from '../../../../../../core/models/complex-data-types.enum';

@Component({
    selector: 'arc-license-edit-users',
    templateUrl: './license-edit-users.component.html',
    styleUrl: './license-edit-users.component.scss'
})
export class LicenseEditUsersComponent extends BaseEditSidebarItemComponent<LicenseModel, LicenseEditModel> {
    override formGroup = new FormGroup({
        userId: new ArcFormControl<OptionalType<number>>(undefined, Validators.required),
        resellerId: new ArcFormControl<OptionalType<number>>(undefined),
        recommendationUserId: new ArcFormControl<OptionalType<number>>(undefined)
    });

    ComplexDataTypesEnum = ComplexDataTypesEnum;

    onItemSet(): void {
        this.formGroup.patchValue(this.item);
    }

    override prepareSaveModel(itemCopy: LicenseEditModel): LicenseEditModel {
        return { ...itemCopy, ...this.formGroup.value, user: undefined };
    }
}
