import { Component } from '@angular/core';

import { BaseCustomColumnComponent } from '../../../../../../components/dynamic-table/column-components/custom-column/base-custom-column.component';
import { FeedbackListModel } from '../../../../../models/responses/feedback-list.model';

@Component({
    selector: 'arc-feedback-status',
    templateUrl: './feedback-status.component.html',
    styleUrl: './feedback-status.component.scss'
})
export class FeedbackStatusComponent extends BaseCustomColumnComponent<FeedbackListModel> {
    get colorClasses(): string[] {
        return this.item.isProcessed ? ['bg-success', 'text-on-success'] : ['bg-element-disabled', 'text-on-element-disabled'];
    }
}
