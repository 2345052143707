import { Injectable } from '@angular/core';

import { StoreConfig } from '../../../core/models/store.config';
import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { FeedbackModel } from '../../models/feedback.model';
import { FeedbackListModel } from '../../models/responses/feedback-list.model';

@Injectable({
    providedIn: 'root'
})
export class FeedbacksStore extends BaseCrudStore<FeedbackModel, FeedbackListModel>  {
    constructor() {
        super(new StoreConfig({ baseController: 'feedbacks' }));
    }
}
