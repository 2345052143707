import { BaseListViewModel } from '../../../../core/abstractions/base-list-view.model';
import { BaseSearchStore } from '../../../../core/abstractions/base-search.store';
import { BaseColumnModel } from '../../../dynamic-table/models/column-types/base-column.model';

export class DataSelectionDialogCustomDataModel {
    dialogTitleKey!: string;
    store!: BaseSearchStore<BaseListViewModel>;
    columnConfig!: BaseColumnModel[];
    isMultiSelect? = false;

    constructor(init: DataSelectionDialogCustomDataModel) {
        this.dialogTitleKey = init.dialogTitleKey;
        this.store = init.store;
        this.columnConfig = init.columnConfig;
        this.isMultiSelect = init.isMultiSelect ?? false;
    }
}
