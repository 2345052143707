import { MatTreeFlattener } from '@angular/material/tree';

import { TreeItemNode } from '../../../../core/models/tree-item.node';
import { TreeDataSelectionConfig } from '../../../form/tree-autocomplete/models/tree-data-selection.config';
import { Tools } from '../../../../core/utils/tools/index';
import { TreeItemFlatNode } from '../../../../core/models/tree-item.flat-node';
import { Identifyable } from '../../../../core/abstractions/identifyable';
import { OptionalType } from '../../../../core/models/types/optional.type';

export class SearchTools {
    static transformSearchResult<T extends Identifyable<TId>, TId>(
        items: T[], treeConfig: TreeDataSelectionConfig<T, TId>
    ): TreeItemNode<TId>[] {
        const result: TreeItemNode<TId>[] = [];

        items.forEach(item => {
            const pathToRoot: T[] = [];
            let currentItem: OptionalType<T> = item;
            do {
                pathToRoot.push(currentItem);
                currentItem = treeConfig.getNodeParent(currentItem);
            } while (!!currentItem);

            const rootItem = pathToRoot.pop()!;
            let rootNode = result.find(r => r.id === rootItem.id);
            if (!rootNode) {
                rootNode = {
                    id: rootItem.id,
                    text: treeConfig.getNodeTitle(rootItem),
                    hasChildren: treeConfig.hasNodeChildren(rootItem)
                };
                result.push(rootNode);
            }

            let currentParentNode = rootNode;
            currentItem = pathToRoot.pop();
            while (!!currentItem) {
                let childNode = currentParentNode.children?.find(r => r.id === currentItem!.id);
                if (!childNode) {
                    if (!currentParentNode.children) {
                        currentParentNode.children = [];
                    }
                    childNode = {
                        id: currentItem.id,
                        text: treeConfig.getNodeTitle(currentItem),
                        hasChildren: treeConfig.hasNodeChildren(currentItem)
                    };
                    currentParentNode.children.push(childNode);
                }
                currentParentNode = childNode;
                currentItem = pathToRoot.pop();
            }
        });

        return result;
    }

    static flattenSearchResult<T extends Identifyable<TId>, TId>(
        nodes: TreeItemNode<TId>[],
        treeConfig: TreeDataSelectionConfig<T, TId>,
        treeFlattener: MatTreeFlattener<TreeItemNode<TId>, TreeItemFlatNode<TId>>
    ): TreeItemFlatNode<TId>[] {
        if (treeConfig.allowsInlineCreation) {
            nodes.forEach(n => {
                n.children = n.children || [];

                n.children.forEach(c => {
                    c.children = c.children || [];
                    c.children.push({
                        id: undefined as TId, // new record button doesn't have id
                        text: '',
                        hasChildren: false,
                        isNewRecordButton: true,
                        parentNode: c
                    });
                });
                n.children.push({
                    id: undefined as TId, // new record button doesn't have id
                    text: '',
                    hasChildren: false,
                    isNewRecordButton: true,
                    parentNode: n
                });
            });

            nodes.push({
                id: undefined as TId, // new record button doesn't have id
                text: '',
                hasChildren: false,
                isNewRecordButton: true
            });
        }

        return this.updateFlattenedNodes(nodes, treeFlattener);
    }

    static updateFlattenedNodes<TId>(
        nodes: TreeItemNode<TId>[], treeFlattener: MatTreeFlattener<TreeItemNode<TId>, TreeItemFlatNode<TId>>
    ): TreeItemFlatNode<TId>[] {
        const flattenedNodes = treeFlattener.flattenNodes(nodes);
        const flattenedNodesBkp = Tools.Utils.deepCopy(flattenedNodes);

        // Cleaning flattened nodes for search.
        // We need to make sure all the repeated nodes are removed in order to avoid using another whole set of flatteners only
        // for this scenario.
        flattenedNodes.forEach(fn => {
            const idx = flattenedNodesBkp.findIndex(fnb => fnb.id === fn.id && fnb.level < fn.level);

            if (idx >= 0) {
                flattenedNodesBkp.splice(idx, 1);
            }
        });

        return flattenedNodesBkp;
    }
}
