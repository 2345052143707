import { IconModel } from '../../../../core/models/icon.model';
import { ColumnTypeEnum } from '../enums/column-type.enum';
import { BaseColumnModel } from './base-column.model';

export class IconColumnModel extends BaseColumnModel {
    iconMapping!: Map<any, IconModel>;

    constructor(init?: Partial<IconColumnModel>) {
        super(ColumnTypeEnum.Icon);

        if (!!init) {
            Object.assign(this, init);
        }
    }
}
