import { WidgetModel } from './widget.model';
import { StatisticParametersRequestModel } from '../../app/models/requests/statistic-parameters-request.model';

export class StatisticsWidgetModel extends WidgetModel {

    /**
     * The parameters for a specific widget instance.
     */
    parameters!: StatisticParametersRequestModel;

    constructor(init: Partial<WidgetModel>, id?: string) {
        super(init, id);

        if (!(init as StatisticsWidgetModel)?.parameters) {
            this.parameters = new StatisticParametersRequestModel();
        }
    }
}
