<div (click)="handleSelectTicket(ticket.id)"
    class="border border-on-app-light border-solid rounded-xl p-2 flex flex-row group hover:bg-element-hover hover:cursor-pointer hover:text-on-app">
    <arc-icon icon="chat_bubble" [size]="30" class="flex-none"></arc-icon>
    <div class="ml-5 max-w-[25rem] grow">
        <h3 class="font-bold">#{{ticket.id}} {{ ticket.title}}</h3>
        @if(ticket.resolvedOrClosedDate) {
            <span>{{ 'Tickets.Edit.ResolvedOn' | transloco }} {{ ticket.resolvedOrClosedDate | date }}</span>
        } @else {
            <span>{{ 'Tickets.Edit.CreatedOn' | transloco }} {{ ticket.createdDate| date }}</span>
        }
    </div>
    <arc-ticket-status [ticket]="ticket"></arc-ticket-status>
</div>