import { Injectable, inject } from '@angular/core';
import { EMPTY, Observable, catchError, map } from 'rxjs';

import { BingApiStore } from './stores/bing-api.store';
import { environment } from '../../environments/environment';
import { AddressModel } from '../models/address.model';

@Injectable({
    providedIn: 'root'
})
export class BingApiService {
    private readonly searchMaxResults = 5;
    private readonly bingApiStore = inject(BingApiStore);

    getSuggestions(query: string, countryIsoCode?: string): Observable<AddressModel[]> {
        return this.bingApiStore.getSuggestions(query, environment.bingMapsApiKey, countryIsoCode ?? 'CH', this.searchMaxResults).pipe(
            catchError(() => EMPTY),
            map(
                response =>
                    response.resourceSets
                        ?.at(0)
                        ?.resources?.at(0)
                        ?.value?.map(r => r.address) ?? []
            ),
            map(bingAddresses => bingAddresses.filter(a => !!a?.addressLine)),
            map(bingAddresses =>
                bingAddresses.map(
                    a =>
                        new AddressModel({
                            street: a.streetName,
                            streetNumber: a.houseNumber,
                            zip: a.postalCode,
                            city: a.locality,
                            countryIsoCode: a.countryRegionIso2
                        })
                )
            )
        );
    }
}
