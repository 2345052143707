import { Injectable } from '@angular/core';

import { StoreConfig } from '../../../core/models/store.config';
import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { NewsletterSubscriberModel } from '../../models/newsletter-subscriber.model';
import { NewsletterSubscriberListModel } from '../../models/responses/newsletter-subscriber-list.model';
import { NewsletterSubscriberEditModel } from '../../models/requests/newsletter-subscriber-edit.model';

@Injectable({
    providedIn: 'root'
})
// eslint-disable-next-line max-len
export class NewsletterSubscribersStore extends BaseCrudStore<NewsletterSubscriberModel, NewsletterSubscriberListModel, NewsletterSubscriberEditModel>  {
    constructor() {
        super(new StoreConfig({ baseController: 'newsletter-subscribers' }));
    }
}
