import { Component } from '@angular/core';

import { HardwareOrderStatesEnum, HardwareOrderStatesEnumExtensions } from '../../../../../models/enums/hardware-order-states.enum';
import { LicenseHardwareOrderListModel } from '../../../../../models/responses/license-hardware-order-list.model';
import { BaseCustomColumnComponent } from '../../../../../../components/dynamic-table/column-components/custom-column/base-custom-column.component';

@Component({
    selector: 'arc-hardware-order-status',
    templateUrl: './hardware-order-status.component.html',
    styleUrl: './hardware-order-status.component.scss'
})
export class LicenseHardwareOrderStatusComponent extends BaseCustomColumnComponent<LicenseHardwareOrderListModel> {
    HardwareOrderStates = HardwareOrderStatesEnum;

    getColorClasses(): string {
        return HardwareOrderStatesEnumExtensions.getColor(this.item.orderState);
    }
}
