import { Directive, inject } from '@angular/core';

import { StoreConfig } from '../models/store.config';
import { RequestService } from '../services/request.service';

/**
 * Stores default class.
 */
@Directive()
export abstract class BaseStore {
    /**
     * Store configuration.
     */
    protected _config: StoreConfig;

    /**
     * Whether it's static (uses local data).
     */
    protected isStatic = false;

    /**
     * Whether the URL is absolute (external server).
     */
    protected isAbsoluteUrl = false;

    /**
     * Request service.
     */
    protected readonly _requestService = inject(RequestService);

    protected constructor(config: StoreConfig) {
        this.isAbsoluteUrl = this.isUrlAbsolute(config.baseController ?? '');
        this._config = config;
    }

    /**
     * Retrieves a URL with the base controller.
     * @param action Action.
     */
    protected getUrl(action: string): string {
        return `${this._config.baseController}${action ? `/${action}` : ''}`;
    }

    /**
     * Checks whether the URL is absolute or not.
     * URLs starting with "[protocol]://" will be considered absolute.
     *  - Accepted protocols: http, https, ftp, ftps
     * @param url URL.
     */
    private isUrlAbsolute(url: string): boolean {
        if (!url) {
            return false;
        }

        return /^(f|ht)tps?:/i.test(url);
    }
}
