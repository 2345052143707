import { Injectable, inject } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, EMPTY, throwError } from 'rxjs';

import { AuthService } from '../auth.service';
import { SettingsStorage } from '../storages/settings.storage';
import { ErrorsService } from '../errors.service';
import { AlertService } from '../alert.service';
import { LoggerService } from '../logger.service';
import { OptionalType } from '../../models/types/optional.type';
import { BrokenRuleModel } from '../../../app/models/broken-rule.model';
import { EnvironmentService } from '../environment.service';

@Injectable()
export class ResponseHandlerHttpInterceptor implements HttpInterceptor {
    private readonly errorsService = inject(ErrorsService);
    private readonly loggerService = inject(LoggerService);
    private readonly alertService = inject(AlertService);
    private readonly authService = inject(AuthService);
    private readonly settingsStorage = inject(SettingsStorage);
    private readonly environmentService = inject(EnvironmentService);

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((errorResponse: HttpErrorResponse) => {
                if (!req.url.startsWith(this.environmentService.baseUrl)) {
                    return throwError(() => errorResponse); // Let the error propagate without handling it.
                }
                if (!!errorResponse) {
                    switch (errorResponse.status) {
                        case 401:
                            this.handle401();
                            break;
                        case 403:
                            this.handle403();
                            break;
                        case 500:
                            this.handle500(errorResponse);
                            break;
                        case 588:
                            this.handle588(errorResponse);
                            break;
                    }
                }

                return EMPTY;
            })
        );
    }

    private handle401(): void {
        this.settingsStorage.saveNotLoggedRedirectUrl(window.location.href);
        this.authService.goToLoginPage();
    }

    private handle403(): void {
        this.alertService.showAlert('General.Permissions.NoPermission', 'General.Permissions.NoPermissionTitle');
    }

    private handle500(errorResponse: HttpErrorResponse): void {
        this.loggerService.error(errorResponse.error.message);
        let content = '';

        if (!!errorResponse.error.logId) {
            const copyIcon =
                // eslint-disable-next-line max-len
                '<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M360-240q-33 0-56.5-23.5T280-320v-480q0-33 23.5-56.5T360-880h360q33 0 56.5 23.5T800-800v480q0 33-23.5 56.5T720-240H360Zm0-80h360v-480H360v480ZM200-80q-33 0-56.5-23.5T120-160v-560h80v560h440v80H200Zm160-240v-480 480Z"/></svg>';
            content = `<div style="display: flex; align-items: center; justify-content: center">
                            <div>
                                ID: ${errorResponse.error.logId.split('-')[0]}
                            </div>
                            <div style="margin-left: 2px">
                                <a href="javascript:navigator.clipboard.writeText('${errorResponse.error.logId}')">${copyIcon}</a>
                            </div>
                        </div>`;
        }

        content += errorResponse.error.message;

        this.alertService.showError(content, undefined, true, true);
    }

    private async handle588(errorResponse: HttpErrorResponse): Promise<void> {
        this.errorsService.setBusinessException(errorResponse.error);

        if (!this.errorsService.shouldDisplayAlertOnError) {
            return;
        }

        let serviceResult = errorResponse.error;
        if (errorResponse.error instanceof Blob) {
            // since a 588 is never a file, we need to parse
            // an expected file result to a json message first
            serviceResult = await this.parseBlobError(errorResponse.error);
        }

        const brokenRules = serviceResult.brokenRules as OptionalType<BrokenRuleModel[]>;
        if (!!brokenRules && brokenRules.length > 0) {
            let content = '<ul class="text-start list-disc list-outside pl-8 w-full">';
            brokenRules.forEach(e => (content += `<li>${e.message}</li>`));
            content += '</ul>';
            this.alertService.showAlert(content, serviceResult.message ?? undefined, true, true);
        } else {
            const content = serviceResult.message || serviceResult.value.message;
            this.alertService.showAlert(content, undefined, true, false);
        }
    }

    private parseBlobError(errorBlob: Blob): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event: any) => {
                const result = JSON.parse(event.target.result);
                // Assuming result has a specific structure of IServiceResult
                resolve(result);
            };
            reader.onerror = error => {
                console.error('Error parsing error blob:', error);
                reject(error);
            };
            reader.readAsText(errorBlob);
        });
    }
}
