import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { StoreConfig } from '../../../core/models/store.config';
import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { ApiResponseModel } from '../../models/responses/api-response.model';
import { KeyValueModel } from '../../../core/models/key-value.model';
import { BlogArticleModel } from '../../models/responses/blog-article.model';
import { BlogArticleListModel } from '../../models/responses/blog-article-list.model';
import { BlogArticleEditModel } from '../../models/requests/blog-article-edit.model';

@Injectable({
    providedIn: 'root'
})
export class BlogArticlesStore extends BaseCrudStore<BlogArticleModel, BlogArticleListModel, BlogArticleEditModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'blog-articles' }));
    }

    getHelpTopics(): Observable<ApiResponseModel<KeyValueModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<KeyValueModel[]>>(this.getUrl('help-topics'));
    }

    getHelpSubTopics(topicId: number): Observable<ApiResponseModel<KeyValueModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<KeyValueModel[]>>(this.getUrl(`help-subtopics/${topicId}`));
    }
}
