export enum UnitsEnum {
    Grams = 'Grams',
    Days = 'Days',
    Percent= 'Percent'
}

export class UnitsEnumHepers {
    static getUnitShortTextKey(unit: UnitsEnum): string {
        return `General.Units.${unit}.Short`;
    }

    static getUnitLongTextKey(unit: UnitsEnum): string {
        return `General.Units.${unit}.Long`;
    }
}
