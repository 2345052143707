import { BaseColumnModel } from './base-column.model';
import { ColumnTypeEnum } from '../enums/column-type.enum';

export class GenericStatusColumnModel extends BaseColumnModel {
    descriptionColumn = 'statusDescription';
    statusColumn = 'status';
    bgColor?: (value: any) => string;

    constructor(init?: Partial<GenericStatusColumnModel>) {
        super(ColumnTypeEnum.GenericStatus);

        if (!!init) {
            Object.assign(this, init);
        }
    }
}
