import { Component } from '@angular/core';

import { BaseSidebarHeaderAdditionalInfoComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-sidebar-header-additional-info.component';
import { TicketModel } from '../../../../../models/ticket.model';
import { TicketStatusEnum, TicketStatusEnumExtensions } from '../../../../../models/enums/ticket-status.enum';

@Component({
    selector: 'arc-ticket-header-additional-info',
    templateUrl: './ticket-header-additional-info.component.html',
    styleUrl: './ticket-header-additional-info.component.scss'
})
export class TicketHeaderAdditionalInfoComponent extends BaseSidebarHeaderAdditionalInfoComponent<TicketModel> {
    onItemSet(): void {}

    getStatusColorClasses(ticketStatus: TicketStatusEnum): string {
        return TicketStatusEnumExtensions.getColor(ticketStatus);
    }
}
