import { BaseRequestModel } from '../../../core/abstractions/base-request.model';

export class ResetPasswordRequestModel extends BaseRequestModel {
    subdomain!: string;
    newPassword!: string;
    confirmPassword!: string;

    constructor(init?: Partial<ResetPasswordRequestModel>) {
        super();

        if (!!init) {
            Object.assign(this, init);
        }
    }
}
