import { Component, OnInit, inject } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { ReportTemplateEditRequestModel } from '../../../../../models/requests/report-template-edit-request.model';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';
import { TranslationService } from '../../../../../../core/services/translation.service';
import { SelectOptionModel } from '../../../../../../core/models/select-option.model';
import { OptionalType } from '../../../../../../core/models/types/optional.type';

@Component({
    selector: 'arc-report-edit',
    templateUrl: './report-template-edit-base-data.component.html',
    styleUrls: ['./report-template-edit-base-data.component.scss']
})
export class ReportTemplateEditBaseDataComponent extends BaseEditSidebarItemComponent<ReportTemplateEditRequestModel> implements OnInit {
    GeneralDataTypeEnum = GeneralDataTypeEnum;

    override formGroup = new FormGroup({
        title_de: new ArcFormControl('', Validators.required),
        title_fr: new ArcFormControl('', Validators.required),
        title_en: new ArcFormControl('', Validators.required),
        title_it: new ArcFormControl('', Validators.required),
        description_de: new ArcFormControl('', Validators.required),
        description_fr: new ArcFormControl('', Validators.required),
        description_en: new ArcFormControl('', Validators.required),
        description_it: new ArcFormControl('', Validators.required),
        key: new ArcFormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(5)]),
        requiredModuleId: new ArcFormControl(),
        version: new ArcFormControl(1.0, Validators.required),
        reportBlobData: new ArcFormControl<OptionalType<number[]>>(undefined),
        fileControl: new ArcFormControl(),
        fileName: new ArcFormControl()
    });

    allowedLanguages: SelectOptionModel<string>[] = [];
    private readonly translationService = inject(TranslationService);

    constructor() {
        super();
        this.allowedLanguages = this.translationService.allowedLanguages.map(l => ({ label: l.name, value: l.code }));
    }

    ngOnInit(): void {
        this.formGroup.controls.fileControl.valueChanges.subscribe(async (file?: File) => {
            this.formGroup.controls.reportBlobData.setValue(!!file ? Array.from(new Uint8Array(await file.arrayBuffer())) : undefined);
            this.formGroup.controls.fileName.setValue(!!file ? file.name : '');
        });
    }

    override onItemSet(): void {
        this.formGroup.patchValue(this.item);

        if (this.isCreate) {
            this.formGroup.controls.reportBlobData.setValidators(Validators.required);
            this.formGroup.controls.fileControl.setValidators(Validators.required);
        }
    }

    override prepareSaveModel(itemCopy: ReportTemplateEditRequestModel): ReportTemplateEditRequestModel {
        return new ReportTemplateEditRequestModel({ ...itemCopy, ...this.formGroup.value });
    }
}
