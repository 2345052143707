/* eslint-disable @typescript-eslint/naming-convention */
import { BaseEntityModel } from '../../../core/abstractions/base-entity.model';
import { PaymentPeriodsEnum } from '../enums/payment-periods.enum';
import { PaymentTypesEnum } from '../enums/payment-types.enum';

export class BenefitEditModel extends BaseEntityModel {
    productId?: number;
    title!: string;
    validFrom?: Date;
    validThru?: Date;
    paymentType?: PaymentTypesEnum;
    paymentPeriod?: PaymentPeriodsEnum;
    discountPercent?: number;
    discountValue?: number;
    code?: string;
    newLicensesOnly?: boolean;
    benefitOnRecurrentFee?: boolean;
    userId?: number;
    resellerId?: number;
    licenseId?: number;
    moduleBundleId?: number;
    constructor(init?: Partial<BenefitEditModel>) {
        super();
        if (!!init) {
            Object.assign(this, init);
        }
    }
}
