import { Injectable, inject } from '@angular/core';

import { SidebarService } from './sidebar.service';
import { HelpComponent } from '../../components/help/help.component';

@Injectable({
    providedIn: 'root'
})
export class HelpService {
    private readonly sidebarService = inject(SidebarService);

    openHelp(helpArticleId?: string): void {
        this.sidebarService
            .openRight<undefined>({
            component: HelpComponent,
            data: helpArticleId,
            width: 'full',
            maxWidth: '800px',
            shouldCloseOnBackdropClick: true
        })
            .subscribe();
    }
}
