import { Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { BaseComponent } from '../../../../../../components/abstractions/base.component';
import { BrokenRuleModel } from '../../../../../models/broken-rule.model';
import { ErrorsService } from '../../../../../../core/services/errors.service';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { ToasterService } from '../../../../../../core/services/toaster.service';
import { TransactionTypeEnum } from '../../../../../models/enums/transaction-type.enum';
import { TranslationService } from '../../../../../../core/services/translation.service';
import { TransactionsStore } from '../../../../../services/stores/transactions.store';
import { TransactionEditModel } from '../../../../../models/requests/transaction-edit.model';
import { SearchRequestService } from '../../../../../../core/services/search-request.service';

@Component({
    selector: 'arc-license-new-transaction-dialog',
    templateUrl: './license-new-transaction-dialog.component.html',
    styleUrl: './license-new-transaction-dialog.component.scss'
})
export class LicenseNewTransactionDialogComponent extends BaseComponent implements OnInit, OnDestroy {
    formGroup = new FormGroup({
        type: new ArcFormControl(TransactionTypeEnum.Individual, Validators.required),
        amount: new ArcFormControl<OptionalType<number>>(undefined, Validators.required),
        publicRemark: new ArcFormControl<OptionalType<string>>(undefined),
        valueDate: new ArcFormControl(new Date(new Date().setDate(new Date().getDate() + 1)), Validators.required),
        poolTime: new ArcFormControl<OptionalType<number>>(undefined)
    });

    isCreating = signal(false);

    readonly translationService = inject(TranslationService);
    readonly transactionTypes = [
        { value: TransactionTypeEnum.Individual, label: this.translationService.getText('License.Details.TransactionTypes.Individual') },
        { value: TransactionTypeEnum.Hardware, label: this.translationService.getText('License.Details.TransactionTypes.Hardware') },
        { value: TransactionTypeEnum.License, label: this.translationService.getText('License.Details.TransactionTypes.License') },
        { value: TransactionTypeEnum.Payment, label: this.translationService.getText('License.Details.TransactionTypes.Payment') }
    ];

    private readonly errorsService = inject(ErrorsService);
    private readonly toasterService = inject(ToasterService);
    private readonly transactionsStore = inject(TransactionsStore);
    private readonly data = inject(MAT_DIALOG_DATA);
    private readonly matDialogRef = inject(MatDialogRef);
    private readonly searchRequestService = inject(SearchRequestService);

    ngOnInit(): void {
        this.errorsService.shouldDisplayAlertOnError = false;
        this.addSubscriptions(
            this.errorsService.onBusinessException.subscribe(response => {
                this.setBusinessErrors(response.brokenRules);
                if (!!response.message) {
                    this.toasterService.showError(response.message, undefined, true);
                } else {
                    this.toasterService.showError('CustomerInteraction.CreateFailed');
                }
            })
        );
    }
    override ngOnDestroy(): void {
        this.errorsService.shouldDisplayAlertOnError = true;
    }

    setBusinessErrors(brokenRules: BrokenRuleModel[]): void {
        if (!this.formGroup) {
            return;
        }

        for (const brokenRule of brokenRules) {
            if (!brokenRule.property) {
                continue;
            }

            const controlName = brokenRule.property.charAt(0).toLowerCase() + brokenRule.property.slice(1);
            const control = this.formGroup.get(controlName);

            if (!control) {
                continue;
            }

            control.setErrors({ businessError: brokenRule.message });
        }
    }

    createLicenseTransaction(): void {
        this.formGroup.markAllAsTouched();
        this.formGroup.updateValueAndValidity();
        if (!this.formGroup.valid) {
            return;
        }

        this.isCreating.set(true);

        const licenseId = this.data.licenseId;
        const newTransaction = new TransactionEditModel({
            id: 0,
            licenseId: licenseId,
            type: this.formGroup.value.type!,
            amount: this.formGroup.value.amount!,
            publicRemark: this.formGroup.value.publicRemark,
            valueDate: this.formGroup.value.valueDate!
        });

        this.transactionsStore.add(newTransaction).subscribe({
            next: response => {
                this.toasterService.showSuccess('License.Details.NewTransaction.CreateSuccess');
                this.matDialogRef.close({ id: response.value });
                this.searchRequestService.forceReload();
            },
            error: () => {
                this.toasterService.showError('General.Alert.UnexpectedError');
            },
            complete: () => {
                this.isCreating.set(false);
            }
        });
    }
}
