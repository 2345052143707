import { Component, inject } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { LicenseEditModel } from '../../../../../models/requests/license-edit.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';
import { CustomValidators } from '../../../../../../core/utils/custom-validators';
import { GeneralDataStore } from '../../../../../services/stores/general-data.store';
import { LicenseModel } from '../../../../../models/license.model';

@Component({
    selector: 'arc-license-base-data',
    templateUrl: './license-base-data.component.html',
    styleUrl: './license-base-data.component.scss'
})
export class LicenseBaseDataComponent extends BaseEditSidebarItemComponent<LicenseModel, LicenseEditModel> {
    override formGroup = new FormGroup({
        subDomain: new ArcFormControl('', [Validators.required, CustomValidators.domain()]),
        productionHostUrl: new ArcFormControl(''),
        licenseHolder: new ArcFormControl('', Validators.required),
        street: new ArcFormControl('', Validators.required),
        streetNumber: new ArcFormControl<OptionalType<string>>(undefined),
        zip: new ArcFormControl('', Validators.required),
        city: new ArcFormControl('', Validators.required),
        countryIsoCode: new ArcFormControl('', Validators.required),
        productId: new ArcFormControl(0, Validators.required),
        numberOfPos: new ArcFormControl(1, [Validators.required, CustomValidators.number({ min: 0 })])
    });

    override skipPropertiesOnUnsavedChangesCheck = ['user', 'paymentType', 'password'];

    isDefaultCountrySet = false;

    GeneralDataTypeEnum = GeneralDataTypeEnum;

    private generalDataStore = inject(GeneralDataStore);

    constructor() {
        super();

        this.formGroup.controls.productId.valueChanges.subscribe(newProductId => {
            if (this.isCreate && !!newProductId) {
                this.generalDataStore
                    .getProductHostUrl(newProductId)
                    .pipe(first())
                    .subscribe(productHost => {
                        if (productHost.value) {
                            this.formGroup.controls.productionHostUrl.setValue(productHost.value);
                        }
                    });
            }
            // Set default country by product currency
            if (this.isCreate && !!newProductId && !this.isDefaultCountrySet) {
                this.generalDataStore
                    .getProductCurrency(newProductId)
                    .pipe(first())
                    .subscribe(productCurrency => {
                        const newCountryCode = productCurrency.value === 'CHF' ? 'CH' : 'DE';
                        this.formGroup.controls.countryIsoCode.setValue(newCountryCode);
                        this.isDefaultCountrySet = true;
                    });
            }
        });
    }
    onItemSet(): void {
        this.formGroup.patchValue(this.item);
        if (!this.isCreate) {
            this.formGroup.controls.numberOfPos.disable();
            if(!this.item.canEditProduct) {
                this.formGroup.controls.productId.disable();
            }
        }

        // Add unique domain validator
        this.formGroup.controls.subDomain.addAsyncValidators(
            CustomValidators.asyncDomainValidator(
                this.item.subDomain,
                () => this.formGroup.value.productionHostUrl ?? '',
                this.generalDataStore.getIsDomainUnique.bind(this.generalDataStore)
            )
        );
    }

    override prepareSaveModel(itemCopy: LicenseEditModel): LicenseEditModel {
        return new LicenseEditModel({ ...itemCopy, ...this.formGroup.value });
    }
}
