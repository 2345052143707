export class PaginatorConfigModel {
    defaultPageSize = 15;
    pageSizeOptions = [10, 15, 20, 25, 50];

    constructor(init?: Partial<PaginatorConfigModel>) {
        if (!!init) {
            Object.assign(this, init);
        }
    }
}
