import { Component, Input, inject } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Observable, of } from 'rxjs';

import { TranslationService } from '../../../core/services/translation.service';

@Component({
    selector: 'arc-form-field-error-control',
    templateUrl: './form-field-error-control.component.html',
    styleUrls: ['./form-field-error-control.component.scss']
})
export class FormFieldErrorControlComponent {
    @Input() control?: AbstractControl;

    private readonly translationService = inject(TranslationService);

    get errorMessage(): Observable<string> {
        if (!this.control) {
            return of('');
        }

        if (this.control.hasError('required')) {
            return this.translationService.getTextObservable('General.ValidationErrors.Required');
        }

        for (const propertyName in this.control.errors) {
            if (this.control.errors.hasOwnProperty(propertyName)) {
                return this.control.errors[propertyName];
            }
        }

        return of('');
    }
}
