import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'arc-save-filter-dialog',
    templateUrl: './save-filter-dialog.component.html',
    styleUrls: ['./save-filter-dialog.component.scss']
})
export class SaveFilterDialogComponent {
    // TODO: this could be replaced with a dynamic form dialog

    form = new FormGroup({
        name: new FormControl('', Validators.required)
    });

    private readonly dialogRef = inject(MatDialogRef);

    save(): void {
        this.form.updateValueAndValidity();
        if (this.form.invalid) {
            return;
        }

        const name = this.form.value.name?.trim();

        if (!name) {
            this.form.setValue({ name: '' });
            this.form.updateValueAndValidity();
            return;
        }

        this.dialogRef.close(name);
    }
}
