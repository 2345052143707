import { Directive } from '@angular/core';

import { BaseComponent } from '../../abstractions/base.component';

@Directive()
export abstract class BaseSidebarComponent extends BaseComponent {
    data?: any;

    onClose(): void {
    }
}
