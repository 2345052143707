/* eslint-disable @typescript-eslint/naming-convention */
import { Url as InnerUrlRef } from './url.tools';
import { Utils as InnerUtilsRef } from './utils.tools';
import { Time as InnerTimeRef } from './time.tools';
import * as rxjsTools from './rxjs.tools';

/**
 * Utils and tools to aid development.
 */
export namespace Tools {
    export const RxJs = rxjsTools;
    export const Url = InnerUrlRef;
    export const Utils = InnerUtilsRef;
    export const Time = InnerTimeRef;
}
