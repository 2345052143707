import { Component } from '@angular/core';

import { PortalLogListModel } from '../../../../../models/responses/portal-log-list.model';
import { PortalLogModel } from '../../../../../models/portal-log.model';
import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';

@Component({
    selector: 'arc-system-log-stacktrace',
    templateUrl: './system-log-stacktrace.component.html',
    styleUrl: './system-log-stacktrace.component.scss'
})
export class SystemLogStacktraceComponent extends BaseDetailWidget<PortalLogListModel, PortalLogModel> {}
