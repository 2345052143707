import { Injectable, inject } from '@angular/core';
import { Observable, Subject, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs';

import { BaseSearchStore } from '../abstractions/base-search.store';
import { ColumnFilterModel } from '../models/column-filter.model';
import { FilterItemModel } from '../models/filter-item.model';
import { Utils } from '../utils/tools/utils.tools';
import { SearchRequestService } from './search-request.service';
import { getFilterOperator } from '../../components/search-filter/filter/filter-operators';
import { SearchRequestModel } from '../../app/models/requests/search-request.model';
import { FilterStateModel } from '../models/filter-state.model';
import { OptionalType } from '../models/types/optional.type';
import { SavedFilterModel } from '../models/saved-filter.model';
import { ComparisonOperatorsEnum } from '../models/enums/comparison-operators.enum';
import { Identifyable } from '../abstractions/identifyable';

@Injectable({
    providedIn: 'root'
})
export class FilterService<TId = number> {
    initialized$: Observable<void>;
    currentFilters$: Observable<ColumnFilterModel[]>;
    currentReadonlyFilters$: Observable<ColumnFilterModel[]>;
    filterItems$: Observable<FilterItemModel[]>;
    savedFilters$: Observable<SavedFilterModel[]>;
    currentSavedFilter$: Observable<OptionalType<SavedFilterModel>>;
    state$: Observable<FilterStateModel>;

    private store?: BaseSearchStore<Identifyable<TId>, TId>;
    private initializedSub = new Subject<void>();
    private currentFilters: ColumnFilterModel[] = [];
    private currentFiltersSub = new Subject<ColumnFilterModel[]>();
    private currentReadonlyFilters: ColumnFilterModel[] = [];
    private currentReadonlyFiltersSub = new Subject<ColumnFilterModel[]>();
    private filterItems: FilterItemModel[] = [];
    private filterItemsSub = new Subject<FilterItemModel[]>();
    private savedFilters: SavedFilterModel[] = [];
    private savedFiltersSub = new Subject<SavedFilterModel[]>();
    private currentSavedFilter?: SavedFilterModel;
    private currentSavedFilterSub = new Subject<OptionalType<SavedFilterModel>>();
    private searchResultCountSubInternal = new Subject<SearchRequestModel>();
    private currentState: FilterStateModel = {
        isValid: false,
        hasUnappliedChanges: false,
        isLoading: false,
        canSave: false,
        isSaving: false,
        isDeleting: false,
        hasUnsavedChanges: false
    };
    private stateSub = new Subject<FilterStateModel>();
    private get applyableFilters(): ColumnFilterModel[] {
        const notEmptyFilters = this.currentFilters.filter(f => !this.isFilterEmpty(f));
        return [...notEmptyFilters, ...this.currentReadonlyFilters];
    }

    private readonly searchRequestService = inject(SearchRequestService);

    constructor() {
        this.initialized$ = this.initializedSub.asObservable();
        this.currentFilters$ = this.currentFiltersSub.asObservable();
        this.currentReadonlyFilters$ = this.currentReadonlyFiltersSub.asObservable();
        this.filterItems$ = this.filterItemsSub.asObservable();
        this.savedFilters$ = this.savedFiltersSub.asObservable();
        this.currentSavedFilter$ = this.currentSavedFilterSub.asObservable();
        this.state$ = this.stateSub.asObservable();

        this.searchResultCountSubInternal
            .pipe(
                distinctUntilChanged((prev, curr) => Utils.areEqual(prev, curr)),
                tap(() => {
                    this.setState({ isLoading: true });
                }),
                debounceTime(200),
                switchMap(searchRequest => this.store!.count(searchRequest))
            )
            .subscribe(result => {
                this.setState({ isLoading: false, resultCount: result.value ?? 0 });
            });
    }

    initService(store: BaseSearchStore<Identifyable<TId>, TId>): void {
        this.store = store;

        this.store.getAllowedFilters().subscribe(result => {
            this.filterItems = result.value ?? [];
            this.filterItemsSub.next(this.filterItems);
            this.initializedSub.next();
        });
        this.addFilter(new ColumnFilterModel());
    }

    resetService(): void {
        this.store = undefined;
        this.filterItems = [];
        this.currentFilters = [];
        this.store = undefined;
        this.currentFilters = [];
        this.currentReadonlyFilters = [];
        this.filterItems = [];
        this.savedFilters = [];
        this.currentSavedFilter = undefined;
        this.currentState = {
            isValid: false,
            hasUnappliedChanges: false,
            isLoading: false,
            canSave: false,
            isSaving: false,
            isDeleting: false,
            hasUnsavedChanges: false
        };
    }

    getFilter(index: number): ColumnFilterModel {
        return Utils.deepCopy(this.currentFilters[index]);
    }

    addFilter(filter: ColumnFilterModel): void {
        const filterCopy = Utils.deepCopy(filter);
        if (this.currentFilters.length === 1 && this.isFilterEmpty(this.currentFilters[0])) {
            this.currentFilters[0] = filterCopy;
        } else {
            this.currentFilters.push(filterCopy);
        }

        this.onFiltersChanged();
    }

    updateFilter(index: number, updatedFilter: ColumnFilterModel): void {
        if (this.currentFilters.length > index && !Utils.areEqual(this.currentFilters[index], updatedFilter)) {
            this.currentFilters[index].column = updatedFilter.column;
            this.currentFilters[index].comparisonOperator = updatedFilter.comparisonOperator;
            this.currentFilters[index].dataType = updatedFilter.dataType;
            this.currentFilters[index].operand = updatedFilter.operand;
            this.currentFilters[index].values = Utils.deepCopy(updatedFilter.values);

            this.onFiltersChanged();
        }
    }

    removeFilter(index: number): void {
        if (index === 0 && this.currentFilters.length === 1) {
            this.currentFilters[0] = new ColumnFilterModel();
        } else if (this.currentFilters.length > index) {
            this.currentFilters.splice(index, 1);
        }

        this.onFiltersChanged();
    }

    addReadonlyFilter(filter: ColumnFilterModel): void {
        this.currentReadonlyFilters.push(Utils.deepCopy(filter));
        this.onFiltersChanged();
    }

    removeReadonlyFilter(index: number): void {
        if (this.currentReadonlyFilters.length > index) {
            this.currentReadonlyFilters.splice(index, 1);
            this.onFiltersChanged();
        }
    }

    resetAllFilters(): void {
        this.currentFilters = [new ColumnFilterModel()];
        this.currentReadonlyFilters = [];

        this.onFiltersChanged();
        this.apply();
    }

    apply(): void {
        if (this.currentState.isValid) {
            this.setState({ hasUnappliedChanges: false, resultCount: undefined });
            this.searchRequestService.setFilters(this.applyableFilters);
        }
    }

    updateSavedFilter(): void {
        if (!this.currentSavedFilter) {
            return;
        }

        this.setState({ isSaving: true });
        this.currentSavedFilter.filters = Utils.deepCopy(this.currentFilters);
        this.onSavedFilterChanged();
    }

    applySavedFilter(id: string): void {
        const savedFilter = Utils.deepCopy(this.savedFilters.find(s => s.id === id));
        if (!savedFilter) {
            return;
        }

        for (const filter of savedFilter.filters) {
            const filterItem = this.filterItems.find(p => p.columnName === filter.column);
            if (!!filterItem) {
                filter.dataType = filterItem.type;
            }
        }

        this.currentSavedFilter = savedFilter;
        this.onSavedFilterChanged();
        this.currentFilters = Utils.deepCopy(savedFilter.filters);
        this.onFiltersChanged();
        this.apply();
    }

    deleteSavedFilter(id: string): void {
        this.setState({ isDeleting: true });
        this.store?.deleteFilters(id).subscribe(() => {
            this.setState({ isDeleting: false });
            this.currentSavedFilter = undefined;
            this.onSavedFilterChanged();
        });
    }

    resetSavedFilter(): void {
        this.currentSavedFilter = undefined;
        this.onSavedFilterChanged();
    }

    getFilterByName(name: string): OptionalType<ColumnFilterModel> {
        return this.currentFilters.find(cf => cf.column === name);
    }

    private onFiltersChanged(): void {
        const isValid = this.areAllFiltersValid();

        if (isValid) {
            const request = this.searchRequestService.current;
            request.filters = this.applyableFilters;
            this.searchResultCountSubInternal.next(Utils.deepCopy(request));
        }

        const hasUnappliedChanges = !Utils.areEqual(this.applyableFilters, this.searchRequestService.current.filters);
        const canSave = isValid && !this.currentFilters.some(f => this.isFilterEmpty(f));
        this.setState({
            isValid,
            hasUnappliedChanges,
            canSave,
            hasUnsavedChanges: !Utils.areEqual(this.currentFilters, this.currentSavedFilter?.filters)
        });
        this.currentFiltersSub.next(this.currentFilters);
        this.currentReadonlyFiltersSub.next(this.currentReadonlyFilters);
    }

    private onSavedFilterChanged(): void {
        this.currentSavedFilterSub.next(this.currentSavedFilter);
    }

    private setState(state: Partial<FilterStateModel>): void {
        this.currentState = { ...this.currentState, ...state };
        this.stateSub.next(this.currentState);
    }

    private areAllFiltersValid(): boolean {
        for (const filter of [...this.currentFilters, ...this.currentReadonlyFilters]) {
            if (!this.isFilterValid(filter)) {
                return false;
            }
        }
        return true;
    }

    private isFilterValid(filter: ColumnFilterModel): boolean {
        // empty filters are valid, but won't be included in the final search request
        if (this.isFilterEmpty(filter)) {
            return true;
        }

        // filter must have column and comparison operator
        if (!filter.column || filter.comparisonOperator === undefined) {
            return false;
        }

        // if filter requires value, value must be set
        const filterExpectedParametersAmount = getFilterOperator(filter.dataType, filter.comparisonOperator)?.expectedParameterAmount ?? 0;
        if (filterExpectedParametersAmount > filter.values.length) {
            return false;
        }

        // range filter first must be greater than second (or equal)
        if ((filter.comparisonOperator === ComparisonOperatorsEnum.InRange) && filter.values[0] > filter.values[1]) {
            return false;
        }

        // filter must be allowed
        if (!this.filterItems.some(f => f.columnName === filter.column)) {
            return false;
        }

        return true;
    }

    private isFilterEmpty(filter: ColumnFilterModel): boolean {
        return !filter.column && !filter.comparisonOperator && !filter.dataType && !filter.values[0];
    }
}
