import { Component } from '@angular/core';

import { BaseCustomColumnComponent } from '../../../../../../components/dynamic-table/column-components/custom-column/base-custom-column.component';
import { TransactionListModel } from '../../../../../models/responses/transaction-list.model';
import { TransactionTypeEnum } from '../../../../../models/enums/transaction-type.enum';

@Component({
    selector: 'arc-transaction-type',
    templateUrl: './transaction-type.component.html',
    styleUrl: './transaction-type.component.scss'
})
export class TransactionTypeComponent extends BaseCustomColumnComponent<TransactionListModel> {
    TransactionTypeEnum = TransactionTypeEnum;
}
