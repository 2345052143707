import { BaseListViewModel } from '../../../../core/abstractions/base-list-view.model';
import { DictionaryType } from '../../../../core/models/types/dictionary.type';
import { BaseColumnModel } from '../../../dynamic-table/models/column-types/base-column.model';

export class StaticBasicTableListViewConfigModel<TList extends BaseListViewModel> {
    availableColumns!: DictionaryType<BaseColumnModel>;
    defaultPageSize!: number;
    data: TList[] = [];
    shouldDisplayHeader = true;

    constructor(init?: Partial<StaticBasicTableListViewConfigModel<TList>>) {
        if (!!init) {
            Object.assign(this, init);
        }
    }
}
