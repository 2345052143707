<div class="carousel" *ngIf="items.length > 0; else noImage">
    <ng-container *ngFor="let item of items; let i = index">
        <ng-container *ngIf="!!item.base64Content && i === currentSlideIndex; else defaultTpl">
            <img [src]="getBase64Src(item)" [width]="width" [height]="height" class="slide" [alt]="getAltText(item)" @carouselAnimation/>
        </ng-container>
        <ng-template #defaultTpl>
            <img *ngIf="i === currentSlideIndex" [ngSrc]="item.imageUrl!" [width]="width" [height]="height" class="slide"
                [alt]="getAltText(item)" @carouselAnimation/>
        </ng-template>
    </ng-container>

    <ng-container *ngIf="shouldShowControls && items.length > 0">
        <button class="control prev" (click)="goToPrevious()">
            <mat-icon>chevron_left</mat-icon>
        </button>
        <button class="control next" (click)="goToNext()">
            <mat-icon>chevron_right</mat-icon>
        </button>
    </ng-container>
</div>
<ng-template #noImage>
    <div class="flex items-center justify-center h-full">
        <span class="text-sm">
            {{'Articles.Detail.NoImageAvailable' | transloco}}
        </span>
    </div>
</ng-template>


