import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { StoreConfig } from '../../../core/models/store.config';
import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { ModuleBundleModel } from '../../models/module-bundle.model';
import { ModuleBundleListModel } from '../../models/responses/module-bundle-list.model';
import { ApiResponseModel } from '../../models/responses/api-response.model';

@Injectable({
    providedIn: 'root'
})
export class ModuleBundlesStore extends BaseCrudStore<ModuleBundleModel, ModuleBundleListModel, ModuleBundleModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'module-bundles' }));
    }

    getModuleBundlesByProductId(productId: number): Observable<ApiResponseModel<ModuleBundleModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<ModuleBundleModel[]>>(this.getUrl(`product/${productId}`));
    }
}
