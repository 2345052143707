import { FormGroup } from '@angular/forms';

import { BaseColumnModel } from '../../../dynamic-table/models/column-types/base-column.model';
import { EditableTableButtonModel } from './editable-table-button.model';

export class EditableTableConfigModel<TData extends object> {
    formGroupGeneratorFn!: () => FormGroup;
    columns?: BaseColumnModel[] = [];
    rowHeightPx = 52;
    showDeleteConfirmation = false;
    shouldHideAddButton = false;
    showPaging = false;
    pageSize = 10;
    additionalButtons: EditableTableButtonModel<TData>[] = [];

    constructor(init?: Partial<EditableTableConfigModel<TData>>) {
        if (!!init) {
            Object.assign(this, init);
        }
    }
}
