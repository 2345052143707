<h1 mat-dialog-title>
    {{ 'Tickets.CreateTitle' | transloco }}
</h1>

<mat-dialog-content class="grow !max-h-[unset] !flex flex-col">
    <div class="pt-2 grow w-full flex flex-col gap-4">
        <arc-quick-search [config]="ComplexDataTypesEnum.License | arcQuickSearchConfig"
            [formControl]="formGroup.controls.licenseId" label="Tickets.Create.License">
        </arc-quick-search>
    </div>
    <arc-checkbox labelKey="Tickets.Create.Escalated" [control]="formGroup.controls.escalated"></arc-checkbox>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-stroked-button [mat-dialog-close]="{ shouldReload: false }">
        {{ 'General.Actions.Cancel' | transloco }}
    </button>
    <arc-button type="flat" color="accent" (clicked)="create()">
        {{ 'General.Actions.Create' | transloco }}
    </arc-button>
</mat-dialog-actions>