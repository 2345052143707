import { Component } from '@angular/core';

import { BaseCustomColumnComponent } from '../../../../../../components/dynamic-table/column-components/custom-column/base-custom-column.component';
import { BenefitListModel } from '../../../../../models/responses/benefit-list.model';

@Component({
    selector: 'arc-benefit-discount',
    templateUrl: './benefit-discount.component.html',
    styleUrl: './benefit-discount.component.scss'
})
export class BenefitDiscountComponent extends BaseCustomColumnComponent<BenefitListModel>{}
