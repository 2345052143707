import { Component, inject, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { BenefitEditModel } from '../../../../../models/requests/benefit-edit.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';
import { PaymentTypesEnum } from '../../../../../models/enums/payment-types.enum';
import { PaymentPeriodsEnum } from '../../../../../models/enums/payment-periods.enum';
import { BenefitDiscountTypesEnum } from '../../../../../models/enums/benefit-discount-types.enum';
import { ComplexDataTypesEnum } from '../../../../../../core/models/complex-data-types.enum';
import { TranslationService } from '../../../../../../core/services/translation.service';

@Component({
    selector: 'arc-benefit-basic-data',
    templateUrl: './benefit-basic-data.component.html',
    styleUrl: './benefit-basic-data.component.scss'
})
export class BenefitBasicDataComponent extends BaseEditSidebarItemComponent<BenefitEditModel> implements OnInit {
    override formGroup = new FormGroup({
        productId: new ArcFormControl<OptionalType<number>>(undefined),
        title: new ArcFormControl('', Validators.required),
        validFrom: new ArcFormControl<OptionalType<Date>>(undefined),
        validThru: new ArcFormControl<OptionalType<Date>>(undefined),
        paymentType: new ArcFormControl<OptionalType<PaymentTypesEnum>>(undefined),
        paymentPeriod: new ArcFormControl<OptionalType<PaymentPeriodsEnum>>(undefined),
        discountValue: new ArcFormControl<OptionalType<number>>(undefined),
        discountPercent: new ArcFormControl<OptionalType<number>>(undefined),
        newLicensesOnly: new ArcFormControl(false, Validators.required),
        benefitOnRecurrentFee: new ArcFormControl(false, Validators.required),
        code: new ArcFormControl<OptionalType<string>>(undefined),
        userId: new ArcFormControl<OptionalType<number>>(undefined),
        resellerId: new ArcFormControl<OptionalType<number>>(undefined),
        licenseId: new ArcFormControl<OptionalType<number>>(undefined),
        moduleBundleId: new ArcFormControl<OptionalType<number>>(undefined),
        discountType: new ArcFormControl(BenefitDiscountTypesEnum.Value)
    });

    GeneralDataTypeEnum = GeneralDataTypeEnum;
    ComplexDataTypesEnum = ComplexDataTypesEnum;
    BenefitDiscountTypesEnum = BenefitDiscountTypesEnum;

    readonly translationService = inject(TranslationService);

    paymentTypes = [
        {
            value: PaymentTypesEnum.Card,
            label: this.translationService.getText('Benefits.Edit.BaseData.PaymentType.Card')
        },
        {
            value: PaymentTypesEnum.Invoice,
            label: this.translationService.getText('Benefits.Edit.BaseData.PaymentType.Invoice')
        },
        {
            // eslint-disable-next-line no-null/no-null
            value: null,
            label: this.translationService.getText('Benefits.Edit.BaseData.PaymentType.Any')
        }
    ];

    paymentPeriods = [
        {
            value: PaymentPeriodsEnum.Annual,
            label: this.translationService.getText('Benefits.Edit.BaseData.PaymentPeriod.Annual')
        },
        {
            value: PaymentPeriodsEnum.Monthly,
            label: this.translationService.getText('Benefits.Edit.BaseData.PaymentPeriod.Monthly')
        },
        {
            // eslint-disable-next-line no-null/no-null
            value: null,
            label: this.translationService.getText('Benefits.Edit.BaseData.PaymentPeriod.Any')
        }
    ];

    discountTypes = [
        {
            value: BenefitDiscountTypesEnum.Value,
            label: this.translationService.getText('Benefits.Edit.BaseData.DiscountType.Value')
        },
        {
            value: BenefitDiscountTypesEnum.Percent,
            label: this.translationService.getText('Benefits.Edit.BaseData.DiscountType.Percent')
        }
    ];

    override skipPropertiesOnUnsavedChangesCheck = ['discountType'];

    ngOnInit(): void {
        this.formGroup.controls.discountType.valueChanges.subscribe(value => {
            this.setDiscountValidators(value);
        });

        this.setDiscountValidators(this.formGroup.controls.discountType.value);
    }

    onItemSet(): void {
        this.formGroup.patchValue(this.item);
        this.formGroup.controls.discountType.setValue(
            this.item.discountPercent ? BenefitDiscountTypesEnum.Percent : BenefitDiscountTypesEnum.Value
        );
    }

    override prepareSaveModel(itemCopy: BenefitEditModel): BenefitEditModel {
        const discountValue =
            this.formGroup.value.discountType === BenefitDiscountTypesEnum.Value ? this.formGroup.value.discountValue : undefined;
        const discountPercent =
            this.formGroup.value.discountType === BenefitDiscountTypesEnum.Percent ? this.formGroup.value.discountPercent : undefined;
        return new BenefitEditModel({ ...itemCopy, ...this.formGroup.value, discountValue, discountPercent });
    }

    private setDiscountValidators(discountType: BenefitDiscountTypesEnum): void {
        if (discountType === BenefitDiscountTypesEnum.Value) {
            this.formGroup.controls.discountPercent.clearValidators();
            this.formGroup.controls.discountPercent.setValue(undefined);

            this.formGroup.controls.discountValue.setValidators([Validators.min(1), Validators.max(10000), Validators.required]);
        } else if (discountType === BenefitDiscountTypesEnum.Percent) {
            this.formGroup.controls.discountValue.clearValidators();
            this.formGroup.controls.discountValue.setValue(undefined);

            this.formGroup.controls.discountPercent.setValidators([Validators.min(0.01), Validators.max(100), Validators.required]);
        }
    }
}
