import { Component, inject, signal, viewChild, WritableSignal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize, tap } from 'rxjs';
import { injectStripe, StripePaymentElementComponent } from 'ngx-stripe';
import { PaymentIntentResult, StripeElementsOptions } from '@stripe/stripe-js';

import { InvoicesStore } from '../../services/stores/invoices.store';
import { InvoicePaymentModel } from '../../models/responses/invoice-payment.model';
import { OptionalType } from '../../../core/models/types/optional.type';
import { environment } from '../../../environments/environment';
import { TranslationService } from '../../../core/services/translation.service';

@Component({
    selector: 'arc-invoice-pay-view',
    templateUrl: './invoice-pay-view.component.html',
    styleUrl: './invoice-pay-view.component.scss'
})
export class InvoicePayViewComponent {
    paymentElement = viewChild(StripePaymentElementComponent);

    invoicePaymentModel = signal<OptionalType<InvoicePaymentModel>>(undefined);
    isLoadingInvoice = signal(true);
    isLoadingStripe = signal(true);
    isPaying = signal(false);
    paymentResult = signal<OptionalType<PaymentIntentResult>>(undefined);
    isCompleting = signal(false);
    isPaymentCompleted = signal(false);

    elementsOptions: WritableSignal<StripeElementsOptions>;

    readonly stripe = injectStripe(environment.stripePublishableKey);

    private invoiceHash: string;

    private readonly activatedRoute = inject(ActivatedRoute);
    private readonly invoicesStore = inject(InvoicesStore);
    private readonly translationService = inject(TranslationService);

    constructor() {
        this.elementsOptions = signal<StripeElementsOptions>({
            clientSecret: this.translationService.current.code,
            locale: 'auto',
            appearance: {
                theme: 'stripe',
                labels: 'floating',
                variables: {
                    colorPrimary: '#004346'
                }
            }
        });

        this.invoiceHash = this.activatedRoute.snapshot.paramMap.get('hash') || '';

        this.invoicesStore
            .createPaymentIntentForInvoice(this.invoiceHash)
            .pipe(
                tap(res => {
                    this.invoicePaymentModel.set(res.value);
                    this.elementsOptions.update(options => {
                        options.clientSecret = this.invoicePaymentModel()?.stripeClientSecret;
                        return options;
                    });
                }),
                finalize(() => this.isLoadingInvoice.set(false))
            )
            .subscribe();
    }

    pay(): void {
        this.isPaying.set(true);

        this.stripe.confirmPayment({
            elements: this.paymentElement()!.elements,
            redirect: 'if_required'
        }).subscribe({
            next: result => {
                this.paymentResult.set(result);

                if (!this.paymentResult()?.error) {
                    this.completePayment();
                }
            },
            complete: () => {
                this.isPaying.set(false);
            }
        });
    }

    reloadPage(): void {
        window.location.reload();
    }

    private completePayment(): void {
        this.isCompleting.set(true);

        this.invoicesStore.confirmPayment(this.invoiceHash).subscribe(() => {
            this.isCompleting.set(false);
            this.isPaymentCompleted.set(true);
        });
    }
}
