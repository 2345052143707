import { Pipe, PipeTransform, inject } from '@angular/core';

import { TranslationService } from '../services/translation.service';

@Pipe({ name: 'arcCurrency' })
export class CurrencyPipe implements PipeTransform {
    private readonly translationService = inject(TranslationService);

    transform(value: any, currencyCode = 'CHF', localize = true): string {
        const numberVal = Number(value);
        if (Number.isNaN(numberVal)) {
            return value;
        }

        if (localize) {
            value = numberVal.toLocaleString(
                this.translationService.current.culture,
                {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }
            );
        } else {
            value = numberVal.toFixed(2);
        }

        return `${currencyCode} ` + value;
    }
}
