import { Component, OnInit } from '@angular/core';

import { BaseColumnComponent } from '../base-column.component';
import { ButtonToggleModel } from '../../../../core/models/button-toggle.model';
import { ButtonToggleColumnModel } from '../../models/column-types/button-toggle-column.model';

@Component({
    selector: 'arc-button-toggle-column',
    templateUrl: './button-toggle-column.component.html',
    styleUrls: ['./button-toggle-column.component.scss']
})
export class ButtonToggleColumnComponent<T extends object> extends BaseColumnComponent<T> implements OnInit {
    columnItems: ButtonToggleModel<T>[] = [];

    override ngOnInit(): void {
        super.ngOnInit();
        const buttonToggleColumnModel = this.columnModel as ButtonToggleColumnModel;
        if(buttonToggleColumnModel){
            this.columnItems = buttonToggleColumnModel.options.map(x => ({value: x.key ?? '', label: x.value ?? ''}));
        }
    }
}
