// copied from Backend Microsoft.Extensions.Logging.LogLevel
export enum LogLevelEnum {
    Trace = 0,
    Debug = 1,
    Information = 2,
    Warning = 3,
    Error = 4,
    Critical = 5,
    None = 6
}

export class LogLevelEnumExtensions {
    static getColor(value: LogLevelEnum): string {
        switch (value) {
            case LogLevelEnum.Information:
                return 'bg-element-selected text-on-app';
            case LogLevelEnum.Warning:
                return 'bg-warn-light text-on-warn-light';
            case LogLevelEnum.Error:
                return 'bg-error-light text-on-error-light';
            case LogLevelEnum.Critical:
                return 'bg-error text-on-error';
            case LogLevelEnum.Debug:
                return 'bg-info-light text-on-info-light';
            default:
                return 'bg-on-app text-app';
        }
    }
}
