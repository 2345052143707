import { BaseRequestModel } from '../../../core/abstractions/base-request.model';

export class AuthRequestModel extends BaseRequestModel {
    email!: string;
    password!: string;

    constructor(init?: AuthRequestModel) {
        super();
        Object.assign(this, init);
    }
}
