import { ComponentType } from '@angular/cdk/portal';
import { ComponentRef, Directive, Input, OnChanges, ViewContainerRef, inject } from '@angular/core';

import { BaseCardComponent } from '../../components/abstractions/base-card.component';
import { Identifyable } from '../abstractions/identifyable';

@Directive({
    selector: '[arcCardContent]'
})
export class CardContentDirective<TList extends Identifyable<TId>, TId = number> implements OnChanges {
    @Input() item!: TList;
    @Input() component!: ComponentType<BaseCardComponent<TList, TId>>;

    private readonly viewContainerRef = inject(ViewContainerRef);

    ngOnChanges(): void {
        const component: ComponentRef<BaseCardComponent<TList, TId>> = this.viewContainerRef.createComponent(this.component);
        component.instance.item = this.item;

        component.instance.afterItemLoad();
    }
}
