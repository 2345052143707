import { inject, OnDestroy, Directive, ViewChild, AfterViewInit, Signal, computed } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

import { TableListViewConfigModel } from '../table-list-view/models/table-list-view-config.model';
import { CardListViewConfigModel } from '../card-list-view/models/card-list-view-config.model';
import { BaseAuthComponent } from '../../abstractions/base-auth.component';
import { ActionButtonsService } from '../../../core/services/action-buttons.service';
import { ActionButtonModel } from '../../../core/models/action-button.model';
import { BaseListViewComponent } from '../../../core/abstractions/base-list-view-component';
import { TableListViewComponent } from '../table-list-view/table-list-view.component';
import { CardListViewComponent } from '../card-list-view/card-list-view.component';
import { Identifyable } from '../../../core/abstractions/identifyable';

@Directive()
export abstract class PageListViewComponent<
    TList extends Identifyable<TId>,
    T extends Identifyable<TId> = any,
    TCreate extends Identifyable<TId> = T,
    TUpdate extends Identifyable<TId> = TCreate,
    TId = number
>
    extends BaseAuthComponent
    implements OnDestroy, AfterViewInit {
    @ViewChild(TableListViewComponent) private tableListView?: TableListViewComponent<T, TList, TCreate, TUpdate, TId>;
    @ViewChild(CardListViewComponent) private cardListView?: CardListViewComponent<T, TList, TCreate, TUpdate, TId>;

    get listView(): BaseListViewComponent<T, TList, TCreate, TUpdate, TId> {
        return (this.tableListView ?? this.cardListView)!;
    }

    config!: CardListViewConfigModel<T, TList, TCreate, TUpdate, TId> | TableListViewConfigModel<TList, T, TCreate, TUpdate, TId>;
    actionButtons: Signal<ActionButtonModel[]>;
    actionButtonsLength: Signal<number>;

    protected readonly _actionButtonsService = inject(ActionButtonsService<TList, T, TCreate, TUpdate, TId>);
    protected readonly _router = inject(Router);
    protected readonly _dataLoadedSubject = new Subject<boolean>();

    private routerSub?: Subscription;

    protected constructor(private permissionId: string) {
        super();

        this.actionButtons = computed(() =>
            this._actionButtonsService.buttons().filter(b => b.min >= 0 && b.max >= 0 && b.isEnabled && !b.isHiddenOnSelection)
        );
        this.actionButtonsLength = computed(() => this.actionButtons().length);
    }

    override ngAfterViewInit(): void {
        super.ngAfterViewInit();

        this.listView.dataLoadedSubject.subscribe(r => {
            this._dataLoadedSubject.next(r);
            this.loadContextActions();
        });
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
        this._actionButtonsService.reset();
        this.routerSub?.unsubscribe();
    }

    tableSelectionChanged(selectedItems: TList[]): void {
        if (selectedItems.length > 0) {
            this._actionButtonsService.select(selectedItems);
        } else {
            this._actionButtonsService.clearSelection();
        }
    }

    afterContextActionsLoad(): void {}

    isTableListView(
        config: TableListViewConfigModel<TList, T, TCreate, TUpdate, TId> | CardListViewConfigModel<T, TList, TCreate, TUpdate, TId>
    ): config is TableListViewConfigModel<TList, T, TCreate, TUpdate, TId> {
        return config instanceof TableListViewConfigModel;
    }

    private loadContextActions(): void {
        this.config.store.getContextActions().subscribe(resp => {
            this._actionButtonsService.generate(this.listView, resp.value);
            this.afterContextActionsLoad();
        });
    }
}
