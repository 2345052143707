import { BaseEntityModel } from '../../../core/abstractions/base-entity.model';
import { FeatureStatusEnum } from '../enums/feature-status.enum';
import { FeatureTypeEnum } from '../enums/feature-type.enum';
import { ProductAreasEnum } from '../enums/product-areas.enum';

export class FeatureEditModel extends BaseEntityModel {
    area!: ProductAreasEnum;
    type!: FeatureTypeEnum;
    status!: FeatureStatusEnum;
    title_de!: string;
    title_en?: string;
    title_fr?: string;
    title_it?: string;
    description_de!: string;
    description_en?: string;
    description_fr?: string;
    description_it?: string;
    isFeatured!: boolean;
    expectedDeliveryTime?: Date;
    constructor(init?: Partial<FeatureEditModel>) {
        super();

        if (!!init) {
            Object.assign(this, init);
        }
    }
}
