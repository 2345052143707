export enum HardwareOrderStatesEnum {
    Created = 0,
    Paid = 1,
    InPreparation = 2,
    Shipped = 3
}

export class HardwareOrderStatesEnumExtensions {
    static getColor(value: HardwareOrderStatesEnum): string {
        switch (value) {
            case HardwareOrderStatesEnum.Created:
                return 'bg-element-selected text-on-app';
            case HardwareOrderStatesEnum.Paid:
                return 'bg-warn-light text-on-warn-light';
            case HardwareOrderStatesEnum.InPreparation:
                return 'bg-info text-on-info';
            case HardwareOrderStatesEnum.Shipped:
                return 'bg-success-light text-on-success-light';
        }
    }
}
