<div class="px-8 bg-app flex justify-between items-center" [class.py-4]="headerService.hasPadding()">
    <div class="flex items-center">
        @if(headerService.hasBackButton()){
        <button mat-icon-button class="my-1 mr-2" (click)="handleBackButtonClick()">
            <mat-icon>arrow_back</mat-icon>
        </button>
        }
        <span>{{ headerService.title() }}</span>
        @if(headerService.subtitle()){
        <pre> | </pre>
        <span class="font-bold">{{ headerService.subtitle() }}</span>
        }
    </div>
    <div class="flex justify-between items-center">
        <ng-template [cdkPortalOutlet]="additionalInfoComponentPortal()"
            (attached)="handleHeaderAdditionalInfoComponentAttached($event)">
        </ng-template>
        @if(headerService.hasCloseButton()){
        <button mat-icon-button class="ml-4" (click)="closeSidebar()">
            <mat-icon>close</mat-icon>
        </button>
        }
    </div>
</div>