import { Component, OnInit, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { SidebarService } from '../../../../core/services/sidebar.service';
import { BaseComponent } from '../../../abstractions/base.component';
import { RouteService } from '../../../../core/services/route.service';
import { LayoutService } from '../../../../core/services/layout.service';
import { MenuItemModel } from '../../../../core/models/menu-item.model';
import { AuthenticatedUserModel } from '../../../../app/models/authenticated-user.model';
import { SecurityStorage } from '../../../../core/services/storages/security.storage';
import { DictionaryType } from '../../../../core/models/types/dictionary.type';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'arc-left-sidebar',
    templateUrl: './left-sidebar.component.html',
    styleUrls: ['./left-sidebar.component.scss']
})
export class LeftSidebarComponent extends BaseComponent implements OnInit {
    currentSubMenu: MenuItemModel[] = [];
    isEmptyFavouritesList = false;
    homeDirectory = '/home';
    user?: AuthenticatedUserModel;

    quickFeedbacks: DictionaryType<string> = {
        reports: '35',
        tags: '53'
    };

    readonly sidebarWidthPx = 230;

    private readonly securityStorage = inject(SecurityStorage);

    constructor(
        protected sidebarService: SidebarService,
        protected layoutService: LayoutService,
        private routeService: RouteService,
        private router: Router
    ) {
        super();

        const isMobileSubscription = this.layoutService.isMobileSubject.subscribe(isMobile => {
            if (isMobile) {
                this.sidebarService.closeLeft();
            } else {
                this.sidebarService.openLeft();
            }
        });

        if (!this.layoutService.isMobile) {
            this.sidebarService.closeLeft();
        }

        // regenerate submenus on route change
        const routerEventsSubscription = this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.routeService.subMenuItemsSub.subscribe(response => {
                    this.currentSubMenu = response;
                    this.setupSubMenu();
                });
                this.routeService.generateSubMenuForUri(event.urlAfterRedirects ?? event.url);
            }
        });

        this.addSubscriptions(isMobileSubscription, routerEventsSubscription);
    }

    ngOnInit(): void {
        this.user = this.securityStorage.getUserInfo();
    }

    handleLeftBackdropClick(): void {
        this.sidebarService.closeLeft();
    }

    private setupSubMenu(): void {
        if (this.currentSubMenu.length === 0) {
            if (this.router.url === this.homeDirectory) {
                this.isEmptyFavouritesList = true;
            } else {
                this.sidebarService.closeLeft();
                this.sidebarService.isLeftDisabled = true;
            }
        } else {
            this.sidebarService.isLeftDisabled = false;
            if (!this.layoutService.isMobile) {
                this.sidebarService.openLeft();
            } else {
                this.sidebarService.closeLeft();
            }
        }
    }

    get portalName(): string {
        return environment.isMity ? 'Mity' : 'Arcavis AG';
    }
}
