import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';

import { AuthService } from '../../core/services/auth.service';
import { PermissionTypeEnum } from '../../core/models/enums/permission-type.enum';
import { PermissionsEnum } from '../../app/models/enums/permissions.enum';
import { ModuleIdentificationsEnum } from '../../app/models/enums/module-identifications.enum';
import { TranslationService } from '../../core/services/translation.service';

@Component({
    selector: 'arc-no-permission-lock',
    templateUrl: './no-permission-lock.component.html',
    styleUrls: ['./no-permission-lock.component.scss']
})
export class NoPermissionLockComponent implements OnInit {
    @Input() requiredModule?: ModuleIdentificationsEnum;
    @Input() requiredPermission?: PermissionsEnum;
    @Input() requiredPermissionType = PermissionTypeEnum.Read;

    @Output() readonly notAllowed = new EventEmitter<void>();

    isAllowed = false;
    tooltipText = '';

    private notAllowedReason?: 'module' | 'permission';

    private readonly authService = inject(AuthService);
    private readonly translationService = inject(TranslationService);

    ngOnInit(): void {
        this.isAllowed = this.checkIsAllowed();
        if (!this.isAllowed) {
            this.tooltipText = this.getTooltipText();
            this.notAllowed.emit();
        }
    }

    private checkIsAllowed(): boolean {
        if (!!this.requiredModule) {
            this.notAllowedReason = 'module';
            return false;
        }

        if (!!this.requiredPermission) {
            this.notAllowedReason = 'permission';
            return false;
        }

        return true;
    }

    private getTooltipText(): string {
        switch (this.notAllowedReason) {
            case 'module':
                const moduleName = Object.entries(ModuleIdentificationsEnum).find(e => e[1] === this.requiredModule)?.[0];
                const module = this.translationService.getText(moduleName ?? '');
                return this.translationService.getText('General.Modules.AvailableWithModule', { module });
            case 'permission':
                return this.translationService.getText('General.Permissions.NoPermission');
        }

        return '';
    }
}
