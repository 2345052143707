import { BaseRequestModel } from '../../../core/abstractions/base-request.model';
import { ColumnFilterModel } from '../../../core/models/column-filter.model';
import { ColumnSortModel } from '../../../core/models/column-sort.model';

export class SearchRequestModel extends BaseRequestModel {
    id?: string;
    name?: string;
    searchText?: string;
    pageIndex?: number;
    pageSize?: number;
    sortings: ColumnSortModel[] = [];
    filters: ColumnFilterModel[] = [];

    constructor(init?: Partial<SearchRequestModel>) {
        super();

        if (!!init) {
            Object.assign(this, init);
        }
    }
}
