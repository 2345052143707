export enum InvoiceStatusEnum {
    Open = 0,
    Paid = 1,
    PastDue = 2,
    Draft = 3
}

export class InvoiceStatusEnumExtensions {
    static getColor(value: InvoiceStatusEnum): string {
        switch (value) {
            case InvoiceStatusEnum.Open:
                return 'bg-warn-light text-on-warn-light';
            case InvoiceStatusEnum.PastDue:
                return 'bg-error-light text-on-error-light';
            case InvoiceStatusEnum.Paid:
                return 'bg-success-light text-on-success-light';
            case InvoiceStatusEnum.Draft:
                return 'bg-element-selected text-on-app';
        }
    }
}
