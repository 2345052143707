import { SortDirectionEnum } from './enums/sort-direction.enum';

export class ColumnSortModel {
    column!: string;
    direction!: SortDirectionEnum;

    constructor(init?: Partial<ColumnSortModel>) {
        Object.assign(this, init);
    }
}
