import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import { of, finalize } from 'rxjs';

import { BaseTreeListComponent } from '../../../../core/abstractions/base.tree-list.component';
import { TreeItemFlatNode } from '../../../../core/models/tree-item.flat-node';
import { DialogService } from '../../../../core/services/dialog.service';
import { SidebarService } from '../../../../core/services/sidebar.service';
import { DynamicTreeDatabase } from '../../../../components/dialogs/tree-data-selection-dialog/utils/dynamic-tree.database';
import { TreeConfigSetupService } from '../../../../components/dialogs/tree-data-selection-dialog/utils/tree-config-setup.service';
import { TreeDataSelectionConfig } from '../../../../components/form/tree-autocomplete/models/tree-data-selection.config';
import { FilterService } from '../../../../core/services/filter.service';
import { SearchRequestService } from '../../../../core/services/search-request.service';
import { TranslationService } from '../../../../core/services/translation.service';
import { HelpTopicListModel } from '../../../models/responses/help-topic-list.model';
import { HelpTopicsStore } from '../../../services/stores/help-topics.store';
import { HelpTopicEditComponent } from './components/help-topic-edit/help-topic-edit.component';
import { generateHelpTopicConfig } from '../../../services/tree-autocomplete-configs.service';
import { HelpSubTopicsStore } from '../../../services/stores/help-sub-topics.store';

function treeConfigFactory(
    helpTopicsStore: HelpTopicsStore,
    helpSubTopicsStore: HelpSubTopicsStore
): TreeDataSelectionConfig<HelpTopicListModel> {
    return generateHelpTopicConfig(helpTopicsStore, helpSubTopicsStore);
}

@Component({
    selector: 'arc-help-topics',
    templateUrl: './help-topics.component.html',
    styleUrl: './help-topics.component.scss',
    // Tree list components need their own instances of these services.
    providers: [
        SearchRequestService,
        FilterService,
        TreeConfigSetupService,
        DynamicTreeDatabase,
        { provide: TreeDataSelectionConfig, useFactory: treeConfigFactory, deps: [HelpTopicsStore, HelpSubTopicsStore, TranslationService] }
    ]
})
export class HelpTopicsComponent extends BaseTreeListComponent<HelpTopicListModel> {
    @ViewChild('newRecordInput') _newRecordInput?: ElementRef;
    @ViewChild('movableItem') movableItem!: ElementRef;

    dragElement?: any;
    draggedNode?: TreeItemFlatNode<number>;
    dragNodeExpandOverWaitTimeMs = 600;
    dragNodeExpandOverTime = 0;
    dragNodeExpandOverNode?: TreeItemFlatNode<number>;

    readonly helpTopicsStore = inject(HelpTopicsStore);
    readonly helpSubTopicsStore = inject(HelpSubTopicsStore);

    private readonly nodeNameProp = 'MAT-TREE-NODE';
    private readonly hoverClasses = ['border', 'border-dashed', 'border-current', 'bg-app'];
    private readonly sidebarService = inject(SidebarService);
    private readonly dialogService = inject(DialogService);

    openEdit(node: TreeItemFlatNode<number>): void {
        const entityName = 'HelpTopics';
        this.sidebarService
            .openEdit({
                entityName,
                store: node.level === 0 ? this.helpTopicsStore : this.helpSubTopicsStore,
                editComponents: [{ titleKey: 'HelpTopics.Edit.BaseData.Name', component: HelpTopicEditComponent }],
                existingId: node.id,
                headerTitle: this.sidebarService.getDefaultRightSidebarHeaderTitleFn(entityName),
                headerSubtitle: item => of(item.title)
            })
            .subscribe(shouldReload => {
                if (shouldReload) {
                    this._searchRequestService.forceReload();
                }
            });
    }

    remove(node: TreeItemFlatNode<number>): void {
        this.dialogService
            .openDeleteDialog('HelpTopics', 1)
            .afterClosed()
            .subscribe(isConfirmed => {
                if (isConfirmed) {
                    this.isLoading = true;
                    this.helpTopicsStore
                        .remove(node.id)
                        .pipe(finalize(() => (this.isLoading = false)))
                        .subscribe(() => this._searchRequestService.forceReload());
                }
            });
    }

    /**
     * Perform logic to move/merge groups.
     * Logic:
     * - First level groups cannot be moved.
     * - Second level groups can be moved to another first level parent - their children are moved with them.
     * - Third level groups can be moved to another second level parent.
     * - Third level groups moved onto another third level group can be merged.
     * @param event Drag event.
     * @param hoveredNode Node being hovered by the user.
     */
    handleDrop(event: DragEvent, hoveredNode: TreeItemFlatNode<number>): void {
        event.preventDefault();

        if (!this.canDrop(hoveredNode)) {
            return;
        }

        if (this.draggedNode!.id !== hoveredNode.id) {
            this.isLoading = true;
            this.helpSubTopicsStore.moveToNewHelpTopic(this.draggedNode?.id!, hoveredNode.id).subscribe(() => {
                this.isLoading = false;
                this._searchRequestService.forceReload();
            });
        }

        this.resetDragNodes();
    }

    handleDragStart(event: DragEvent, node: TreeItemFlatNode<number>): void {
        this.draggedNode = node;

        event.dataTransfer?.setData('text/plain', node.id.toString()); // Required for Firefox.
        this.treeControl.collapse(node);
    }

    handleDragOver(event: DragEvent, hoveredNode: TreeItemFlatNode<number>): void {
        event.preventDefault();

        const element = (event.target as any).nodeName === this.nodeNameProp ? (event.target as any) : (event.target as any).parentElement;

        this.dragElement?.classList.remove(...this.hoverClasses);

        if (this.canDrop(hoveredNode)) {
            element.classList.add(...this.hoverClasses);
        }

        // Handling whether the node should be expanded.
        if (hoveredNode === this.dragNodeExpandOverNode) {
            if (
                hoveredNode.level < 1 &&
                this.draggedNode !== hoveredNode &&
                !this.treeControl.isExpanded(hoveredNode) &&
                new Date().getTime() - this.dragNodeExpandOverTime > this.dragNodeExpandOverWaitTimeMs
            ) {
                this.treeControl.expand(hoveredNode);
            }
        } else {
            this.dragElement = element;
            this.dragNodeExpandOverNode = hoveredNode;
            this.dragNodeExpandOverTime = new Date().getTime();
        }
    }

    handleDragEnd(): void {
        this.resetDragNodes();
    }

    private resetDragNodes(): void {
        this.dragElement?.classList.remove(...this.hoverClasses);

        this.dragElement = undefined;
        this.draggedNode = undefined;
        this.dragNodeExpandOverNode = undefined;
        this.dragNodeExpandOverTime = 0;
    }

    /**
     * Whether the item can be dropped in the current node.
     * Logic:
     * - First level groups cannot be moved.
     * - Second level groups can be moved to another first level parent - their children are moved with them.
     * - Third level groups can be moved to another second level parent.
     * - Third level groups moved onto another third level group can be merged.
     * @param hoveredNode Node being hovered by the user.
     * @private
     */
    private canDrop(hoveredNode: TreeItemFlatNode<number>): boolean {
        return !!this.draggedNode && this.draggedNode.level === 1 && hoveredNode.level === 0;
    }
}
