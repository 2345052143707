<div class="grid grid-cols-2 mt-4">
    <div>
        <span class="text-on-app-light text-sm">{{'General.Description' | transloco}}</span>
        <br/>
        <div [innerHTML]="sanitizedDesc"></div>
    </div>
    <div class="ml-4 mr-8">
        <span class="text-on-app-light text-sm">{{'General.Tags' | transloco}}</span>
        <br/>
        <span>
            <mat-chip-set>
                <mat-chip *ngFor="let tag of tags">{{tag.title}}</mat-chip>
            </mat-chip-set>
        </span>
    </div>
</div>
