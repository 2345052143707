import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { BaseComponent } from '../../../../../../components/abstractions/base.component';
import { NewslettertypesEnum } from '../../../../../models/enums/newsletter-types.enum';

@Component({
    selector: 'arc-newsletter-email-editor',
    templateUrl: './newsletter-email-editor.component.html',
    styleUrl: './newsletter-email-editor.component.scss'
})
export class NewsletterEmailEditorComponent extends BaseComponent {
    @Input() emailMessageFormGroup!: FormGroup;
    @Input() messageType!: string;

    get isTextWithButtonEmailMessage(): boolean {
        return this.messageType === NewslettertypesEnum.TextWithButtonEmailMessage;
    }
}
