import { BaseEntityModel } from '../../../core/abstractions/base-entity.model';
import { PaymentTypesEnum } from '../enums/payment-types.enum';

export class InvoiceEditModel extends BaseEntityModel {
    perDate!: Date;
    payedDate?: Date;
    sentDate?: Date;
    payableUntilDate!: Date;
    remindedDate?: Date;
    remindedDate2?: Date;
    remarks?: string;
    paymentType!: PaymentTypesEnum;

    constructor(init?: Partial<InvoiceEditModel>) {
        super();

        if (!!init) {
            Object.assign(this, init);
        }
    }
}
