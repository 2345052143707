import { animate, style, transition, trigger } from '@angular/animations';

export const carouselAnimations = [
    trigger('carouselAnimation', [
        transition('void => *', [
            style({ opacity: 0 }),
            animate('300ms', style({ opacity: 1 }))
        ]),
        transition('* => void', [
            animate('300ms', style({ opacity: 0 }))
        ])
    ])
];
