@if(!!createTicketResponse) {
  <div class="flex flex-col gap-4 my-4">
    <arc-input [control]="titleControl"></arc-input>
    <span>{{ 'Tickets.Create.Message' | transloco }}</span>
    <arc-markdown-editor [formControl]="formGroup.controls.message"> </arc-markdown-editor>
    <arc-file-upload class="block w-full !h-[60px] px-[2px]" [shouldForceMinHeight]="false" [formControl]="fileControl" [allowUrlUpload]="false"
      [maxFileSizeInKb]="2048">
    </arc-file-upload>
    @for(file of formGroup.value.attachments; track file.blobDataId) {
      <div class="flex items-center p-4 bg-gray-100 rounded-lg">
        <mat-icon class="text-gray-700">insert_drive_file</mat-icon>
        <span class="ml-2 text-gray-700">{{ file.fileName }}</span>
        <button mat-icon-button class="ml-auto" (click)="removeFile(file.blobDataId)">
          <mat-icon class="text-gray-700">delete</mat-icon>
        </button>
      </div>
    }
  </div>
  @if(createTicketResponse().additionalData.length > 0) {
      <arc-dynamic-form #dynamicForm [formFields]="createTicketResponse().additionalData"
        (formSubmitted)="createTicket($event)" classLayout="grid grid-cols-2 gap-x-4"></arc-dynamic-form>
  }
  <div class="flex flex-col items-start">
    <div class="text-xl font-semibold mb-4">{{ 'Tickets.Create.Priority.Name' | transloco }}</div>
    <div class="w-full flex justify-between mb-2 gap-2 items-start">
      <div class="text-center w-1/4">
        <div class="font-bold">{{ 'Tickets.Create.Priority.Low' | transloco }}</div>
        <div class="text-sm">{{'Tickets.Priority.' + TicketPrioritiesEnum[TicketPrioritiesEnum.Low] | transloco}}</div>
      </div>
      <div class="text-center w-1/4">
        <div class="font-bold">{{ 'Tickets.Create.Priority.Medium' | transloco }}</div>
        <div class="text-sm">{{'Tickets.Priority.' + TicketPrioritiesEnum[TicketPrioritiesEnum.Medium] | transloco}}</div>
      </div>
      <div class="text-center w-1/4">
        <div class="font-bold">{{ 'Tickets.Create.Priority.High' | transloco }}</div>
        <div class="text-sm">{{'Tickets.Priority.' + TicketPrioritiesEnum[TicketPrioritiesEnum.High] | transloco}}</div>
      </div>
      <div class="text-center w-1/4">
        <div class="font-bold">{{ 'Tickets.Create.Priority.Immediate' | transloco }}</div>
        <div class="text-sm">{{'Tickets.Priority.' + TicketPrioritiesEnum[TicketPrioritiesEnum.Immediate] | transloco}}</div>
      </div>
    </div>
    <div class="flex w-[78.5%] ml-[5.6rem]">
      <mat-slider min="0" max="3" step="1" tickInterval="1" class="!w-full" [ngClass]="sliderColor()">
        <input matSliderThumb [formControl]="formGroup.controls.priority">
      </mat-slider>
    </div>
  </div>
}