import { ColumnTypeEnum } from '../enums/column-type.enum';
import { BaseColumnModel } from './base-column.model';

export class ColoredStackedColumnModel extends BaseColumnModel {
    propertyName2!: string;
    color!: string;
    isColorInHex = false;

    constructor(init?: Partial<ColoredStackedColumnModel>) {
        super(ColumnTypeEnum.ColoredStack);

        if (!!init) {
            Object.assign(this, init);
        }
    }
}
