import { Injectable, WritableSignal, signal } from '@angular/core';
import { ComponentType } from '@angular/cdk/portal';
import { Subject } from 'rxjs';

import { OptionalType } from '../models/types/optional.type';
import { BaseSidebarHeaderAdditionalInfoComponent } from '../../components/sidebar-components/edit-sidebar/base-edit-sidebar-header-additional-info.component';

@Injectable({
    providedIn: 'root'
})
export class SidebarHeaderService<T = any> {
    title = signal('');
    subtitle = signal('');
    additionalInfoComponent: WritableSignal<OptionalType<ComponentType<BaseSidebarHeaderAdditionalInfoComponent<T>>>> = signal(undefined);
    item: WritableSignal<OptionalType<T>> = signal(undefined);
    hasCloseButton = signal(false);
    hasBackButton = signal(false);
    hasPadding = signal(true);

    readonly backButtonClicked = new Subject<void>();

    reset(): void {
        this.title.set('');
        this.subtitle.set('');
        this.additionalInfoComponent.set(undefined);
        this.item.set(undefined);
        this.hasCloseButton.set(false);
        this.hasBackButton.set(false);
        this.hasPadding.set(true);
    }

    handleBackButtonClicked(): void {
        this.backButtonClicked.next();
    }
}
