<div class="flex flex-col">
    <span class="truncate" [ngClass]="{'text-xs': castedColumnModel.isSecondPropertySmaller}"
        [class.font-bold]="isBold(castedColumnModel.propertyName)">
        {{ getItemValue(castedColumnModel.propertyName2) }}
    </span>
    <span class="truncate" [class.text-on-app-light]="!!castedColumnModel.suffixPropertyName"
        [style.margin-top]="castedColumnModel.isCompact ? '-5px' : ''">
        <span class="text-on-app" [class.text-sm]="castedColumnModel.isCompact" [class.font-bold]="isBold(castedColumnModel.propertyName)">
            {{ getItemValue(castedColumnModel.propertyName) }}
        </span>
        <span *ngIf="!!castedColumnModel.suffixPropertyName" class="ml-2 text-sm text-on-app-light">
            {{ getItemValue(castedColumnModel.suffixPropertyName) }}
        </span>
    </span>
</div>
