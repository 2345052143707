<form [formGroup]="formGroup" class="flex flex-col gap-4">
    <arc-input labelKey="NewsletterSubscriber.EditFields.SubscriptionType" [control]="formGroup.controls.subscriptionType"></arc-input>
    <arc-checkbox labelKey="NewsletterSubscriber.EditFields.IsPrivate" [control]="formGroup.controls.hiddenSubscriptionType"></arc-checkbox>
    <arc-input labelKey="NewsletterSubscriber.EditFields.Email" [control]="formGroup.controls.email"></arc-input>
    <arc-input labelKey="NewsletterSubscriber.EditFields.Name" [control]="formGroup.controls.name"></arc-input>
    <arc-select
        labelKey="NewsletterSubscriber.EditFields.LanguageIsoCode"
        [options]="allowedLanguages"
        [control]="formGroup.controls.languageIsoCode"
        class="w-1/3"></arc-select>
    <arc-quick-search
        label="NewsletterSubscriber.EditFields.License"
        [config]="ComplexDataTypesEnum.License | arcQuickSearchConfig"
        [formControl]="formGroup.controls.licenseId">
    </arc-quick-search>
</form>
