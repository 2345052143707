import { Directive } from '@angular/core';

import { Identifyable } from '../../core/abstractions/identifyable';

@Directive()
export abstract class BaseCardComponent<TList extends Identifyable<TId>, TId = number> {
    item!: TList;

    afterItemLoad(): void {
    }
}
