import { Directive, Input } from '@angular/core';

import { BaseColumnModel } from '../../models/column-types/base-column.model';

/**
 * This is the base class for all components that can be used inside a table as a custom column.
 */
@Directive()
export class BaseCustomColumnComponent<T extends object> {
    @Input() item!: T;
    @Input() columnModel!: BaseColumnModel;
}
