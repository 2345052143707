import { OnDestroy, Directive } from '@angular/core';
import { Subscription } from 'rxjs';

@Directive()
export abstract class BaseComponent implements OnDestroy {
    private subscription = new Subscription();

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    protected addSubscriptions(...subscriptions: Subscription[]): void {
        subscriptions.forEach(s => {
            if (s) {
                this.subscription.add(s);
            }
        });
    }
}
