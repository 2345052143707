<div class="w-full">
    <gridster [options]="gridsterOptions" class="bg-transparent !static">
        <gridster-item [item]="widget.gridsterItem" *ngFor="let widget of widgets" class="bg-widget rounded-lg">
            <button *ngIf="isDashboardEditable && widgets.includes(widget)"
                matTooltip="{{ 'General.Actions.Delete' | transloco }}"
                class="remove-widget absolute right-4 top-4 z-10" (mousedown)="removeWidget($event, widget)"
                (touchstart)="removeWidget($event, widget)">
                <i class="material-icons text_align-center">close</i>
            </button>

            <!-- widget components -->
            <ng-container [ngSwitch]="widget.type">
                <arc-statistics-widget
                    *ngSwitchCase="[dashboardComponentTypeEnum.LicensesByProduct].includes(widget.type) ? widget.type : ''"
                    [widget]="asStatisticsWidgetModel(widget)"></arc-statistics-widget>
                    <arc-statistics-widget
                    *ngSwitchCase="[dashboardComponentTypeEnum.TransactionsByReseller].includes(widget.type) ? widget.type : ''"
                    [widget]="asStatisticsWidgetModel(widget)"></arc-statistics-widget>
                <ng-container *ngSwitchDefault>
                    "Not implemented yet."
                </ng-container>
            </ng-container>
        </gridster-item>
    </gridster>
</div>
<div class="fixed bottom-8 right-8 z-50 flex gap-2">
    <button mat-fab *ngIf="isDashboardEditable" [matMenuTriggerFor]="newWidgetContextMenu" color="accent">
        <mat-icon>add</mat-icon>
    </button>
    <mat-menu #newWidgetContextMenu="matMenu">
        <button mat-menu-item *ngFor="let widgetType of widgetTypesList" (click)="addWidget(widgetType)">
            {{ 'Widgets.WidgetTypes.' + widgetType | transloco }}
        </button>
    </mat-menu>

    <button mat-fab color="primary" (click)="toggleDashboardEdit()">
        <mat-icon>{{ isDashboardEditable ? 'check' : 'edit' }}</mat-icon>
    </button>
</div>
