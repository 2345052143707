import { Identifyable } from '../../../core/abstractions/identifyable';

export class LicenseModuleBundleEditModel implements Identifyable {
    id!: number;
    quantity!: number;
    validThru!: Date;
    validFrom!: Date;
    cancelledDate?: Date;
    billedUntilDate?: Date;
    price!: number;
    poolTime!: number;
    monthlyPrice!: number;
    isDemo!: boolean;
    constructor(init?: Partial<LicenseModuleBundleEditModel>) {
        if (!!init) {
            Object.assign(this, init);
        }
    }
}
