import { Component, input } from '@angular/core';
import { FormControl } from '@angular/forms';

import { ButtonToggleModel } from '../../core/models/button-toggle.model';

@Component({
    selector: 'arc-button-toggle-group',
    templateUrl: './button-toggle-group.component.html',
    styleUrl: './button-toggle-group.component.scss'
})
export class ButtonToggleGroupComponent<T> {
    items = input.required<ButtonToggleModel<T>[]>();
    control = input.required<FormControl>();
    toggleButtonClasses = input<string>();
}
