import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs';

import { HelpArticleViewModel } from '../../models/responses/help-article-view.model';
import { HelpArticlesStore } from '../../services/stores/help-articles.store';

@Component({
    selector: 'arc-help-article-view',
    templateUrl: './help-article-view.component.html',
    styleUrl: './help-article-view.component.scss'
})
export class HelpArticleViewComponent {
    helpArticle: HelpArticleViewModel | undefined;
    isLoading = true;

    private readonly activatedRoute = inject(ActivatedRoute);
    private readonly helpArticlesStore = inject(HelpArticlesStore);

    constructor() {
        const id = this.activatedRoute.snapshot.paramMap.get('id') || '';

        this.helpArticlesStore
            .getPublicArticleById(id)
            .pipe(first())
            .subscribe(helpArticle => {
                this.helpArticle = helpArticle.value;
                this.isLoading = false;
            });
    }
}
