<mat-form-field [floatLabel]="hasFloatingLabel ? 'always' : 'auto'" class="w-full" [color]="color"
    [arcErrorTooltip]="_formControl" [subscriptSizing]="hasFixedSubscript ? 'fixed' : 'dynamic'">
    <mat-label>{{ isLabelTranslated ? label : (label | transloco) }}</mat-label>
    <input matInput [formControl]="internalControl" (blur)="onBlur()" (focus)="onFocus()" type="text"
        [placeholder]="isPlaceholderTranslated ? placeholder : (placeholder | transloco)"
        [matAutocomplete]="autocomplete" (input)="onInput()" />
    <mat-autocomplete autoActiveFirstOption #autocomplete="matAutocomplete"
        [panelWidth]="shouldPanelWidthFitContent ? 'fit-content' : ''" [displayWith]="valueDisplayFunction.bind(this)"
        (optionSelected)="onOptionSelected($event)">
        <mat-option *ngFor="let option of options" [value]="option">
            {{ getOptionDisplay(option) }}
        </mat-option>
    </mat-autocomplete>
    <div *ngIf="isLoading" class="!absolute right-0 top-1/2 -translate-y-1/2 w-[40px] h-[40px] grid place-items-center">
        <mat-spinner diameter="22"></mat-spinner>
    </div>
</mat-form-field>