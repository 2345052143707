import { GeneralDataTypeEnum } from '../../../core/models/enums/general-data-type.enum';
import { KeyValueModel } from '../../../core/models/key-value.model';
import { SelectOptionModel } from '../../../core/models/select-option.model';
import { DynamicFormControlTypeEnum } from './dynamic-form-control-type-enum';

export class DynamicFormField {
    // ** The name of the form field. */
    key!: string;
    // ** The label that will be shown for the control. */
    label!: string;
    // ** When true, the label is already translated. */
    // ** When false it holds a translation key */
    isLabelTranslated = false;
    // ** The value of the control.
    // For dropdowns it has to be string. */
    value?: any;
    // ** The type of control used to render the field.  */
    type!: DynamicFormControlTypeEnum;
    // ** For type = GeneralData, the type of general data to show.  */
    generalDataType?: GeneralDataTypeEnum;
    // ** The options which can be selected. */
    options?: KeyValueModel[];
    // ** Specifies if the field can have a null (undefined) value. */
    isNullable = true;
    // ** Specifies if the the field allows multiple selection. */
    isMultiselect = false;

    selectOptions?: SelectOptionModel[];

    constructor(init?: Partial<DynamicFormField>) {
        if (!!init) {
            Object.assign(this, init);
        }

        if (this.options) {
            this.selectOptions = this.options?.map(keyvalue => ({ value: keyvalue.key, label: keyvalue.value ?? '' })) ?? [];
        }
    }
}
