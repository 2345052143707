import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseStore } from '../../../core/abstractions/base.store';
import { StoreConfig } from '../../../core/models/store.config';
import { ApiResponseModel } from '../../models/responses/api-response.model';
import { GlobalSearchResultResponseModel } from '../../models/responses/global-search-result-response.model';

@Injectable({
    providedIn: 'root'
})
export class GlobalSearchStore extends BaseStore {
    constructor() {
        super(new StoreConfig({ baseController: 'search' }));
    }

    search(searchTerm: string): Observable<ApiResponseModel<GlobalSearchResultResponseModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<GlobalSearchResultResponseModel[]>>(this.getUrl(''), searchTerm);
    }
}
