import { Component, EventEmitter, Input, OnInit, Output, WritableSignal, inject, signal } from '@angular/core';
import { CdkPortalOutletAttachedRef, ComponentPortal } from '@angular/cdk/portal';
import { of } from 'rxjs';

import { EditSidebarComponentModel } from '../models/edit-sidebar-component.model';
import { SharedDataService } from '../../../../core/services/shared-data.service';
import { OptionalType } from '../../../../core/models/types/optional.type';
import { BaseEditSidebarItemComponent } from '../base-edit-item/base-edit-sidebar-item.component';
import { Identifyable } from '../../../../core/abstractions/identifyable';

@Component({
    selector: 'arc-edit-sidebar-item',
    templateUrl: './edit-sidebar-item.component.html',
    styleUrl: './edit-sidebar-item.component.scss'
})
export class EditSidebarItemComponent<T extends Identifyable<TId>, TUpdate extends Identifyable<TId> = T, TId = number> implements OnInit {
    @Input({ required: true }) shouldStartExpanded!: boolean;
    @Input({ required: true }) editComponent!: EditSidebarComponentModel<T, TUpdate, TId>;
    @Input() componentPortal?: ComponentPortal<BaseEditSidebarItemComponent<T, TUpdate, TId>>;

    @Output() readonly componentAttached = new EventEmitter<CdkPortalOutletAttachedRef>();
    @Output() readonly expandedChanged = new EventEmitter<boolean>();

    isExpanded = signal(true);
    isHidden: WritableSignal<OptionalType<boolean>> = signal(false);
    hasPermission = signal(true);
    noPermissionTooltip = of('');

    private readonly sharedDataService = inject(SharedDataService);

    ngOnInit(): void {
        this.isExpanded.set(this.shouldStartExpanded);

        if (!!this.editComponent.shouldHideSignalName) {
            this.isHidden = this.sharedDataService.getOrCreateSignal<boolean>(this.editComponent.shouldHideSignalName);
        }
    }

    setExpanded(isExpanded: boolean): void {
        this.expandedChanged.emit(isExpanded);
        this.isExpanded.set(isExpanded);
    }
}
