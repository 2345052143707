/* eslint-disable no-null/no-null */
import { AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Observable, catchError, map, of } from 'rxjs';

import { ApiResponseModel } from '../../app/models/responses/api-response.model';

export class CustomValidators {
    static number(params: { min?: number; max?: number; isInteger?: boolean } = {}): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (
                !control.hasValidator(Validators.required) &&
                (control.value === undefined || control.value === null || control.value === '')
            ) {
                return null;
            }

            const value = Number(control.value);

            if (isNaN(value)) {
                return { number: true };
            }

            const isRange = params.min !== undefined && params.max !== undefined;
            if (params.min !== undefined || params.max !== undefined) {
                const isTooSmall = params.min !== undefined && value < params.min;
                const isTooBig = params.max !== undefined && value > params.max;
                if (isRange && (isTooSmall || isTooBig)) {
                    return { numberRange: { min: params.min, max: params.max } };
                } else if (isTooSmall) {
                    return { numberMin: { min: params.min } };
                } else if (isTooBig) {
                    return { numberMax: { max: params.max } };
                }
            }

            if (params.isInteger && !Number.isInteger(value)) {
                return { numberInteger: true };
            }

            return null;
        };
    }

    static url(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            try {
                return Boolean(new URL(control.value)) ? null : { url: true };
            } catch (e) {
                return { url: true };
            }
        };
    }

    static matchFields(fieldNames: string[]): ValidatorFn {
        return (form: AbstractControl): ValidationErrors | null => {
            const controls = fieldNames.map(fieldName => form.get(fieldName));
            const values = controls.map(c => c?.value);

            // Check if all controls have values and they are all equal
            const isValid = values.every((value, index, array) => value === array[0]);

            if (!isValid) {
                // Set error if they are not all equal
                fieldNames.forEach(fieldName => {
                    const control = form.get(fieldName);
                    if (!!control) {
                        control.setErrors({ matchFields: true });
                    }
                });
                return { matchFields: true };
            } else {
                // Clear the error if they are all equal or one of them is undefined
                fieldNames.forEach(fieldName => {
                    const control = form.get(fieldName);
                    if (!!control) {
                        control.setErrors(null);
                    }
                });
                return null;
            }
        };
    }

    static domain(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const domainRegex = /^[a-z0-9-]+$/;

            const isValid = domainRegex.test(control.value);
            return isValid ? null : { invalidDomain: { value: control.value } };
        };
    }

    static asyncDomainValidator(
        originalValue: string,
        hostUrl: () => string,
        checkDomainUniqueness: (subDomain: string, hostUrl: string) => Observable<ApiResponseModel<boolean>>
    ): AsyncValidatorFn {
        return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
            const domain = control.value;
            if (domain === originalValue) {
                return of(null);
            }
            return checkDomainUniqueness(domain, hostUrl()).pipe(
                map(isUnique => (isUnique.value ? null : { alreadyTaken: true })),
                catchError(() => of({ alreadyTaken: true }))
            );
        };
    }

    static atLeastOneFieldValidator(fieldNames: string[]): ValidatorFn {
        return (form: AbstractControl): ValidationErrors | null => {
            const hasAtLeastOne = fieldNames.some(field => !!form.get(field)?.value);

            if (!hasAtLeastOne) {
                // Set error if they are not all equal
                fieldNames.forEach(fieldName => {
                    const control = form.get(fieldName);
                    if (!!control) {
                        control.setErrors({ matchFields: true });
                    }
                });
                return { matchFields: true };
            } else {
                // Clear the error if they are all equal or one of them is undefined
                fieldNames.forEach(fieldName => {
                    const control = form.get(fieldName);
                    if (!!control) {
                        control.setErrors(null);
                    }
                });
                return null;
            }
        };
    }
}
