import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators } from '@angular/forms';
import { Component, inject } from '@angular/core';

import { BaseComponent } from '../../abstractions/base.component';
import { TranslationDialogDataModel } from './models/translation-dialog-data.model';
import { ArcFormControl } from '../../../core/utils/arc-form-control';

@Component({
    selector: 'arc-translation-dialog',
    templateUrl: './translation-dialog.component.html',
    styleUrls: ['./translation-dialog.component.scss']
})
export class TranslationDialogComponent extends BaseComponent {
    readonly data: TranslationDialogDataModel = inject(MAT_DIALOG_DATA);

    formGroup = new FormGroup({
        valueDe: new ArcFormControl(this.data.valueDe, this.data.currentLanguageCode === 'de' ? Validators.required : []),
        valueEn: new ArcFormControl(this.data.valueEn, this.data.currentLanguageCode === 'en' ? Validators.required : []),
        valueFr: new ArcFormControl(this.data.valueFr, this.data.currentLanguageCode === 'fr' ? Validators.required : []),
        valueIt: new ArcFormControl(this.data.valueIt, this.data.currentLanguageCode === 'it' ? Validators.required : [])
    });

    private readonly dialogRef = inject(MatDialogRef<TranslationDialogComponent>);


    onSave(): void {
        this.formGroup.markAllAsTouched();
        this.formGroup.updateValueAndValidity();
        if(this.formGroup.invalid){
            return;
        }
        this.dialogRef.close(this.formGroup.value);
    }
}
