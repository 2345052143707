import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { ApiResponseModel } from '../../app/models/responses/api-response.model';

@Injectable({
    providedIn: 'root'
})
export class ErrorsService {
    onBusinessException = new Subject<ApiResponseModel<any>>();
    shouldDisplayAlertOnError = true;

    setBusinessException(apiReponseModel: ApiResponseModel<any>): void {
        this.onBusinessException.next(apiReponseModel);
    }
}
