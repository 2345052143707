import { Injectable } from '@angular/core';

import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { StoreConfig } from '../../../core/models/store.config';
import { PortalLogModel } from '../../models/portal-log.model';
import { PortalLogListModel } from '../../models/responses/portal-log-list.model';

@Injectable({
    providedIn: 'root'
})
export class PortalLogsStore extends BaseCrudStore<PortalLogModel, PortalLogListModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'logs' }));
    }
}
