import { Component, OnInit, inject } from '@angular/core';

import { BaseColumnComponent } from '../base-column.component';
import { NumberColumnModel } from '../../models/column-types/number-column.model';
import { TranslationService } from '../../../../core/services/translation.service';
import { UnitsEnum, UnitsEnumHepers } from '../../../../core/models/enums/units.enum';

@Component({
    selector: 'arc-number-column',
    templateUrl: './number-column.component.html',
    styleUrls: ['./number-column.component.scss']
})
export class NumberColumnComponent<T extends object> extends BaseColumnComponent<T> implements OnInit {
    unitTranslated = '';

    private readonly translationService = inject(TranslationService);

    override ngOnInit(): void {
        super.ngOnInit();
        if (!!this.castedColumnModel.unit) {
            let unit: string;
            if (typeof this.castedColumnModel.unit === 'string') {
                unit = this.castedColumnModel.unit;
            } else {
                unit = this.castedColumnModel.unit(this.item);
            }

            if (this.castedColumnModel.isUnitTranslated) {
                this.unitTranslated = ` ${unit}` ?? '';
            } else {
                const unitKey = !!UnitsEnum[unit as UnitsEnum]
                    ? UnitsEnumHepers.getUnitShortTextKey(unit as UnitsEnum)
                    : unit;

                this.translationService.getTextObservable(unitKey).subscribe(result => {
                    this.unitTranslated = ` ${result}`;
                });
            }
        }
    }

    get castedColumnModel(): NumberColumnModel {
        return this.columnModel as NumberColumnModel;
    }
}
