<div class="grid grid-cols-2 grid-rows-2 h-full">
    <div class="flex flex-col gap-2 items-center justify-center">
        <span class="text-2xl">{{ 'Components.ImportDialog.ImportStatusTypes.New' | transloco }}</span>
        <span class="text-4xl">{{ resultData?.newEntities ?? 0 }}</span>
    </div>
    <div class="flex flex-col gap-2 items-center justify-center">
        <span class="text-2xl">{{ 'Components.ImportDialog.ImportStatusTypes.Changed' | transloco }}</span>
        <span class="text-4xl">{{ resultData?.changedEntities ?? 0 }}</span>
    </div>
    <div class="flex flex-col gap-2 items-center justify-center">
        <span class="text-2xl">{{ 'Components.ImportDialog.ImportStatusTypes.Unchanged' | transloco }}</span>
        <span class="text-4xl">{{ resultData?.unchangedEntities ?? 0 }}</span>
    </div>
    <div class="flex flex-col gap-2 items-center justify-center text-error">
        <span class="text-2xl">{{ 'Components.ImportDialog.ImportStatusTypes.Invalid' | transloco }}</span>
        <span class="text-4xl">{{ resultData?.invalidEntities ?? 0 }}</span>
    </div>
</div>