/* eslint-disable @typescript-eslint/naming-convention */

import { BaseEntityModel } from '../../../core/abstractions/base-entity.model';
import { ModuleBundleQuantityDiscountModel } from '../module-bundle-quantity-discount.model';

export class HardwareBundleEditModel extends BaseEntityModel {
    productId!: number;
    title_de!: string;
    title_fr?: string;
    title_it?: string;
    title_en?: string;
    groupTitle_de?: string;
    groupTitle_fr?: string;
    groupTitle_it?: string;
    groupTitle_en?: string;
    minQuantity!: number;
    maxQuantity!: number;
    price!: number;
    noBenefit!: boolean;
    imageBlobData?: number[];
    imageBlobFileName?: string;
    usePosQuantity!: boolean;
    quantityDiscounts!: ModuleBundleQuantityDiscountModel[];

    constructor(init?: Partial<HardwareBundleEditModel>) {
        super();
        if (!!init) {
            Object.assign(this, init);
        }
    }
}
