import { FormGroup } from '@angular/forms';
import { Injectable, inject } from '@angular/core';
import { TranslocoService, HashMap } from '@ngneat/transloco';
import { registerLocaleData } from '@angular/common';
import { DateAdapter } from '@angular/material/core';
import localeBritishEnglish from '@angular/common/locales/en-GB';
import localeGermanSwiss from '@angular/common/locales/de-CH';
import localeFrenchSwiss from '@angular/common/locales/fr-CH';
import localeItalianSwiss from '@angular/common/locales/it-CH';
import { Observable, firstValueFrom, forkJoin, map } from 'rxjs';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/de-ch';

import { TranslationLanguageModel } from '../models/translation-language.model';
import { SecurityStorage } from './storages/security.storage';
import { SettingsStorage } from './storages/settings.storage';
import { UsersStore } from '../../app/services/stores/users.store';
import dayjs from '../utils/dayjs';
import { DayjsDateAdapter } from '../utils/dayjs-date-adapter';
import { GeneralDataStore } from '../../app/services/stores/general-data.store';

@Injectable({
    providedIn: 'root'
})
export class TranslationService {
    get allowedLanguages(): TranslationLanguageModel[] {
        return this.availableLanguages;
    }

    /**
     * Retrieves current selected language.
     */
    get current(): TranslationLanguageModel {
        return this.currentLanguage;
    }

    get languagesForTranslation(): string[] {
        return ['de', 'en', 'fr', 'it'];
    }

    private currentLanguage: TranslationLanguageModel;
    private defaultLanguageCode = 'de';
    private availableLanguages: TranslationLanguageModel[] = [
        {
            code: 'en',
            name: 'English',
            culture: 'en',
            dayjsLocaleCode: 'en-gb',
            dateFormat: 'DD/MM/YYYY',
            dateFormatFlatPicker: 'd/m/Y H:i'
        },
        {
            code: 'de',
            name: 'Deutsch',
            culture: 'de-CH',
            dayjsLocaleCode: 'de-ch',
            dateFormat: 'DD.MM.YYYY',
            dateFormatFlatPicker: 'd.m.Y, H:i'
        },
        {
            code: 'it',
            name: 'Italiano',
            culture: 'it-CH',
            dayjsLocaleCode: 'de-ch',
            dateFormat: 'DD.MM.YYYY',
            dateFormatFlatPicker: 'd.m.Y, H:i'
        },
        {
            code: 'fr',
            name: 'Français',
            culture: 'fr-CH',
            dayjsLocaleCode: 'de-ch',
            dateFormat: 'DD.MM.YYYY',
            dateFormatFlatPicker: 'd.m.Y, H:i'
        }
    ];
    private get currentLangSet(): TranslationLanguageModel[] {
        return this.allowedLanguages.length > 0 ? this.allowedLanguages : this.availableLanguages;
    }

    private readonly usersStore = inject(UsersStore);
    private readonly generalDataStore = inject(GeneralDataStore);
    private readonly translocoService = inject(TranslocoService);
    private readonly securityStorage = inject(SecurityStorage);
    private readonly settingsStorage = inject(SettingsStorage);
    private readonly dateAdapter = inject(DateAdapter) as DayjsDateAdapter;

    constructor() {
        let userSelectedLangCode: string | undefined;
        const userInfo = this.securityStorage.getUserInfo();

        if (!!userInfo) {
            userSelectedLangCode = userInfo.languageId;
        } else if (this.settingsStorage.getNotLoggedLanguageCode()) {
            userSelectedLangCode = this.settingsStorage.getNotLoggedLanguageCode();
        }

        if (!userSelectedLangCode) {
            if (navigator.language?.startsWith('de')) {
                userSelectedLangCode = 'de';
            } else if (navigator.language?.startsWith('en')) {
                userSelectedLangCode = 'en';
            } else if (navigator.language?.startsWith('fr')) {
                userSelectedLangCode = 'fr';
            } else if (navigator.language?.startsWith('it')) {
                userSelectedLangCode = 'it';
            } else {
                userSelectedLangCode = this.defaultLanguageCode;
            }
        }

        this.currentLanguage = this.currentLangSet.find(l => l.code === userSelectedLangCode)!;

        this.translocoService.setActiveLang(this.currentLanguage.code);
        this.setup();

        this.setDateAdapterLocale();
    }

    setup(): void {
        registerLocaleData(localeBritishEnglish, 'en');
        registerLocaleData(localeGermanSwiss, 'de');
        registerLocaleData(localeFrenchSwiss, 'fr');
        registerLocaleData(localeItalianSwiss, 'it');
    }

    switch(code: string): void {
        if (this.currentLanguage?.code === code) {
            return;
        }

        let newLang = this.currentLangSet.find(l => l.code === code);

        if (!newLang) {
            newLang =
                this.currentLangSet.find(l => l.code === navigator.language) ||
                this.currentLangSet.find(l => l.code === this.defaultLanguageCode);
            code = newLang!.code;
        }

        this.translocoService.setActiveLang(code);

        this.currentLanguage = newLang!;
        const userInfo = this.securityStorage.getUserInfo();

        if (!!userInfo) {
            userInfo.languageId = code;
            this.securityStorage.saveUserInfo(userInfo);
            this.usersStore.changeLanguage(userInfo.id, code).subscribe();
        } else {
            this.settingsStorage.saveNotLoggedLanguageCode(code);
        }

        this.reloadPage();
    }

    /**
     * Retrieves a translated text based on the provided key.
     * @param key Translation text's key.
     * @param params Translation parameters
     */
    getText<T = string>(key: string, params?: HashMap): T {
        return this.translocoService.translate<T>(key, params);
    }

    /**
     * Retrieves an Observable for a translated text based on the provided key.
     * @param key Translation text's key.
     * @param params Translation parameters
     */
    getTextObservable<T = string>(key: string, params?: HashMap): Observable<T> {
        return this.translocoService.selectTranslate<T>(key, params);
    }

    async getTextAsync<T = string>(key: string, params?: HashMap): Promise<T> {
        return firstValueFrom(this.getTextObservable(key, params));
    }

    translateFormControls(formGroup: FormGroup, formControlNames: string[]): Observable<void[]> {
        const observables: Observable<void>[] = [];

        for (const controlName of formControlNames) {
            const [valueToTranslate, sourceLanguage] = this.getValueToTranslate(formGroup, controlName);
            if (!valueToTranslate) {
                continue;
            }

            for (const targetLanguage of this.languagesForTranslation) {
                const control = formGroup.get(`${controlName}_${targetLanguage}`);
                if (!!control?.value) {
                    continue;
                }
                const observable = this.generalDataStore.getTranslatedValue(valueToTranslate, targetLanguage, sourceLanguage).pipe(
                    map(result => {
                        if (result?.value) {
                            control?.patchValue(result.value);
                        }
                    })
                );
                observables.push(observable);
            }
        }

        return forkJoin(observables);
    }

    private getValueToTranslate(formGroup: FormGroup, controlName: string): [string, string] {
        for (const lang of this.languagesForTranslation) {
            const value = formGroup.get(`${controlName}_${lang}`)?.value;
            if (!!value) {
                return [value, lang];
            }
        }
        return ['', ''];
    }

    private setDateAdapterLocale(): void {
        this.dateAdapter.setLocale(this.currentLanguage.culture);
        dayjs.locale(this.currentLanguage.dayjsLocaleCode);
    }

    private reloadPage(): void {
        window.location.href = window.location.href.split('?')[0];
    }
}
