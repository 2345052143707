import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { MatTooltipModule } from '@angular/material/tooltip';

import { MaterialModule } from '../../core/utils/material.module';
import { PipesModule } from '../../core/pipes/pipes.module';
import { BlankLayoutComponent } from './blank-layout/blank-layout.component';
import { DefaultLayoutComponent } from './default-layout/default-layout.component';
import { NoPaddingLayoutComponent } from './no-padding-layout/no-padding-layout.component';
import { SidebarsComponent } from './partials/sidebars/sidebars.component';
import { RightSidebarComponent } from './partials/right-sidebar/right-sidebar.component';
import { LeftSidebarComponent } from './partials/left-sidebar/left-sidebar.component';
import { TopBarComponent } from './partials/top-bar/top-bar.component';
import { GlobalSearchComponent } from './partials/top-bar/global-search/global-search.component';
import { DirectivesModule } from '../../core/directives/directives.module';
import { ComponentsModule } from '../components.module';
import { LoginLayoutComponent } from './login-layout/login-layout.component';
import { SidebarHeaderComponent } from '../sidebar-components/sidebar-header/sidebar-header.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        TranslocoModule,
        MaterialModule,
        MatTooltipModule,
        PipesModule,
        DirectivesModule,
        ComponentsModule
    ],
    declarations: [
        BlankLayoutComponent,
        DefaultLayoutComponent,
        NoPaddingLayoutComponent,
        SidebarsComponent,
        LeftSidebarComponent,
        RightSidebarComponent,
        TopBarComponent,
        GlobalSearchComponent,
        LoginLayoutComponent,
        SidebarHeaderComponent
    ]
})
export class LayoutsModule {}
