<div class="max-w-[560px] flex flex-col gap-12">
    <div>
        <h1 class="text-3xl mb-4 break-words">
            {{ 'Register.Title' | transloco }}
        </h1>
        <p>
            {{ 'Register.Description' | transloco }}
        </p>
    </div>
    <form [formGroup]="formGroup" (submit)="register()">
        <mat-card-content class="grid grid-cols-2 gap-4">
            <arc-input [control]="formGroup.controls.company" labelKey="Register.Company"> </arc-input>
            <arc-input [control]="formGroup.controls.email" labelKey="Register.Email"> </arc-input>
            <arc-input [control]="formGroup.controls.firstName" labelKey="Register.FirstName"> </arc-input>
            <arc-input [control]="formGroup.controls.lastName" labelKey="Register.LastName"> </arc-input>
            <arc-select class="col-span-2" labelKey="Register.LanguageCode" [options]="allowedLanguages"
                [control]="formGroup.controls.languageCode"></arc-select>
            <arc-address-picker class="col-span-2"
                [streetControl]="formGroup.controls.street"
                [streetNumberControl]="formGroup.controls.streetNumber" 
                [zipControl]="formGroup.controls.zip"
                [cityControl]="formGroup.controls.city" 
                [countryIsoCodeControl]="formGroup.controls.countryIsoCode">
            </arc-address-picker>
            <arc-checkbox class="col-span-2" labelKey="Register.IsIntegrator" [control]="formGroup.controls.isIntegrator"></arc-checkbox>
            
            <div>
                <button type="button" class="text-accent mb-10 hover:underline" (click)="redirectToLogin()">
                    {{ 'Register.AlreadyRegistered' | transloco }}
                </button>
            </div>
        </mat-card-content>
        <mat-card-actions>
            <button type="submit" mat-flat-button color="accent">{{ 'Register.Register' | transloco }}</button>
        </mat-card-actions>
    </form>
</div>