import { ImportExecutionRowStatusEnum } from '../../../../../app/models/enums/import-execution-row-status.enum';
import { ImportExecutionRowErrorModel } from '../../../../../app/models/responses/import-execution-row-error.model';
import { DictionaryType } from '../../../../../core/models/types/dictionary.type';

export class ImportPreviewElementModel {
    importStatus!: ImportExecutionRowStatusEnum;
    importGeneralErrors: string[] = [];
    importColumnErrors: ImportExecutionRowErrorModel[] = [];
    importColumnErrorsDict: DictionaryType<string> = {};
    hasErrors!: boolean;
}
