import { Component } from '@angular/core';

import { BaseColumnComponent } from '../base-column.component';
import { ButtonColumnModel } from '../../models/column-types/button-column.model';

@Component({
    selector: 'arc-button-column',
    templateUrl: './button-column.component.html',
    styleUrls: ['./button-column.component.scss']
})
export class ButtonColumnComponent<T extends object> extends BaseColumnComponent<T> {
    get buttonColumnModel(): ButtonColumnModel<T> {
        return this.columnModel as ButtonColumnModel<T>;
    }
}
