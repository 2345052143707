import { Component, OnInit } from '@angular/core';

import { BaseColumnComponent } from '../base-column.component';

@Component({
    selector: 'arc-checkbox-column',
    templateUrl: './checkbox-column.component.html',
    styleUrls: ['./checkbox-column.component.scss']
})
export class CheckboxColumnComponent<T extends object> extends BaseColumnComponent<T> implements OnInit {
    override ngOnInit(): void {
        super.ngOnInit();

        this.classes = 'w-full';
    }
}
