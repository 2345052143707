<h1 mat-dialog-title>
    {{ 'Newsletter.Create.Name' | transloco }}
</h1>

<mat-dialog-content class="grow !max-h-[unset] !flex flex-col">
    <div class="pt-2 grow w-full flex flex-col gap-4" [formGroup]="formGroup">
        <arc-input labelKey="Newsletter.Create.Title" [control]="formGroup.controls.title"></arc-input>
        <arc-general-data-select [generalDataType]="GeneralDataTypeEnum.NewsletterTypes"
            [formControl]="formGroup.controls.messageType" label="Newsletter.Create.MessageType">
        </arc-general-data-select>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-stroked-button [mat-dialog-close]="{ shouldReload: false }">{{ 'General.Actions.Cancel' | transloco }}</button>
    <arc-button type="flat" color="accent" [isLoading]="isCreating" (clicked)="create()">
        {{ 'General.Actions.Create' | transloco }}
    </arc-button>
</mat-dialog-actions>