import { HasRoleGuard } from '../../core/services/guards/has-role.guard';
import { UserRoles } from '../models/enums/user-roles.enum';
import { CustomerInteractionsComponent } from '../views/support/customer-interactions/customer-interactions.component';
import { FeaturesComponent } from '../views/support/features/features.component';
import { FeedbackComponent } from '../views/support/feedback/feedback.component';
import { HelpTooltipsComponent } from '../views/support/help-tooltips/help-tooltips.component';
import { HelpTopicsComponent } from '../views/support/help-topics/help-topics.component';
import { HelpComponent } from '../views/support/help/help.component';
import { TicketsComponent } from '../views/support/tickets/tickets.component';

export const supportRoutes = [
    {
        path: 'support',
        data: {
            showInMainMenu: true,
            mainMenuIndex: 1,
            subMenu: 'support',
            title: 'Support.Support',
            requiredRole: UserRoles.Tickets | UserRoles.HelpArticles | UserRoles.Features | UserRoles.Feedback | UserRoles.BlogArticles
        },
        children: [
            {
                path: 'tickets',
                component: TicketsComponent,
                canActivate: [HasRoleGuard],
                data: {
                    subMenu: 'support',
                    subMenuIndex: 1,
                    startingQueryParams: { IsOpen: 'true', MyTickets: 'true' },
                    title: 'Support.Tickets',
                    requiredRole: UserRoles.Tickets
                }
            },
            {
                path: 'customer-interactions',
                component: CustomerInteractionsComponent,
                canActivate: [HasRoleGuard],
                data: {
                    subMenu: 'support',
                    subMenuIndex: 2,
                    title: 'Support.CustomerInteractions',
                    requiredRole: UserRoles.CustomerInteractions
                }
            },
            {
                path: 'help',
                component: HelpComponent,
                canActivate: [HasRoleGuard],
                data: {
                    subMenu: 'support',
                    subMenuIndex: 3,
                    title: 'Support.Help',
                    requiredRole: UserRoles.HelpArticles
                }
            },
            {
                path: 'help-tooltips',
                component: HelpTooltipsComponent,
                canActivate: [HasRoleGuard],
                data: {
                    subMenu: 'support',
                    subMenuIndex: 4,
                    title: 'Support.HelpTooltips',
                    requiredRole: UserRoles.ToolTips
                }
            },
            {
                path: 'features',
                component: FeaturesComponent,
                canActivate: [HasRoleGuard],
                data: {
                    subMenu: 'support',
                    subMenuIndex: 5,
                    title: 'Support.Features',
                    requiredRole: UserRoles.Features
                }
            },
            {
                path: 'feedback',
                component: FeedbackComponent,
                canActivate: [HasRoleGuard],
                data: {
                    subMenu: 'support',
                    subMenuIndex: 6,
                    title: 'Support.Feedback',
                    startingQueryParams: { IsProcessed: 'false' },
                    requiredRole: UserRoles.Feedback
                }
            },
            {
                path: 'help-topics',
                component: HelpTopicsComponent,
                canActivate: [HasRoleGuard],
                data: {
                    subMenu: 'support',
                    subMenuIndex: 7,
                    title: 'Support.HelpTopics',
                    requiredRole: UserRoles.HelpTopics
                }
            }
        ]
    }
];
