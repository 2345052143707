<mat-form-field class="w-full">
    <mat-label>{{label || (labelKey | transloco)}}</mat-label>
    <mat-chip-grid #chipGrid aria-label=>
        <mat-chip-row *ngFor="let chip of selectedChips" (removed)="remove(chip)">
            {{ optionDisplayFn(chip)}}
            <button matChipRemove>
                <mat-icon>cancel</mat-icon>
            </button>
        </mat-chip-row>
    </mat-chip-grid>
    <input #chipInput (focus)="onFocus()" (input)="onInput()" (blur)="onBlur()" [placeholder]="placeholder | transloco"
        [matChipInputFor]="chipGrid" [matAutocomplete]="auto" [matChipInputSeparatorKeyCodes]="separtorKeyCodes"
        (matChipInputTokenEnd)="add($event)" [(ngModel)]="searchText" />
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)">
        <mat-option *ngFor="let option of filteredChips" [value]="option">
            {{ optionDisplayFn(option) }}
        </mat-option>
    </mat-autocomplete>
    <div *ngIf="isLoading" class="!absolute right-0 top-1/2 -translate-y-1/2 w-[40px] h-[40px] grid place-items-center">
        <mat-spinner diameter="22"></mat-spinner>
    </div>
</mat-form-field>