import { Injectable, inject, signal } from '@angular/core';
import { interval, Observable, Subscription, tap } from 'rxjs';

import { OptionalType } from '../models/types/optional.type';
import { TicketModel } from '../../app/models/ticket.model';
import { SidebarService } from './sidebar.service';
import { RightSidebarConfigModel } from '../models/right-sidebar-config.model';
import { TicketsStore } from '../../app/services/stores/tickets.store';
import { TranslationService } from './translation.service';
import { SidebarHeaderService } from './sidebar-header.service';
import { TicketHeaderAdditionalInfoComponent } from '../../app/views/support/tickets/components/ticket-header-additional-info/ticket-header-additional-info.component';
import { LicenseModel } from '../../app/models/license.model';
import { LicensesStore } from '../../app/services/stores/licenses.store';
import { TicketListModel } from '../../app/models/responses/ticket-list.model';

@Injectable({
    providedIn: 'root'
})
export class TicketSidebarService {
    ticket = signal<OptionalType<TicketModel>>(undefined);
    license = signal<OptionalType<LicenseModel>>(undefined);
    licenseTickets = signal<OptionalType<TicketListModel[]>>([]);

    private ticketTimeIntervalSubscription = new Subscription();

    private readonly sidebarService = inject(SidebarService);
    private readonly ticketsStore = inject(TicketsStore);
    private readonly licenseStore = inject(LicensesStore);
    private readonly translationService = inject(TranslationService);
    private readonly headerService = inject(SidebarHeaderService);

    editTicket(config: RightSidebarConfigModel): Observable<OptionalType<boolean>> {
        this.ticketsStore
            .get(config.data)
            .pipe(
                tap(result => {
                    if (!result.value) {
                        return;
                    }
                    this.licenseStore.get(result.value.licenseId).subscribe({
                        next: license => {
                            this.license.set(license.value);
                        }
                    });
                }),
                tap(result => {
                    if (!result.value) {
                        return;
                    }
                    this.licenseStore.getLicenseTickets(result.value.licenseId, result.value.id).subscribe({
                        next: license => {
                            this.licenseTickets.set(license.value);
                        }
                    });
                })
            )
            .subscribe({
                next: result => {
                    const ticket = result.value;
                    this.ticket.set(ticket);

                    this.ticketsStore.updateTicketTime(ticket?.id!).subscribe();

                    this.ticketTimeIntervalSubscription = interval(10000).subscribe(() => {
                        this.ticketsStore.updateTicketTime(ticket?.id!).subscribe();
                    });

                    const headerText = this.translationService.getText('Tickets.Edit.Ticket') + ` #${ticket?.id}`;
                    this.headerService.title.set(headerText);
                    this.headerService.hasCloseButton.set(false);
                    this.headerService.item.set(ticket);
                    this.headerService.additionalInfoComponent.set(TicketHeaderAdditionalInfoComponent);
                }
            });
        return this.sidebarService.openRight<undefined>(config).pipe(
            tap(() => {
                this.resetTicket();
            })
        );
    }

    createTicket(config: RightSidebarConfigModel): Observable<OptionalType<boolean>> {
        const headerText = this.translationService.getText('Tickets.NewEntity');
        this.headerService.title.set(headerText);
        this.headerService.hasCloseButton.set(false);

        return this.sidebarService.openRight<undefined>(config).pipe(
            tap(() => {
                this.resetTicket();
            })
        );
    }

    resetTicket(): void {
        this.ticket.set(undefined);
        this.license.set(undefined);
        this.licenseTickets.set(undefined);
        this.headerService.reset();
        this.ticketTimeIntervalSubscription.unsubscribe();
    }
}
