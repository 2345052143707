import { Component, ViewChild, inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DynamicFormComponent } from '../../dynamic-form/dynamic-form.component';
import { DynamicFormField } from '../../dynamic-form/models/dynamic-form-field';
import { StatisticsWidgetOptionsDialogData } from './models/statistics-widget-options-dialog-data.model';


@Component({
    selector: 'arc-statistics-widget-options-dialog',
    templateUrl: './statistics-widget-options-dialog.component.html',
    styleUrls: ['./statistics-widget-options-dialog.component.scss']
})
export class StatisticsWidgetOptionsDialogComponent {
    @ViewChild('dynamicForm') dynamicForm!: DynamicFormComponent;

    formFields: DynamicFormField[] = [];

    private readonly data: StatisticsWidgetOptionsDialogData = inject(MAT_DIALOG_DATA);
    private readonly dialogRef = inject(MatDialogRef);

    onUpdateSettings(): void {
        this.dynamicForm.submitForm();
    }

    onFormSubmitted(data: any): void {
        this.data.parameters = { ...this.data.parameters, ...data };
        this.dialogRef.close(this.data.parameters);
    }
}
