import { AbstractControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Component, Input, OnInit, inject } from '@angular/core';

import { ArcFormControl, ArcFormControlInterface } from '../../../core/utils/arc-form-control';
import { TranslationService } from '../../../core/services/translation.service';
import { BaseControlComponent } from '../base-control/base-control.component';
import { TranslationDialogComponent } from '../../dialogs/translation-dialog/translation-dialog.component';
import { TranslationDialogDataModel } from '../../dialogs/translation-dialog/models/translation-dialog-data.model';

@Component({
    selector: 'arc-translation-input',
    templateUrl: './translation-input.component.html',
    styleUrls: ['./translation-input.component.scss']
})
export class TranslationInputComponent extends BaseControlComponent implements OnInit {
    @Input() controlDe!: ArcFormControlInterface;
    @Input() controlEn!: ArcFormControlInterface;
    @Input() controlFr!: ArcFormControlInterface;
    @Input() controlIt!: ArcFormControlInterface;
    @Input() translationDialogTitleKey!: string;

    override control = new ArcFormControl('');

    private readonly translationService = inject(TranslationService);
    private readonly matDialog = inject(MatDialog);

    override ngOnInit(): void {
        super.ngOnInit();
        const currentValue = !!this.currentLanguageControl.value ? this.currentLanguageControl.value : this.controlDe.value;

        this.control.setValue(currentValue);
        const valueChangesSub = this.control.valueChanges.subscribe(val => {
            this.currentLanguageControl.setValue(val);
            if (!this.controlDe.value) {
                this.controlDe.setValue(val);
            }
        });

        // the main control uses the validators from the DE control
        this.control.addValidators(this.controlDe.validator ?? []);

        // since DE has the validation, when DE control status changes, we need to set errors on this control too
        const deStatusChangesSub = this.controlDe.statusChanges.subscribe(() => {
            this.control.setErrors(this.controlDe.errors);
        });

        // since DE has the validation, when DE is touched, we need to set errors and mark this control as touched too
        const deTouchedSub = this.controlDe.onTouched.subscribe(() => {
            this.control.setErrors(this.controlDe.errors);
            this.control.markAsTouched();
        });

        this.addSubscriptions(valueChangesSub, deStatusChangesSub, deTouchedSub);
    }

    get currentLanguageCode(): string {
        return this.translationService.current.code;
    }

    get currentLanguageControl(): AbstractControl {
        switch (this.currentLanguageCode) {
            case 'en':
                return this.controlEn;
            case 'fr':
                return this.controlFr;
            case 'it':
                return this.controlIt;
            default:
                return this.controlDe;
        }
    }

    openTranslationDialog(): void {
        const dialogref = this.matDialog.open(TranslationDialogComponent, {
            data: new TranslationDialogDataModel({
                labelKey: this.translationDialogTitleKey,
                label: this.label,
                valueDe: this.controlDe.value,
                valueEn: this.controlEn.value,
                valueFr: this.controlFr.value,
                valueIt: this.controlIt.value,
                currentLanguageCode: this.currentLanguageCode
            }),
            width: '500px',
            maxWidth: '98vw',
            maxHeight: '98vsh'
        });

        dialogref.afterClosed().subscribe(data => {
            if (!data) {
                return;
            }
            let germanValue = data.valueDe;
            if (!germanValue) {
                switch (this.currentLanguageCode) {
                    case 'en':
                        germanValue = data.valueEn;
                        break;
                    case 'fr':
                        germanValue = data.valueFr;
                        break;
                    case 'it':
                        germanValue = data.valueIt;
                        break;
                }
            }
            this.controlDe.setValue(germanValue);
            this.controlEn.setValue(data.valueEn);
            this.controlFr.setValue(data.valueFr);
            this.controlIt.setValue(data.valueIt);
            this.control.setValue(this.currentLanguageControl.value);
        });
    }
}
