import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { UsersStore } from '../../../services/stores/users.store';

@Component({
    selector: 'arc-request-pwd-token',
    templateUrl: './request-password-token.component.html',
    styleUrls: ['./request-password-token.component.scss'],
    providers: [UsersStore]
})
export class RequestPasswordTokenComponent implements OnInit {
    formGroup: FormGroup;
    wasRequestSent = false;
    subdomain = '';

    private readonly formBuilder = inject(FormBuilder);
    private readonly usersStore = inject(UsersStore);
    private readonly router = inject(Router);

    constructor() {
        this.formGroup = this.formBuilder.group({
            email: [undefined, Validators.required]
        });
    }

    requestResetEmail(): void {
        this.formGroup.markAllAsTouched();

        if (this.formGroup.invalid) {
            return;
        }

        this.usersStore.requestPasswordChange(this.formGroup.value.email).subscribe(resp =>
            this.wasRequestSent = resp.value || false
        );
    }

    returnToLogin(): void {
        this.router.navigate(['login']).then();
    }

    ngOnInit(): void {
        const subdomains = window.location.host.split('.');
        this.subdomain = subdomains[0].includes('arcavis') ? '' : subdomains[0];
    }
}
