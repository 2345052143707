import { Injectable } from '@angular/core';

import { StoreConfig } from '../../../core/models/store.config';
import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { BenefitModel } from '../../models/benefit.model';
import { BenefitListModel } from '../../models/responses/benefit-list.model';
import { BenefitEditModel } from '../../models/requests/benefit-edit.model';

@Injectable({
    providedIn: 'root'
})
export class BenefitsStore extends BaseCrudStore<BenefitModel, BenefitListModel, BenefitEditModel>  {
    constructor() {
        super(new StoreConfig({ baseController: 'benefits' }));
    }
}
