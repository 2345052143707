<div [arcErrorTooltip]="_formControl"
    class="relative w-full h-full min-w-[500px] grid place-items-center outline-2 outline-dashed outline-on-app-light rounded-[4px] transition-all"
    [class.-outline-offset-8]="isDragging"
    [class.bg-element-selected]="isDragging"
    [class.outline-error]="_formControl?.invalid && _formControl?.touched" [ngClass]="customClasses">
    <!-- actual html input -->
    <input type="file" [accept]="fileTypes" hidden [disabled]="isDisabled" (change)="onFileInputChanged($event)"
        #fileInput id="fileInput" class="absolute inset-0 opacity-10 -z-10">

    <div class="absolute top-2 mx-auto mt-2 flex" *ngIf="!!label">
        {{ isLabelTranslated ? label : (label | transloco) }}
    </div>

    <div class="grid gap-2 place-items-center">
        <arc-icon [icon]="!value ? 'file_upload' : 'description'" [size]="24"></arc-icon>

        <div *ngIf="!value; else valueTpl" class="flex"
            [class.text-error]="_formControl?.invalid && _formControl?.touched">
            <label for="fileInput" class="underline hover:cursor-pointer hover:text-accent">
                {{ 'Components.FileUpload.ChooseFile' | transloco }}
            </label>
            <ng-container *ngIf="allowUrlUpload">
                ,&nbsp;
                <a href="javascript:void(0)" (click)="addFromUrl()" class="underline hover:text-accent">
                    {{ 'Components.FileUpload.FromUrl' | transloco }}
                </a>
            </ng-container>
            <span>&nbsp;{{ 'Components.FileUpload.OrDropHere' | transloco }}</span>
            <span *ngIf="isRequired">*</span>
        </div>

        <ng-template #valueTpl>
            <p>{{ value!.name }}</p>
        </ng-template>


        <div class="flex text-on-app-light">
            <ng-container *ngIf="!value; else newValueTpl">
                <span *ngIf="!!placeholder">
                    {{ isPlaceholderTranslated ? placeholder : (placeholder | transloco) }}
                </span>
            </ng-container>
            <ng-template #newValueTpl>
                <label for="fileInput" class="hover:cursor-pointer hover:text-accent">
                    {{ 'Components.FileUpload.ClickChooseNewFile' | transloco }}
                </label>
            </ng-template>
        </div>
    </div>
</div>
