<h1 mat-dialog-title>{{ 'License.Details.NewTransaction.Title' | transloco }}</h1>

<mat-dialog-content class="grow !max-h-[unset] overflow-y-auto !flex flex-col gap-4 !pt-2">
    <arc-button-toggle-group [control]="formGroup.controls.type" [items]="transactionTypes"></arc-button-toggle-group>
    <arc-datepicker [control]="formGroup.controls.valueDate" labelKey="License.Details.TransactionsWidget.ValueDate"></arc-datepicker>
    <div class="flex gap-2">
        <arc-currency-input [formControl]="formGroup.controls.amount" label="License.Details.TransactionsWidget.Amount">
        </arc-currency-input>
        <arc-number-input [formControl]="formGroup.controls.poolTime" label="License.Details.TransactionsWidget.PoolTime" [decimalPlaces]="2">
        </arc-number-input>
    </div>
    <arc-textarea [control]="formGroup.controls.publicRemark" labelKey="License.Details.TransactionsWidget.PublicRemark"></arc-textarea>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-stroked-button [mat-dialog-close]="{ shouldReload: false }">{{ 'General.Actions.Cancel' | transloco }}</button>
    <arc-button type="flat" color="accent" [isLoading]="isCreating()" (clicked)="createLicenseTransaction()">
        {{ 'General.Actions.Create' | transloco }}
    </arc-button>
</mat-dialog-actions>