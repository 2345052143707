import { Injectable } from '@angular/core';

import { StoreConfig } from '../../../core/models/store.config';
import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { NewsletterModel } from '../../models/newsletter.model';
import { NewsletterListModel } from '../../models/responses/newsletter-list.model';
import { NewsletterEditModel } from '../../models/requests/newsletter-edit.model';
import { NewsletterCreateModel } from '../../models/requests/newsletter-create.model';

@Injectable({
    providedIn: 'root'
})
export class NewsletterStore extends BaseCrudStore<NewsletterModel, NewsletterListModel, NewsletterCreateModel, NewsletterEditModel>  {
    constructor() {
        super(new StoreConfig({ baseController: 'newsletter' }));
    }
}
