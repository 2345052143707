<div class="h-full flex flex-col">
    <div class="absolute smd:hidden bottom-[5px] left-[10px] text-on-app-light text-xs z-[201]" *ngIf="item">
        {{ id }}
    </div>
    <div class="p-8 smd:p-2 grow overflow-y-auto flex flex-col">
        <mat-accordion *ngIf="!isLoading && !hasError; else loading" multi cdkDropList
            (cdkDropListDropped)="drop($event)" class="grow" [@.disabled]="shouldDisableAnimation">
            <ng-container *ngFor="let editComponent of editComponents; let i = index">
                <arc-edit-sidebar-item [editComponent]="editComponent"
                    [shouldStartExpanded]="!!editComponentsExpanded.get(editComponent.titleKey)"
                    [componentPortal]="componentPortals.get(editComponent)"
                    (componentAttached)="handleComponentAttached($event, editComponent)"
                    (expandedChanged)="editComponentsExpanded.set(editComponent.titleKey, $event)">
                </arc-edit-sidebar-item>
            </ng-container>
        </mat-accordion>
    </div>
    <ng-template #loading>
        <div class="grow grid place-items-center" *ngIf="!hasError; else error">
            <mat-spinner [diameter]="48"></mat-spinner>
        </div>
    </ng-template>
    <ng-template #error>
        <div class="grow flex flex-col items-center justify-center text-error">
            <mat-icon>close</mat-icon>
            <span>{{ 'General.Alert.UnexpectedError' | transloco }}</span>
        </div>
    </ng-template>
    <div class="flex items-center gap-4 mt-4 sticky bottom-0 bg-white p-8 smd:p-4 shadow-[rgba(0,0,15,0.5)_1px_-6px_9px_-11px] z-[200]">
        <button mat-stroked-button (click)="cancel()" [disabled]="isSaving">
            {{ 'General.Actions.Cancel' | transloco }}
        </button>
        <div class="grow"></div>
        @if (!!visibleSecondaryAction) {
            <button mat-stroked-button [matTooltip]="getButtonText(visibleSecondaryAction)" matTooltipPosition="left"
                [disabled]="isButtonDisabled(visibleSecondaryAction)" (click)="onContextActionClick($event,visibleSecondaryAction)">
                <div class="flex gap-2">
                    @if (visibleSecondaryAction.isLoading) {
                        <mat-spinner diameter="22"></mat-spinner>
                    } @else {
                        <i class="material-icons">{{ visibleSecondaryAction.icon }}</i>
                    }
                    <span>{{ getButtonText(visibleSecondaryAction) }}</span>
                </div>
            </button>
        }

        <button mat-flat-button color="accent" [matTooltip]="getButtonText(visiblePrimaryAction)" matTooltipPosition="left"
            [disabled]="isButtonDisabled(visiblePrimaryAction)" (click)="onContextActionClick($event, visiblePrimaryAction)">
            <div class="flex gap-2">
                @if (visiblePrimaryAction.isLoading) {
                    <mat-spinner diameter="22"></mat-spinner>
                } @else {
                    <i class="material-icons">{{ visiblePrimaryAction.icon }}</i>
                }
                <span>{{ getButtonText(visiblePrimaryAction) }}</span>
            </div>
        </button>

        @if (collapsedActions.length > 0) {
            <button mat-icon-button [matMenuTriggerFor]="contextActionsMenu">
                <mat-icon>more_horiz</mat-icon>
            </button>
            <mat-menu #contextActionsMenu="matMenu">
                @for (btn of collapsedActions; track btn.key) {
                    <button mat-menu-item [matTooltip]="getButtonText(btn)" matTooltipPosition="left" [disabled]="isButtonDisabled(btn)"
                        (click)="onContextActionClick($event, btn)">
                        <div class="flex gap-2">
                            @if (btn.isLoading) {
                                <mat-spinner diameter="22"></mat-spinner>
                            } @else {
                                <i class="material-icons">{{ btn.icon }}</i>
                            }
                            <span>{{ getButtonText(btn) }}</span>
                        </div>
                    </button>
                }
            </mat-menu>
        }
    </div>
</div>
