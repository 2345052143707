import { Component, OnInit, inject } from '@angular/core';

import { FeatureListModel } from '../../../../../models/responses/feature-list.model';
import { FeatureModel } from '../../../../../models/feature.model';
import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { StaticBasicTableListViewConfigModel } from '../../../../../../components/list-views/static-basic-table-list-view/models/static-table-list-view-config.model';
import { DateColumnModel } from '../../../../../../components/dynamic-table/models/column-types/date-column.model';
import { FeatureVoteListModel } from '../../../../../models/responses/feeature-vote.list.model';
import { FeaturesStore } from '../../../../../services/stores/features.store';
import { IconColumnModel } from '../../../../../../components/dynamic-table/models/column-types/icon-column.model';
import { IconModel } from '../../../../../../core/models/icon.model';
import { StackedColumnModel } from '../../../../../../components/dynamic-table/models/column-types/stacked-column.model';
import { FeedbackVoteTextColumnComponent } from '../../components/feedback-vote-text/feedback-vote-text-column.component';
import { FeedbackVoteTextColumnModel } from '../../../../../../components/dynamic-table/models/column-types/feedback-vote-text-column.model';

@Component({
    selector: 'arc-feature-vote',
    templateUrl: './feature-vote.component.html',
    styleUrl: './feature-vote.component.scss'
})
export class FeatureVoteComponent extends BaseDetailWidget<FeatureListModel, FeatureModel> implements OnInit {
    tableConfig?: StaticBasicTableListViewConfigModel<FeatureVoteListModel>;
    isLoading = true;
    items: FeatureVoteListModel[] = [];
    private readonly featuresStore = inject(FeaturesStore);

    ngOnInit(): void {
        this.setupVotes();
    }
    setupVotes(): void {
        this.isLoading = true;
        this.items = [];
        this.featuresStore.getVotes(this.entityModel.id).subscribe(response => {
            this.items = response?.value || [];
            this.setupTable();
            this.isLoading = false;
        });
    }
    setupTable(): void {
        this.tableConfig = new StaticBasicTableListViewConfigModel<FeatureVoteListModel>({
            data: this.items,
            defaultPageSize: 4,
            availableColumns: {
                createdDate: new DateColumnModel({
                    columnTitleKey: 'Feature.List.CreatedDate',
                    propertyName: 'createdDate',
                    format: 'short',
                    widthPixels: 145
                }),
                createdByEmail: new StackedColumnModel({
                    columnTitleKey: 'Feature.List.UserDisplayName',
                    propertyName: 'createdByEmail',
                    propertyName2: 'licenseHolderName',
                    widthPixels: 215
                }),
                vote: new IconColumnModel({
                    columnTitleKey: 'Feature.List.Vote',
                    propertyName: 'vote',
                    widthPixels: 25,
                    iconMapping: new Map([
                        [null, new IconModel({ name: 'clear' })],
                        [0, new IconModel({ name: 'sentiment_neutral' })],
                        [-1, new IconModel({ name: 'sentiment_dissatisfied' })],
                        [1, new IconModel({ name: 'sentiment_satisfied' })]
                    ])
                }),
                lastFeedbackText: new FeedbackVoteTextColumnModel({
                    customComponent: FeedbackVoteTextColumnComponent,
                    refreshFn: () => this.setupVotes()
                })
            }
        });
    }
}
