<form [formGroup]="form" class="h-full">

    <p class="pt-6 pb-12">
        {{ 'Components.ImportDialog.ImportPrompt' | transloco : { entityName: entityName + '.Title' | transloco } }}
    </p>

    <arc-file-upload
        class="block w-full h-[500px]"
        [formControl]="form.controls.file"
        fileTypes=".xls,.xlsx,.csv,.xml"
        labelKey="Components.ImportDialog.ImportFile">
    </arc-file-upload>

    <div class="pt-12">
        <p class="font-bold">{{ 'Components.ImportDialog.MappingRequired' | transloco }}</p>
        <div class="flex items-center gap-2" *ngFor="let column of requiredColumns">
            <p>{{ column.displayName }}</p>
            <arc-icon *ngIf="!!column.tooltip" icon="info_outline" [size]="12" [matTooltip]="column.tooltip"></arc-icon>
            <p class="text-on-app-light">{{ column.infoText }}</p>
        </div>
    </div>
</form>
