import { Component, EventEmitter, Input, Output, ViewChild, forwardRef, inject } from '@angular/core';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Subject, debounceTime, map, switchMap, tap } from 'rxjs';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { BaseListViewModel } from '../../../core/abstractions/base-list-view.model';
import { SearchRequestModel } from '../../../app/models/requests/search-request.model';
import { OptionalType } from '../../../core/models/types/optional.type';
import { BaseControlValueAccessor } from '../../../core/abstractions/base-control-value-accessor';
import { DataSelectionDialogComponent } from '../../dialogs/data-selection-dialog/data-selection-dialog.component';
import { QuickSearchConfigModel } from './models/quick-search-config.model';

@Component({
    selector: 'arc-quick-search',
    templateUrl: './quick-search.component.html',
    styleUrls: ['./quick-search.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => QuickSearchComponent),
            multi: true
        }
    ]
})
export class QuickSearchComponent<TList extends BaseListViewModel> extends BaseControlValueAccessor<string> {
    @ViewChild(MatAutocomplete) autocomplete!: MatAutocomplete;

    @Input({ required: true }) config!: QuickSearchConfigModel<TList>;

    @Output() readonly optionSelected = new EventEmitter<TList>();

    isLoading = false;
    isInvalidInput = false;
    options: TList[] = [];

    protected readonly _debounceTimeMs = 250;
    protected readonly _searchResultSize = 50;

    private currentSelection?: TList;

    private readonly internalSearchSubject = new Subject<string>();

    private readonly matDialog = inject(MatDialog);

    constructor() {
        super();

        this.internalSearchSubject
            .pipe(
                tap(() => {
                    this.options = [];
                    this.isLoading = true;
                    this.isInvalidInput = true;
                }),
                debounceTime(this._debounceTimeMs),
                switchMap(searchText =>
                    this.config.store
                        .search(new SearchRequestModel({ searchText, pageSize: this._searchResultSize }))
                        .pipe(map(response => response.value?.records ?? []))
                )
            )
            .subscribe(options => {
                this.options = options;
                this.isLoading = false;
            });
    }

    override writeValue(value?: string): void {
        if (!value) {
            super.writeValue(undefined);
            this.select(undefined, false);
            return;
        }

        this.isLoading = true;
        this.config.store.getListModel(value).subscribe({
            next: result => {
                super.writeValue(result.value?.id);
                this.select(result.value, false);
            },
            complete: () => this.isLoading = false
        });
    }

    input(): void {
        if (this.internalControl.value.length > 0) {
            this.internalSearchSubject.next(this.internalControl.value);
        } else {
            this.options = [];
            this.select(undefined);
        }
    }

    onBlur(): void {
        if (this.isInvalidInput) {
            this.select(this.currentSelection);
        }
    }

    onOptionSelected(event: MatAutocompleteSelectedEvent): void {
        const value = event.option.value as TList;
        this.select(value);
        this.options = [value];
    }

    openDataSelectionDialog(event: MouseEvent): void {
        event.stopPropagation();
        if (this.isDisabled) {
            return;
        }

        const dialogRef = this.matDialog.open(DataSelectionDialogComponent, {
            data: this.config.dataSelectionDialogConfig,
            width: '800px',
            maxWidth: '98vw',
            height: '800px',
            maxHeight: '98svh'
        });

        dialogRef.afterClosed().subscribe((result?: TList[]) => {
            if (this.isDisabled) {
                return;
            }

            this.isInvalidInput = false;
            if (!!result && result.length > 0) {
                this.select(result[0]);
            }
            this.markAsTouched();
        });
    }

    private select(option: OptionalType<TList>, shouldMarkAsTouched = true): void {
        this.valueChanged(option?.id, shouldMarkAsTouched);
        this.currentSelection = option;
        this.internalControl.setValue(option);
        this.optionSelected.emit(option);
        this.isInvalidInput = false;
    }
}
