import { BaseColumnModel } from '../../../../dynamic-table/models/column-types/base-column.model';
import { DictionaryType } from '../../../../../core/models/types/dictionary.type';
import { TableListDetailWidgetModel } from '../../models/table-list-detail-widget.model';

export class TableSettingsDialogModel {
    entityName!: string;
    availableColumns!: DictionaryType<BaseColumnModel>;
    currentColumnOrder!: string[];
    defaultColumnOrder!: string[];
    availableDetailWidgets!: DictionaryType<TableListDetailWidgetModel<any, any>>;
    currentDetailWidgetOrder!: string[];
    defaultDetailWidgetOrder!: string[];
    currentTab?: number;

    constructor(init?: Partial<TableSettingsDialogModel>) {
        Object.assign(this, init);
    }
}
