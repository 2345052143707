import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TableDetailWidgetDirective } from './table-detail-widget.directive';
import { CardContentDirective } from './card-content.directive';
import { ErrorTooltipDirective } from './error-tooltip.directive';
import { DynamicColumnDirective } from './dynamic-column.directive';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        TableDetailWidgetDirective,
        CardContentDirective,
        ErrorTooltipDirective,
        DynamicColumnDirective
    ],
    exports: [
        TableDetailWidgetDirective,
        CardContentDirective,
        ErrorTooltipDirective,
        DynamicColumnDirective
    ]
})
export class DirectivesModule { }
