<h1 mat-dialog-title>
    {{ 'Components.TableSettingsDialog.Title' | transloco }}: {{ (data.entityName + '.Title') | transloco }}
</h1>

<mat-dialog-content class="grow !max-h-full !flex flex-col">
    <mat-tab-group #tabGroup color="accent" class="overflow-y-auto grow" [(selectedIndex)]="currentTab">
        <mat-tab [label]="'Components.TableSettingsDialog.TableColumns' | transloco" labelClass="!basis-0">
            <div class="grow mt-4 grid grid-cols-2 gap-4">
                <div class="grow flex flex-col gap-2">
                    <h2>{{ 'Components.TableSettingsDialog.CurrentValues' | transloco }}</h2>
                    <div class="draggable-items-container" cdkDropList #currentColumnsList="cdkDropList"
                        [cdkDropListData]="currentColumns" [cdkDropListConnectedTo]="[availableColumnsList]"
                        (cdkDropListDropped)="drop($event)">
                        <div class="draggable-item" *ngFor="let item of currentColumns" cdkDrag>
                            {{ data.availableColumns[item].columnTitleKey | transloco }}
                        </div>
                    </div>
                </div>
                <div class="grow flex flex-col gap-2">
                    <h2>{{ 'Components.TableSettingsDialog.AvailableValues' | transloco }}</h2>
                    <div class="draggable-items-container" cdkDropList #availableColumnsList="cdkDropList"
                        [cdkDropListData]="availableColumns" [cdkDropListConnectedTo]="[currentColumnsList]"
                        (cdkDropListDropped)="drop($event)">
                        <div class="draggable-item" *ngFor="let item of availableColumns" cdkDrag>
                            {{ data.availableColumns[item].columnTitleKey | transloco }}
                        </div>
                    </div>
                </div>
            </div>
            <button mat-button class="w-full !mt-4" (click)="resetColumnsToDefault()"
                [disabled]="currentColumns.toString() === data.defaultColumnOrder.toString()" color="warn">
                {{ 'General.Actions.RestoreDefaultValues' | transloco }}
            </button>
        </mat-tab>

        <mat-tab [label]="'Components.TableSettingsDialog.DetailWidgets' | transloco" labelClass="!basis-0">
            <div class="grow mt-4 grid grid-cols-2 gap-4 pr-4">
                <div class="grow flex flex-col gap-2">
                    <h2>{{ 'Components.TableSettingsDialog.CurrentValues' | transloco }}</h2>
                    <div class="draggable-items-container" cdkDropList #currentDetailWidgetsList="cdkDropList"
                        [cdkDropListData]="currentDetailWidgets" [cdkDropListConnectedTo]="[availableDetailWidgetsList]"
                        (cdkDropListDropped)="drop($event)">
                        <div class="draggable-item" *ngFor="let item of currentDetailWidgets" cdkDrag>
                            {{ data.availableDetailWidgets[item].name | transloco }}
                        </div>
                    </div>
                </div>
                <div class="grow flex flex-col gap-2">
                    <h2>{{ 'Components.TableSettingsDialog.AvailableValues' | transloco }}</h2>
                    <div class="draggable-items-container" cdkDropList #availableDetailWidgetsList="cdkDropList"
                        [cdkDropListData]="availableDetailWidgets" [cdkDropListConnectedTo]="[currentDetailWidgetsList]"
                        (cdkDropListDropped)="drop($event)">
                        <div class="draggable-item" *ngFor="let item of availableDetailWidgets" cdkDrag>
                            {{ data.availableDetailWidgets[item].name | transloco }}
                        </div>
                    </div>
                </div>
            </div>
            <button mat-button class="w-full !mt-4" (click)="resetDetailWidgetsToDefault()"
                [disabled]="currentDetailWidgets.toString() === data.defaultDetailWidgetOrder.toString()" color="warn">
                {{ 'General.Actions.RestoreDefaultValues' | transloco }}
            </button>
        </mat-tab>
    </mat-tab-group>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close cdkFocusInitial>{{ 'General.Actions.Cancel' | transloco }}</button>
    <button mat-flat-button color="accent" (click)="save()">{{ 'General.Actions.Save' | transloco }}</button>
</mat-dialog-actions>
