import { Component, OnInit, inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs';

import { AuthService } from '../../../../core/services/auth.service';
import { AuthRequestModel } from '../../../models/requests/auth-request.model';
import { EnvironmentService } from '../../../../core/services/environment.service';

@Component({
    selector: 'arc-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    formGroup: FormGroup;
    isCheckingAuth = true;
    showPasswordClearText = false;

    subdomain = '';

    private readonly router = inject(Router);
    private readonly formBuilder = inject(FormBuilder);
    private readonly authService = inject(AuthService);
    private readonly environmentService = inject(EnvironmentService);

    constructor() {
        this.formGroup = this.formBuilder.group({
            email: ['', Validators.required],
            password: ['', Validators.required]
        });
    }

    ngOnInit(): void {
        this.subdomain = this.environmentService.getSubdomain();
        this.authService
            .isLoggedIn()
            .pipe(finalize(() => (this.isCheckingAuth = false)))
            .subscribe(isLoggedIn => {
                this.isCheckingAuth = false;

                if (isLoggedIn) {
                    this.authService.redirectFromLogin();
                }
            });
    }

    login(): void {
        this.formGroup.markAllAsTouched();

        if (this.formGroup.invalid) {
            return;
        }

        this.isCheckingAuth = true;

        this.authService
            .login(
                new AuthRequestModel({
                    ...this.formGroup.value
                })
            )
            .pipe(finalize(() => (this.isCheckingAuth = false)))
            .subscribe();
    }

    redirectToPasswordRecover(): void {
        this.router.navigate(['request-password-reset']);
    }

    redirectToRegister(): void {
        this.router.navigate(['register']);
    }
}
