import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { StoreConfig } from '../../../core/models/store.config';
import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { TransactionModel } from '../../models/transaction.model';
import { TransactionListModel } from '../../models/responses/transaction-list.model';
import { TransactionEditModel } from '../../models/requests/transaction-edit.model';
import { ApiResponseModel } from '../../models/responses/api-response.model';

@Injectable({
    providedIn: 'root'
})
export class TransactionsStore extends BaseCrudStore<TransactionModel, TransactionListModel, TransactionEditModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'transactions' }));
    }

    getTransactionsByLicenseId(licenseId: number): Observable<ApiResponseModel<TransactionListModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<TransactionListModel[]>>(this.getUrl(`license/${licenseId}`));
    }
}
