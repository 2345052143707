<mat-form-field [floatLabel]="hasFloatingLabel ? 'always' : 'auto'" class="w-full" [color]="color"
    [arcErrorTooltip]="control" [subscriptSizing]="hasFixedSubscript ? 'fixed' : 'dynamic'">
    <mat-label>{{ label || (labelKey | transloco) }}</mat-label>
    <mat-select [formControl]="castedControl" (selectionChange)="onChange($event)" (blur)="onBlur()"
        [compareWith]="customComparator" [multiple]="isMultiselect"
        [placeholder]="placeholder || (placeholderKey | transloco)">
        <mat-option *ngFor="let option of options" [value]="option.value">
            {{ areOptionsTranslated ? option.label : (option.label | transloco) }}
        </mat-option>
    </mat-select>
</mat-form-field>