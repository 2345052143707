import { Component, HostBinding, OnInit, ViewContainerRef, inject } from '@angular/core';

import { CustomColumnModel } from '../../models/column-types/custom-column.model';
import { BaseColumnComponent } from '../base-column.component';

@Component({
    selector: 'arc-custom-column',
    templateUrl: './custom-column.component.html',
    styleUrls: ['./custom-column.component.scss']
})
export class CustomColumnComponent<T extends object> extends BaseColumnComponent<T> implements OnInit {
    @HostBinding('style') style = { display: 'none' };

    viewContainerRef = inject(ViewContainerRef);

    override ngOnInit(): void {
        super.ngOnInit();
        const customColumnModel = this.columnModel as CustomColumnModel<T>;
        const comp = this.viewContainerRef.createComponent(customColumnModel.customComponent);
        comp.setInput('item', this.item);
        comp.setInput('columnModel', this.columnModel);
    }
}
