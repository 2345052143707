import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';

import { ColumnTitleAlignment } from '../../../../core/models/enums/column-title-alignment-enum';
import { ColumnTypeEnum } from '../enums/column-type.enum';

export abstract class BaseColumnModel {
    columnTitleKey!: string;
    columnTitleSuffix?: string;
    columnTitleAlignment = ColumnTitleAlignment.Left;
    shouldHideColumnTitle? = false;
    propertyName?: string;
    sortColumn?: string;
    widthPixels!: number;
    allowSort = true;
    isCompact = false;
    customFormatter?: (record: any, value: any, propertyName?: string) => string;
    isBold?: (record: any, value: any, propertyName?: string) => boolean;

    isEditable = false;
    isDisabled = false;

    action?: (control: FormControl)  => Observable<any>;
    actionIcon?: string;

    protected constructor(readonly columnType: ColumnTypeEnum, init?: Partial<BaseColumnModel>) {
        if (!!init) {
            Object.assign(this, init);
        }
    }

    get identifier(): string {
        return this.propertyName ?? this.columnTitleKey;
    }
}
