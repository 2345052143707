import { ColumnTypeEnum } from '../enums/column-type.enum';
import { BaseColumnModel } from './base-column.model';

export class StringColumnModel extends BaseColumnModel {
    suffixPropertyName?: string;

    constructor(init?: Partial<StringColumnModel>) {
        super(ColumnTypeEnum.Text);

        if (!!init) {
            Object.assign(this, init);
        }
    }
}
