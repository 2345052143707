import { Component, Input } from '@angular/core';

@Component({
    selector: 'arc-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss']
})
export class IconComponent {
    @Input() size = 16;
    @Input() icon!: string;
    @Input() fontWeight = 'normal';
    @Input() color = 'icon-light';

    getColor(): string {
        return `var(--${this.color})`;
    }
}
