export enum GeneralDataTypeEnum {
    Countries = 'countries',
    Currencies = 'currencies',
    NewsletterTypes = 'newsletter-types',
    FeatureStatuses = 'feature-statuses',
    FeatureTypes = 'feature-types',
    ProductAreas = 'product-areas',
    ReportTemplates = 'report-templates',
    Tags = 'tags',
    HelpContext = 'help-context',
    HelpTags = 'help-tags',
    Modules = 'modules',
    Products = 'products',
    ModuleBundles = 'module-bundles',
    UserRoles = 'user-roles',
    Resellers = 'resellers',
}
