<div class="flex items-center space-x-2">
    <arc-button mat-icon-button actionButtonKey="LicenseNumberOfPosReduceContextAction" [currentData]="license()" type="icon">
        <mat-icon>remove</mat-icon>
    </arc-button>
    <div (click)="handleChangeProductContextClick()" class="bg-on-app text-app px-4 py-1 rounded-xl" [ngClass]="{'cursor-pointer': canChangeProduct()}">
        {{license().numberOfPos}}x {{license().productTitle}}
    </div>
    <arc-button mat-icon-button actionButtonKey="LicenseNumberOfPosIncreaseContextAction" [currentData]="license()" type="icon">
        <mat-icon>add</mat-icon>
    </arc-button>
</div>