<div class="flex flex-col gap-4">
    <div class="flex justify-between">
        <div class="flex items-center gap-6">
            <p>{{ 'HelpArticle.Edit.Language' | transloco }}</p>
            <arc-button-toggle-group [control]="language" [items]="languageItems" toggleButtonClasses="w-[80px]"></arc-button-toggle-group>
        </div>
        <button mat-icon-button (click)="translate()">
            <mat-icon>translate</mat-icon>
        </button>
    </div>
    <arc-input labelKey="HelpArticle.Edit.Title" [control]="title"></arc-input>
    <arc-input labelKey="HelpArticle.Edit.Summary" [control]="summary"></arc-input>
    <arc-markdown-editor styleClass="mde-help" [formControl]="text"> </arc-markdown-editor>
    <div class="grid grid-cols-2 gap-4">
        <div>
            <arc-select
                labelKey="HelpArticle.Edit.Topic"
                class="col-span-2"
                [control]="formGroup.controls.helpTopicId"
                (change)="handleHelpTopicChange()"
                [options]="helpTopics">
            </arc-select>
        </div>
        <div>
            <arc-select
                labelKey="HelpArticle.Edit.SubTopic"
                class="col-span-2"
                [control]="formGroup.controls.helpSubTopicId"
                [options]="helpSubTopics">
            </arc-select>
        </div>
        <arc-input type="number" labelKey="HelpArticle.Edit.MinDbVersion" [control]="formGroup.controls.minDbVersion"></arc-input>
        <arc-general-data-select
            [formControl]="formGroup.controls.context"
            [generalDataType]="GeneralDataTypeEnum.HelpContext"
            label="HelpArticle.Edit.Context">
        </arc-general-data-select>
    </div>
    <arc-chip-selector labelKey="HelpArticle.Edit.HelpTags" [control]="helpTagsControl"
        [optionDisplayFn]="dropdownOptionDisplay.bind(this)" [searchFn]="tagsSearch.bind(this)"
        [selectedChips]="helpTags">
    </arc-chip-selector>
    <arc-quick-search [config]="ComplexDataTypesEnum.License | arcQuickSearchConfig" [formControl]="formGroup.controls.licenseId"
        label="HelpArticle.Edit.License"></arc-quick-search>
    <arc-checkbox labelKey="HelpArticle.Edit.IsPublic" [control]="formGroup.controls.isPublic"></arc-checkbox>
</div>
