import { Identifyable } from '../../../core/abstractions/identifyable';

export class HelpTooltipEditModel implements Identifyable {
    id!: number;
    key!: string;
    text_de!: string;
    text_en?: string;
    text_fr?: string;
    text_it?: string;

    constructor(init?: Partial<HelpTooltipEditModel>) {
        if (!!init) {
            Object.assign(this, init);
        }
    }
}
