import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { BaseColumnComponent } from '../base-column.component';
import { GeneralDataColumnModel } from '../../models/column-types/general-data-column.model';

@Component({
    selector: 'arc-general-data-column',
    templateUrl: './general-data-column.component.html',
    styleUrls: ['./general-data-column.component.scss']
})
export class GeneralDataColumnComponent<T extends object> extends BaseColumnComponent<T> implements OnInit {
    readonlyControl = new FormControl();

    get castedCellModel(): GeneralDataColumnModel {
        return this.columnModel as GeneralDataColumnModel;
    }

    override ngOnInit(): void {
        super.ngOnInit();
        this.readonlyControl.disable();
        this.readonlyControl.setValue(this.getItemValue(this.columnModel.propertyName));
    }

    onOptionSelected(newValue: any): void {
        if (!!this.castedCellModel.onValueChanged) {
            this.castedCellModel.onValueChanged(this.item, newValue);
        }
    }
}
