import { Component, HostBinding, inject } from '@angular/core';

import { CreateTicketService } from '../../../../../../../core/services/create-ticket.service';
import { CreateTicketStepEnum } from '../../../../../../models/enums/create-ticket-step.enum';
import { BaseSidebarComponent } from '../../../../../../../components/sidebar-components/base-sidebar/base-sidebar.component';
import { SidebarService } from '../../../../../../../core/services/sidebar.service';

@Component({
    selector: 'arc-new-ticket',
    templateUrl: './new-ticket.component.html',
    styleUrl: './new-ticket.component.scss'
})
export class NewTicketComponent extends BaseSidebarComponent {
    @HostBinding('class') classes = 'h-full flex flex-col px-8 pt-8 smd:px-2 smd:pt-2';

    readonly createTicketService = inject(CreateTicketService);
    CreateTicketStepEnum = CreateTicketStepEnum;

    private readonly sidebarService = inject(SidebarService);

    cancel(): void {
        this.createTicketService.reset();
        this.sidebarService.closeRight();
    }
}
