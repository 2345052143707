export enum TicketPrioritiesEnum {
    Low,
    Medium,
    High,
    Immediate
}

export class TicketPrioritiesEnumExtensions {
    static getColor(value: TicketPrioritiesEnum): string {
        switch (value) {
            case TicketPrioritiesEnum.Low:
                return 'bg-element-selected text-on-app';
            case TicketPrioritiesEnum.Medium:
                return 'bg-warn-light text-on-warn-light';
            case TicketPrioritiesEnum.High:
                return 'bg-error-light text-on-error-light';
            case TicketPrioritiesEnum.Immediate:
                return 'bg-error text-on-error';
            default:
                return 'bg-on-app text-app';
        }
    }
}
