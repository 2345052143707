import { Injectable, inject } from '@angular/core';
import { Observable, map, of, tap } from 'rxjs';

import { CacheService } from './cache.service';
import { GeneralDataStore } from '../../app/services/stores/general-data.store';
import { KeyValueModel } from '../models/key-value.model';
import { GeneralDataTypeEnum } from '../models/enums/general-data-type.enum';

@Injectable({
    providedIn: 'root'
})
export class GeneralDataService {
    private readonly cacheService = new CacheService();
    private readonly generalDataStore = inject(GeneralDataStore);

    getGeneralData(type: GeneralDataTypeEnum): Observable<KeyValueModel[]> {
        const value = this.cacheService.get<KeyValueModel[]>(type);
        if (!!value) {
            return of(value);
        }
        return this.generalDataStore.getGeneralData(type).pipe(
            map(data => data.value ?? []),
            tap(data => this.cacheService.set(type, data))
        );
    }

    clearCache(type: GeneralDataTypeEnum): void {
        this.cacheService.clear(type);
    }
}
