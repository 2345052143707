import { BaseEntityModel } from '../../../core/abstractions/base-entity.model';

export class PosReportTemplateEditRequestModel extends BaseEntityModel {
    title!: string;
    title_de!: string;
    title_en!: string;
    title_fr!: string;
    title_it!: string;
    description!: string;
    description_de!: string;
    description_en!: string;
    description_fr!: string;
    description_it!: string;
    key!: string;
    languageIsoCode!: string;
    requiredModuleId?: string;
    version?: number;

    constructor(init?: Partial<PosReportTemplateEditRequestModel>) {
        super();
        if (!!init) {
            Object.assign(this, init);
        }
    }
}
