<div class="grid items-center grid-cols-12 grid-flow-row auto-cols-auto gap-4 relative">
    <arc-autocomplete class="col-span-2 smd:col-span-5" label="General.Address.Zip" [formControl]="zipFormControl"
        [searchFn]="zipSearchFn.bind(this)" valueAccessor="zip"
        [optionDisplayAccessor]="placeOptionDisplayFn.bind(this)" [allowArbitraryValues]="true"
        [shouldSkipDistinctCheck]="true" [shouldPanelWidthFitContent]="true" (inputChanged)="updateValidity()"
        (blurred)="checkIfPlaceExists()" (optionSelected)="onPlaceSelected($event)">
    </arc-autocomplete>

    <arc-autocomplete class="col-span-5 smd:col-span-7" label="General.Address.City" [formControl]="cityFormControl"
        [searchFn]="citySearchFn.bind(this)" valueAccessor="city"
        [optionDisplayAccessor]="placeOptionDisplayFn.bind(this)" [allowArbitraryValues]="true"
        [shouldSkipDistinctCheck]="true" (inputChanged)="updateValidity()" (blurred)="checkIfPlaceExists()"
        (optionSelected)="onPlaceSelected($event)">
    </arc-autocomplete>

    <arc-general-data-select class="col-span-5 smd:col-span-12" label="General.Address.Country"
        [generalDataType]="GeneralDataTypeEnum.Countries" [formControl]="countryIsoCodeFormControl"
        (optionSelected)="onCountryChanged()">
    </arc-general-data-select>

    <div class="absolute left-0 right-0 -bottom-2" *ngIf="isChecking">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>
<div class="w-full mt-1 text-warn" *ngIf="isValid && !isChecking && !isExistingPlace">
    {{ 'Components.PlaceSelect.NewPlaceWillBeCreated' | transloco }}
</div>