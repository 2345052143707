import { Component, HostBinding, ViewChild, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { tap, switchMap } from 'rxjs';

import { DataSelectionDialogDataModel } from './models/data-selection-dialog-data.model';
import { ComplexDataTypesEnum } from '../../../core/models/complex-data-types.enum';
import { BaseListViewModel } from '../../../core/abstractions/base-list-view.model';
import { BaseSearchStore } from '../../../core/abstractions/base-search.store';
import { SearchRequestService } from '../../../core/services/search-request.service';
import { SearchRequestModel } from '../../../app/models/requests/search-request.model';
import { BaseComponent } from '../../abstractions/base.component';
import { FilterService } from '../../../core/services/filter.service';
import { SelectableEntry } from './models/selectable-entry.model';
import { ColumnSortModel } from '../../../core/models/column-sort.model';
import { SortDirectionEnum } from '../../../core/models/enums/sort-direction.enum';
import { ColumnTypeEnum } from '../../dynamic-table/models/enums/column-type.enum';
import { BaseColumnModel } from '../../dynamic-table/models/column-types/base-column.model';
import { DataSelectionDialogCustomDataModel } from './models/data-selection-dialog-custom-data.model';
import { LicensesStore } from '../../../app/services/stores/licenses.store';
import { licenseSelectionTableConfig } from './data-selection-table-configs/license-selection-table.config';
import { userSelectionTableConfig } from './data-selection-table-configs/user-selection-table.config';
import { UsersStore } from '../../../app/services/stores/users.store';
import { ResellersStore } from '../../../app/services/stores/resellers.store';

@Component({
    selector: 'arc-data-selection-dialog',
    templateUrl: './data-selection-dialog.component.html',
    styleUrls: ['./data-selection-dialog.component.scss'],
    providers: [SearchRequestService, FilterService] // data selection dialog needs its own instances of these services
})
export class DataSelectionDialogComponent extends BaseComponent {
    @HostBinding('class') classes = 'grow flex flex-col overflow-y-auto';

    @ViewChild(MatPaginator) paginator!: MatPaginator;

    ComplexDataTypesEnum = ComplexDataTypesEnum;
    ColumnTypeEnum = ColumnTypeEnum;

    data: SelectableEntry<BaseListViewModel>[] = [];
    totalRecords = 0;
    selection: BaseListViewModel[] = [];
    displayedColumns: string[] = [];

    pageSize = 15;
    pageSizeOptions = [10, 15, 20, 25];

    isLoading = true;

    dialogTitleKey: string;
    store: BaseSearchStore<BaseListViewModel>;
    columnConfig: BaseColumnModel[];

    protected readonly _dialogData: DataSelectionDialogDataModel | DataSelectionDialogCustomDataModel = inject(MAT_DIALOG_DATA);
    private readonly matDialogRef = inject(MatDialogRef);
    private readonly searchRequestService = inject(SearchRequestService);
    private readonly licensesStore = inject(LicensesStore);
    private readonly usersStore = inject(UsersStore);
    private readonly resellersStore = inject(ResellersStore);

    constructor() {
        super();

        if (this._dialogData instanceof DataSelectionDialogCustomDataModel) {
            this.store = this._dialogData.store;
            this.columnConfig = this._dialogData.columnConfig;
            this.dialogTitleKey = this._dialogData.dialogTitleKey;
        } else {
            // set store from type
            switch (this._dialogData.type) {
                case ComplexDataTypesEnum.License:
                    this.store = this.licensesStore;
                    break;
                case ComplexDataTypesEnum.User:
                    this.store = this.usersStore;
                    break;
                case ComplexDataTypesEnum.Reseller:
                    this.store = this.resellersStore;
                    break;
            }

            // set column config
            if (!!this._dialogData.customColumnConfig) {
                this.columnConfig = this._dialogData.customColumnConfig;
            } else {
                switch (this._dialogData.type) {
                    case ComplexDataTypesEnum.License:
                        this.columnConfig = licenseSelectionTableConfig;
                        break;
                    case ComplexDataTypesEnum.User:
                        this.columnConfig = userSelectionTableConfig;
                        break;
                    case ComplexDataTypesEnum.Reseller:
                        this.columnConfig = userSelectionTableConfig;
                        break;
                }
            }

            // set dialog title
            this.dialogTitleKey = {
                [ComplexDataTypesEnum.License]: 'License.EntityName',
                [ComplexDataTypesEnum.User]: 'User.EntityName',
                [ComplexDataTypesEnum.Reseller]: 'General.Reseller'
            }[this._dialogData.type];
        }

        this.displayedColumns = this.columnConfig?.map(c => c.propertyName!) ?? [];
        if (this._dialogData.isMultiSelect) {
            this.displayedColumns = ['selection', ...this.displayedColumns];
        }

        this.searchRequestService.init(new SearchRequestModel({ pageSize: this.pageSize }));
        const loadingStartSub = this.searchRequestService.loadingStart$.subscribe(() => (this.isLoading = true));
        const searchRequestChangedSub = this.searchRequestService.searchRequestChanged$
            .pipe(
                tap(() => (this.isLoading = true)),
                switchMap(searchRequest => this.store.search(searchRequest))
            )
            .subscribe(result => {
                if (this.totalRecords !== result.value?.totalRecords) {
                    this.paginator.firstPage();
                }
                this.isLoading = false;
                const records = result.value?.records ?? [];
                this.data = records.map(e => ({ isSelected: this.isIdSelected(e.id), value: e }));
                this.totalRecords = result.value?.totalRecords ?? 0;
            });

        this.addSubscriptions(loadingStartSub, searchRequestChangedSub);

        this.searchRequestService.forceReload();
    }

    select(entry: SelectableEntry<BaseListViewModel>): void {
        if (this._dialogData.isMultiSelect) {
            return;
        }

        this.handleSelectionChanged(entry);
    }

    handleSelectionChanged(entry: SelectableEntry<BaseListViewModel>): void {
        if (!this._dialogData.isMultiSelect) {
            this.matDialogRef.close([entry.value]);
        }

        const currentSelectionIndex = this.selection.findIndex(e => e.id === entry.value.id);
        if (currentSelectionIndex !== -1) {
            this.selection.splice(currentSelectionIndex, 1);
        } else {
            this.selection.push(entry.value);
        }
    }

    handleSort(sort: Sort): void {
        this.searchRequestService.setSortings([
            new ColumnSortModel({
                column: sort.active,
                direction: sort.direction === 'desc' ? SortDirectionEnum.Desc : SortDirectionEnum.Asc
            })
        ]);
    }

    handlePageEvent(event: PageEvent): void {
        this.searchRequestService.setPaginatorOptions(event.pageIndex, event.pageSize);
    }

    private isIdSelected(id: string): boolean {
        return this.selection.some(e => e.id === id);
    }
}
