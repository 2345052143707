<form [formGroup]="formGroup" class="flex flex-col gap-4">
    <arc-general-data-select
        [generalDataType]="GeneralDataTypeEnum.Products"
        [formControl]="formGroup.controls.productId"
        label="HardwareBundle.Edit.BaseData.Product">
    </arc-general-data-select>
    <div class="flex gap-2">
        <arc-translation-input
            labelKey="HardwareBundle.Edit.BaseData.Title"
            translationDialogTitleKey="General.Actions.TranslateTitle"
            [controlDe]="formGroup.controls.title_de"
            [controlEn]="formGroup.controls.title_en"
            [controlFr]="formGroup.controls.title_fr"
            [controlIt]="formGroup.controls.title_it">
        </arc-translation-input>
    </div>
    <div class="flex gap-2 items-center">
        <arc-number-input label="HardwareBundle.Edit.BaseData.MinQuantity" [formControl]="formGroup.controls.minQuantity" [decimalPlaces]="0">
        </arc-number-input>
        <arc-number-input label="HardwareBundle.Edit.BaseData.MaxQuantity" [formControl]="formGroup.controls.maxQuantity" [decimalPlaces]="0">
        </arc-number-input>
        <arc-checkbox labelKey="HardwareBundle.Edit.BaseData.UsePosQuantity" [control]="formGroup.controls.usePosQuantity"></arc-checkbox>
    </div>
    <arc-currency-input label="HardwareBundle.Edit.BaseData.Price" [formControl]="formGroup.controls.price"></arc-currency-input>
    <arc-checkbox labelKey="HardwareBundle.Edit.BaseData.NoBenefits" [control]="formGroup.controls.noBenefit"></arc-checkbox>
</form>
