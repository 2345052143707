<h1 mat-dialog-title>
    {{ 'Components.FileUpload.AddImageFromUrl' | transloco }}
</h1>
<mat-dialog-content class="grow !max-h-[unset] overflow-y-auto">
    <div class="!my-2">
        <arc-input labelKey="General.URL" [control]="urlControl"></arc-input>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-stroked-button [mat-dialog-close]="false">{{ 'General.Actions.Cancel' | transloco }}</button>
    <arc-button type="flat" color="accent" [isLoading]="isSaving" (clicked)="save()">
        {{ 'General.Actions.Add' | transloco }}
    </arc-button>
</mat-dialog-actions>
