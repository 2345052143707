import { Directive } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { BrokenRuleModel } from '../../../../app/models/broken-rule.model';
import { DictionaryType } from '../../../../core/models/types/dictionary.type';
import { Identifyable } from '../../../../core/abstractions/identifyable';

@Directive()
export abstract class BaseEditSidebarItemComponent<T extends Identifyable<TId>, TUpdate extends Identifyable<TId> = T, TId = number> {
    item!: T;
    formGroup?: FormGroup;
    isCreate!: boolean;
    brokenRules: DictionaryType<string> = {};
    skipPropertiesOnUnsavedChangesCheck: string[] = [];

    updateAndCheckValidity(): boolean {
        this.formGroup?.markAllAsTouched();
        this.formGroup?.updateValueAndValidity();

        return this.formGroup?.valid ?? true;
    }

    setBusinessErrors(brokenRules: BrokenRuleModel[]): void {
        const newBrokenRulesObj: DictionaryType<string> = {};
        if (!this.formGroup) {
            return;
        }

        for (const brokenRule of brokenRules) {
            if (!brokenRule.property) {
                newBrokenRulesObj['GLOBAL'] = brokenRule.message;
                continue;
            }

            const controlName = brokenRule.property.charAt(0).toLowerCase() + brokenRule.property.slice(1);
            newBrokenRulesObj[controlName] = brokenRule.message;
            const control = this.formGroup.get(controlName);

            if (!control) {
                continue;
            }

            control.setErrors({ businessError: brokenRule.message });
        }

        this.brokenRules = newBrokenRulesObj;
    }

    shouldReloadOnCancel(): boolean {
        return false;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    shouldSave(itemCopy: T): Promise<boolean> {
        return Promise.resolve(true);
    }

    abstract onItemSet(): void;
    abstract prepareSaveModel(itemCopy: TUpdate): TUpdate;
}
