import { Component, inject } from '@angular/core';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { FeatureListModel } from '../../../models/responses/feature-list.model';
import { FeatureModel } from '../../../models/feature.model';
import { TableListViewConfigModel } from '../../../../components/list-views/table-list-view/models/table-list-view-config.model';
import { StackedColumnModel } from '../../../../components/dynamic-table/models/column-types/stacked-column.model';
import { DateColumnModel } from '../../../../components/dynamic-table/models/column-types/date-column.model';
import { StringColumnModel } from '../../../../components/dynamic-table/models/column-types/string-column.model';
import { CustomColumnModel } from '../../../../components/dynamic-table/models/column-types/custom-column.model';
import { FeatureStatusComponent } from './components/feature-status/feature-status.component';
import { IconColumnModel } from '../../../../components/dynamic-table/models/column-types/icon-column.model';
import { IconModel } from '../../../../core/models/icon.model';
import { ColumnSortModel } from '../../../../core/models/column-sort.model';
import { SortDirectionEnum } from '../../../../core/models/enums/sort-direction.enum';
import { FeaturesStore } from '../../../services/stores/features.store';
import { FeatureTypeEnum } from '../../../models/enums/feature-type.enum';
import { ProductAreasEnum } from '../../../models/enums/product-areas.enum';
import { TableListDetailWidgetModel } from '../../../../components/list-views/table-list-view/models/table-list-detail-widget.model';
import { FeatureVoteComponent } from './features-widgets/feature-vote/feature-vote.component';
import { FeatureDescriptionComponent } from './features-widgets/feature-description/feature-description.component';
import { NumberColumnModel } from '../../../../components/dynamic-table/models/column-types/number-column.model';
import { FeaturesEditComponent } from './features-edit-items/features-edit/features-edit.component';
import { FeatureEditModel } from '../../../models/requests/feature-edit.model';

@Component({
    selector: 'arc-features',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrl: './features.component.scss'
})
export class FeaturesComponent extends PageListViewComponent<FeatureListModel, FeatureModel, FeatureEditModel> {
    private readonly featureStore = inject(FeaturesStore);
    constructor() {
        super('Feature');
        this.config = new TableListViewConfigModel<FeatureListModel, FeatureModel, FeatureEditModel>({
            entityName: 'Feature',
            availableColumns: {
                title: new StackedColumnModel({
                    columnTitleKey: 'Feature.List.Title',
                    propertyName: 'title',
                    propertyName2: 'id',
                    sortColumn: 'id',
                    widthPixels: 350
                }),
                createdDate: new DateColumnModel({
                    columnTitleKey: 'Feature.List.CreatedDate',
                    propertyName: 'createdDate',
                    widthPixels: 150
                }),
                createdUserId: new StringColumnModel({
                    columnTitleKey: 'Feature.List.UserDisplayName',
                    propertyName: 'createdUserId',
                    customFormatter: (record) => record['userDisplayName'],
                    widthPixels: 100
                }),
                isFeatured: new IconColumnModel({
                    columnTitleKey: 'Feature.List.IsFeatured',
                    shouldHideColumnTitle: true,
                    propertyName: 'isFeatured',
                    iconMapping: new Map([
                        [true, new IconModel({ name: 'rocket_launch', tooltip: 'Feature.List.IsFeatured', color: 'accent' })],
                        [false, new IconModel()]
                    ]),
                    widthPixels: 60
                }),
                type: new StringColumnModel({
                    columnTitleKey: 'Feature.List.Type',
                    propertyName: 'type',
                    customFormatter: (record, value) => FeatureTypeEnum[value],
                    widthPixels: 100
                }),
                area: new StringColumnModel({
                    columnTitleKey: 'Feature.List.Area',
                    propertyName: 'area',
                    customFormatter: (record, value) => ProductAreasEnum[value],
                    widthPixels: 150
                }),
                votes: new StringColumnModel({
                    columnTitleKey: 'Feature.List.Votes',
                    propertyName: 'totalVotes',
                    widthPixels: 150
                }),
                acceptancePercentage: new NumberColumnModel({
                    columnTitleKey: 'Feature.List.AcceptancePercentage',
                    propertyName: 'acceptancePercentage',
                    decimalPlaces: 2,
                    widthPixels: 120
                }),
                status: new CustomColumnModel({
                    propertyName: 'status',
                    shouldHideColumnTitle: true,
                    customComponent: FeatureStatusComponent,
                    widthPixels: 160
                })
            },
            defaultColumnOrder: [
                'title',
                'createdDate',
                'createdUserId',
                'isFeatured',
                'type',
                'area',
                'votes',
                'acceptancePercentage',
                'status'
            ],
            defaultSort: new ColumnSortModel({
                column: 'acceptancePercentage',
                direction: SortDirectionEnum.Desc
            }),
            availableDetailWidgets: {
                votes: new TableListDetailWidgetModel({
                    name: 'Feature.Detail.VotesWidget',
                    component: FeatureVoteComponent,
                    shouldHideHeader: true,
                    takeFullSizeIfOnly: true
                }),
                description: new TableListDetailWidgetModel({
                    name: 'Feature.Detail.DescriptionWidget',
                    component: FeatureDescriptionComponent,
                    shouldHideHeader: true,
                    takeFullSizeIfOnly: true
                })
            },
            defaultDetailWidgetOrder: ['votes', 'description'],
            editSidebarConfig: {
                editComponents: [
                    {
                        titleKey: 'Feature.Edit.Name',
                        component: FeaturesEditComponent
                    }
                ]
            },

            store: this.featureStore
        });
    }
}
