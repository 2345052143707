import { tap, switchMap, of } from 'rxjs';
import { FlatTreeControl } from '@angular/cdk/tree';
import { ElementRef, inject } from '@angular/core';

import { TreeItemFlatNode } from '../models/tree-item.flat-node';
import { BaseComponent } from '../../components/abstractions/base.component';
import { DynamicTreeDataSource } from '../../components/dialogs/tree-data-selection-dialog/utils/dynamic-tree.data-source';
import { SearchRequestService } from '../services/search-request.service';
import { DynamicTreeDatabase } from '../../components/dialogs/tree-data-selection-dialog/utils/dynamic-tree.database';
import { TreeConfigSetupService } from '../../components/dialogs/tree-data-selection-dialog/utils/tree-config-setup.service';
import { TreeDataSelectionConfig } from '../../components/form/tree-autocomplete/models/tree-data-selection.config';
import { ColumnSortModel } from '../models/column-sort.model';
import { SortDirectionEnum } from '../models/enums/sort-direction.enum';
import { Identifyable } from './identifyable';

export abstract class BaseTreeListComponent<T extends Identifyable<TId>, TId = number> extends BaseComponent {
    isLoading = true;
    readonly dataSource: DynamicTreeDataSource<T, TId>;
    get treeControl(): FlatTreeControl<TreeItemFlatNode<TId>> {
        return this.treeConfigSetupService.treeControl;
    }

    protected readonly _searchRequestService = inject(SearchRequestService);

    private previousNodeInEditMode?: TreeItemFlatNode<TId>;
    private readonly treeConfigSetupService = inject(TreeConfigSetupService<T, TId>);
    private readonly treeDatabase = inject(DynamicTreeDatabase<T, TId>);
    private readonly treeConfig = inject(TreeDataSelectionConfig);

    protected abstract _newRecordInput?: ElementRef;

    protected constructor() {
        super();

        this.dataSource = new DynamicTreeDataSource<T, TId>(
            this.treeControl,
            this.treeConfigSetupService.treeConfig,
            this.treeDatabase
        );
        const loadingStartSub = this._searchRequestService.loadingStart$.subscribe(() => this.isLoading = true);
        const searchRequestChangedSub = this._searchRequestService.searchRequestChanged$
            .pipe(
                tap(() => this.isLoading = true),
                switchMap(searchRequest => {
                    searchRequest.sortings = [new ColumnSortModel({ column: 'Title', direction: SortDirectionEnum.Asc })];

                    if (!searchRequest.searchText) {
                        this.loadRootItems();
                        return of(undefined);
                    }

                    return this.treeDatabase.search(searchRequest);
                })
            )
            .subscribe((result) => {
                if (!!result) {
                    this.completeNodesLoad(result, true);
                }
            });

        this.addSubscriptions(loadingStartSub, searchRequestChangedSub);
    }

    hasChild = (index: number, nodeData: TreeItemFlatNode<TId>): boolean => nodeData.isExpandable;

    isNewButton = (index: number, nodeData: TreeItemFlatNode<TId>): boolean => !!nodeData.isNewRecordButton;

    getAddNewItemKeyForLevel(node: TreeItemFlatNode<TId>): string {
        switch (node.level) {
            case 0:
                return this.treeConfig.addItemAtLevelZeroTextKey;
            case 1:
                return this.treeConfig.addItemAtLevelOneTextKey;
            default:
                return this.treeConfig.addItemAtLevelTwoTextKey;
        }
    }

    toggleEditMode(node: TreeItemFlatNode<TId>): void {
        if (!node.isInEditMode && !!this.previousNodeInEditMode) {
            this.previousNodeInEditMode.isInEditMode = false;
        }

        this.previousNodeInEditMode = node;
        node.isInEditMode = !node.isInEditMode;

        if (node.isInEditMode) {
            setTimeout(() => this._newRecordInput!.nativeElement.focus(), 0);
        }
    }

    saveNode(node: TreeItemFlatNode<TId>): void {
        if (!!this.treeConfig.saveNewRecordHandler) {
            node.isSaving = true;
            const text = this._newRecordInput!.nativeElement.value;

            this.treeConfig.saveNewRecordHandler(node, text).subscribe(resp => {
                this.dataSource.addNewItem(
                    {
                        id: resp.value,
                        text: text,
                        level: node.level,
                        isExpandable: node.level === 0
                    },
                    node
                );
            });
        }
    }

    private completeNodesLoad(nodes: TreeItemFlatNode<TId>[], isFromSearch = false): void {
        this.dataSource.data = nodes;
        this.dataSource.isFromSearch = isFromSearch;
        this.isLoading = false;
    }

    private loadRootItems(): void {
        this.treeDatabase.getRootItems().subscribe(nodes => this.completeNodesLoad(nodes));
    }
}
