<div class="flex flex-col items-start p-2 gap-2">
    <div class="flex gap-6">
        <div>
            <p class="text-sm">{{ license().id }}</p>
            <h2 class="text-lg font-bold">{{ license().licenseHolder }}</h2>
        </div>
        <div>
            <p class="text-sm">{{ license().numberOfPos }}x</p>
            <h2 class="text-lg font-bold">{{ license().productTitle }}</h2>
            <div class="flex gap-2 mt-2">
                @for (module of licenseModules(); track $index) {
                <div class="bg-element-selected px-4 py-1 rounded-2xl">
                    {{ module }}
                </div>
                }
            </div>
        </div>
    </div>
    <div class="flex gap-4">
        <div>
            <p class="text-sm">{{ 'License.Details.Start' | transloco}}</p>
            <h2 class="text-lg font-bold">{{ minValidFrom() | arcDate }}</h2>
        </div>
        <div>
            <p class="text-sm">{{ 'License.Details.BilledTo' | transloco}}</p>
            <h2 class="text-lg font-bold">{{ license().billedUntilDate | arcDate }}</h2>
        </div>
        @if(license().cancelledDate) {
            <div>
                <p class="text-sm">{{ 'License.Details.Cancelled' | transloco}}</p>
                <h2 class="text-lg font-bold">{{ license().cancelledDate| arcDate }}</h2>
            </div>
        } @else {
            <div>
                <p class="text-sm">{{ 'License.Details.ValidTo' | transloco}}</p>
                @if(maxValidThru() && maxValidThru()! < license().billedUntilDate!) { 
                    <h2 class="text-lg font-bold">{{ maxValidThru() | arcDate }}</h2>
                } @else if(license().billedUntilDate) {
                    <mat-icon>check_box</mat-icon>
                }
            </div>
        }
    </div>
    <div class="flex gap-4">
        <div>
            <p class="text-sm">{{ 'License.Details.FirstName' | transloco}}</p>
            <h2 class="text-lg font-bold">{{ license().user.firstName }}</h2>
        </div>
        <div>
            <p class="text-sm">{{ 'License.Details.LastName' | transloco}}</p>
            <h2 class="text-lg font-bold">{{ license().user.lastName }}</h2>
        </div>
    </div>
    <div class="flex gap-4">
        <div>
            <p class="text-sm">{{ 'License.Details.Phone' | transloco}}</p>
            @if(license().user.phoneNumber) {
                <a href="tel:{{ license().user.phoneNumber }}" class="text-lg font-bold">{{ license().user.phoneNumber }}</a>
            }
        </div>
        <div>
            <p class="text-sm">{{ 'License.Details.Email' | transloco}}</p>
            @if(license().user.email) {
                <a href="mailto:{{ license().user.email }}" class="text-lg font-bold">{{ license().user.email }}</a>
            }
        </div>
    </div>
    <div>
        <p class="text-sm">{{ 'License.Details.Reseller' | transloco}}</p>
        @if(license().reseller) {
            <h2 class="text-lg font-bold">{{ license().reseller }}</h2>
        }
    </div>
</div>
