import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { StoreConfig } from '../../../core/models/store.config';
import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { ApiResponseModel } from '../../models/responses/api-response.model';
import { HardwareBundleModel } from '../../models/hardware-bundle.model';
import { HardwareBundleListModel } from '../../models/responses/hardware-bundle-list.model';
import { HardwareBundleEditModel } from '../../models/requests/hardware-bundle-edit.model';

@Injectable({
    providedIn: 'root'
})
export class HardwareBundlesStore extends BaseCrudStore<HardwareBundleModel, HardwareBundleListModel, HardwareBundleEditModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'hardware-bundles' }));
    }

    getHardwareBundlesByProductId(productId: number): Observable<ApiResponseModel<HardwareBundleModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<HardwareBundleModel[]>>(this.getUrl(`product/${productId}`));
    }
}
