import { BaseEntityModel } from '../../../core/abstractions/base-entity.model';
import { EmailMessage } from '../email-message.model';

export class NewsletterEditModel extends BaseEntityModel {
    title!: string;
    messageType!: string;
    emailMessageDe?: EmailMessage;
    emailMessageEn!: EmailMessage;
    emailMessageFr?: EmailMessage;
    emailMessageIt?: EmailMessage;

    constructor(init?: Partial<NewsletterEditModel>) {
        super();

        if (!!init) {
            Object.assign(this, init);
        }
    }
}
