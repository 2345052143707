import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { SettingsStorage } from '../storages/settings.storage';
import { AuthService } from '../auth.service';
import { SecurityStorage } from '../storages/security.storage';

@Injectable({
    providedIn: 'root'
})
export class OnlyLoggedUsersGuard  {
    private readonly settingsStorage = inject(SettingsStorage);
    private readonly securityStorage = inject(SecurityStorage);
    private readonly authService = inject(AuthService);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (!!this.securityStorage.getUserInfo()) {
            return true;
        } else {
            this.settingsStorage.saveNotLoggedRedirectUrl(window.location.href);
            this.authService.goToLoginPage();

            return false;
        }
    }
}
