import { Component, HostBinding, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import { BaseControlComponent } from '../base-control/base-control.component';

@Component({
    selector: 'arc-rich-text-editor-form',
    templateUrl: './rich-text-editor-form.component.html',
    styleUrls: ['./rich-text-editor-form.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RichTextEditorFormComponent),
            multi: true
        }
    ]
})
export class RichTextEditorFormComponent extends BaseControlComponent implements ControlValueAccessor {
    @HostBinding('class') classes = 'grow';

    @Input() minLength?: number;
    @Input() maxLength?: number;

    private currentValue = '';

    get isInvalid(): boolean {
        return !!this.castedControl?.errors && this.castedControl?.touched;
    }

    override onChange(event: any): void {
        super.onChange(event);
        this.control.setValue(event);
    }

    onTouched: () => void = () => {
    };

    registerOnChange(fn: (value?: File) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    writeValue(text: string): void {
        this.currentValue = text;
    }
}
