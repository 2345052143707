/**
 * Represents an URL parameter.
 */
export class KeyValueModel<TKey = string, TValue = string> {
    /**
     * Key.
     */
    key?: TKey;

    /**
     * Value.
     */
    value?: TValue;

    constructor(init: KeyValueModel) {
        Object.assign(this, init);
    }
}
