import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseStore } from '../../../core/abstractions/base.store';
import { StoreConfig } from '../../../core/models/store.config';
import { ResponseTypeEnum } from '../../../core/models/enums/response-type.enum';

@Injectable({
    providedIn: 'root'
})
export class BlobsStore extends BaseStore {
    constructor() {
        super(new StoreConfig({ baseController: 'blobs' }));
    }

    /**
     *  Retrieves the contents of a file by its name.
     */
    downloadBlob(blobId: number): Observable<any> {
        return this._requestService.makeGet(
            this.getUrl(blobId.toString()),
            undefined,
            undefined,
            undefined,
            undefined,
            ResponseTypeEnum.Blob
        );
    }
}
