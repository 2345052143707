import { Observable } from 'rxjs';

import { BaseSearchStore } from '../../../../core/abstractions/base-search.store';
import { ApiResponseModel } from '../../../../app/models/responses/api-response.model';
import { Identifyable } from '../../../../core/abstractions/identifyable';
import { OptionalType } from '../../../../core/models/types/optional.type';
import { PermissionsEnum } from '../../../../app/models/enums/permissions.enum';

export class TreeDataSelectionConfig<TModel extends Identifyable<TId>, TId = number> {
    getNodeTitle!: (item: TModel) => string;
    search!: (searchText: string) => Observable<TModel[]>;

    loadRootItems!: () => Observable<TModel[]>;
    loadChildren!: (parentId: TId) => Observable<TModel[]>;

    getNodeParent!: (node: TModel) => OptionalType<TModel>;
    hasNodeChildren!: (node: TModel) => boolean;

    store!: BaseSearchStore<TModel, TId>;

    /**
     * If true, allows the user to create a new record inline.
     * Default: true.
     */
    allowsInlineCreation = true;

    /**
     * Define which level(s) are selectable.
     * 0 is the root level.
     * Default: [2].
     */
    allowSelectionAtLevels: number[] = [2];

    /**
     * The deepest possible level for this tree.
     */
    deepestLevel = 2;

    /**
     * If true, allows the user to edit the records.
     */
    allowGroupEditing = true;

    /**
     * The title key of the search & selection dialog.
     */
    selectionDialogTitleKey!: string;

    /**
     * The required permission for using this control.
     */
    requiredPermission?: PermissionsEnum;

    /**
     * Whether the user is allowed to select the empty option - no option selected.
     */
    allowEmptySelection = false;
    addItemAtLevelZeroTextKey = 'General.Actions.Create';
    addItemAtLevelOneTextKey = 'General.Actions.Create';
    addItemAtLevelTwoTextKey = 'General.Actions.Create';
    saveNewRecordHandler?: (node: any, text: string) => Observable<ApiResponseModel<any>>;

    constructor(init: Partial<TreeDataSelectionConfig<TModel, TId>>) {
        if (!!init) {
            Object.assign(this, init);
        }
    }

    getById(id: TId): Observable<ApiResponseModel<TModel>> {
        return this.store.getListModel(id);
    }
}
