import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { StoreConfig } from '../../../core/models/store.config';
import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { ContractModel } from '../../models/contract.model';
import { ContractListModel } from '../../models/responses/contract-list.model';
import { ContractEditModel } from '../../models/requests/contract-edit.model';
import { ApiResponseModel } from '../../models/responses/api-response.model';
import { ContractViewModel } from '../../models/responses/contract-view.model';

@Injectable({
    providedIn: 'root'
})
export class ContractsStore extends BaseCrudStore<ContractModel, ContractListModel, ContractEditModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'contracts' }));
    }

    getContractByKey(key: string): Observable<ApiResponseModel<ContractViewModel>> {
        return this._requestService.makeGet<ApiResponseModel<ContractViewModel>>(this.getUrl(`view/${key}`));
    }

    getContracts(code: string, email: string): Observable<ApiResponseModel<ContractModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<ContractModel[]>>(this.getUrl(`${code}/${email}`));
    }

    signContracts(code: string, email: string): Observable<ApiResponseModel<boolean>> {
        return this._requestService.makePost<ApiResponseModel<boolean>>(this.getUrl(`${code}/${email}/sign`));
    }
}
