import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../auth.service';

@Injectable({
    providedIn: 'root'
})
export class HasRoleGuard {
    private readonly authService = inject(AuthService);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        const requiredRole = route.data['requiredRole'];

        if (this.authService.hasRole(requiredRole)) {
            return true;
        } else {
            this.authService.goToLoginPage();

            return false;
        }
    }
}
