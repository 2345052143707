import { ThemePalette } from '@angular/material/core';

import { CreateTicketStepEnum } from '../../app/models/enums/create-ticket-step.enum';
import { ButtonType } from '../../components/button/models/button.type';

export class TicketActionButtonModel {
    id = '';
    color: ThemePalette = 'accent';
    type: ButtonType = 'flat';
    text = '';
    step = CreateTicketStepEnum.None;

    constructor(init: Partial<TicketActionButtonModel>) {
        Object.assign(this, init);
    }
    clickFn: () => void = () => {};
}
