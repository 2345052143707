import { Component, Input } from '@angular/core';

import { BaseControlComponent } from '../base-control/base-control.component';

@Component({
    selector: 'arc-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent extends BaseControlComponent {
    @Input() isRequired = false;
    @Input() isIndeterminate = false;
    @Input() showLabelInFront = false;
}
