export enum ColumnTypeEnum {
    Text,
    Stacked,
    Icon,
    Checkbox,
    Number,
    Currency,
    ColoredStack,
    Custom,
    Button,
    ButtonToggle,
    GeneralData,
    Date,
    GenericStatus
}
