@if(!ticket || !license) {
    <div class="w-full h-full grid place-items-center">
        <mat-spinner [diameter]="32"></mat-spinner>
    </div>
} @else {
    <div class="flex flex-col gap-4">
        <div>
            <div class="text-base">{{ 'Tickets.Edit.CreatedBy' | transloco }}</div>
            <div class="text-lg">{{ ticket.company }}</div>
        </div>
        <div>
            <div class="text-base">{{ 'Tickets.Edit.Title' | transloco }}</div>
            <div class="text-lg">{{ ticket.title }}</div>
        </div>
        <div class="flex flex-row">
            <div class="w-1/2">
                <div class="text-base">{{ 'Tickets.Edit.Priority' | transloco }}</div>
                <div class="text-lg">{{ ticket.priorityDescription }}</div>
            </div>
            <div>
                <div class="text-base">{{ 'Tickets.Edit.Status' | transloco }}</div>
                <div class="text-lg">{{ ticket.statusDescription }}</div>
            </div>
        </div>
        @if(!!ticket.assignedToUser) {
            <div>
                <div class="text-base">{{ 'Tickets.Edit.AssignedTo' | transloco }}</div>
                <div class="text-lg">{{ ticket.assignedToUser }}</div>
            </div>
        }
        
        <div class="border border-on-app-light border-solid rounded-xl p-5">
            <arc-license-db-info [license]="license" [isDarkTheme]="true"></arc-license-db-info>
        </div>
        <div class="text-lg">{{ 'Tickets.Edit.License' | transloco }}</div>
        <div class="border border-on-app-light border-solid rounded-xl p-5">
            <arc-license-details [license]="license"></arc-license-details>
        </div>
        <div>
            <div class="text-base">{{ 'Tickets.Edit.Tickets' | transloco }}</div>
            <arc-ticket-list></arc-ticket-list>
        </div>
    </div>

} 