import { Component, computed, HostBinding, inject } from '@angular/core';

import { BaseSidebarComponent } from '../../../../../../components/sidebar-components/base-sidebar/base-sidebar.component';
import { TicketSidebarService } from '../../../../../../core/services/ticket-sidebar.service';

@Component({
    selector: 'arc-ticket-base-data',
    templateUrl: './ticket-base-data.component.html',
    styleUrl: './ticket-base-data.component.scss'
})
export class TicketBaseDataComponent extends BaseSidebarComponent {
    @HostBinding('class') classes = 'h-full flex flex-col px-8 pt-8 smd:px-2 smd:pt-2 w-[500px] bg-gray-700 text-white overflow-y-auto';
    readonly ticketSidebarService = inject(TicketSidebarService);

    ticket = computed(() => this.ticketSidebarService.ticket());
    license = computed(() => this.ticketSidebarService.license());

    timeSpent = computed(() => {
        const totalTime = this.ticket()?.totalTime;
        if (!totalTime) {
            return '';
        }

        return this.getRoundedTime(totalTime);
    });

    getRoundedTime(timeSpentMinutes: number): string {
        // Round to the nearest 0.5 minute
        const roundedMinutes = Math.round(timeSpentMinutes * 2) / 2;

        const hours = Math.floor(roundedMinutes / 60); // Get whole hours
        const minutes = roundedMinutes % 60; // Get remaining minutes

        if (hours > 0) {
            return `${hours}h ${minutes}min`;
        } else {
            return `${minutes}min`;
        }
    }
}
