import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LoggerService {
    log(msg: any): void {
        // eslint-disable-next-line no-console
        console.log(msg);
    }

    error(msg: any): void {
        console.error(msg);
    }

    warn(msg: any): void {
        console.warn(msg);
    }
}
