<mat-sidenav-container autosize [hasBackdrop]="true" (backdropClick)="handleRightBackdropClick()">
    <mat-sidenav-content class="!h-screen !flex flex-col">
        <ng-content></ng-content>
    </mat-sidenav-content>

    <mat-sidenav position="end" mode="over" [opened]="sidebarService.rightOpenSubject | async" [disableClose]="true"
        class="smd:min-w-[100vw] !rounded-l-[10px]" [ngStyle]="{ width, maxWidth }">
        <div class="flex w-full h-full">
            @if(!!config?.extraComponent) {
                <div>
                    <ng-template *ngIf="componentExtraPortalSubject | async as portalExtra" [cdkPortalOutlet]="portalExtra"
                            (attached)="componentAttached($event)">
                    </ng-template>
                </div>
            }
            <div class="w-full h-full flex flex-col" [class.overflow-y-hidden]="!!config?.extraComponent">
                @if (sidebarHeaderService.title()) {
                   <arc-sidebar-header></arc-sidebar-header>
                }
                <div class="grow" [ngClass]="{'overflow-y-auto': !!config?.extraComponent}">
                    <ng-template *ngIf="componentPortalSubject | async as portal" [cdkPortalOutlet]="portal"
                        (attached)="componentAttached($event)">
                    </ng-template>
                </div>
            </div>
        </div>
    </mat-sidenav>
</mat-sidenav-container>