import { Component, OnInit } from '@angular/core';

import { environment } from '../../../environments/environment';

@Component({
    selector: 'arc-login-layout',
    templateUrl: './login-layout.component.html',
    styleUrls: ['./login-layout.component.scss']
})
export class LoginLayoutComponent implements OnInit {
    didViewLoad = false;
    baseUrl = '';

    ngOnInit(): void {
        this.didViewLoad = true;
        this.baseUrl = environment.baseUrl;
    }

}
