import {
    Component,
    Input,
    OnInit,
    ViewChildren,
    QueryList,
    ElementRef,
    inject,
    NgZone,
    ChangeDetectorRef,
    AfterViewInit, ViewChild
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

import { BaseListViewModel } from '../../../core/abstractions/base-list-view.model';
import { StaticBasicTableListViewConfigModel } from './models/static-table-list-view-config.model';
import { SortDirectionEnum } from '../../../core/models/enums/sort-direction.enum';
import { BaseColumnModel } from '../../dynamic-table/models/column-types/base-column.model';
import { ColumnSortModel } from '../../../core/models/column-sort.model';
import { Tools } from '../../../core/utils/tools/index';

@Component({
    selector: 'arc-static-basic-table-list-view',
    templateUrl: './static-basic-table-list-view.component.html',
    styleUrls: ['./static-basic-table-list-view.component.scss']
})
export class StaticBasicTableListViewComponent<TList extends BaseListViewModel> implements OnInit, AfterViewInit {
    @ViewChildren('accordion') accordion?: QueryList<ElementRef>;
    @ViewChild(MatPaginator) paginator!: MatPaginator;

    @Input() config!: StaticBasicTableListViewConfigModel<TList>;
    @Input() shouldUseCompactStyle = false;
    /**
     * Whether the row has no stack columns (only one line).
     */
    @Input() isSingleLineRow = false;

    SortDirectionEnum = SortDirectionEnum;

    readonly columnGap = 4;
    dataSource!: MatTableDataSource<TList>;
    shouldDisplayHeader = true;
    pageSize = 0;
    totalRecords = 0;
    visibleColumns: BaseColumnModel[] = [];
    currentSorting?: ColumnSortModel;
    get keys(): string[] {
        return Object.keys(this.config.availableColumns);
    }
    get visibleColumnNames(): string[] {
        const colNames: string[] = [];

        for (const key of this.keys) {
            if (!this.config.availableColumns[key].shouldHideColumnTitle) {
                colNames.push(key);
            }
        }

        return colNames;
    }

    private readonly zone = inject(NgZone);
    private readonly changeDetectorRef = inject(ChangeDetectorRef);

    ngOnInit(): void {
        this.totalRecords = this.config.data.length;
        this.pageSize = this.config.defaultPageSize;
        this.shouldDisplayHeader = this.config.shouldDisplayHeader;
        this.dataSource = new MatTableDataSource<TList>(this.config.data);
    }

    ngAfterViewInit(): void {
        this.setupWidth();
        this.dataSource.paginator = this.paginator;
    }

    sortColumn(column: BaseColumnModel): void {
        const previousColumn = this.currentSorting?.column;
        this.currentSorting ??= new ColumnSortModel();

        if (!!previousColumn && previousColumn === column.propertyName) {
            // cycle through sort states
            if (!this.currentSorting.direction) {
                this.currentSorting.direction = SortDirectionEnum.Asc;
            } else if (this.currentSorting.direction === SortDirectionEnum.Asc) {
                this.currentSorting.direction = SortDirectionEnum.Desc;
            } else {
                // reset sortings
                this.currentSorting = undefined;
            }
        } else if (!!column.propertyName) {
            this.currentSorting.column = column.propertyName;
            this.currentSorting.direction = SortDirectionEnum.Asc;
        } else {
            // reset sortings
            this.currentSorting = undefined;
        }

        if (!!this.currentSorting) {
            let filteredItems = [...this.config.data];
            filteredItems = filteredItems.sort((a, b) => {
                const sort = this.currentSorting!;
                const valueA = Tools.Utils.getValueForProperty(a, sort.column);
                const valueB = Tools.Utils.getValueForProperty(b, sort.column);
                let comparatorResult = 0;

                if (valueA && valueB) {
                    if (valueA > valueB) {
                        comparatorResult = 1;
                    } else if (valueA < valueB) {
                        comparatorResult = -1;
                    }
                } else if (valueA) {
                    comparatorResult = 1;
                } else if (valueB) {
                    comparatorResult = -1;
                }

                return comparatorResult * (sort.direction === SortDirectionEnum.Asc ? 1 : -1);
            });
            this.config.data = [...filteredItems];
        }
    }

    private setupWidth(): void {
        this.zone.run(() => {
            const accordionWidth = this.accordion?.first?.nativeElement?.clientWidth;

            if (!accordionWidth) {
                return;
            }

            const availableWidth = accordionWidth;

            this.visibleColumns = [];
            let totalWidth = 0;

            for (const columnName of Object.keys(this.config.availableColumns)) {
                const column = this.config.availableColumns[columnName];
                totalWidth += column.widthPixels + this.columnGap;

                // always show min. 1 column on very small devices
                if (this.visibleColumns.length > 0 && totalWidth >= availableWidth) {
                    return;
                }

                this.visibleColumns.push(column);
                this.changeDetectorRef.detectChanges();
            }
        });
    }
}
