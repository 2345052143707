import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ImportEntityColumnModel } from '../../../../../app/models/import-entity-column.model';

@Component({
    selector: 'arc-step2-mapping-group',
    templateUrl: './step2-mapping-group.component.html',
    styleUrls: ['./step2-mapping-group.component.scss']
})
export class Step2MappingGroupComponent {
    @Input() titleKey!: string;
    @Input() form!: FormGroup;
    @Input() columns!: ImportEntityColumnModel[];
    @Input() importFileColumns!: string[];
    @Input() isOpen = false;
    @Input() isRequired = false;
}
