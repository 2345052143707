import { Component, inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import { ArcFormControl } from '../../../../core/utils/arc-form-control';
import { CustomValidators } from '../../../../core/utils/custom-validators';

@Component({
    selector: 'arc-add-image-from-url-dialog',
    templateUrl: './add-image-from-url-dialog.component.html',
    styleUrl: './add-image-from-url-dialog.component.scss'
})
export class AddImageFromUrlDialogComponent {
    isSaving = false;
    urlControl = new ArcFormControl('', [Validators.required, CustomValidators.url()]);

    private readonly matDialogRef = inject(MatDialogRef);

    save(): void {
        this.urlControl.updateValueAndValidity();

        if (this.urlControl.valid) {
            this.matDialogRef.close(this.urlControl.value);
        }
    }
}
