<h1 mat-dialog-title>{{'PosReportTemplates.OpenReport' | transloco}}</h1>
<mat-dialog-content class="flex-1 overflow-auto !p-4">
    <div class="mb-4">
        @if(formFields.length > 0 && !isLoading){
        <arc-dynamic-form #dynamicForm [formFields]="formFields"
            (formSubmitted)="onFormSubmitted($event)"></arc-dynamic-form>
        }
        @else if(isLoading){
        <ng-container *ngIf="isLoading">
            <div class="grid place-items-center">
                <mat-spinner [diameter]="64"></mat-spinner>
            </div>
        </ng-container>
        }
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close>
        {{'General.Actions.Cancel' | transloco}}
    </button>
    <button mat-flat-button (click)="onOpenReport()" color="accent">
        {{'General.Actions.Open' | transloco}}
    </button>
</mat-dialog-actions>