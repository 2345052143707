export class TranslationDialogDataModel {
    propertyName!: string;
    labelKey?: string;
    label?: string;
    valueDe!: string;
    valueEn?: string;
    valueFr?: string;
    valueIt?: string;
    currentLanguageCode!: string;

    constructor(init: Partial<TranslationDialogDataModel>) {
        if (!!init) {
            Object.assign(this, init);
        }
    }
}
