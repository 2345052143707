import { Component } from '@angular/core';

import { BaseCardComponent } from '../../../../../components/abstractions/base-card.component';
import { NewsletterListModel } from '../../../../models/responses/newsletter-list.model';

@Component({
    selector: 'arc-newsletter-card',
    templateUrl: './newsletter-card.component.html',
    styleUrl: './newsletter-card.component.scss'
})
export class NewsletterCardComponent extends BaseCardComponent<NewsletterListModel> {}
