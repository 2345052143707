import { Component, inject } from '@angular/core';
import { UserRoles } from 'src/app/models/enums/user-roles.enum';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { TableListViewConfigModel } from '../../../../components/list-views/table-list-view/models/table-list-view-config.model';
import { StackedColumnModel } from '../../../../components/dynamic-table/models/column-types/stacked-column.model';
import { DateColumnModel } from '../../../../components/dynamic-table/models/column-types/date-column.model';
import { StringColumnModel } from '../../../../components/dynamic-table/models/column-types/string-column.model';
import { ColumnSortModel } from '../../../../core/models/column-sort.model';
import { SortDirectionEnum } from '../../../../core/models/enums/sort-direction.enum';
import { CustomColumnModel } from '../../../../components/dynamic-table/models/column-types/custom-column.model';
import { TableListDetailWidgetModel } from '../../../../components/list-views/table-list-view/models/table-list-detail-widget.model';
import { BlogArticleListModel } from '../../../models/responses/blog-article-list.model';
import { BlogArticleModel } from '../../../models/responses/blog-article.model';
import { BlogArticleEditModel } from '../../../models/requests/blog-article-edit.model';
import { BlogArticlesStore } from '../../../services/stores/blog-articles.store';
import { HelpTopicItemComponent } from '../help/components/help-topic-item/help-topic-item.component';
import { HelpSubTopicItemComponent } from '../help/components/help-sub-topic-item/help-sub-topic-item.component';
import { BlogEditComponent } from './blog-edit-items/blog-edit/blog-edit.component';
import { BlogDescriptionComponent } from './blog-detail-widgets/blog-description/blog-description.component';

@Component({
    selector: 'arc-blog',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrl: './blog.component.scss'
})
export class BlogComponent extends PageListViewComponent<BlogArticleListModel, BlogArticleModel, BlogArticleEditModel> {
    private readonly blogArticlesStore = inject(BlogArticlesStore);

    constructor() {
        super('BlogArticle');
        const canEdit = this._authService.hasRole(UserRoles.BlogArticlesAdmin);
        this.config = new TableListViewConfigModel<BlogArticleListModel, BlogArticleModel, BlogArticleEditModel>({
            entityName: 'BlogArticle',
            availableColumns: {
                title: new StackedColumnModel({
                    columnTitleKey: 'BlogArticle.List.Title',
                    propertyName: 'title',
                    propertyName2: 'id',
                    widthPixels: 260
                }),
                helpTopic: new CustomColumnModel({
                    columnTitleKey: 'BlogArticle.List.HelpTopic',
                    widthPixels: 200,
                    customComponent: HelpTopicItemComponent
                }),
                helpSubTopic: new CustomColumnModel({
                    columnTitleKey: 'BlogArticle.List.HelpSubTopic',
                    widthPixels: 220,
                    customComponent: HelpSubTopicItemComponent
                }),
                lastChangeUserName: new StringColumnModel({
                    columnTitleKey: 'BlogArticle.List.LastChangeUserName',
                    propertyName: 'lastChangeUserName',
                    widthPixels: 160
                }),
                lastChangeDate: new DateColumnModel({
                    columnTitleKey: 'BlogArticle.List.LastChangeDate',
                    propertyName: 'lastChangeDate',
                    format: 'short',
                    widthPixels: 180
                }),
                views: new StringColumnModel({
                    columnTitleKey: 'BlogArticle.List.Views',
                    propertyName: 'views',
                    widthPixels: 120
                }),
                upVotes: new StringColumnModel({
                    columnTitleKey: 'BlogArticle.List.UpVotes',
                    propertyName: 'upVotes',
                    widthPixels: 120
                }),
                downVotes: new StringColumnModel({
                    columnTitleKey: 'BlogArticle.List.DownVotes',
                    propertyName: 'downVotes',
                    widthPixels: 120
                })
            },
            defaultColumnOrder: [
                'title',
                'helpTopic',
                'helpSubTopic',
                'lastChangeUserName',
                'lastChangeDate',
                'views',
                'upVotes',
                'downVotes'
            ],
            defaultSort: new ColumnSortModel({
                column: 'id',
                direction: SortDirectionEnum.Desc
            }),
            availableDetailWidgets: {
                description: new TableListDetailWidgetModel({
                    name: 'BlogArticle.Detail.DescriptionWidget',
                    component: BlogDescriptionComponent,
                    shouldHideHeader: true,
                    takeFullSizeIfOnly: true
                })
            },
            defaultDetailWidgetOrder: ['description'],
            editSidebarConfig: {
                editComponents: [
                    {
                        titleKey: 'BlogArticle.Edit.Name',
                        component: BlogEditComponent
                    }
                ]
            },
            store: this.blogArticlesStore,
            showCheckboxes: canEdit,
            buttonsVisibility: {
                hasEdit: canEdit,
                hasDelete:  canEdit,
                hasCreate:  canEdit,
                hasImport: false,
                hasExport: false
            }
        });
    }
}
