import { ComparisonOperatorsEnum } from './enums/comparison-operators.enum';
import { FilterItemTypeEnum } from './enums/filter-item-type.enum';
import { OperandTypeEnum } from './enums/operand-type.enum';

export class ColumnFilterModel {
    column!: string;
    values: any[] = [];
    dataType!: FilterItemTypeEnum;
    comparisonOperator!: ComparisonOperatorsEnum;
    operand = OperandTypeEnum.And;

    constructor(init?: Partial<ColumnFilterModel>) {
        Object.assign(this, init);
    }
}
