import { Injectable, inject } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

import { TranslationService } from '../translation.service';

@Injectable()
export class LanguageHttpInterceptor implements HttpInterceptor {
    private translationService = inject(TranslationService);

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!!this.translationService.current) {
            const languageRequest = request.clone({
                headers: request.headers.set('Accept-Language', this.translationService.current.culture)
            });
            return next.handle(languageRequest);
        }

        return next.handle(request);
    }
}
