import { Component, OnInit, inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs';

import { UsersStore } from '../../../services/stores/users.store';
import { ResetPasswordRequestModel } from '../../../models/requests/reset-password-request.model';
import { AlertService } from '../../../../core/services/alert.service';

@Component({
    selector: 'arc-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
    providers: [UsersStore]
})
export class ResetPasswordComponent implements OnInit {
    formGroup: FormGroup;
    isResettingPassword = false;
    subdomain = '';

    private readonly userId: number;
    private readonly token?: string;

    private readonly activatedRoute = inject(ActivatedRoute);
    private readonly router = inject(Router);
    private readonly formBuilder = inject(FormBuilder);
    private readonly usersStore = inject(UsersStore);
    private readonly alertService = inject(AlertService);

    constructor() {
        this.formGroup = this.formBuilder.group({
            newPassword: [undefined, Validators.required],
            confirmPassword: [undefined, Validators.required]
        });
        this.userId = +(this.activatedRoute.snapshot.paramMap.get('userId') || 0);
        this.token = this.activatedRoute.snapshot.queryParamMap.get('token') || '';
    }

    ngOnInit(): void {
        const subdomains = window.location.host.split('.');
        this.subdomain = subdomains[0].includes('arcavis') ? '' : subdomains[0];
    }

    resetPassword(): void {
        if (!this.token) {
            this.alertService.showAlert('ResetPassword.EmptyToken');
            return;
        }

        this.formGroup.markAllAsTouched();

        if (this.formGroup.invalid) {
            return;
        }

        this.isResettingPassword = true;

        this.usersStore.resetPassword(
            this.userId,
            this.token,
            new ResetPasswordRequestModel(this.formGroup.value)
        ).pipe(
            finalize(() => this.isResettingPassword = false)
        ).subscribe(resp => {
            if (!!resp.value) {
                this.alertService.showSuccess(
                    'ResetPassword.SuccessMessage',
                    undefined,
                    () => this.router.navigate(['login']).then()
                );
            } else {
                this.alertService.showError('ResetPassword.EmptyToken', undefined);
            }
        });
    }
}
