import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { StoreConfig } from '../../../core/models/store.config';
import { TicketModel } from '../../models/ticket.model';
import { TicketListModel } from '../../models/responses/ticket-list.model';
import { TicketEditModel } from '../../models/requests/ticket-edit.model';
import { ApiResponseModel } from '../../models/responses/api-response.model';
import { TicketCreateRequestModel } from '../../models/requests/ticket-create-request.model';
import { TicketCreateModel } from '../../models/requests/ticket-create.model';
import { TicketCreateResponseModel } from '../../models/responses/ticket-create-response.model';

@Injectable({
    providedIn: 'root'
})
export class TicketsStore extends BaseCrudStore<TicketModel, TicketListModel, TicketEditModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'tickets' }));
    }

    createTicket(requestId: string, requestModel: TicketCreateModel): Observable<ApiResponseModel<boolean>> {
        return this._requestService.makePost<ApiResponseModel<boolean>>(this.getUrl(`${requestId}/create`), requestModel);
    }

    resolveTicket(requestId: string): Observable<ApiResponseModel<boolean>> {
        return this._requestService.makePost<ApiResponseModel<boolean>>(this.getUrl(`${requestId}/resolve`));
    }

    createTicketRequest(requestModel: TicketCreateRequestModel): Observable<ApiResponseModel<TicketCreateResponseModel>> {
        return this._requestService.makePost<ApiResponseModel<TicketCreateResponseModel>>(this.getUrl('request'), requestModel);
    }

    updateTicketTime(ticketId: number): Observable<ApiResponseModel<boolean>> {
        return this._requestService.makePut<ApiResponseModel<boolean>>(this.getUrl(`${ticketId}/ticket-time`));
    }
}
