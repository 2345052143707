import { Component, Input, OnInit, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subject, debounceTime, distinctUntilChanged, forkJoin, map, of, switchMap, tap } from 'rxjs';

import { PlacesStore } from '../../../app/services/stores/places.store';
import { GeneralDataTypeEnum } from '../../../core/models/enums/general-data-type.enum';
import { PlaceModel } from '../../../app/models/place.model';
import { OptionalType } from '../../../core/models/types/optional.type';
import { Utils } from '../../../core/utils/tools/utils.tools';

@Component({
    selector: 'arc-place-select',
    templateUrl: './place-select.component.html',
    styleUrls: ['./place-select.component.scss']
})
export class PlaceSelectComponent implements OnInit {
    @Input() zipFormControl!: FormControl;
    @Input() cityFormControl!: FormControl;
    @Input() countryIsoCodeFormControl!: FormControl;

    GeneralDataTypeEnum = GeneralDataTypeEnum;

    isValid = false;

    isChecking = false;
    isExistingPlace = false;

    protected readonly _debounceTimeMs = 250;

    private readonly checkIfPlaceExistsSubject = new Subject<PlaceModel>();
    private readonly placesStore = inject(PlacesStore);

    constructor() {
        this.checkIfPlaceExistsSubject
            .pipe(
                distinctUntilChanged((prev, curr) => Utils.areEqual(prev, curr)),
                tap(() => {
                    this.isChecking = true;
                }),
                debounceTime(this._debounceTimeMs),
                switchMap(place =>
                    forkJoin({
                        input: of(place),
                        result: this.placesStore.search(place.countryIsoCode, place.zip, place.city)
                    })
                )
            )
            .subscribe(result => {
                this.isExistingPlace = (result.result.value ?? []).some(
                    p => p.countryIsoCode === result.input.countryIsoCode && p.zip === result.input.zip && p.city === result.input.city
                );
                this.isChecking = false;
                this.updateValidity();
            });
    }

    ngOnInit(): void {
        this.zipFormControl.valueChanges.subscribe(() => this.checkIfPlaceExists());
        this.cityFormControl.valueChanges.subscribe(() => this.checkIfPlaceExists());
        this.countryIsoCodeFormControl.valueChanges.subscribe(() => this.checkIfPlaceExists());
    }

    citySearchFn(query: string): Observable<PlaceModel[]> {
        if (!!query) {
            return this.placesStore.search(this.countryIsoCodeFormControl.value, '', query).pipe(map(result => result.value ?? []));
        }

        return this.placesStore
            .search(this.countryIsoCodeFormControl.value, this.zipFormControl.value, '')
            .pipe(map(result => result.value ?? []));
    }

    zipSearchFn(query: string): Observable<PlaceModel[]> {
        return this.placesStore.search(this.countryIsoCodeFormControl.value, query, '').pipe(map(result => result.value ?? []));
    }

    placeOptionDisplayFn(option: PlaceModel): string {
        return `${option.zip} - ${option.city}`;
    }

    checkIfPlaceExists(): void {
        this.updateValidity();
        if (!this.isValid) {
            return;
        }
        const countryIsoCode = this.countryIsoCodeFormControl.value;
        const zip = this.zipFormControl.value;
        const city = this.cityFormControl.value;
        this.checkIfPlaceExistsSubject.next(
            new PlaceModel({
                countryIsoCode,
                zip,
                city
            })
        );
    }

    onPlaceSelected(place: OptionalType<PlaceModel>): void {
        this.zipFormControl.setValue(place?.zip);
        this.cityFormControl.setValue(place?.city);

        this.updateValidity();
        this.isExistingPlace = place !== undefined;
    }

    onCountryChanged(): void {
        this.zipFormControl.setValue(undefined);
        this.cityFormControl.setValue(undefined);
        this.updateValidity();
    }

    updateValidity(): void {
        this.isValid = !!this.zipFormControl.value && !!this.cityFormControl.value && !!this.countryIsoCodeFormControl.value;
    }
}
