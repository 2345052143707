import { Component, HostBinding } from '@angular/core';

import { ApiIntegrationListModel } from '../../../../../models/responses/api-integration-list.model';
import { BaseCustomColumnComponent } from '../../../../../../components/dynamic-table/column-components/custom-column/base-custom-column.component';

@Component({
    selector: 'arc-api-integration-status',
    templateUrl: './api-integration-status.component.html',
    styleUrl: './api-integration-status.component.scss'
})
export class ApiIntegrationStatusComponent extends BaseCustomColumnComponent<ApiIntegrationListModel> {
    @HostBinding('class') classList = 'h-full w-full';
}
