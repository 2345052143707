import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { Chart, ChartData, ChartType } from 'chart.js/auto'; // doc https://www.chartjs.org/docs/latest/

@Component({
    selector: 'arc-chart',
    templateUrl: './chart.component.html',
    styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements AfterViewInit, OnDestroy {
    @ViewChild('canvasElement') canvasElement!: ElementRef;

    /**
     * The type of chart. Possible options are: area, bar, bubble, doughnut, pie, line, polarArea, radar, scatter.
     * Default type is: bar.
     */

    @Input() set type(value: ChartType) {
        this._type = value;
        this.chart?.update();
    }

    get type(): ChartType {
        return this._type;
    }

    @Input() width = '100%';

    @Input() height = '100%';

    @Input() isResponsive = true;

    @Input() plugins: any[] = [];

    isInitialized!: boolean;

    chart: Chart | undefined;

    private _type: ChartType = 'bar';

    private _data!: ChartData;

    private _options: any = {};

    get data(): ChartData {
        return this._data;
    }

    @Input() set data(val: ChartData) {
        this._data = val;
        this.reinit();
    }

    get options(): any {
        return this._options;
    }

    @Input() set options(val: any) {
        this._options = val;
        this.reinit();
    }

    ngAfterViewInit(): void {
        this.initChart();
        this.isInitialized = true;
    }

    ngOnDestroy(): void {
        if (this.chart) {
            this.chart.destroy();
            this.isInitialized = false;
            this.chart = undefined;
        }
    }

    initChart(): void {
        const style = getComputedStyle(document.body);

        Chart.defaults.font.family = 'Poppins, "Helvetica Neue", sans-serif';
        Chart.defaults.color = style.getPropertyValue('--on-widget');

        const opts = this.options || {};
        opts.responsive = this.isResponsive;
        opts.maintainAspectRatio = false;

        if (this.type === 'bar') {
            opts.scales = {
                x: {
                    stacked: true,
                    grid: {
                        display: false
                    }
                },
                y: {
                    stacked: true,
                    grid: {
                        display: false
                    }
                }
            };
        }
        opts.maintainAspectRatio = false;

        if (this.type === 'doughnut' || this.type === 'line') {
            opts.scales = {
                x: {
                    display: false
                },
                y: {
                    display: false
                }
            };
        }

        // allows chart to resize in responsive mode
        if (opts.responsive && (this.height || this.width)) {
            opts.maintainAspectRatio = false;
        }

        this.chart = new Chart(this.canvasElement.nativeElement, {
            type: this.type,
            data: this.data,
            options: this.options,
            plugins: this.plugins
        });
    }

    reinit(): void {
        if (this.chart) {
            this.chart.destroy();
            this.initChart();
        }
    }
}
