<div class="flex flex-col items-center justify-center h-[4.5rem] w-[5.5rem] rounded cursor-pointer" matTooltip="{{ module().title }}" [ngClass]="moduleClasses()"
    (click)="handleClick()">
    <span>{{module().key}}</span>
    @if(!module().isLicensed) {
        <span class="text-xl">+</span>
    } @else if(module().cancelledDate){
        <span class="text-sm">{{ module().cancelledDate | arcDate }}</span>
    } @else if(module().validFrom > now){
        <span class="text-sm">{{module().validFrom | arcDate }}</span>
    } @else if(module().validFrom <= now) { 
        <span class="text-xl">-</span>
    }
</div>