import { Component } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { HelpTooltipModel } from '../../../../../models/responses/help-tooltip.model';
import { HelpTooltipEditModel } from '../../../../../models/requests/help-tooltip-edit.model';
import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { OptionalType } from '../../../../../../core/models/types/optional.type';

@Component({
    selector: 'arc-help-tooltip-edit',
    templateUrl: './help-tooltip-edit.component.html',
    styleUrl: './help-tooltip-edit.component.scss'
})
export class HelpTooltipEditComponent extends BaseEditSidebarItemComponent<HelpTooltipModel, HelpTooltipEditModel> {
    override formGroup = new FormGroup({
        key: new ArcFormControl('', Validators.required),
        text_de: new ArcFormControl('', Validators.required),
        text_en: new ArcFormControl<OptionalType<string>>(undefined),
        text_fr: new ArcFormControl<OptionalType<string>>(undefined),
        text_it: new ArcFormControl<OptionalType<string>>(undefined)
    });

    onItemSet(): void {
        this.formGroup.patchValue(this.item);
    }

    override prepareSaveModel(itemCopy: HelpTooltipEditModel): HelpTooltipEditModel {
        return new HelpTooltipEditModel({ ...itemCopy, ...this.formGroup.value });
    }
}
