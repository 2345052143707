<div class="!mx-4">
    <div class="mb-2">
        <p class="text-on-app-light">{{ 'Newsletter.List.CreatedDate' | transloco }}</p>
        <p class="text-xl">{{ item.createdDate | arcDate: 'short'}}</p>
    </div>
    <div class="mb-2">
        <p class="text-on-app-light">{{ 'Newsletter.List.CreatedBy' | transloco }}</p>
        <p class="text-xl">{{ item.createdBy}}</p>
    </div>
    <div class="mb-2">
        <p class="text-on-app-light">{{ 'Newsletter.List.RecepientsCount' | transloco }}</p>
        <p class="text-xl">{{ item.recipientsCount }}</p>
    </div>
    <div class="mb-2">
        <p class="text-on-app-light">{{ 'Newsletter.List.SentCount' | transloco }}</p>
        <p class="text-xl">{{ item.sentCount }}</p>
    </div>
</div>
