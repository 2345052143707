import { Component, effect, HostBinding, inject } from '@angular/core';

import { TicketModel } from '../../../../../models/ticket.model';
import { BaseSidebarComponent } from '../../../../../../components/sidebar-components/base-sidebar/base-sidebar.component';
import { TicketSidebarService } from '../../../../../../core/services/ticket-sidebar.service';
import { LicenseModel } from '../../../../../models/license.model';

@Component({
    selector: 'arc-ticket-base-data',
    templateUrl: './ticket-base-data.component.html',
    styleUrl: './ticket-base-data.component.scss'
})
export class TicketBaseDataComponent extends BaseSidebarComponent {
    @HostBinding('class') classes = 'h-full flex flex-col px-8 pt-8 smd:px-2 smd:pt-2 w-[500px] bg-gray-700 text-white overflow-y-auto';
    ticket?: TicketModel;
    license?: LicenseModel;

    private readonly ticketSidebarService = inject(TicketSidebarService);

    constructor() {
        super();

        effect(() => {
            this.ticket = this.ticketSidebarService.ticket();
            this.license = this.ticketSidebarService.license();
        });
    }
}
