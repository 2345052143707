import { BaseEntityModel } from '../../../core/abstractions/base-entity.model';
import { HelpTagModel } from '../responses/help-tag.model';

export class HelpArticleEditModel extends BaseEntityModel {
    minDbVersion?: number;
    helpTopicId!: number;
    helpSubTopicId!: number;
    context?: string;
    title_de!: string;
    title_en?: string;
    title_fr?: string;
    title_it?: string;
    text_de!: string;
    text_en?: string;
    text_fr?: string;
    text_it?: string;
    summary_de!: string;
    summary_en?: string;
    summary_fr?: string;
    summary_it?: string;
    helpTags!: HelpTagModel[];
    isPublic!: boolean;
    licenseId?: number;
    constructor(init?: Partial<HelpArticleEditModel>) {
        super();
        Object.assign(this, init);
    }
}
