import { BaseColumnModel } from '../../../dynamic-table/models/column-types/base-column.model';
import { StackedColumnModel } from '../../../dynamic-table/models/column-types/stacked-column.model';
import { StringColumnModel } from '../../../dynamic-table/models/column-types/string-column.model';

export const licenseSelectionTableConfig: BaseColumnModel[] = [
    new StackedColumnModel({
        propertyName: 'licenseHolder',
        propertyName2: 'id',
        columnTitleKey: 'License.List.LicenseHolder'
    }),
    new StringColumnModel({
        columnTitleKey: 'License.List.UserName',
        propertyName: 'userName',
        widthPixels: 250
    }),
    new StringColumnModel({
        columnTitleKey: 'License.List.NumberOfPos',
        propertyName: 'numberOfPos',
        widthPixels: 160
    })
];
