import { inject, Injectable, signal } from '@angular/core';

import { TicketCreateResponseModel } from '../../app/models/responses/ticket-create-response.model';
import { TicketCreateRequestModel } from '../../app/models/requests/ticket-create-request.model';
import { HelpArticleModel } from '../../app/models/responses/help-article.model';
import { CreateTicketStepEnum } from '../../app/models/enums/create-ticket-step.enum';
import { TicketActionButtonModel } from '../models/ticket-action-button.model';
import { TicketsStore } from '../../app/services/stores/tickets.store';
import { TicketRequestModel } from '../../app/models/requests/ticket-request.model';

@Injectable({
    providedIn: 'root'
})
export class CreateTicketService {
    isLoading = signal(false);
    currentStep = signal(CreateTicketStepEnum.None);
    createTicketResponse = signal<TicketCreateResponseModel | undefined>(undefined);
    createTicketRequest = signal<TicketCreateRequestModel | undefined>(undefined);
    selectedArticle = signal<HelpArticleModel | undefined>(undefined);
    licenseId: number | undefined = undefined;
    isEscalated = false;

    readonly buttons = signal<TicketActionButtonModel[]>([]);
    private readonly ticketStore = inject(TicketsStore);

    addButtons(...newButtons: TicketActionButtonModel[]): void {
        newButtons = newButtons.filter(button => this.buttons().findIndex(b => b.id === button.id) === -1);
        this.buttons.update(buttons => [...buttons, ...newButtons]);
    }

    reset(): void {
        this.licenseId = undefined;
        this.isEscalated = false;
        this.currentStep.set(CreateTicketStepEnum.None);
        this.buttons.set([]);
        this.createTicketResponse.set(undefined);
        this.createTicketRequest.set(undefined);
        this.selectedArticle.set(undefined);
        this.isLoading.set(false);
    }

    startNewTicket(licenseId: number, escalated: boolean): void {
        this.licenseId = licenseId;
        this.isEscalated = escalated;
        this.currentStep.set(CreateTicketStepEnum.CreateRequest);
    }

    acceptSolution(): void {
        this.reset();
    }

    createTicket(): void {
        this.currentStep.set(CreateTicketStepEnum.CreateTicket);
    }

    ticketResponseCreated(ticketResponse?: TicketCreateResponseModel): void {
        this.createTicketResponse.set(ticketResponse);
        this.currentStep.set(CreateTicketStepEnum.RequestCreated);
        this.isLoading.set(false);
    }

    createRequest(ticket: TicketRequestModel): void {
        this.isLoading.set(true);
        const createTicketRequest = {
            ...ticket,
            licenseId: this.licenseId || 0,
            escalated: this.isEscalated
        };
        this.createTicketRequest.set(createTicketRequest);

        this.ticketStore.createTicketRequest(createTicketRequest).subscribe(result => {
            this.ticketResponseCreated(result.value);
        });
    }
}
