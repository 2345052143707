import { ColumnTypeEnum } from '../enums/column-type.enum';
import { BaseColumnModel } from './base-column.model';

export class StackedColumnModel extends BaseColumnModel {
    propertyName2!: string;
    suffixPropertyName?: string;
    isSecondPropertySmaller? = true;

    constructor(init?: Partial<StackedColumnModel>) {
        super(ColumnTypeEnum.Stacked);

        if (!!init) {
            Object.assign(this, init);
        }
    }
}
