import { Injectable } from '@angular/core';

import { StoreConfig } from '../../../core/models/store.config';
import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { LicenseModuleBundleModel } from '../../models/license-module-bundle.model';
import { LicenseModuleBundleListModel } from '../../models/responses/license-module-bundle-list.model';
import { LicenseModuleBundleEditModel } from '../../models/requests/license-module-bundle-edit.model';

@Injectable({
    providedIn: 'root'
})
// eslint-disable-next-line max-len
export class LicenseModuleBundlesStore extends BaseCrudStore<LicenseModuleBundleModel, LicenseModuleBundleListModel, LicenseModuleBundleEditModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'license-module-bundles' }));
    }
}
