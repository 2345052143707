import { ComparisonOperatorsEnum } from '../../../core/models/enums/comparison-operators.enum';
import { FilterItemTypeEnum } from '../../../core/models/enums/filter-item-type.enum';
import { FilterItemModel } from '../../../core/models/filter-item.model';
import { OptionalType } from '../../../core/models/types/optional.type';

export class FilterOperator {
    operator: ComparisonOperatorsEnum;
    labelKey: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    expectedParameterAmount: number;
    isDefault?: boolean;
    shouldShowPredicate?: (filterItem: FilterItemModel) => boolean;

    constructor(
        operator: ComparisonOperatorsEnum,
        labelKey: string,
        expectedParameterAmount: number,
        options?: {
            isDefault?: boolean;
            shouldShowPredicate?: (filterItem: FilterItemModel) => boolean;
        }
    ) {
        this.operator = operator;
        this.labelKey = 'Components.SearchFilter.ComparisonOperators.' + labelKey;
        this.expectedParameterAmount = expectedParameterAmount;
        this.isDefault = options?.isDefault ?? false;
        this.shouldShowPredicate = options?.shouldShowPredicate;
    }
}

export const filterOperatorsString: FilterOperator[] = [
    new FilterOperator(ComparisonOperatorsEnum.Equal, 'EqualsString', 1),
    new FilterOperator(ComparisonOperatorsEnum.NotEqual, 'NotEqualsString', 1),
    new FilterOperator(ComparisonOperatorsEnum.Contains, 'Contains', 1, { isDefault: true }),
    new FilterOperator(ComparisonOperatorsEnum.DoesNotContain, 'NotContains', 1),
    new FilterOperator(ComparisonOperatorsEnum.StartsWith, 'StartsWith', 1),
    new FilterOperator(ComparisonOperatorsEnum.EndsWith, 'EndsWith', 1),
    new FilterOperator(ComparisonOperatorsEnum.Empty, 'IsEmpty', 0, { shouldShowPredicate: i => i.isNullable }),
    new FilterOperator(ComparisonOperatorsEnum.NotEmpty, 'IsNotEmpty', 0, { shouldShowPredicate: i => i.isNullable })
];

export const filterOperatorsNumber: FilterOperator[] = [
    new FilterOperator(ComparisonOperatorsEnum.Equal, 'EqualNumber', 1, { isDefault: true }),
    new FilterOperator(ComparisonOperatorsEnum.NotEqual, 'NotEqualNumber', 1),
    new FilterOperator(ComparisonOperatorsEnum.GreaterThanOrEqualTo, 'GreaterThanOrEqualToNumber', 1),
    new FilterOperator(ComparisonOperatorsEnum.LessThanOrEqualTo, 'LessThanOrEqualToNumber', 1),
    new FilterOperator(ComparisonOperatorsEnum.GreaterThan, 'GreaterThanNumber', 1),
    new FilterOperator(ComparisonOperatorsEnum.LessThan, 'LessThanNumber', 1),
    new FilterOperator(ComparisonOperatorsEnum.InRange, 'InRangeNumber', 2)
];

export const filterOperatorsBoolean: FilterOperator[] = [
    new FilterOperator(ComparisonOperatorsEnum.True, 'IsTrue', 0, { isDefault: true }),
    new FilterOperator(ComparisonOperatorsEnum.False, 'IsFalse', 0)
];

export const filterOperatorsDate: FilterOperator[] = [
    new FilterOperator(ComparisonOperatorsEnum.Equal, 'EqualDate', 1, { isDefault: true }),
    new FilterOperator(ComparisonOperatorsEnum.InRange, 'InRangeDate', 2),
    new FilterOperator(ComparisonOperatorsEnum.LessThanOrEqualTo, 'OnOrBeforeDate', 1),
    new FilterOperator(ComparisonOperatorsEnum.GreaterThanOrEqualTo, 'OnOrAfterDate', 1),
    new FilterOperator(ComparisonOperatorsEnum.Today, 'Today', 0),
    new FilterOperator(ComparisonOperatorsEnum.ThisMonth, 'ThisMonth', 0),
    new FilterOperator(ComparisonOperatorsEnum.ThisYear, 'ThisYear', 0),
    new FilterOperator(ComparisonOperatorsEnum.Empty, 'IsEmpty', 0, { shouldShowPredicate: i => i.isNullable }),
    new FilterOperator(ComparisonOperatorsEnum.NotEmpty, 'IsNotEmpty', 0, { shouldShowPredicate: i => i.isNullable })
];

export const filterOperatorsGuid: FilterOperator[] = [
    new FilterOperator(ComparisonOperatorsEnum.Equal, 'EqualsString', 1, { isDefault: true }),
    new FilterOperator(ComparisonOperatorsEnum.NotEqual, 'NotEqualsString', 1),
    new FilterOperator(ComparisonOperatorsEnum.Empty, 'IsEmpty', 0),
    new FilterOperator(ComparisonOperatorsEnum.NotEmpty, 'IsNotEmpty', 0)
];

export const filterOperatorsTime: FilterOperator[] = [
    new FilterOperator(ComparisonOperatorsEnum.InRange, 'InRangeTime', 2, { isDefault: true }),
    new FilterOperator(ComparisonOperatorsEnum.LessThanOrEqualTo, 'OnOrBeforeTime', 1),
    new FilterOperator(ComparisonOperatorsEnum.GreaterThanOrEqualTo, 'OnOrAfterTime', 1)
];

export const filterOperatorsGroup: FilterOperator[] = [
    new FilterOperator(ComparisonOperatorsEnum.Equal, 'EqualGroup', 1, { isDefault: true })
];

function getFilterOperatorsByType(type?: FilterItemTypeEnum): FilterOperator[] {
    switch (type) {
        case FilterItemTypeEnum.Boolean:
            return filterOperatorsBoolean;
        case FilterItemTypeEnum.Date:
            return filterOperatorsDate;
        case FilterItemTypeEnum.Time:
            return filterOperatorsTime;
        case FilterItemTypeEnum.Guid:
            return filterOperatorsGuid;
        case FilterItemTypeEnum.Number:
            return filterOperatorsNumber;
        case FilterItemTypeEnum.String:
            return filterOperatorsString;
        case FilterItemTypeEnum.Group:
            return filterOperatorsGroup;
        default:
            return [];
    }
}

export function getFilterOperator(type: FilterItemTypeEnum, operator: ComparisonOperatorsEnum): OptionalType<FilterOperator> {
    return getFilterOperatorsByType(type).find(f => f.operator === operator);
}

export function getFilterOperators(item?: FilterItemModel): FilterOperator[] {
    if (!!item?.allowedComparisonOperators && item.allowedComparisonOperators.length > 0) {
        return item.allowedComparisonOperators.map(o => getFilterOperator(item.type, o)!).filter(o => !!o);
    }
    return getFilterOperatorsByType(item?.type);
}
