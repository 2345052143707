import { Component, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, map, of, switchMap } from 'rxjs';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { NewsletterListModel } from '../../../models/responses/newsletter-list.model';
import { NewsletterModel } from '../../../models/newsletter.model';
import { CardListViewConfigModel } from '../../../../components/list-views/card-list-view/models/card-list-view-config.model';
import { NewsletterStore } from '../../../services/stores/newsletter.store';
import { NewsletterCardComponent } from './newsletter-card/newsletter-card.component';
import { ColumnSortModel } from '../../../../core/models/column-sort.model';
import { SortDirectionEnum } from '../../../../core/models/enums/sort-direction.enum';
import { BaseListViewModel } from '../../../../core/abstractions/base-list-view.model';
import { GeneralPromptDialogComponent } from '../../../../components/dialogs/general-prompt-dialog/general-prompt-dialog.component';
import { SearchRequestService } from '../../../../core/services/search-request.service';
import { NewsletterEditComponent } from './newsletter-edit-items/newsletter-edit/newsletter-edit.component';
import { NewsletterEditModel } from '../../../models/requests/newsletter-edit.model';
import { NewsletterCreateDialogComponent } from './newsletter-create-dialog/newsletter-create-dialog.component';
import { SidebarService } from '../../../../core/services/sidebar.service';

@Component({
    selector: 'arc-newsletter',
    templateUrl: './newsletter.component.html',
    styleUrl: './newsletter.component.scss'
})
export class NewsletterComponent extends PageListViewComponent<NewsletterListModel> {
    readonly cardConfig: CardListViewConfigModel<NewsletterModel, NewsletterListModel, NewsletterEditModel>;
    private readonly newsletterStore = inject(NewsletterStore);
    private readonly searchRequestService = inject(SearchRequestService);
    private readonly matDialog = inject(MatDialog);
    private readonly sidebarService = inject(SidebarService);

    constructor() {
        super('Newsletter');
        this.config = new CardListViewConfigModel<NewsletterModel, NewsletterListModel, NewsletterEditModel>({
            entityName: 'Newsletter',
            store: this.newsletterStore,
            cardComponent: NewsletterCardComponent,
            cardWidth: '350px',
            cardHeight: '465px',
            actionButtonsCustomClasses: 'max-h-[52px]',
            defaultSort: new ColumnSortModel({
                column: 'createdDate',
                direction: SortDirectionEnum.Desc
            }),
            cardTitle: newsletter => newsletter.title,
            handleDeleteButtonClick: items => this.handleDeleteButtonClick(items),
            editSidebarConfig: {
                editComponents: [
                    {
                        titleKey: 'Newsletter.Edit.Name',
                        component: NewsletterEditComponent
                    }
                ]
            }
        });

        this.cardConfig = this.config;
    }

    handleDeleteButtonClick(items: BaseListViewModel[]): Observable<boolean> {
        const dialogRef = this.matDialog.open(GeneralPromptDialogComponent, {
            data: { promptKey: 'General.Prompts.DeleteEntry' }
        });

        return dialogRef.afterClosed().pipe(
            switchMap(result => {
                if (result) {
                    return this.newsletterStore.removeMany(items.map(i => i.id)).pipe(
                        map(response => {
                            if (response.value) {
                                this.searchRequestService.forceReload();
                            }
                            return true;
                        })
                    );
                } else {
                    return of(false);
                }
            })
        );
    }

    handleCreate(): void {
        const dialogRef = this.matDialog.open(NewsletterCreateDialogComponent, {
            maxHeight: '98svh',
            maxWidth: '98vw'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!result?.id) {
                return;
            }
            this.sidebarService
                .openEdit({
                    entityName: this.config!.entityName,
                    existingId: result.id,
                    store: this.config!.store,
                    editComponents: this.config.editSidebarConfig!.editComponents,
                    headerTitle: this.sidebarService.getDefaultRightSidebarHeaderTitleFn(this.config.entityName)
                })
                .subscribe(shouldReload => {
                    if (shouldReload) {
                        this.listView?.refresh();
                    }
                });
        });
    }
}
