<div class="flex flex-col gap-4">
    <arc-input labelKey="Newsletter.EditFields.Subject" [control]="emailMessageFormGroup.controls['title']"></arc-input>
    <arc-markdown-editor styleClass="mde-mail" [formControl]="emailMessageFormGroup.controls['text'] | formControl"> </arc-markdown-editor>

    <div *ngIf="isTextWithButtonEmailMessage" class="flex flex-col gap-4">
        <arc-input labelKey="Newsletter.EditFields.ButtonText" [control]="emailMessageFormGroup.controls['buttonText']"></arc-input>
        <arc-input labelKey="Newsletter.EditFields.ButtonUrl" [control]="emailMessageFormGroup.controls['buttonUrl']"></arc-input>
        <arc-markdown-editor styleClass="mde-mail" [formControl]="emailMessageFormGroup.controls['textBelowButton'] | formControl"> </arc-markdown-editor>
    </div>
</div>
