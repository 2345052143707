import { Component, ElementRef, EventEmitter, HostBinding, Output, ViewChild, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, debounceTime, switchMap, tap, distinctUntilChanged } from 'rxjs';

import { GlobalSearchResultResponseModel } from '../../../../../app/models/responses/global-search-result-response.model';
import { GlobalSearchStore } from '../../../../../app/services/stores/global-search.store';
import { DictionaryType } from '../../../../../core/models/types/dictionary.type';
import { HelpService } from '../../../../../core/services/help.service';

@Component({
    selector: 'arc-global-search',
    templateUrl: './global-search.component.html',
    styleUrls: ['./global-search.component.scss']
})
export class GlobalSearchComponent {
    static readonly searchDebounceTimeMs = 250;

    @HostBinding('class') classList = 'grow flex px-0 ml-4 justify-start';

    @ViewChild('searchbarContainer') searchbarContainer!: ElementRef;
    @ViewChild('searchbar') searchbar!: ElementRef;

    @Output() readonly searchbarCollapsed = new EventEmitter<boolean>();

    isLoading = false;
    searchString = '';
    searchSubject = new Subject<string>();
    shouldShowResults = false;
    results: GlobalSearchResultResponseModel[] = [];
    isSearchbarCollapsed = true;
    readonly resultTypeIconMap: DictionaryType<string> = {
        'License': 'store',
        'HelpArticle': 'help',
        'Ticket': 'chat_bubble'
    };

    private readonly router = inject(Router);
    private readonly globalSearchStore = inject(GlobalSearchStore);
    private readonly helpService = inject(HelpService);

    constructor() {
        this.searchSubject.pipe(
            distinctUntilChanged(),
            tap(searchString => {
                this.isLoading = true;
                this.shouldShowResults = !!searchString;
            }),
            debounceTime(GlobalSearchComponent.searchDebounceTimeMs),
            switchMap(searchString => this.globalSearchStore.search(searchString))
        ).subscribe(result => {
            this.isLoading = false;
            this.results = result.value ?? [];
        });
    }

    handleGlobalSearch(): void {
        this.searchSubject.next(this.searchString);
    }

    closeOverlay(): void {
        this.shouldShowResults = false;
    }

    toggleSearchbarCollapse(): void {
        this.isSearchbarCollapsed = !this.isSearchbarCollapsed;
        this.searchbarCollapsed.emit(this.isSearchbarCollapsed);

        if (!this.isSearchbarCollapsed) {
            this.searchbar.nativeElement.focus();
        }
    }

    goToResult(result: GlobalSearchResultResponseModel): void {
        let url = '';

        switch (result.type) {
            case 'License':
                url = `user-licenses/licenses?Id=${result.id}`;
                break;
            case 'HelpArticle':
                url = `support/help?Id=${result.id}`;
                break;
            case 'Ticket':
                url = `support/tickets?Id=${result.id}`;
                break;
            default:
                url = result.id;
                break;
        }

        this.reset();
        this.router.navigateByUrl(url).then();
    }

    private reset(): void {
        this.shouldShowResults = false;
        this.searchString = '';
        this.isSearchbarCollapsed = true;

        this.searchbarCollapsed.emit(this.isSearchbarCollapsed);
    }
}
