import { Component, inject } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';

import { OptionalType } from '../../../../core/models/types/optional.type';
import { ArcFormControl } from '../../../../core/utils/arc-form-control';
import { ImportService } from '../../../../core/services/import.service';
import { ImportStepBase } from '../models/import-step-base';
import { ImportEntityColumnModel } from '../../../../app/models/import-entity-column.model';

@Component({
    selector: 'arc-step1-upload-file',
    templateUrl: './step1-upload-file.component.html',
    styleUrls: ['./step1-upload-file.component.scss']
})
export class Step1UploadFileComponent extends ImportStepBase {
    form = new FormGroup({
        file: new ArcFormControl<OptionalType<File>>(undefined, Validators.required)
    });

    entityName = '';

    requiredColumns: ImportEntityColumnModel[] = [];
    override changes = this.form.valueChanges;

    private readonly importService = inject(ImportService);

    init(): void {
        this.entityName = this.importService.entityType;
        this.requiredColumns = this.importService.entityImportColumns.filter(c => c.isRequired);
    }

    allowNext(): boolean {
        this.form.updateValueAndValidity({ emitEvent: false });
        return this.form.valid;
    }

    allowPrevious = (): boolean => false;
    allowCancel = (): boolean => true;

    next(): Observable<any> {
        return this.uploadFile();
    }

    private uploadFile(): Observable<boolean> {
        return this.importService.uploadFile(this.form.value.file!);
    }
}
