export enum PaymentTypesEnum {
    Invoice = 0,
    Card = 1
}

export class PaymentTypesEnumExtensions {
    static getColor(value: PaymentTypesEnum): string {
        switch (value) {
            case PaymentTypesEnum.Invoice:
                return 'bg-warn-light text-on-warn-light';
            case PaymentTypesEnum.Card:
                return 'bg-element-selected text-on-app';
        }
    }
}
