<form [formGroup]="formGroup" class="flex flex-col gap-4">
    <arc-input labelKey="User.EditFields.Email" [control]="formGroup.controls.email"></arc-input>
    <arc-input labelKey="User.EditFields.Company" [control]="formGroup.controls.company"></arc-input>
    <div class="flex gap-2">
        <arc-input labelKey="User.EditFields.FirstName" [control]="formGroup.controls.firstName"></arc-input>
        <arc-input labelKey="User.EditFields.LastName" [control]="formGroup.controls.lastName"></arc-input>
    </div>
    <arc-select labelKey="User.EditFields.LanguageCode" [options]="allowedLanguages" [control]="formGroup.controls.languageCode" class="w-1/3"></arc-select>
    <arc-checkbox labelKey="User.EditFields.SetPassword" [control]="setPasswordControl"></arc-checkbox>
    <div *ngIf="setPasswordControl.value" class="flex flex-col gap-4">
        <arc-input type="password" labelKey="User.EditFields.Password" [control]="formGroup.controls.password"></arc-input>
        <arc-input type="password" labelKey="User.EditFields.ConfirmPassword" [control]="formGroup.controls.confirmPassword"></arc-input>
    </div>
    <arc-general-data-select
        [generalDataType]="GeneralDataTypeEnum.UserRoles"
        [formControl]="formGroup.controls.roles"
        label="User.EditFields.UserRole">
    </arc-general-data-select>
    @if(!isReseller()) {
        <arc-general-data-select
            [generalDataType]="GeneralDataTypeEnum.Resellers"
            [formControl]="formGroup.controls.resellerId"
            label="User.EditFields.Reseller">
        </arc-general-data-select>
    }
    <arc-address-picker class="col-span-12"
        [streetControl]="formGroup.controls.street"
        [streetNumberControl]="formGroup.controls.streetNumber"
        [zipControl]="formGroup.controls.zip"
        [cityControl]="formGroup.controls.city"
        [countryIsoCodeControl]="formGroup.controls.countryIsoCode">
    </arc-address-picker>
    <arc-general-data-select [generalDataType]="GeneralDataTypeEnum.Currencies"
        [formControl]="formGroup.controls.currencyIsoCode" label="User.EditFields.CurrencyIsoCode" class="w-1/3"></arc-general-data-select>
    <arc-input labelKey="User.EditFields.InvoiceRecipientEmails" [control]="formGroup.controls.invoiceRecipientEmails"></arc-input>
    @if(isAdmin()){
        <arc-checkbox labelKey="User.EditFields.IsReseller" [control]="formGroup.controls.isReseller"></arc-checkbox>
    }
</form>
