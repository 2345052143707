import { AfterViewInit, Component, ElementRef, HostBinding, Input, OnDestroy, ViewChild, forwardRef, inject } from '@angular/core';
import * as EasyMDE from 'easymde';
import { HttpParams } from '@angular/common/http';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';

import { environment } from '../../environments/environment';
import { Tools } from '../../core/utils/tools';
import { SecurityStorage } from '../../core/services/storages/security.storage';

@Component({
    selector: 'arc-markdown-editor',
    templateUrl: './markdown-editor.component.html',
    styleUrl: './markdown-editor.component.scss',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MarkdownEditorComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => MarkdownEditorComponent),
            multi: true
        }
    ]
})
export class MarkdownEditorComponent implements AfterViewInit, OnDestroy, ControlValueAccessor, Validator {
    @ViewChild('arcMarkdownEditor') markdownEditor: ElementRef | undefined;
    @Input() @HostBinding('class') styleClass = 'mde-default';
    easyMDE: EasyMDE | undefined;
    text = '';
    isTouched = false;

    control?: AbstractControl;
    private securityStorage = inject(SecurityStorage);

    onTouched: () => void = () => {};
    onChange: (value?: string) => void = () => {};

    ngAfterViewInit(): void {
        const userInfo = this.securityStorage.getUserInfo();
        const imageEndpointParams = new HttpParams().set('token', userInfo?.token || '');
        this.easyMDE = new EasyMDE({
            element: this.markdownEditor?.nativeElement,
            uploadImage: true,
            imagePathAbsolute: true,
            imageMaxSize: 1024 * 1024 * 10,
            imageUploadEndpoint: `${Tools.Url.getUrl('blobs', environment.baseUrl)}?${imageEndpointParams.toString()}`
        });

        if (!!this.text) {
            this.easyMDE.value(this.text);
        }

        this.easyMDE.codemirror.on('change', () => {
            if (!this.isTouched) {
                this.isTouched = true;
                this.onTouched();
            }
            this.onChange(this.easyMDE?.value());
        });
    }

    ngOnDestroy(): void {
        this.easyMDE?.cleanup();
    }

    writeValue(value: string): void {
        this.text = value ?? '';
        this.easyMDE?.value(this.text);
    }
    registerOnChange(fn: (value?: string) => void): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    validate(control: AbstractControl): ValidationErrors | null {
        this.control = control;
        // eslint-disable-next-line no-null/no-null
        return null;
    }
}
