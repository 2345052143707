import { BaseRequestModel } from '../../../core/abstractions/base-request.model';

export class UploadFileRequestModel extends BaseRequestModel {
    blob!: string;
    originalFileExtension!: string;

    constructor(init?: Partial<UploadFileRequestModel>) {
        super();
        Object.assign(this, init);
    }
}
