import { Injectable, inject } from '@angular/core';
import Swal, { SweetAlertOptions, SweetAlertIcon } from 'sweetalert2';
import { HashMap } from '@ngneat/transloco';

import { TranslationService } from './translation.service';

@Injectable({
    providedIn: 'root'
})
export class ToasterService {
    private readonly translationService = inject(TranslationService);

    /**
     * Displays a toast message of type success.
     * @param message Message to be displayed to the user.
     * @param autoDismissTime Time in ms after which the toast disappears automatically.
     * @param isTranslated Whether the message is already translated.
     * @param closeOnClick Whether the message should be dismissed on click.
     * @param messageParams The translation parameters to be used.
     */
    showSuccess(message: string, autoDismissTime = 3000, isTranslated = false, closeOnClick = true, messageParams?: HashMap): void {
        const opt = this.generateOptions(message, isTranslated, 'success', autoDismissTime, closeOnClick, '#09BC8A', messageParams);
        Swal.fire(opt).then();
    }

    /**
     * Displays a toast message of type info.
     * @param message Message to be displayed to the user.
     * @param autoDismissTime Time in ms after which the toast disappears automatically.
     * @param isTranslated Whether the message is already translated.
     * @param closeOnClick Whether the message should be dismissed on click.
     * @param messageParams The translation parameters to be used.
     */
    showInfo(message: string, autoDismissTime = 3000, isTranslated = false, closeOnClick = true, messageParams?: HashMap): void {
        const opt = this.generateOptions(message, isTranslated, 'info', autoDismissTime, closeOnClick, '#84ACCE', messageParams);
        Swal.fire(opt).then();
    }

    /**
     * Displays a toast message of type warning.
     * @param message Message to be displayed to the user.
     * @param autoDismissTime Time in ms after which the toast disappears automatically.
     * @param isTranslated Whether the message is already translated.
     * @param closeOnClick Whether the message should be dismissed on click.
     * @param messageParams The translation parameters to be used.
     */
    showWarning(message: string, autoDismissTime = 3000, isTranslated = false, closeOnClick = true, messageParams?: HashMap): void {
        const opt = this.generateOptions(message, isTranslated, 'warning', autoDismissTime, closeOnClick, '#E2AA3C', messageParams);
        Swal.fire(opt).then();
    }

    /**
     * Displays a toast message of type error.
     * @param message Message to be displayed to the user.
     * @param autoDismissTime Time in ms after which the toast disappears automatically.
     * @param isTranslated Whether the message is already translated.
     * @param closeOnClick Whether the message should be dismissed on click.
     * @param messageParams The translation parameters to be used.
     */
    showError(message: string, autoDismissTime = 3000, isTranslated = false, closeOnClick = true, messageParams?: HashMap): void {
        const opt = this.generateOptions(message, isTranslated, 'error', autoDismissTime, closeOnClick, '#EF6F6C', messageParams);
        Swal.fire(opt).then();
    }

    private generateOptions(
        message: string,
        isTranslated: boolean,
        icon?: SweetAlertIcon,
        autoDismissTime?: number,
        closeOnClick?: boolean,
        iconColor?: string,
        messageParams?: HashMap
    ): SweetAlertOptions {
        return {
            toast: true,
            showConfirmButton: false,
            position: 'top-end',
            timer: autoDismissTime,
            title: isTranslated ? message : this.translationService.getText(message, messageParams),
            icon,
            iconColor,
            showClass: {
                popup: 'swal2-show'
            },
            didOpen: toast => {
                if (closeOnClick) {
                    toast.addEventListener('click', () => Swal.close());
                }
            }
        };
    }
}
