import { Component } from '@angular/core';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { BlogArticleListModel } from '../../../../../models/responses/blog-article-list.model';
import { BlogArticleModel } from '../../../../../models/responses/blog-article.model';

@Component({
    selector: 'arc-blog-description',
    templateUrl: './blog-description.component.html',
    styleUrl: './blog-description.component.scss'
})
export class BlogDescriptionComponent extends BaseDetailWidget<BlogArticleListModel, BlogArticleModel> {}
