import { HasRoleGuard } from '../../core/services/guards/has-role.guard';
import { UserRoles } from '../models/enums/user-roles.enum';
import { BenefitsComponent } from '../views/licenses/benefits/benefits.component';
import { InvoicesComponent } from '../views/licenses/invoices/invoices.component';
import { LicenseHardwareOrdersComponent } from '../views/licenses/license-hardware-orders/license-hardware-orders.component';
import { LicenseModuleBundlesComponent } from '../views/licenses/license-module-bundles/license-module-bundles.component';
import { LicensesComponent } from '../views/licenses/licenses/licenses.component';
import { TransactionsComponent } from '../views/licenses/transactions/transactions.component';

export const licensesRoutes = [
    {
        path: 'user-licenses',
        data: {
            showInMainMenu: true,
            mainMenuIndex: 0,
            subMenu: 'licenses',
            title: 'Licenses.Licenses',
            requiredRole: UserRoles.Licenses | UserRoles.Transactions | UserRoles.Invoices | UserRoles.Benefits
        },
        children: [
            {
                path: 'licenses',
                component: LicensesComponent,
                canActivate: [HasRoleGuard],
                data: {
                    subMenu: 'licenses',
                    subMenuIndex: 0,
                    title: 'Licenses.Licenses',
                    requiredRole: UserRoles.Licenses,
                    startingQueryParams: { IsActive: 'true' }
                }
            },
            {
                path: 'licenses',
                component: LicensesComponent,
                canActivate: [HasRoleGuard],
                data: {
                    subMenu: 'licenses',
                    subMenuIndex: 1,
                    title: 'Licenses.Demos',
                    requiredRole: UserRoles.Licenses,
                    startingQueryParams: { IsDemo: 'true' }
                }
            },
            {
                path: 'license-bundles',
                component: LicenseModuleBundlesComponent,
                canActivate: [HasRoleGuard],
                data: {
                    subMenu: 'licenses',
                    subMenuIndex: 2,
                    title: 'Licenses.LicenseBundles',
                    requiredRole: UserRoles.Transactions,
                    startingQueryParams: { IsActive: 'true' }
                }
            },
            {
                path: 'hardware-orders',
                component: LicenseHardwareOrdersComponent,
                canActivate: [HasRoleGuard],
                data: {
                    subMenu: 'licenses',
                    subMenuIndex: 3,
                    title: 'Licenses.HardwareOrders',
                    requiredRole: UserRoles.Transactions
                }
            },
            {
                path: 'transactions',
                component: TransactionsComponent,
                data: {
                    subMenu: 'licenses',
                    subMenuIndex: 4,
                    title: 'Licenses.Transactions',
                    requiredRole: UserRoles.Transactions
                }
            },
            {
                path: 'invoices',
                component: InvoicesComponent,
                canActivate: [HasRoleGuard],
                data: {
                    subMenu: 'licenses',
                    subMenuIndex: 5,
                    title: 'Licenses.Invoices',
                    requiredRole: UserRoles.Invoices,
                    startingQueryParams: { InvoiceShowPaid: 0 }
                }
            },
            {
                path: 'benefits',
                component: BenefitsComponent,
                canActivate: [HasRoleGuard],
                data: {
                    subMenu: 'licenses',
                    subMenuIndex: 6,
                    title: 'Licenses.Benefits',
                    requiredRole: UserRoles.Benefits
                }
            }
        ]
    }
];
