import { animate, state, style, transition, trigger } from '@angular/animations';

export const fabAnimations = [
    trigger('fabToggler', [
        state('inactive', style({
            transform: 'rotate(0deg)'
        })),
        state('active', style({
            transform: 'rotate(90deg)'
        })),
        transition('* <=> *', animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ]),
    trigger('fabStagger', [
        state('inactive', style({
            opacity: 0,
            transform: 'translateY(10px)',
            visibility: 'hidden',
            height: 0
        })),
        state('active', style({
            opacity: 1,
            transform: 'none',
            visibility: 'visible',
            height: 'unset'
        })),
        transition('active <=> inactive', animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
];
