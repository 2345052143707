<form [formGroup]="formGroup" class="flex flex-col gap-4">
    <arc-general-data-select
        [generalDataType]="GeneralDataTypeEnum.Products"
        [formControl]="formGroup.controls.productId"
        label="Benefits.Edit.BaseData.Product"></arc-general-data-select>

    <arc-input labelKey="Benefits.Edit.BaseData.Title" [control]="formGroup.controls.title"></arc-input>
    <arc-datepicker [control]="formGroup.controls.validFrom" labelKey="Benefits.Edit.BaseData.ValidFrom"></arc-datepicker>
    <arc-datepicker [control]="formGroup.controls.validThru" labelKey="Benefits.Edit.BaseData.ValidTo"></arc-datepicker>
    <arc-button-toggle-group [control]="formGroup.controls.paymentType" [items]="paymentTypes"></arc-button-toggle-group>
    <arc-button-toggle-group [control]="formGroup.controls.paymentPeriod" [items]="paymentPeriods"></arc-button-toggle-group>
    <arc-button-toggle-group [control]="formGroup.controls.discountType" [items]="discountTypes"></arc-button-toggle-group>

    @if(formGroup.value.discountType === BenefitDiscountTypesEnum.Value){
        <arc-currency-input label="Benefits.Edit.BaseData.DiscountValue" [formControl]="formGroup.controls.discountValue"></arc-currency-input>
    } @else {
        <arc-number-input label="Benefits.Edit.BaseData.DiscountPercent" [formControl]="formGroup.controls.discountPercent" [decimalPlaces]="2"></arc-number-input>
    }

    <arc-checkbox labelKey="Benefits.Edit.BaseData.NewLicensesOnly" [control]="formGroup.controls.newLicensesOnly"></arc-checkbox>
    <arc-checkbox
        labelKey="Benefits.Edit.BaseData.BenefitOnRecurrentFee"
        [control]="formGroup.controls.benefitOnRecurrentFee"></arc-checkbox>
    <arc-input labelKey="Benefits.Edit.BaseData.Code" [control]="formGroup.controls.code"></arc-input>

    <arc-quick-search
        [config]="ComplexDataTypesEnum.User | arcQuickSearchConfig"
        [formControl]="formGroup.controls.userId"
        class="col-span-2"
        label="Benefits.Edit.BaseData.User">
    </arc-quick-search>
    <arc-quick-search
        [config]="ComplexDataTypesEnum.Reseller | arcQuickSearchConfig"
        [formControl]="formGroup.controls.resellerId"
        class="col-span-2"
        label="Benefits.Edit.BaseData.Reseller">
    </arc-quick-search>
    <arc-quick-search
        [config]="ComplexDataTypesEnum.License | arcQuickSearchConfig"
        [formControl]="formGroup.controls.licenseId"
        class="col-span-2"
        label="Benefits.Edit.BaseData.License">
    </arc-quick-search>
    <arc-general-data-select
        [generalDataType]="GeneralDataTypeEnum.Modules"
        [formControl]="formGroup.controls.moduleBundleId"
        label="Benefits.Edit.BaseData.ModuleBundle"></arc-general-data-select>
</form>
