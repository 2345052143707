import { Component } from '@angular/core';

import { BaseCustomColumnComponent } from '../../../../../../components/dynamic-table/column-components/custom-column/base-custom-column.component';
import { LicenseListModel } from '../../../../../models/responses/license-list.model';
import { LicenseStatusEnum } from '../../../../../models/enums/license-status.enum';

@Component({
    selector: 'arc-license-valid-thru',
    templateUrl: './license-valid-thru.component.html',
    styleUrl: './license-valid-thru.component.scss'
})
export class LicenseValidThruComponent extends BaseCustomColumnComponent<LicenseListModel> {
    LicenseStatusEnum = LicenseStatusEnum;
    get colorClasses(): string[] {
        if(this.item.status === LicenseStatusEnum.Draft || this.item.isDemo){
            return ['bg-element-disabled', 'text-on-element-disabled'];
        } else if (this.item.status === LicenseStatusEnum.Cancelled) {
            return ['bg-error', 'text-on-error'];
        } else if (this.item.status === LicenseStatusEnum.Expired) {
            return ['bg-warn', 'text-on-warn-light'];
        } else if (this.item.status === LicenseStatusEnum.Ok) {
            return ['bg-success', 'text-on-success'];
        } else if (this.item.status === LicenseStatusEnum.ExpiredAndLocked) {
            return ['bg-error', 'text-on-error'];
        }

        return [];
    }
}
