import { BaseApiModel } from '../../core/abstractions/base-api.model';

export class ImportExecutionColumnModel extends BaseApiModel {
    constructor(
        public importEntityColumnId: string,
        public fileColumnName: string,
        public isIdentifier = false
    ) {
        super();
    }
}
