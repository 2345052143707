import { Component } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { HelpTopicModel } from '../../../../../models/help-topic.model';
import { HelpTopicEditModel } from '../../../../../models/requests/help-topic-edit.model';
import { UserRoles } from '../../../../../models/enums/user-roles.enum';
import { SelectOptionModel } from '../../../../../../core/models/select-option.model';

@Component({
    selector: 'arc-help-topic-edit',
    templateUrl: './help-topic-edit.component.html',
    styleUrl: './help-topic-edit.component.scss'
})
export class HelpTopicEditComponent extends BaseEditSidebarItemComponent<HelpTopicModel, HelpTopicEditModel> {
    override formGroup = new FormGroup({
        title_de: new ArcFormControl('', Validators.required),
        title_fr: new ArcFormControl<OptionalType<string>>(undefined),
        title_en: new ArcFormControl<OptionalType<string>>(undefined),
        title_it: new ArcFormControl<OptionalType<string>>(undefined),
        icon: new ArcFormControl<OptionalType<string>>(undefined),
        requiredRole: new ArcFormControl<OptionalType<UserRoles>>(undefined)
    });

    readonly roles = this.getUserRoles();

    override onItemSet(): void {
        this.formGroup.patchValue(this.item);
    }

    override prepareSaveModel(itemCopy: HelpTopicEditModel): HelpTopicEditModel {
        return { ...itemCopy, ...this.formGroup.value };
    }

    getUserRoles(): SelectOptionModel<UserRoles | undefined>[] {
        const userRoles = Object.keys(UserRoles)
            .filter(key => isNaN(Number(key)))
            .map(key => ({ label: key, value: UserRoles[key as keyof typeof UserRoles] }));

        return [{ label: '-', value: undefined }, ...userRoles];
    }
}
