import { BaseEntityModel } from '../../../core/abstractions/base-entity.model';

export class NewUserModel extends BaseEntityModel {
    firstName?: string;
    lastName?: string;
    company?: string;
    email!: string;
    constructor(init?: Partial<NewUserModel>) {
        super();
        Object.assign(this, init);
    }
}
