<h1 mat-dialog-title>{{ dialogTitleKey | transloco }}</h1>

<mat-dialog-content class="grow !max-h-[unset] overflow-y-auto">
    <arc-search-filter [store]="store"></arc-search-filter>

    <div class="relative min-h-[128px]">
        <table mat-table [dataSource]="data" matSort (matSortChange)="handleSort($event)">
            <!-- selection -->
            <ng-container matColumnDef="selection">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let entry">
                    <mat-checkbox [checked]="entry.isSelected" (change)="handleSelectionChanged(entry)"></mat-checkbox>
                </td>
            </ng-container>

            <!-- columns -->
            <ng-container *ngFor="let column of columnConfig" [matColumnDef]="column.propertyName!">
                <th mat-header-cell *matHeaderCellDef [mat-sort-header]="column.propertyName!">
                    {{ column.columnTitleKey| transloco }}
                </th>
                <td mat-cell *matCellDef="let entry">
                    <ng-container arcDynamicColumn [columnModel]="column" [item]="entry.value"></ng-container>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let entry; columns: displayedColumns;"
                [ngClass]="{ 'cursor-pointer hover:!bg-element-hover': !_dialogData.isMultiSelect }"
                (click)="select(entry)"></tr>
        </table>

        <div *ngIf="isLoading" class="absolute inset-0 bg-overlay grid place-items-center">
            <mat-spinner [diameter]="64"></mat-spinner>
        </div>
    </div>

    <mat-paginator [length]="totalRecords" [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize"
        (page)="handlePageEvent($event)"></mat-paginator>

</mat-dialog-content>

<mat-dialog-actions>
    <button mat-stroked-button [mat-dialog-close]="undefined">{{ 'General.Actions.Cancel' | transloco }}</button>
    <button *ngIf="_dialogData.isMultiSelect" mat-flat-button color="accent" [disabled]="selection.length === 0" [mat-dialog-close]="selection">
        {{ 'Components.DataSelectionDialog.AddX' | transloco:{ amount: selection.length } }}
    </button>
</mat-dialog-actions>