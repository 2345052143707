<div class="z-10 fixed inset-0" *ngIf="fabTogglerState === 'active'" (click)="toggle()"></div>

<div class="z-20 fixed bottom-8 right-8 smd:bottom-2 smd:right-2 flex flex-col-reverse items-center gap-2">
    <div class="flex flex-col-reverse gap-2">
        <button *ngFor="let btn of visibleButtons; let i = index" mat-fab [attr.color]="i === 0 ? 'accent' : ''"
            [ngClass]="{ '!bg-fab-more !text-on-fab-more': i > 0 }" [disabled]="!btn.isEnabled || btn.isLoading"
            [matTooltip]="btn.text | transloco" matTooltipPosition="left" (click)="onClick($event, btn)">
            <i *ngIf="!btn.isLoading" class="material-icons">{{ btn.icon }}</i>
            <mat-spinner *ngIf="btn.isLoading" diameter="22"></mat-spinner>
        </button>
    </div>
    <ng-container *ngIf="menuButtons.length > 0">
        <button mat-fab class="fab-main" class="!bg-fab-more !text-on-fab-more" (click)="toggle()">
            <i class="material-icons" [@fabToggler]="fabTogglerState">more_vert</i>
        </button>
        <div [@fabStagger]="fabTogglerState" class="flex flex-col-reverse gap-4 mb-2">
            <button *ngFor="let btn of menuButtons" mat-mini-fab class="!bg-fab-more !text-on-fab-more"
                [matTooltip]="btn.text | transloco" matTooltipPosition="left"
                [disabled]="!btn.isEnabled || btn.isLoading" (click)="onClick($event, btn)">
                <i *ngIf="!btn.isLoading" class="material-icons">{{ btn.icon }}</i>
                <mat-spinner *ngIf="btn.isLoading" diameter="22"></mat-spinner>
            </button>
        </div>
    </ng-container>
</div>