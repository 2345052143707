import { Component } from '@angular/core';

import { BaseCustomColumnComponent } from '../../../../../../components/dynamic-table/column-components/custom-column/base-custom-column.component';
import { FeatureListModel } from '../../../../../models/responses/feature-list.model';
import { FeatureStatusEnum } from '../../../../../models/enums/feature-status.enum';

@Component({
    selector: 'arc-feature-status',
    templateUrl: './feature-status.component.html',
    styleUrl: './feature-status.component.scss'
})
export class FeatureStatusComponent extends BaseCustomColumnComponent<FeatureListModel> {
    get colorClasses(): string[] {
        if (this.item.status === FeatureStatusEnum.Idea) {
            return ['bg-element-disabled', 'text-on-element-disabled'];
        } else if (this.item.status === FeatureStatusEnum.Accepted) {
            return ['bg-warn', 'text-on-warn'];
        } else if (this.item.status === FeatureStatusEnum.InProgress) {
            return ['bg-info', 'text-on-info'];
        } else if (this.item.status === FeatureStatusEnum.Done) {
            return ['bg-success', 'text-on-success'];
        } else if (this.item.status === FeatureStatusEnum.Rejected) {
            return ['bg-error', 'text-on-error'];
        }

        return [];
    }

    get status(): string {
        return this.item.status !== undefined ? FeatureStatusEnum[this.item.status] : '';
    }
}
