import { Directive, HostBinding, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { BaseColumnModel } from '../models/column-types/base-column.model';

@Directive()
export abstract class BaseColumnComponent<T extends object> implements OnInit {
    @HostBinding('class') classes = 'w-full';

    @Input() columnModel!: BaseColumnModel;
    @Input() item!: T;
    @Input() control?: AbstractControl;

    ngOnInit(): void {
        if (this.columnModel.isDisabled && !!this.control) {
            this.control.disable();
        }

        // editable columns should always be full height
        if (this.columnModel.isEditable && !!this.control) {
            this.classes = 'w-full h-full';
        }
    }

    getItemValue(propertyName?: string): any {
        return !!this.columnModel.customFormatter
            ? this.columnModel.customFormatter(this.item, this.getValue(propertyName), propertyName)
            : this.getValue(propertyName);
    }

    isBold(propertyName?: string): boolean {
        return !!this.columnModel.isBold
            && this.columnModel.isBold(this.item, this.getValue(propertyName), propertyName);
    }

    private getValue(propertyName?: string): any {
        return !!propertyName && propertyName in this.item ? this.item[propertyName as keyof typeof this.item] : '';
    }
}
