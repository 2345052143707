<mat-form-field [floatLabel]="hasFloatingLabel ? 'always' : 'auto'" class="w-full" [color]="color"
    [arcErrorTooltip]="internalControl" [subscriptSizing]="hasFixedSubscript ? 'fixed' : 'dynamic'">
    <mat-label *ngIf="!!label">{{ isLabelTranslated ? label : (label | transloco) }}</mat-label>
    <input matInput [formControl]="internalControl" (blur)="onBlur()" type="text"
        [placeholder]="isPlaceholderTranslated ? placeholder : (placeholder | transloco)"
        [matAutocomplete]="autocomplete" (input)="input()" />
    <mat-autocomplete autoActiveFirstOption #autocomplete="matAutocomplete"
        [displayWith]="config.displayFn.bind(this)" (optionSelected)="onOptionSelected($event)">
        <mat-option *ngFor="let option of options" [value]="option">
            {{ config.displayFn(option) }}
        </mat-option>
    </mat-autocomplete>
    <div matSuffix class="flex gap-2">
        <button type="button" mat-icon-button
            *ngIf="!config.shouldHideDataSelectDialogButton && !!config.dataSelectionDialogConfig && !isLoading && !isDisabled"
            (click)="openDataSelectionDialog($event)">
            <mat-icon>search</mat-icon>
        </button>
        <div *ngIf="isLoading" class="w-[40px] h-[40px] grid place-items-center">
            <mat-spinner diameter="22"></mat-spinner>
        </div>
    </div>
</mat-form-field>