import { Injectable, OnDestroy, inject } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { Observable, Subject, BehaviorSubject, map } from 'rxjs';

import { DashboardConfigurationModel } from '../models/dashboard-configuration.model';
import { UsersStore } from '../../app/services/stores/users.store';
import { ListViewSettingsModel } from '../models/list-view-settings.model';
import { TableSettingsModel } from '../models/table-settings.model';

@Injectable({
    providedIn: 'root'
})
export class LayoutService implements OnDestroy {
    isMobile = false;
    isMobileSubject = new BehaviorSubject<boolean>(false);
    anyScrollSubject = new Subject<void>();

    private readonly mobileQuery: MediaQueryList;
    private readonly mobileQueryListener: (query: MediaQueryListEvent) => void;
    private readonly mediaMatcher = inject(MediaMatcher);
    private readonly usersStore = inject(UsersStore);

    private themeChanged = new Subject<boolean>();

    constructor() {
        this.mobileQuery = this.mediaMatcher.matchMedia('(max-width: 639px)');
        this.mobileQueryListener = queryEvent => {
            this.isMobile = queryEvent.matches;
            this.isMobileSubject.next(this.isMobile);
        };
        this.isMobile = this.mobileQuery.matches;

        this.mobileQuery.addEventListener('change', this.mobileQueryListener);
        this.isMobileSubject.next(this.isMobile);

        // `true` is needed so that any scroll event fires this event, not only scroll on main window
        window.addEventListener('scroll', this.onAnyScroll.bind(this), true);
    }

    ngOnDestroy(): void {
        this.mobileQuery.removeEventListener('change', this.mobileQueryListener);
        window.removeEventListener('scroll', this.onAnyScroll.bind(this), true);
    }

    getThemeChangedObservable(): Observable<boolean> {
        return this.themeChanged.asObservable();
    }

    setDarkMode(isDarkMode: boolean): void {
        this.themeChanged.next(isDarkMode);
    }

    getDashboardConfiguration(): Observable<DashboardConfigurationModel> {
        return this.usersStore.getDashboardSettings().pipe(map(result => result.value ?? new DashboardConfigurationModel()));
    }
    saveDashboardConfiguration(dashboardConfig: DashboardConfigurationModel): void {
        this.usersStore.getDashboardSettings().subscribe(r => {
            const settings = new DashboardConfigurationModel({ ...r.value, ...dashboardConfig });
            this.usersStore.saveDashboardSettings(settings).subscribe();
        });
    }

    getTableSettings(key: string): Observable<TableSettingsModel> {
        return this.usersStore.getTableSettings(key).pipe(
            map(result => result.value ?? new TableSettingsModel())
        );
    }

    saveTableSettings(key: string, tableSettings: TableSettingsModel): void {
        this.usersStore.getTableSettings(key).subscribe(r => {
            const settings = new TableSettingsModel({ ...r.value, ...tableSettings });
            this.usersStore.saveTableSettings(settings, key).subscribe();
        });
    }

    getListViewSettings(key: string): Observable<ListViewSettingsModel> {
        return this.usersStore.getListViewSettings(key).pipe(
            map(result => result.value ?? new ListViewSettingsModel())
        );
    }

    saveListViewSettings(key: string, listViewSettings: ListViewSettingsModel): void {
        this.usersStore.getListViewSettings(key).subscribe(r => {
            const settings = new ListViewSettingsModel({ ...r.value, ...listViewSettings });
            this.usersStore.saveListViewSettings(settings, key).subscribe();
        });
    }

    private onAnyScroll(): void {
        this.anyScrollSubject.next();
    }
}
