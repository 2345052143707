import { Component, Input, OnInit } from '@angular/core';

import { BaseControlComponent } from '../base-control/base-control.component';

@Component({
    selector: 'arc-datepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent extends BaseControlComponent implements OnInit {
    @Input() min?: Date;
    @Input() max?: Date;
    @Input() showHint = true;

    dateFormat = '';

    override ngOnInit(): void {
        super.ngOnInit();
        this.dateFormat = this._translationService.current.dateFormat;
    }
}
