<div [formGroup]="formGroup" [arcErrorTooltip]="control">
    <span class="text-sm">{{ label || (labelKey | transloco)}}</span>
    <div class="flex gap-4 items-center">
        <arc-rich-text-editor class="w-full" [maxLength]="maxLength || 0" [placeholder]="placeholder || (placeholderKey | transloco)"
            (valueChange)="onChange($event)" [value]="castedControl.value"></arc-rich-text-editor>
    </div>
    <div class="text-error" *ngIf="isInvalid">
        <arc-form-field-error-control [control]="castedControl"></arc-form-field-error-control>
    </div>
</div>
