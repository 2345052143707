import { Component } from '@angular/core';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { CustomerInteractionListModel } from '../../../../../models/responses/customer-interaction-list.model';
import { CustomerInteractionModel } from '../../../../../models/customer-interaction.model';

@Component({
    selector: 'arc-customer-interactions-note-widget',
    templateUrl: './customer-interactions-note.detail-widget.html',
    styleUrl: './customer-interactions-note.detail-widget.scss'
})
// eslint-disable-next-line max-len
export class CustomerInteractionsNoteDetailWidget extends BaseDetailWidget<CustomerInteractionListModel,CustomerInteractionModel> {}
