import { BaseEntityModel } from '../../../core/abstractions/base-entity.model';
import { UserRoles } from '../enums/user-roles.enum';

export class UserEditModel extends BaseEntityModel {
    city?: string;
    company?: string;
    confirmPassword?: string;
    countryIsoCode?: string;
    currencyIsoCode?: string;
    email!: string;
    firstName?: string;
    invoiceRecipientEmails?: string;
    languageCode!: string;
    lastName!: string;
    password?: string;
    roles!: UserRoles;
    street!: string;
    streetNumber?: string;
    zip?: string;
    isReseller!: boolean;
    resellerId?: number;
    phoneNumber?: string;
    isSuspended!: boolean;

    constructor(init?: Partial<UserEditModel>) {
        super();
        Object.assign(this, init);
    }
}
