import { Component } from '@angular/core';

import { BaseColumnComponent } from '../base-column.component';
import { StringColumnModel } from '../../models/column-types/string-column.model';

@Component({
    selector: 'arc-string-column',
    templateUrl: './string-column.component.html',
    styleUrls: ['./string-column.component.scss']
})
export class StringColumnComponent<T extends object> extends BaseColumnComponent<T> {
    get castedColumnModel(): StringColumnModel {
        return this.columnModel as StringColumnModel;
    }
}
