import { Component, OnInit, inject } from '@angular/core';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { FeedbackListModel } from '../../../../../models/responses/feedback-list.model';
import { FeedbackModel } from '../../../../../models/feedback.model';
import { Tools } from '../../../../../../core/utils/tools';
import { BlobsStore } from '../../../../../services/stores/blobs.store';

@Component({
    selector: 'arc-feetback-text',
    templateUrl: './feedback-text.component.html',
    styleUrl: './feedback-text.component.scss'
})
export class FeedbackTextComponent extends BaseDetailWidget<FeedbackListModel, FeedbackModel> implements OnInit {
    text = '';

    private readonly blobsStore = inject(BlobsStore);

    ngOnInit(): void {
        this.text = this.entityModel.text;
    }

    downloadFile(): void {
        this.blobsStore.downloadBlob(this.listModel.blobId!).subscribe(response => {
            const blob = new Blob(['', response.body], { type: response.body.type });
            Tools.Utils.saveFile(blob, Tools.Utils.getFileNameFromResponse(response));
        });
    }
}
