import { BaseApiModel } from './base-api.model';
import { ApiModelTypesEnum } from '../models/enums/api-model-types.enum';

/**
 * Represents any entity from the server
 */
export abstract class BaseEntityModel extends BaseApiModel {
    override readonly apiModelType = ApiModelTypesEnum.EntityModel;
    id: any = '';

    protected constructor(init?: Partial<BaseEntityModel>) {
        super();
        Object.assign(this, init);
    }
}
