@if (!!contract) {
    <div mat-dialog-title class="flex-col">
        <span class="text-3xl">{{ contract.title }}</span>
        <div class="flex gap-2 w-full mt-8 ml-4">
            <span class="text-xl">{{ 'ContractView.LastUpdated' | transloco }}</span>:
            <span>{{ contract.lastUpdated | arcDate : 'short' }}</span>
        </div>
        <div markdown class="mt-6 ml-8 mb-8" [data]="contract.lastChangesSummary">asddsadsadasdasd asd as das</div>
    </div>
}

<mat-dialog-content class="grow !max-h-[unset] overflow-y-auto">
    <div class="relative min-h-[128px]">
        @if (!isLoading && !!contract) {
            <div markdown class="arc-markdown" [data]="contract.text"></div>
        } @else {
            <div class="absolute inset-0 grid place-items-center h-100">
                <mat-spinner [diameter]="64"></mat-spinner>
            </div>
        }
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-flat-button color="accent" [disabled]="isAcceptingContract" (click)="accept()">
        {{ 'ContractView.Accept' | transloco }}
    </button>
</mat-dialog-actions>
