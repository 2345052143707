export enum TicketStatusEnum {
    Open,
    WaitingOnRequester,
    WaitingOnThirdParty,
    ResolveSuggested,
    Resolved,
    Closed
}

export class TicketStatusEnumExtensions {
    static getColor(value: TicketStatusEnum): string {
        switch (value) {
            case TicketStatusEnum.Open:
                return 'bg-warn-light text-on-warn-light';
            case TicketStatusEnum.WaitingOnRequester:
                return 'bg-element-selected text-on-app';
            case TicketStatusEnum.WaitingOnThirdParty:
                return 'bg-element-selected text-on-app';
            case TicketStatusEnum.ResolveSuggested:
                return 'bg-info text-on-info';
            case TicketStatusEnum.Resolved:
                return 'bg-success-light text-on-success-light';
            case TicketStatusEnum.Closed:
                return 'bg-element-selected text-on-app';
            default:
                return 'bg-element-selected text-on-app';
        }
    }
}
