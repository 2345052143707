<mat-form-field [floatLabel]="hasFloatingLabel ? 'always' : 'auto'" class="w-full" [color]="color"
    [arcErrorTooltip]="controlDe" [subscriptSizing]="hasFixedSubscript ? 'fixed' : 'dynamic'">
    <mat-label *ngIf="label || labelKey">{{ label || (labelKey | transloco)}}</mat-label>
    <input class="mr-[40px]" matInput [id]="tagId" (blur)="onBlur()" (input)="onChange($event)"
        [placeholder]="placeholder || (placeholderKey | transloco)" [formControl]="control"/>
    <span class="!absolute right-0 top-1/2 -translate-y-1/2">
        <arc-no-permission-lock [requiredModule]="requiredModule" [requiredPermission]="requiredPermission"
            [requiredPermissionType]="requiredPermissionType"
            (notAllowed)="handleNotAllowed()"></arc-no-permission-lock>
    </span>
    <button type="button" mat-icon-button matSuffix *ngIf="isAllowed"
        (click)="openTranslationDialog()">
        <mat-icon>translate</mat-icon>
    </button>
</mat-form-field>