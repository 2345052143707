import { Component, OnInit, inject, computed } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { PosReportTemplateEditRequestModel } from '../../../../../models/requests/pos-report-template-edit-request.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { TranslationService } from '../../../../../../core/services/translation.service';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { PosReportTypeEnum } from '../../../../../../core/models/enums/pos-report-type.enum';

@Component({
    selector: 'arc-pos-report-edit',
    templateUrl: './pos-report-edit-base-data.component.html',
    styleUrls: ['./pos-report-edit-base-data.component.scss']
})
export class PosReportTemplateEditBaseDataComponent
    extends BaseEditSidebarItemComponent<PosReportTemplateEditRequestModel>
    implements OnInit {
    GeneralDataTypeEnum = GeneralDataTypeEnum;

    override formGroup = new FormGroup({
        title_de: new ArcFormControl('', Validators.required),
        title_fr: new ArcFormControl('', Validators.required),
        title_en: new ArcFormControl('', Validators.required),
        title_it: new ArcFormControl('', Validators.required),
        description_de: new ArcFormControl('', Validators.required),
        description_fr: new ArcFormControl('', Validators.required),
        description_en: new ArcFormControl('', Validators.required),
        description_it: new ArcFormControl('', Validators.required),
        reportType: new ArcFormControl(PosReportTypeEnum.Report, Validators.required),
        key: new ArcFormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(5)]),
        requiredModuleId: new ArcFormControl(),
        version: new ArcFormControl(1.0, Validators.required),
        reportBlobData: new ArcFormControl<OptionalType<number[]>>(undefined),
        fileControl: new ArcFormControl(),
        fileName: new ArcFormControl()
    });

    reportTypeItems = computed(() => {
        const items = [
            {
                label: this.translationService.getText('PosReportTemplates.PosReportTypeEnum.Report'),
                value: PosReportTypeEnum.Report
            },
            {
                label: this.translationService.getText('PosReportTemplates.PosReportTypeEnum.Receipt'),
                value: PosReportTypeEnum.Receipt
            },
            {
                label: this.translationService.getText('PosReportTemplates.PosReportTypeEnum.PosEndOfDay'),
                value: PosReportTypeEnum.PosEndOfDay
            },
            {
                label: this.translationService.getText('PosReportTemplates.PosReportTypeEnum.UserEndOfDay'),
                value: PosReportTypeEnum.UserEndOfDay
            },
            {
                label: this.translationService.getText('PosReportTemplates.PosReportTypeEnum.Order'),
                value: PosReportTypeEnum.Order
            }
        ];

        return items;
    });

    private readonly translationService = inject(TranslationService);

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.formGroup.controls.fileControl.valueChanges.subscribe(async (file?: File) => {
            this.formGroup.controls.reportBlobData.setValue(!!file ? Array.from(new Uint8Array(await file.arrayBuffer())) : undefined);
            this.formGroup.controls.fileName.setValue(!!file ? file.name : '');
        });
    }

    override onItemSet(): void {
        this.formGroup.patchValue({ ...this.item });

        if (this.isCreate) {
            this.formGroup.controls.reportBlobData.setValidators(Validators.required);
            this.formGroup.controls.fileControl.setValidators(Validators.required);
        }
    }

    override prepareSaveModel(itemCopy: PosReportTemplateEditRequestModel): PosReportTemplateEditRequestModel {
        return new PosReportTemplateEditRequestModel({
            ...itemCopy,
            ...this.formGroup.value
        });
    }
}
