<div class="flex justify-center p-8">
    @if(isLoading){
        <div class="w-full h-full grid place-items-center">
            <mat-spinner [diameter]="32"></mat-spinner>
        </div>
    } @else if(!!helpArticle) {
        <div class="flex flex-col gap-4">
            <h2 class="text-3xl font-bold text-accent">{{helpArticle.title}}</h2>
            <div class="arc-markdown" markdown [innerHTML]="helpArticle.text">
            </div>
        </div>
    } @else {
        <p>{{ 'ContractView.ContractNotFound' | transloco }}</p>
    }
</div>