import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    inject
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { NumberInputComponent } from '../number-input/number-input.component';
import { GeneralDataService } from '../../../core/services/general-data.service';
import { GeneralDataTypeEnum } from '../../../core/models/enums/general-data-type.enum';
import { KeyValueModel } from '../../../core/models/key-value.model';

@Component({
    selector: 'arc-currency-input',
    templateUrl: '../number-input/number-input.component.html',
    styleUrls: ['../number-input/number-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: CurrencyInputComponent
        }
    ]
})
export class CurrencyInputComponent
    extends NumberInputComponent
    implements OnInit, OnChanges {
    @Input() currencyId?: string;
    currencies: KeyValueModel[] = [];

    override readonly decimalPlaces = 2;

    private readonly generalDataService = inject(GeneralDataService);

    constructor() {
        super();
    }

    override ngOnInit(): void {
        super.ngOnInit();
        this.isUnitTranslated = true;

        if (!!this.currencyId) {
            this.generalDataService
                .getGeneralData(GeneralDataTypeEnum.Currencies)
                .subscribe((data) => {
                    this.currencies = data;
                    this.setupUnit();
                });
        }
    }

    override ngOnChanges(changes: SimpleChanges): void {
        super.ngOnChanges(changes);

        if (changes['currencyId']?.currentValue !== this.currencyId) {
            this.setupUnit();
        }
    }
    private setupUnit(): void {
        if (!!this.currencyId) {
            const currency = this.currencies.find((c) => c.key === this.currencyId);
            this.unit = currency?.value || '';
        }
        this.unitText = this.unit ?? '';
    }
}
