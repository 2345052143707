import { BaseEntityModel } from '../../../core/abstractions/base-entity.model';
import { PaymentTypesEnum } from '../enums/payment-types.enum';
import { PaymentPeriodsEnum } from '../enums/payment-periods.enum';
import { LicenseEditUserModel } from './license-edit-user.model';

export class LicenseEditModel extends BaseEntityModel {
    subDomain!: string;
    licenseHolder!: string;
    street!: string;
    streetNumber?: string;
    zip!: string;
    city!: string;
    countryIsoCode!: string;
    productId!: number;
    numberOfPos!: number;
    userId?: number;
    recommendationUserId!: number;
    resellerId?: number;
    paymentType!: PaymentTypesEnum;
    paymentPeriod!: PaymentPeriodsEnum;
    user?: LicenseEditUserModel;
    isDemo!: boolean;
    benefitCode?: string;

    constructor(init?: Partial<LicenseEditModel>) {
        super();
        if (!!init) {
            Object.assign(this, init);
        }
    }
}
