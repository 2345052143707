import { ComponentRef, Directive, Input, OnChanges, OnInit, ViewContainerRef, inject } from '@angular/core';
import { ComponentType } from '@angular/cdk/portal';
import { AbstractControl } from '@angular/forms';


import { BaseColumnModel } from '../../components/dynamic-table/models/column-types/base-column.model';
import { ColumnTypeEnum } from '../../components/dynamic-table/models/enums/column-type.enum';
import { BaseColumnComponent } from '../../components/dynamic-table/column-components/base-column.component';
import { StringColumnComponent } from '../../components/dynamic-table/column-components/string-column/string-column.component';
import { StackedColumnComponent } from '../../components/dynamic-table/column-components/stacked-column/stacked-column.component';
import { IconColumnComponent } from '../../components/dynamic-table/column-components/icon-column/icon-column.component';
import { CheckboxColumnComponent } from '../../components/dynamic-table/column-components/checkbox-column/checkbox-column.component';
import { CurrencyColumnComponent } from '../../components/dynamic-table/column-components/currency-column/currency-column.component';
import { ColoredStackedColumnComponent } from '../../components/dynamic-table/column-components/colored-stacked-column/colored-stacked-column.component';
import { CustomColumnComponent } from '../../components/dynamic-table/column-components/custom-column/custom-column.component';
import { ButtonColumnComponent } from '../../components/dynamic-table/column-components/button-column/button-column.component';
import { ButtonToggleColumnComponent } from '../../components/dynamic-table/column-components/button-toggle-column/button-toggle-column.component';
import { DateColumnComponent } from '../../components/dynamic-table/column-components/date-column/date-column.component';
import {
    GenericStatusColumnComponent
} from '../../components/dynamic-table/column-components/generic-status-column/generic-status-column.component';
import { NumberColumnComponent } from '../../components/dynamic-table/column-components/number-column/number-column.component';
import { GeneralDataColumnComponent } from '../../components/dynamic-table/column-components/general-data-column/general-data-column.component';

@Directive({
    selector: '[arcDynamicColumn]'
})
export class DynamicColumnDirective<T extends object> implements OnInit, OnChanges {
    @Input() columnModel!: BaseColumnModel;
    @Input() item!: T;
    @Input() control?: AbstractControl<any, any>;

    component?: ComponentRef<BaseColumnComponent<T>>;

    private readonly mapping: { [key in ColumnTypeEnum]: ComponentType<BaseColumnComponent<T>> } = {
        [ColumnTypeEnum.Text]: StringColumnComponent,
        [ColumnTypeEnum.Stacked]: StackedColumnComponent,
        [ColumnTypeEnum.Icon]: IconColumnComponent,
        [ColumnTypeEnum.Checkbox]: CheckboxColumnComponent,
        [ColumnTypeEnum.Number]: NumberColumnComponent,
        [ColumnTypeEnum.Currency]: CurrencyColumnComponent,
        [ColumnTypeEnum.ColoredStack]: ColoredStackedColumnComponent,
        [ColumnTypeEnum.Custom]: CustomColumnComponent,
        [ColumnTypeEnum.Button]: ButtonColumnComponent,
        [ColumnTypeEnum.ButtonToggle]: ButtonToggleColumnComponent,
        [ColumnTypeEnum.GeneralData]: GeneralDataColumnComponent,
        [ColumnTypeEnum.Date]: DateColumnComponent,
        [ColumnTypeEnum.GenericStatus]: GenericStatusColumnComponent
    };
    private readonly viewContainerRef = inject(ViewContainerRef);

    ngOnInit(): void {
        const componentType = this.mapping[this.columnModel.columnType];
        if (!componentType) {
            return;
        }
        this.component = this.viewContainerRef.createComponent(componentType);
        this.component.setInput('columnModel', this.columnModel);
        this.component.setInput('item', this.item);
        this.component.setInput('control', this.control);

        this.component.location.nativeElement.classList.add('arc-dynamic-table-cell');
    }

    ngOnChanges(): void {
        this.component?.setInput('columnModel', this.columnModel);
        this.component?.setInput('item', this.item);
        this.component?.setInput('control', this.control);
    }
}
