<h1 mat-dialog-title>{{ data.label || (data.labelKey | transloco)}}</h1>
<mat-dialog-content>
    <div class="flex flex-col gap-4 !mt-4 !mr-2 !ml-2"> <!-- adding a bit margin to accomodate quickFeedback -->
        <arc-input labelKey="General.Languages.German" [control]="formGroup.controls.valueDe"></arc-input>
        <arc-input labelKey="General.Languages.English" [control]="formGroup.controls.valueEn"></arc-input>
        <arc-input labelKey="General.Languages.French" [control]="formGroup.controls.valueFr"></arc-input>
        <arc-input labelKey="General.Languages.Italian" [control]="formGroup.controls.valueIt"></arc-input>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close>{{'General.Actions.Cancel' | transloco}}</button>
    <button mat-flat-button color="accent" (click)="onSave()">{{'General.Actions.Save' | transloco}}</button>
</mat-dialog-actions>