<div class="flex flex-row items-center gap-2">
    <button mat-icon-button (click)="handleDeleteClick()">
        <mat-icon> close </mat-icon>
    </button>
    <span>{{ this.item.lastFeedbackText }}</span>
    @if(this.item.feedbackId){
        <a mat-icon-button routerLink="/support/feedback" [queryParams]="{ Id: this.item.feedbackId }">
            <mat-icon> launch </mat-icon>
        </a>
    }
</div>
