import { Component, inject } from '@angular/core';
import { Observable, of } from 'rxjs';

import { ImportStepBase } from '../models/import-step-base';
import { ImportService } from '../../../../core/services/import.service';
import { ImportExecutionResultResponseModel } from '../../../../app/models/responses/import-execution-result-response.model';

@Component({
    selector: 'arc-step4-result',
    templateUrl: './step4-result.component.html',
    styleUrls: ['./step4-result.component.scss']
})
export class Step4ResultComponent extends ImportStepBase {
    resultData?: ImportExecutionResultResponseModel;

    private readonly importService = inject(ImportService);

    allowNext = (): boolean => false;
    allowPrevious = (): boolean => false;
    allowCancel = (): boolean => false;

    init(): void {
        this.resultData = this.importService.resultData;
    }

    next = (): Observable<any> => of(undefined);
}
