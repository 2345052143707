import { Component, inject, OnInit, signal } from '@angular/core';
import { Router } from '@angular/router';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { LicenseModel } from '../../../../../models/license.model';
import { LicenseListModel } from '../../../../../models/responses/license-list.model';
import { HelpArticleModel } from '../../../../../models/responses/help-article.model';
import { LicenseApiIntegrationModel } from '../../../../../models/license-api-integration.model';
import { LicensesStore } from '../../../../../services/stores/licenses.store';

@Component({
    selector: 'arc-license-assets-widget',
    templateUrl: './license-assets.detail-widget.html',
    styleUrl: './license-assets.detail-widget.scss'
})
export class LicenseAssetsWidgetComponent extends BaseDetailWidget<LicenseListModel, LicenseModel> implements OnInit {
    helpArticles = signal<HelpArticleModel[]>([]);
    apiIntegrations = signal<LicenseApiIntegrationModel[]>([]);

    private readonly licenseStore = inject(LicensesStore);
    private readonly router = inject(Router);
    ngOnInit(): void {
        this.licenseStore.getLicenseApiIntegrations(this.listModel.id).subscribe(result => {
            this.apiIntegrations.set(result.value ?? []);
        });
        this.licenseStore.getLicenseHelpArticles(this.listModel.id).subscribe(result => {
            this.helpArticles.set(result.value ?? []);
        });
    }

    handleApiIntegrationClick(apiIntegrationId: number): void {
        this.router.navigate(['basic-data', 'api-integrations'], { queryParams: { Id: [apiIntegrationId] } }).then();
    }
}
