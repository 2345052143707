import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';

import { GeneralDataService } from '../../../core/services/general-data.service';
import { GeneralDataTypeEnum } from '../../../core/models/enums/general-data-type.enum';
import { SelectOptionModel } from '../../../core/models/select-option.model';
import { BaseControlValueAccessor } from '../../../core/abstractions/base-control-value-accessor';

@Component({
    selector: 'arc-general-data-select',
    templateUrl: './general-data-select.component.html',
    styleUrls: ['./general-data-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: GeneralDataSelectComponent
        }
    ]
})
export class GeneralDataSelectComponent extends BaseControlValueAccessor<any> implements OnInit, AfterViewInit {
    @Input() generalDataType!: GeneralDataTypeEnum;
    @Input() isMultiselect!: boolean;
    @Input() takeFirstOnInit? = false;

    @Output() private readonly optionSelected = new EventEmitter<any>();

    isLoading = true;
    data: SelectOptionModel[] = [];

    private readonly generalDataService = inject(GeneralDataService);

    override ngOnInit(): void {
        super.ngOnInit();

        this.generalDataService.getGeneralData(this.generalDataType).subscribe(data => {
            if (!this._formControl?.hasValidator(Validators.required) && !this.isMultiselect) {
                this.data.push({ value: undefined, label: '-' });
            }
            this.data = [...this.data, ...data.map(x => ({ value: x.key, label: x.value ?? '' }))];

            // check if the current value is still valid
            if (!this.isValueValid(this.value)) {
                this.writeValue(undefined);
                this.valueChanged(undefined, false);
            }

            if (this.takeFirstOnInit && this.data.length > 0) {
                this.writeValue(this.data[0].value);
                this.valueChanged(this.data[0].value, false);
            }

            this.isLoading = false;
        });
    }

    ngAfterViewInit(): void {
        if (this.takeFirstOnInit && this.data.length > 0) {
            this.writeValue(this.data[0].value);
            this.valueChanged(this.data[0].value, false);
        }
    }

    handleSelect(event: MatSelectChange): void {
        this.valueChanged(event.value);
        this.optionSelected.emit(event.value);
    }

    /**
     * Only allow values that are in {@link data}.
     * Or allow all values if data is still empty. Once data is filled, this will be checked again.
     */
    protected override isValueValid(value?: any): boolean {
        if (value === undefined || this.data.length === 0) {
            return true;
        }

        let isValid = true;
        if (Array.isArray(value)) {
            for (const val of value) {
                if (!this.data.some(d => d.value === val)) {
                    isValid = false;
                    break;
                }
            }
        } else {
            isValid = this.data.some(d => d.value === value);
        }
        return isValid;
    }
}
