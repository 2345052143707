import { Component } from '@angular/core';

import { BaseCustomColumnComponent } from '../../../../../../components/dynamic-table/column-components/custom-column/base-custom-column.component';
import { FeedbackListModel } from '../../../../../models/responses/feedback-list.model';
import { FeedbackTypesEnum } from '../../../../../models/enums/feedback-types.enum';

@Component({
    selector: 'arc-feedback-type',
    templateUrl: './feedback-type-column.component.html',
    styleUrl: './feedback-type-column.component.scss'
})
export class FeedbackTypeColumnComponent extends BaseCustomColumnComponent<FeedbackListModel> {
    get icon(): string {
        switch (this.item.type) {
            case FeedbackTypesEnum.Praise:
                return 'thumb_up';
            case FeedbackTypesEnum.Idea:
                return 'lightbulb';
            case FeedbackTypesEnum.Bug:
                return 'bug_report';
        }
        return '';
    }

    get iconColor(): string {
        switch (this.item.type) {
            case FeedbackTypesEnum.Praise:
                return 'text-success';
            case FeedbackTypesEnum.Idea:
                return 'text-warning';
            case FeedbackTypesEnum.Bug:
                return 'text-error';
        }
        return '';
    }
}
