import { StatisticChartTypesEnum } from '../../../core/models/enums/statistic-chart-types.enum';
import { StatisticalPeriodsEnum } from '../enums/statistical-periods.enum';

export class StatisticParametersRequestModel {
    [key: string]: any;
    period: StatisticalPeriodsEnum = StatisticalPeriodsEnum.Last7Days;
    mainGroupId?: number;
    wareGroupId?: number;
    pointOfSaleId?: number;
    branchId?: number;
    regionId?: number;
    userId?: number;
    paymentTypeId?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    comparePeriod = false;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    salesBudget = false;
    chartType: StatisticChartTypesEnum = StatisticChartTypesEnum.Bar;

    constructor(init?: Partial<StatisticParametersRequestModel>) {
        if (init) {
            Object.assign(init);
        }
    }
}

