import { Component, effect, inject } from '@angular/core';
import { Router } from '@angular/router';

import { BaseComponent } from '../../../../../../components/abstractions/base.component';
import { TicketListModel } from '../../../../../models/responses/ticket-list.model';
import { TicketSidebarService } from '../../../../../../core/services/ticket-sidebar.service';
import { SidebarService } from '../../../../../../core/services/sidebar.service';

@Component({
    selector: 'arc-ticket-list',
    templateUrl: './ticket-list.component.html',
    styleUrl: './ticket-list.component.scss'
})
export class TicketListComponent extends BaseComponent {
    tickets?: TicketListModel[] = [];

    private readonly ticketSidebarService = inject(TicketSidebarService);
    private readonly sidebarService = inject(SidebarService);
    private readonly router = inject(Router);
    constructor() {
        super();
        effect(() => {
            this.tickets = this.ticketSidebarService.licenseTickets();
        });
    }
    selectTicket(ticketId: number): void {
        this.router.navigate(['support/tickets'], { queryParams: { Id: [ticketId] } }).then(() => this.sidebarService.closeRight());
    }
}
