import { BaseColumnModel } from './base-column.model';
import { ColumnTypeEnum } from '../enums/column-type.enum';

export class DateColumnModel extends BaseColumnModel {
    format = 'shortDate';

    constructor(init?: Partial<DateColumnModel>) {
        super(ColumnTypeEnum.Date);

        if (!!init) {
            Object.assign(this, init);
        }
    }
}
