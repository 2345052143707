import { Component, inject, OnDestroy, signal } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { LicenseHardwareOrderModel } from '../../../../../models/license-hardware-orders.model';
import { LicenseHardwareOrderEditModel } from '../../../../../models/requests/license-hardware-order-edit.model';
import { HardwareOrderStatesEnum } from '../../../../../models/enums/hardware-order-states.enum';
import { ComplexDataTypesEnum } from '../../../../../../core/models/complex-data-types.enum';
import { GeneralDataStore } from '../../../../../services/stores/general-data.store';
import { SelectOptionModel } from '../../../../../../core/models/select-option.model';

@Component({
    selector: 'arc-license-hardware-order-base-data',
    templateUrl: './license-hardware-order-base-data.component.html',
    styleUrl: './license-hardware-order-base-data.component.scss'
})
// eslint-disable-next-line max-len
export class LicenseHardwareOrderBaseDataComponent extends BaseEditSidebarItemComponent<LicenseHardwareOrderModel, LicenseHardwareOrderEditModel> implements OnDestroy {
    override formGroup = new FormGroup({
        quantity: new ArcFormControl<OptionalType<number>>(undefined, [Validators.required, Validators.min(0)]),
        price: new ArcFormControl<OptionalType<number>>(undefined, [Validators.required, Validators.min(0)]),
        orderedDate: new ArcFormControl<OptionalType<Date>>(undefined, [Validators.required]),
        sentDate: new ArcFormControl<OptionalType<Date>>(undefined),
        sentUserId: new ArcFormControl<OptionalType<number>>(undefined),
        licenseId: new ArcFormControl<OptionalType<number>>(undefined, [Validators.required]),
        hardwareBundleId: new ArcFormControl<OptionalType<number>>(undefined, [Validators.required]),
        serialNumber: new ArcFormControl<OptionalType<string>>(undefined),
        trackingNumber: new ArcFormControl<OptionalType<string>>(undefined),
        orderState: new ArcFormControl(HardwareOrderStatesEnum.Created, [Validators.required]),
        street: new ArcFormControl<OptionalType<string>>(undefined, [Validators.required]),
        city: new ArcFormControl<OptionalType<string>>(undefined, [Validators.required]),
        streetNumber: new ArcFormControl<OptionalType<string>>(undefined),
        zip: new ArcFormControl<OptionalType<string>>(undefined, [Validators.required]),
        countryIsoCode: new ArcFormControl<OptionalType<string>>(undefined, [Validators.required]),
        deliveryAddressName: new ArcFormControl<OptionalType<string>>(undefined, [Validators.required])
    });

    ComplexDataTypesEnum = ComplexDataTypesEnum;

    licenseHardwareBundles = signal<SelectOptionModel<string>[]>([]);

    hardwareOrderOptions = Object.keys(HardwareOrderStatesEnum)
        .filter(key => isNaN(Number(key)))
        .map(key => ({
            value: HardwareOrderStatesEnum[key as keyof typeof HardwareOrderStatesEnum],
            label: key
        }));

    private readonly generalDataStore = inject(GeneralDataStore);
    private subscription = new Subscription();

    constructor() {
        super();

        this.subscription.add(this.formGroup.controls.licenseId.valueChanges.subscribe(licenseId => {
            if (!licenseId) {
                return;
            }
            this.handleLicenseUpdate(licenseId);
        }));
    }
    onItemSet(): void {
        this.formGroup.patchValue(this.item);
        if (!!this.item.licenseId) {
            this.handleLicenseUpdate(this.item.licenseId);
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    override prepareSaveModel(itemCopy: LicenseHardwareOrderEditModel): LicenseHardwareOrderEditModel {
        return { ...itemCopy, ...this.formGroup.value };
    }

    handleLicenseUpdate(licenseId: number): void {
        this.generalDataStore
            .getLicenseHardwareBundles(licenseId)
            .subscribe(result => {
                this.licenseHardwareBundles.set(result.value?.map(x => ({ label: x.value!, value: x.key! })) ?? []);
            });
    }
}
