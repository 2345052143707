import { Injectable } from '@angular/core';

import { StorageTypeEnum } from '../../models/enums/storage-type.enum';
import { BaseStorage } from '../../abstractions/base.storage';
import { HelpArticleModel } from '../../../app/models/responses/help-article.model';
import { OptionalType } from '../../models/types/optional.type';

@Injectable({
    providedIn: 'root'
})
export class SettingsSessionStorage extends BaseStorage {
    constructor() {
        super('settings-session', StorageTypeEnum.Session);
    }

    saveCurrentHelpContext(context: OptionalType<string>): void {
        this.save(context, 'currentHelpContext');
    }

    getCurrentHelpContext(): string {
        return this.get<string>('currentHelpContext') || '';
    }

    saveCurrentHelpArticle(helpArticleModel: OptionalType<HelpArticleModel>): void {
        this.save(helpArticleModel, 'currentHelpArticle');
    }

    getCurrentHelpArticle(): OptionalType<HelpArticleModel> {
        return this.get<HelpArticleModel>('currentHelpArticle');
    }
}
