export class CarouselItemModel {
    imageUrl?: string;
    base64Content?: string;
    description?: string;
    descriptionKey?: string;
    tooltip?: string;
    tooltipKey?: string;

    constructor(init?: Partial<CarouselItemModel>) {
        Object.assign(this, init);
    }
}
