import { Injectable } from '@angular/core';

import { StoreConfig } from '../../../core/models/store.config';
import { CustomerInteractionModel } from '../../models/customer-interaction.model';
import { CustomerInteractionListModel } from '../../models/responses/customer-interaction-list.model';
import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';

@Injectable({
    providedIn: 'root'
})
// eslint-disable-next-line max-len
export class CustomerInteractionsStore extends BaseCrudStore<CustomerInteractionModel, CustomerInteractionListModel, CustomerInteractionModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'customer-interactions' }));
    }
}
