<div class="flex flex-col" [ngClass]="message().isMyResponse ? 'items-end ml-12' : 'items-start mr-12'">
    <span class="float-right text-xs text-gray-400" [ngClass]="{'mr-1': message().isMyResponse, 'ml-1': !message().isMyResponse}">{{ createdBy() }} {{'Tickets.Edit.On' | transloco}} {{ message().createdDate
        | arcDate: 'short' }}</span>
    <div class="p-4 rounded-lg" [style.background-color]="message().backgroundColorHex" [ngClass]="message().isMyResponse ? 'bg-success-light text-on-success-light' : 'bg-element-selected text-on-app'">
        <div markdown>{{ message().text }}</div>
    </div>
    @for(attachment of message().attachments; track attachment.id){
        <div class="p-4 rounded-lg my-4" [style.background-color]="message().backgroundColorHex" [ngClass]="message().isMyResponse ? 'bg-success-light text-on-app' : 'bg-element-selected text-on-app'">
            <div class="flex items-center gap-2">
                <mat-icon class="mr-2">insert_drive_file</mat-icon>
                <span>{{attachment.fileName}}</span>
                <button class="ml-auto" mat-icon-button (click)="downloadFile(attachment)">
                    <mat-icon>download</mat-icon>
                </button>
            </div>
        </div>
    }
</div>