import { Component } from '@angular/core';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { PortalLogListModel } from '../../../../../models/responses/portal-log-list.model';
import { PortalLogModel } from '../../../../../models/portal-log.model';

@Component({
    selector: 'arc-system-log-message',
    templateUrl: './system-log-message.component.html',
    styleUrl: './system-log-message.component.scss'
})
export class SystemLogMessageComponent extends BaseDetailWidget<PortalLogListModel, PortalLogModel> {}
{
}
