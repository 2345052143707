import { Component, inject, OnInit, Input, OnChanges } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';

import { EntityTagModel } from '../../../../app/models/entity-tag.model';

@Component({
    selector: 'arc-description-widget',
    templateUrl: './description-widget.component.html',
    styleUrls: ['./description-widget.component.scss']
})
export class DescriptionWidgetComponent implements OnInit, OnChanges {
    @Input() tags: EntityTagModel[] = [];
    @Input() description = '';

    sanitizedDesc: SafeHtml = '';

    private readonly domSanitizer = inject(DomSanitizer);

    ngOnInit(): void {
        this.sanitizedDesc = this.domSanitizer.bypassSecurityTrustHtml(this.description);
    }

    ngOnChanges(): void {
        this.sanitizedDesc = this.domSanitizer.bypassSecurityTrustHtml(this.description);
    }
}
