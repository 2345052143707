import { Injectable, inject } from '@angular/core';
import { map } from 'rxjs';

import { TreeDataSelectionConfig } from '../../components/form/tree-autocomplete/models/tree-data-selection.config';
import { SearchRequestModel } from '../models/requests/search-request.model';
import { PermissionsEnum } from '../models/enums/permissions.enum';
import { HelpTopicsStore } from './stores/help-topics.store';
import { HelpTopicListModel } from '../models/responses/help-topic-list.model';
import { HelpSubTopicsStore } from './stores/help-sub-topics.store';

export const generateHelpTopicConfig = (
    helpTopicsStore: HelpTopicsStore,
    helpSubTopicsStore: HelpSubTopicsStore
): TreeDataSelectionConfig<HelpTopicListModel> =>
    new TreeDataSelectionConfig({
        selectionDialogTitleKey: 'HelpTopics.HelpTopic',
        requiredPermission: PermissionsEnum.HelpTopics,
        deepestLevel: 1,
        getNodeTitle: (group: HelpTopicListModel) => group.title,
        search: (searchText: string) => {
            const request = new SearchRequestModel({ searchText });

            return helpTopicsStore.search(request).pipe(map(response => response.value?.records ?? []));
        },

        loadRootItems: () => helpTopicsStore.getHelpTopics().pipe(map(response => response.value ?? [])),
        loadChildren: parentId => helpSubTopicsStore.getByHelpTopicId(parentId).pipe(map(response => response.value ?? [])),

        getNodeParent: node => node.parentNode,
        hasNodeChildren: node => node.hasChildren,

        store: helpTopicsStore,
        addItemAtLevelZeroTextKey: 'HelpTopics.AddHelpTopic',
        addItemAtLevelOneTextKey: 'HelpTopics.AddHelpSubTopic',
        saveNewRecordHandler: (node, text) => {
            const isRootLevel = node.level === 0;
            const newTopic = {
                id: 0,
                title_de: text,
                ...(isRootLevel ? {} : { helpTopicId: node.parentNode.id })
            };

            return isRootLevel ? helpTopicsStore.add(newTopic) : helpSubTopicsStore.add(newTopic);
        }
    });

@Injectable({
    providedIn: 'root'
})
export class TreeAutocompleteConfigsService {
    get helpTopicConfig(): TreeDataSelectionConfig<HelpTopicListModel> {
        return generateHelpTopicConfig(this.helpTopicsStore, this.helpSubTopicsStore);
    }

    private readonly helpTopicsStore = inject(HelpTopicsStore);
    private readonly helpSubTopicsStore = inject(HelpSubTopicsStore);
}
