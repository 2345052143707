import { Injectable, inject } from '@angular/core';
import { MatTreeFlattener } from '@angular/material/tree';
import { Observable, map } from 'rxjs';

import { TreeItemNode } from '../../../../core/models/tree-item.node';
import { TreeItemFlatNode } from '../../../../core/models/tree-item.flat-node';
import { TreeConfigSetupService } from './tree-config-setup.service';
import { TreeDataSelectionConfig } from '../../../form/tree-autocomplete/models/tree-data-selection.config';
import { SearchRequestModel } from '../../../../app/models/requests/search-request.model';
import { SearchTools } from './search-tools';
import { Identifyable } from '../../../../core/abstractions/identifyable';

@Injectable()
export class DynamicTreeDatabase<T extends Identifyable<TId>, TId> {
    private treeConfigSetupService: TreeConfigSetupService<T, TId> = inject(TreeConfigSetupService<T, TId>);
    private get treeConfig(): TreeDataSelectionConfig<T, TId> {
        return this.treeConfigSetupService.treeConfig;
    }
    private get treeFlattener(): MatTreeFlattener<TreeItemNode<TId>, TreeItemFlatNode<TId>> {
        return this.treeConfigSetupService.treeFlattener;
    }

    /**
     * Returns the root nodes.
     */
    getRootItems(): Observable<TreeItemFlatNode<TId>[]> {
        return this.treeConfig.loadRootItems().pipe(
            map(items => this.treeFlattener.flattenNodes(items.map(i => this.transformItem(i))))
        );
    }

    /**
     * Returns the children of a specific node.
     * @param node Parent node.
     */
    getChildren(node: TreeItemFlatNode<TId>): Observable<TreeItemFlatNode<TId>[]> {
        return this.treeConfig.loadChildren(node.id).pipe(
            map(children => {
                const childLevel = node.level + 1;

                // If there are no children, we return empty or the "New group" button.
                if (children.length < 1) {
                    return this.treeConfig.allowsInlineCreation
                        ? [
                            {
                                id: undefined as TId, // new record button doesn't have id
                                isExpandable: false,
                                level: childLevel,
                                text: '',
                                isLoading: false,
                                isNewRecordButton: true,
                                parentNode: node
                            }
                        ]
                        : [];
                }

                // Maps children nodes to the flat node type.
                const nodes = children.map<TreeItemFlatNode<TId>>(item => ({
                    id: item.id,
                    text: this.treeConfig.getNodeTitle(item),
                    level: childLevel,
                    isExpandable: this.treeConfig.hasNodeChildren(item) || childLevel < this.treeConfig.deepestLevel
                }));

                // Adds "New group" button, if allowed.
                if (this.treeConfig.allowsInlineCreation) {
                    nodes.push({
                        id: undefined as TId, // new record button doesn't have id
                        isExpandable: false,
                        level: childLevel,
                        text: '',
                        isNewRecordButton: true,
                        parentNode: node
                    });
                }

                return nodes;
            })
        );
    }

    /**
     * Searches and formats the result data into a flat node.
     * @param searchRequest Search request.
     */
    search(searchRequest: SearchRequestModel): Observable<TreeItemFlatNode<TId>[]> {
        if (!searchRequest.searchText) {
            return this.getRootItems();
        }

        searchRequest.pageSize = 999;

        return this.treeConfig.store.search(searchRequest).pipe(
            map(resp => {
                const transformedRecords = SearchTools.transformSearchResult(resp.value?.records ?? [], this.treeConfig);
                return SearchTools.flattenSearchResult(transformedRecords, this.treeConfig, this.treeFlattener);
            })
        );
    }

    private transformItem(item: T): TreeItemNode<TId> {
        return {
            id: item.id,
            text: this.treeConfig.getNodeTitle(item),
            hasChildren: this.treeConfig.hasNodeChildren(item)
        };
    }
}
