import { Component, Input, OnChanges, OnInit, SimpleChanges, inject } from '@angular/core';

import { carouselAnimations } from './animations';
import { CarouselItemModel } from './models/carousel-item.model';
import { TranslationService } from '../../core/services/translation.service';

@Component({
    selector: 'arc-carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss'],
    animations: carouselAnimations
})
export class CarouselComponent implements OnInit, OnChanges {
    @Input() items: CarouselItemModel[] = [];
    @Input() imagesUrls: string[] = [];
    @Input() imagesContents: string[] = [];
    @Input() shouldShowControls = true;
    @Input() width = 350;
    @Input() height = 250;

    currentSlideIndex = 0;

    private readonly translationService = inject(TranslationService);

    ngOnInit(): void {
        this.setupItems();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!!changes['imagesUrls'] || !!changes['imagesContents']) {
            this.setupItems();
        }
    }

    goToPrevious(): void {
        this.currentSlideIndex = (this.currentSlideIndex - 1 + this.items.length) % this.items.length;
    }

    goToNext(): void {
        this.currentSlideIndex = (this.currentSlideIndex + 1) % this.items.length;
    }

    getAltText(item: CarouselItemModel): string {
        if (!!item.tooltip) {
            return item.tooltip;
        }

        return !!item.tooltipKey ? this.translationService.getText(item.tooltipKey) : '';
    }

    getBase64Src(item: CarouselItemModel): string {
        return 'data:image/jpg;base64,' + item.base64Content;
    }

    private setupItems(): void {
        this.imagesUrls.forEach(url => {
            const idx = this.items.findIndex(i => i.imageUrl === url);

            if (idx < 0) {
                this.items.push(new CarouselItemModel({ imageUrl: url }));
            }
        });
        this.imagesContents.forEach(content => {
            const idx = this.items.findIndex(i => i.base64Content === content);

            if (idx < 0) {
                this.items.push(new CarouselItemModel({ base64Content: content }));
            }
        });
    }
}
