<div class="flex justify-between items-center mb-4">
    <div>
        <p class="text-sm font-semibold">{{ license().id }}</p>
        <h2 class="text-lg font-bold">{{ license().licenseHolder }}</h2>
    </div>
    <arc-license-product-item [license]="license()"></arc-license-product-item>
</div>
@if(isLoading()) {
    <div class="w-full h-full grid place-items-center">
        <mat-spinner [diameter]="32"></mat-spinner>
    </div>
} @else {
    <div class="grid grid-cols-5 gap-2">
        @for (module of licenseModules(); track module.id) {
            <arc-license-module-item [module]="module" [license]="license()"></arc-license-module-item>
        }
    </div>
}