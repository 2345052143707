import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs';

import { ContractsStore } from '../../services/stores/contracts.store';
import { ContractViewModel } from '../../models/responses/contract-view.model';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'arc-contract-view',
    templateUrl: './contract-view.component.html',
    styleUrl: './contract-view.component.scss'
})
export class ContractViewComponent {
    contract: ContractViewModel | undefined;
    isLoading = true;

    private readonly activatedRoute = inject(ActivatedRoute);
    private readonly contractsStore = inject(ContractsStore);

    constructor() {
        const key = this.activatedRoute.snapshot.paramMap.get('key') || '';

        this.contractsStore
            .getContractByKey(key)
            .pipe(first())
            .subscribe(contract => {
                this.contract = contract.value;
                this.isLoading = false;
            });
    }

    get isMity(): boolean {
        return environment.isMity;
    }
}
