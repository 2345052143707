/**
 * Browser's storage type.
 */
export enum StorageTypeEnum {
    /**
     * Local storage.
     */
    Local = 'localStorage',
    /**
     * Session storage.
     */
    Session = 'sessionStorage'
}
