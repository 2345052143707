import { BaseApiResponseModel } from '../../../core/abstractions/base-api-response.model';
import { BrokenRuleModel } from '../broken-rule.model';

export class ApiResponseModel<T extends any> extends BaseApiResponseModel {
    isList?: boolean;
    type?: string;
    page?: number;
    pageSize?: number;
    totalPages?: number;
    totalRecords?: number;
    value?: T;
    brokenRules: BrokenRuleModel[] = [];

    constructor(init?: Partial<ApiResponseModel<T>>) {
        super(init);

        if (!!init) {
            Object.assign(this, init);
        }
    }
}
