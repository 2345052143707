import { Directive, AfterViewInit, inject } from '@angular/core';
import { timer, tap } from 'rxjs';

import { BaseComponent } from './base.component';
import { AuthService } from '../../core/services/auth.service';

@Directive()
export class BaseAuthComponent extends BaseComponent implements AfterViewInit {
    protected readonly _authService = inject(AuthService);

    ngAfterViewInit(): void {
        const healthCheckSub = timer(1000, 60000).pipe(
            tap(() =>
                this._authService.isLoggedIn().pipe(
                    tap(isLoggedIn => {
                        if (!isLoggedIn) {
                            healthCheckSub.unsubscribe();
                            this._authService.goToLoginPage();
                        }
                    })
                ).subscribe()
            )
        ).subscribe();
        this.addSubscriptions(healthCheckSub);
    }
}
