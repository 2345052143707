import { Injectable } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlattener } from '@angular/material/tree';

import { TreeItemFlatNode } from '../../../../core/models/tree-item.flat-node';
import { TreeItemNode } from '../../../../core/models/tree-item.node';
import { TreeDataSelectionConfig } from '../../../form/tree-autocomplete/models/tree-data-selection.config';
import { Identifyable } from '../../../../core/abstractions/identifyable';

@Injectable()
export class TreeConfigSetupService<T extends Identifyable<TId>, TId> {
    treeControl!: FlatTreeControl<TreeItemFlatNode<TId>>;
    treeFlattener!: MatTreeFlattener<TreeItemNode<TId>, TreeItemFlatNode<TId>>;
    flatNodeMap = new Map<TreeItemFlatNode<TId>, TreeItemNode<TId>>();
    nestedNodeMap = new Map<TreeItemNode<TId>, TreeItemFlatNode<TId>>();
    treeConfig: TreeDataSelectionConfig<T, TId>;

    constructor(treeConfig: TreeDataSelectionConfig<T, TId>) {
        this.treeConfig = treeConfig;
        this.treeFlattener = new MatTreeFlattener(
            this.transformer.bind(this),
            node => node.level,
            node => node.isExpandable,
            this.getChildren.bind(this)
        );
        this.treeControl = new FlatTreeControl<TreeItemFlatNode<TId>>(
            node => node.level,
            node => node.isExpandable
        );
    }

    getChildren = (node: TreeItemNode<TId>): TreeItemNode<TId>[] => node.children || [];

    transformer(node: TreeItemNode<TId>, level: number): TreeItemFlatNode<TId> {
        const existingNode = this.nestedNodeMap.get(node);
        const flatNode: TreeItemFlatNode<TId> = {
            ...(!!existingNode && existingNode.text === node.text ? existingNode : {}),
            id: node.id,
            text: node.text,
            level: level,
            isExpandable: !!node.hasChildren || (node.children?.length || 0) > 0 || (level < 2 && !node.isNewRecordButton),
            isNewRecordButton: node.isNewRecordButton,
            parentNode: !!node.parentNode ? this.nestedNodeMap.get(node.parentNode!) : undefined
        };

        this.flatNodeMap.set(flatNode, node);
        this.nestedNodeMap.set(node, flatNode);

        return flatNode;
    }
}
