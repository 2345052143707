<form [formGroup]="formGroup" class="grid items-center grid-cols-12 grid-flow-row auto-cols-auto gap-4">
    <arc-input class="col-span-12" labelKey="ApiIntegrations.EditFields.Title" [control]="formGroup.controls.title"></arc-input>

    @if(isApiIntegrationAdmin) {
        <arc-quick-search class="col-span-6 ssm:col-span-12" [config]="ComplexDataTypesEnum.User | arcQuickSearchConfig"
            label="ApiIntegrations.EditFields.User" [formControl]="formGroup.controls.userId">
        </arc-quick-search>
        <arc-number-input class="col-span-6 ssm:col-span-12" [formControl]="formGroup.controls.maxRequestsPer15Mins"
            label="ApiIntegrations.EditFields.MaxRequestsPer15Mins">
        </arc-number-input>
        <arc-datepicker labelKey="ApiIntegrations.EditFields.ApprovedDate" class="col-span-6 ssm:col-span-12"
            [control]="formGroup.controls.approvedDate">
        </arc-datepicker>
    }
    
    <label class="col-span-12" for="helpText">{{ 'ApiIntegrations.EditFields.HelpText' | transloco }}</label>
    <arc-markdown-editor class="col-span-12" id="helpText" styleClass="mde-help" [formControl]="formGroup.controls.helpText"> </arc-markdown-editor>
</form>