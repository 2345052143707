import { Component, inject } from '@angular/core';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { ApiIntegrationListModel } from '../../../models/responses/api-integration-list.model';
import { ApiIntegrationModel } from '../../../models/api-intagration.model';
import { ApiIntegrationsStore } from '../../../services/stores/api-integrations.store';
import { TableListViewConfigModel } from '../../../../components/list-views/table-list-view/models/table-list-view-config.model';
import { StringColumnModel } from '../../../../components/dynamic-table/models/column-types/string-column.model';
import { DateColumnModel } from '../../../../components/dynamic-table/models/column-types/date-column.model';
import { ColumnSortModel } from '../../../../core/models/column-sort.model';
import { SortDirectionEnum } from '../../../../core/models/enums/sort-direction.enum';
import { CustomColumnModel } from '../../../../components/dynamic-table/models/column-types/custom-column.model';
import { ApiIntegrationStatusComponent } from './components/api-integration-status/api-integration-status.component';
import { ColumnTitleAlignment } from '../../../../core/models/enums/column-title-alignment-enum';
import { ApiIntegrationEditBaseDataComponent } from './api-integration-edit-items/api-integration-edit-base-data/api-integration-edit-base-data.component';

@Component({
    selector: 'arc-api-integrations',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrl: './api-integrations.component.scss'
})
export class ApiIntegrationsComponent extends PageListViewComponent<ApiIntegrationListModel, ApiIntegrationModel> {
    private readonly apiIntegrationsStore = inject(ApiIntegrationsStore);
    constructor() {
        super('ApiIntegrations');
        this.config = new TableListViewConfigModel<ApiIntegrationListModel, ApiIntegrationModel>({
            entityName: 'ApiIntegrations',
            availableColumns: {
                status: new CustomColumnModel({
                    columnTitleKey: 'ApiIntegrations.List.Status',
                    customComponent: ApiIntegrationStatusComponent,
                    widthPixels: 100,
                    columnTitleAlignment: ColumnTitleAlignment.Center
                }),
                key: new StringColumnModel({
                    columnTitleKey: 'ApiIntegrations.List.Key',
                    propertyName: 'key',
                    widthPixels: 400
                }),
                title: new StringColumnModel({
                    columnTitleKey: 'ApiIntegrations.List.Title',
                    propertyName: 'title',
                    widthPixels: 300
                }),
                userEmail: new StringColumnModel({
                    columnTitleKey: 'ApiIntegrations.List.UserEmail',
                    propertyName: 'userEmail',
                    widthPixels: 300
                }),
                approvedDate: new DateColumnModel({
                    columnTitleKey: 'ApiIntegrations.List.ApprovedDate',
                    propertyName: 'approvedDate',
                    widthPixels: 200
                })
            },
            defaultColumnOrder: ['status', 'key', 'title', 'userEmail', 'approvedDate'],
            defaultSort: new ColumnSortModel({
                column: 'id',
                direction: SortDirectionEnum.Desc
            }),
            availableDetailWidgets: {},
            defaultDetailWidgetOrder: [],
            editSidebarConfig: {
                editComponents: [{ titleKey: 'ApiIntegrations.Edit.BaseData', component: ApiIntegrationEditBaseDataComponent }]
            },
            store: this.apiIntegrationsStore
        });
    }
}
