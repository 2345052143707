import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { BaseAuthComponent } from '../../../../../components/abstractions/base-auth.component';
import { EnvironmentService } from '../../../../../core/services/environment.service';
import { SecurityStorage } from '../../../../../core/services/storages/security.storage';
import { TranslationService } from '../../../../../core/services/translation.service';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare const GrapeCity: any;

@Component({
    selector: 'arc-reports-designer',
    templateUrl: './reports-designer.component.html',
    styleUrls: ['./reports-designer.component.scss']
})
export class ReportsDesignerComponent extends BaseAuthComponent implements OnInit, OnDestroy {
    private readonly environmentService = inject(EnvironmentService);
    private readonly securityStorage = inject(SecurityStorage);
    private readonly activatedRoute = inject(ActivatedRoute);
    private readonly router = inject(Router);
    private readonly titleService = inject(Title);
    private readonly translationService = inject(TranslationService);

    ngOnInit(): void {
        const userToken = this.securityStorage.getUserInfo()?.token;
        const paramsSub = this.activatedRoute.params.subscribe(() => {
            const reportId = this.activatedRoute.snapshot.paramMap.get('id');
            let previewConfig: any = { canPreview: false };

            if (!reportId?.startsWith('{')) {
                previewConfig = {
                    canPreview: true,
                    openViewer: (options: any) => {
                        this.router.navigate(['reports', 'view', options.documentInfo.id.split('.')[0]]).then();
                    }
                };
            }

            GrapeCity.ActiveReports.Designer.create('#ar-web-designer', {
                rpx: { enabled: true },
                appBar: {
                    openButton: { visible: false },
                    aboutButton: { visible: false },
                    saveAsButton: { visible: false }
                },
                menu: { logo: { custom: { type: 'css', class: 'example-icon' } } }, // TODO: add Arcavis logo
                server: {
                    url: this.environmentService.reportingUrl + '/api/',
                    onBeforeRequest: (init: any) => {
                        init.headers.Authorization = `Bearer ${userToken}`;
                    }
                },
                storeUnsavedReport: false,
                document: {
                    id: reportId,
                    type: { platform: 'rdlx', type: 'report' }
                },
                title: {
                    onUpdate: () => {
                        this.translationService.getTextAsync('Layout.Menu.Sales.Reports').then(txt =>
                            this.titleService.setTitle(`Arcavis | ${txt}`)
                        );
                        return undefined;
                    }
                },
                documents: {
                    fileView: { visible: false },
                    handlers: {
                        onAfterSave: (info: any) => {
                            if (info.success) {
                                // TODO: waiting for response from GrapeCity - Case ID: CAS-45354-S7B2Y8
                                // After we have a solution, we should get the ID returned from the backend and use it to redirect
                                // the user to the URL: /reports/edit/{received_id}, instead of the JSON data.
                            }
                        }
                    }
                },
                data: { dataSets: { canModify: false }, dataSources: { canModify: false } },
                preview: previewConfig
            });
        });

        this.addSubscriptions(paramsSub);
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
        GrapeCity.ActiveReports.Designer.destroy('#ar-web-designer');
    }
}
