<div class="w-full h-full flex flex-col">
    @if(isLoading()){
    <div class="w-full h-full flex-1 grid place-items-center">
        <mat-spinner [diameter]="32"></mat-spinner>
    </div>
    }
    @if(!!tableConfig && !isLoading()){
        <arc-static-basic-table-list-view [config]="tableConfig" [shouldUseCompactStyle]="true" class="flex-1">
        </arc-static-basic-table-list-view>
    }
</div>