import { AfterViewInit, Component, HostBinding, ViewChild, inject } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Observable } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatChipListboxChange } from '@angular/material/chips';

import { ImportStepBase } from '../models/import-step-base';
import { ImportService } from '../../../../core/services/import.service';
import { ImportExecutionRowStatusEnum } from '../../../../app/models/enums/import-execution-row-status.enum';
import { ImportPreviewElementModel } from './models/import-preview-element.model';
import { ImportEntityColumnModel } from '../../../../app/models/import-entity-column.model';
import { DictionaryType } from '../../../../core/models/types/dictionary.type';

@Component({
    selector: 'arc-step3-preview-execute',
    templateUrl: './step3-preview-execute.component.html',
    styleUrls: ['./step3-preview-execute.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
        ])
    ]
})
export class Step3PreviewExecuteComponent extends ImportStepBase implements AfterViewInit {
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;

    @HostBinding('class') classes = 'flex flex-col';

    ImportExecutionRowStatusEnum = ImportExecutionRowStatusEnum;

    datasource: MatTableDataSource<ImportPreviewElementModel>;
    importResultAmountTotal = 0;
    importResultAmounts: DictionaryType<number> = {};

    displayedEntityColumns: ImportEntityColumnModel[] = [];
    get allDisplayedColumns(): string[] {
        return ['importStatus', ...this.displayedEntityColumns.map(x => x.id)];
    }
    expandedElement?: ImportPreviewElementModel;

    private readonly importService = inject(ImportService);

    constructor() {
        super();
        this.datasource = new MatTableDataSource<ImportPreviewElementModel>([]);
        this.datasource.filterPredicate = (element, filter) => filter === '' || element.importStatus.toString() === filter;
    }

    ngAfterViewInit(): void {
        this.datasource.paginator = this.paginator;
        this.datasource.sort = this.sort;
    }

    onFilterChanged(event: MatChipListboxChange): void {
        const status = event.value as ImportExecutionRowStatusEnum;
        this.datasource.filter = status?.toString() ?? '';
        if (status === ImportExecutionRowStatusEnum.All) {
            this.datasource.filter = '';
        }
        this.datasource.paginator?.firstPage();
    }

    allowNext(): boolean {
        const validRows =
            this.importService.previewData?.rows?.filter(
                r => r.status === ImportExecutionRowStatusEnum.New || r.status === ImportExecutionRowStatusEnum.Changed
            ) ?? [];
        return validRows.length > 0;
    }

    allowPrevious = (): boolean => true;
    allowCancel = (): boolean => true;

    init(): void {
        this.displayedEntityColumns = [];
        this.datasource.data = [];
        this.importResultAmountTotal = 0;
        this.importResultAmounts = {};

        const rows = this.importService.previewData?.rows;
        if (!!rows && rows.length > 0) {
            const firstRowWithData = rows.find(r => Object.entries(r.fieldsByColumnName).length > 0);
            if (!!firstRowWithData) {
                const displayedColumnIds = Object.keys(firstRowWithData.fieldsByColumnName);
                this.displayedEntityColumns = displayedColumnIds.map(
                    id => this.importService.entityImportColumns.find(c => c.id === id)
                ).filter(c => !!c) as ImportEntityColumnModel[];
            }

            const previewData = [];
            this.importResultAmountTotal = 0;
            this.importResultAmounts[ImportExecutionRowStatusEnum.New] = 0;
            this.importResultAmounts[ImportExecutionRowStatusEnum.Unchanged] = 0;
            this.importResultAmounts[ImportExecutionRowStatusEnum.Changed] = 0;
            this.importResultAmounts[ImportExecutionRowStatusEnum.Invalid] = 0;

            for (const row of rows) {
                if (Object.entries(row.fieldsByColumnName).length > 0) {
                    const entry = new ImportPreviewElementModel();
                    entry.importStatus = row.status;
                    Object.assign(entry, row.fieldsByColumnName);
                    entry.importGeneralErrors = row.generalErrorMessages;
                    entry.importColumnErrors = row.fieldErrors;
                    entry.importColumnErrorsDict = {};
                    for (const fieldError of row.fieldErrors) {
                        if (entry.importColumnErrorsDict[fieldError.fieldName]) {
                            entry.importColumnErrorsDict[fieldError.fieldName] += `, ${fieldError.errorMessage}`;
                        } else {
                            entry.importColumnErrorsDict[fieldError.fieldName] = fieldError.errorMessage;
                        }
                    }
                    entry.hasErrors = row.fieldErrors.length > 0 || row.generalErrorMessages.length > 0;

                    this.importResultAmountTotal++;
                    if (entry.importStatus !== ImportExecutionRowStatusEnum.Ignored) {
                        this.importResultAmounts[entry.importStatus]++;
                    }

                    previewData.push(entry);
                }
            }
            this.datasource.data = previewData;
        }
    }

    next(): Observable<any> {
        return this.importService.executeImport();
    }

    expand(element: ImportPreviewElementModel): void {
        if (element.hasErrors) {
            this.expandedElement = this.expandedElement === element ? undefined : element;
        }
    }
}
