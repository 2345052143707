<div class="bg-app">
    <div class="mx-8 py-8 min-h-svh grid place-items-center">
        <div class="bg-element p-8 rounded-xl flex flex-col items-center drop-shadow-lg">
            @if (!paymentResult()?.paymentIntent) {
                @if (invoicePaymentModel(); as invoice) {
                    @if (invoice.amountOpen > 0) {
                        <!-- invoice payment form -->
                        <p class="font-bold text-xl">
                            {{ 'InvoicePayment.AmountOpen' | transloco }}:
                            {{ invoice.amountOpen | arcCurrency : invoice.currencyIsoCode }}
                        </p>
                        <p class="mb-4">{{ invoice.displayName }}</p>

                        <ngx-stripe-elements [stripe]="stripe" [elementsOptions]="elementsOptions()">
                            <ngx-stripe-payment (ready)="isLoadingStripe.set(false)" />
                        </ngx-stripe-elements>

                        @if (paymentResult()?.error; as paymentResultError) {
                            <!-- payment failed -->
                            <p class="my-4 text-error max-w-[300px]">{{ 'InvoicePayment.PaymentFailed' | transloco }}:
                                {{ paymentResultError.message }}</p>
                        }

                        @if (isLoadingStripe()) {
                            <mat-spinner [diameter]="32"></mat-spinner>
                        } @else {
                            <div class="mt-4 mb-8 max-w-[300px]" [innerHTML]="'InvoicePayment.AcceptGtc' | transloco">
                            </div>

                            <arc-button type="flat" color="primary" (clicked)="pay()" [isLoading]="isPaying()">
                                {{ 'InvoicePayment.Pay' | transloco }}
                            </arc-button>
                        }
                    } @else {
                        <!-- invoice already paid -->
                        <p>
                            {{ 'InvoicePayment.InvoiceAlreadyPaid' | transloco : { amount: invoice.totalAmount | arcCurrency : invoice.currencyIsoCode } }}
                        </p>
                    }
                } @else if (!isLoadingInvoice()) {
                    <!-- invoice not found -->
                    <p>{{ 'InvoicePayment.InvoiceNotFound' | transloco }}</p>
                } @else {
                    <mat-spinner [diameter]="32"></mat-spinner>
                }
            } @else {
                <!-- payment is being completed -->
                @if (isCompleting()) {
                    <mat-spinner [diameter]="32" class="mb-4"></mat-spinner>
                    <p>{{ 'InvoicePayment.CompletingPayment' | transloco }}</p>
                } @else if (isPaymentCompleted()) {
                    <!-- payment completed -->
                    <p>{{ 'InvoicePayment.PaymentCompleted' | transloco }}</p>
                }
            }
        </div>
    </div>
</div>