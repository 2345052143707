export class HelpTagModel  {
    id!: number;
    title!: string;

    constructor(init?: Partial<HelpTagModel>) {
        if (!!init) {
            Object.assign(this, init);
        }
    }
}
