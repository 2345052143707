import { BaseRequestModel } from '../../../core/abstractions/base-request.model';
import { ImportExecutionColumnModel } from '../import-execution-column.model';

export class ExecuteImportRequestModel extends BaseRequestModel {
    isTest!: boolean;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    overwriteExistingData!: boolean;
    importExecutionColumns!: ImportExecutionColumnModel[];
    keysToIgnore?: string[];

    constructor(init?: Partial<ExecuteImportRequestModel>) {
        super();
        Object.assign(this, init);
    }
}
