import { Observable, Subject } from 'rxjs';

export abstract class ImportStepBase {
    changes: Observable<any> = new Subject();

    abstract allowNext(): boolean;
    abstract allowPrevious(): boolean;
    abstract allowCancel(): boolean;

    abstract init(): void;
    abstract next(): Observable<any>;
}
