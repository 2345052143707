<mat-sidenav-container class="h-full" autosize [hasBackdrop]="layoutService.isMobileSubject | async"
    (backdropClick)="handleLeftBackdropClick()">
    <mat-sidenav-content>
        <ng-content></ng-content>
    </mat-sidenav-content>

    <mat-sidenav
        [mode]="(layoutService.isMobileSubject | async) ? 'over' : 'side'"
        [opened]="sidebarService.leftOpenSubject | async" class="!border-r-0">
        <div class="h-full flex flex-col" [style.width.px]="sidebarWidthPx">
            <div class="grow overflow-y-auto">
                <mat-nav-list class="h-full !flex flex-col !p-0">
                    <ng-container *ngIf="!isEmptyFavouritesList; else emptyList">
                        <ng-container *ngFor="let menuItem of currentSubMenu">
                            <mat-list-item class="!h-[45px] shrink-0 hover:bg-element-hover" *ngIf="!menuItem.isDisabled; else disabledMenu"
                                routerLinkActive="!bg-element-selected" [routerLink]="menuItem.path" [queryParams]="menuItem.startingQueryParams">
                                <span matListItemTitle class="ml-2 !text-inherit">{{ menuItem.labelKey | transloco }}</span>
                            </mat-list-item>
                            <ng-template #disabledMenu>
                                <mat-list-item class="!h-[45px]" [matTooltip]="'General.ComingSoon' | transloco"
                                    disabled>
                                    <span matListItemTitle class="ml-2 !text-inherit">{{ menuItem.labelKey | transloco }}</span>
                                </mat-list-item>
                            </ng-template>
                        </ng-container>
                    </ng-container>
                    <ng-template #emptyList>
                        <p class="mt-10 mx-4 break-words text-center text-base text-on-app-light">{{ 'Layout.Menu.Favourites.NoFavourites' | transloco }}</p>
                    </ng-template>
                </mat-nav-list>
            </div>

            <!-- footer -->
            <div class="px-4 py-4 text-on-app-light text-xs">
                <div class="line-clamp-1">&copy; {{portalName}}</div>
            </div>
        </div>
    </mat-sidenav>
</mat-sidenav-container>
