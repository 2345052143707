import { ComponentRef, Directive, Input, OnChanges, ViewContainerRef, inject } from '@angular/core';

import { BaseDetailWidget } from '../../components/abstractions/base.detail-widget';
import { TableListDetailWidgetModel } from '../../components/list-views/table-list-view/models/table-list-detail-widget.model';
import { Identifyable } from '../abstractions/identifyable';

@Directive({
    selector: '[arcTableDetailWidget]'
})
export class TableDetailWidgetDirective<TList extends Identifyable<TId>, T extends Identifyable<TId>, TId = number> implements OnChanges {
    @Input() listDetailWidget!: TableListDetailWidgetModel<TList, T, TId>;
    @Input() listModel!: TList;
    @Input() entityModel!: T;
    @Input() isFromPoll = false;

    private hasLoaded = false;
    private readonly viewContainerRef = inject(ViewContainerRef);

    ngOnChanges(): void {
        if (!!this.listDetailWidget && (!this.isFromPoll || !this.hasLoaded)) {
            this.hasLoaded = true;
            this.viewContainerRef.clear();
            const component: ComponentRef<BaseDetailWidget<TList, T, TId>> = this.viewContainerRef
                .createComponent(this.listDetailWidget.component);
            component.instance.listModel = this.listModel;
            component.instance.entityModel = this.entityModel;
        }
    }
}
