export class MainCardButtonModel {
    text!: string;
    clickFn!: (data: any) => void;
    isHidden?: (data?: any) => boolean;
    isEnabled?: (data?: any) => boolean;

    constructor(init: Partial<MainCardButtonModel>) {
        Object.assign(this, init);
    }
}
