<div class="flex flex-col gap-4">
    <div class="flex justify-between">
        <div class="flex items-center gap-6">
            <p>{{ 'Feature.Edit.Language' | transloco }}</p>
            <arc-button-toggle-group [control]="language" [items]="languageItems" toggleButtonClasses="w-[80px]"></arc-button-toggle-group>
        </div>
        <button mat-icon-button (click)="translate()">
            <mat-icon>translate</mat-icon>
        </button>
    </div>
    <arc-input labelKey="Feature.EditFields.Title" [control]="title"></arc-input>
    <arc-markdown-editor styleClass="mde-mail" [formControl]="description"> </arc-markdown-editor>
    <div class="flex gap-2">
        <arc-general-data-select
            [formControl]="formGroup.controls.type"
            [generalDataType]="GeneralDataTypeEnum.FeatureTypes"
            label="Feature.EditFields.Type">
        </arc-general-data-select>
        <arc-general-data-select
            [formControl]="formGroup.controls.area"
            [generalDataType]="GeneralDataTypeEnum.ProductAreas"
            label="Feature.EditFields.Area">
        </arc-general-data-select>
        <arc-general-data-select
            [formControl]="formGroup.controls.status"
            [generalDataType]="GeneralDataTypeEnum.FeatureStatuses"
            label="Feature.EditFields.Status">
        </arc-general-data-select>
    </div>
    <arc-checkbox labelKey="Feature.EditFields.Featured" [control]="formGroup.controls.isFeatured"></arc-checkbox>
    <arc-datepicker labelKey="Feature.EditFields.ExpectedDeliveryTime" [control]="formGroup.controls.expectedDeliveryTime"></arc-datepicker>
</div>
