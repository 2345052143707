<div class="flex flex-col gap-4">
    <div class="flex justify-between">
        <div class="flex items-center gap-6">
            <p>{{ 'BlogArticle.Edit.Language' | transloco }}</p>
            <arc-button-toggle-group [control]="language" [items]="languageItems" toggleButtonClasses="w-[80px]"></arc-button-toggle-group>
        </div>
        <button mat-icon-button (click)="translate()">
            <mat-icon>translate</mat-icon>
        </button>
    </div>
    <arc-input labelKey="BlogArticle.Edit.Title" [control]="title"></arc-input>
    <arc-input labelKey="BlogArticle.Edit.Summary" [control]="summary"></arc-input>
    <arc-markdown-editor styleClass="mde-help" [formControl]="text"> </arc-markdown-editor>
    <div class="grid grid-cols-2 gap-4">
        <div>
            <arc-select
                labelKey="BlogArticle.Edit.Topic"
                class="col-span-2"
                [control]="formGroup.controls.helpTopicId"
                (change)="handleHelpTopicChange()"
                [options]="helpTopics">
            </arc-select>
        </div>
        <div>
            <arc-select
                labelKey="BlogArticle.Edit.SubTopic"
                class="col-span-2"
                [control]="formGroup.controls.helpSubTopicId"
                [options]="helpSubTopics">
            </arc-select>
        </div>
    </div>
    <arc-chip-selector labelKey="BlogArticle.Edit.HelpTags" [control]="helpTagsControl"
        [optionDisplayFn]="dropdownOptionDisplay.bind(this)" [searchFn]="tagsSearch.bind(this)"
        [selectedChips]="helpTags">
    </arc-chip-selector>
    <arc-checkbox labelKey="BlogArticle.Edit.IsPublic" [control]="formGroup.controls.isPublic"></arc-checkbox>
</div>
