import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseStore } from '../../abstractions/base.store';
import { AuthenticatedUserModel } from '../../../app/models/authenticated-user.model';
import { StoreConfig } from '../../models/store.config';
import { AuthRequestModel } from '../../../app/models/requests/auth-request.model';
import { ApiResponseModel } from '../../../app/models/responses/api-response.model';

@Injectable({
    providedIn: 'root'
})
export class AuthStore extends BaseStore {
    constructor() {
        super(new StoreConfig({ baseController: 'auth' }));
    }

    login(data: AuthRequestModel): Observable<ApiResponseModel<AuthenticatedUserModel>> {
        return this._requestService.makePost<ApiResponseModel<AuthenticatedUserModel>>(this.getUrl(''), data);
    }

    authCheck(): Observable<any> {
        return this._requestService.makeGet<any>(this.getUrl(''), undefined);
    }
}
