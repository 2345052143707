<div class="grid grid-cols-2 gap-4">
    <arc-number-input [formControl]="formGroup.controls.quantity" label="LicenseModuleBundles.Edit.BaseData.Quantity">
    </arc-number-input>
    <div></div>
    <arc-datepicker labelKey="LicenseModuleBundles.Edit.BaseData.ValidFrom" [control]="formGroup.controls.validFrom">
    </arc-datepicker>
    <arc-datepicker labelKey="LicenseModuleBundles.Edit.BaseData.ValidThru" [control]="formGroup.controls.validThru">
    </arc-datepicker>
    <arc-datepicker labelKey="LicenseModuleBundles.Edit.BaseData.BilledUntilDate"
        [control]="formGroup.controls.billedUntilDate"></arc-datepicker>
    <arc-datepicker labelKey="LicenseModuleBundles.Edit.BaseData.CancelledDate"
        [control]="formGroup.controls.cancelledDate"></arc-datepicker>
    <arc-number-input [formControl]="formGroup.controls.monthlyPrice" label="LicenseModuleBundles.Edit.BaseData.MonthlyPrice">
    </arc-number-input>
    <arc-number-input [formControl]="formGroup.controls.price" label="LicenseModuleBundles.Edit.BaseData.Price">
    </arc-number-input>
    <arc-number-input [formControl]="formGroup.controls.poolTime" label="LicenseModuleBundles.Edit.BaseData.PoolTime">
    </arc-number-input>
    <div></div>
    <arc-checkbox labelKey="LicenseModuleBundles.Edit.BaseData.IsDemo" [control]="formGroup.controls.isDemo"></arc-checkbox>
</div>