import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { StoreConfig } from '../../../core/models/store.config';
import { BaseStore } from '../../../core/abstractions/base.store';
import { ApiResponseModel } from '../../models/responses/api-response.model';
import { KeyValueModel } from '../../../core/models/key-value.model';
import { HelpTopicListModel } from '../../models/responses/help-topic-list.model';

@Injectable({
    providedIn: 'root'
})
export class GeneralDataStore extends BaseStore {
    constructor() {
        super(new StoreConfig({ baseController: 'general-data' }));
    }

    getUnreadMessagesCount(): Observable<ApiResponseModel<number>> {
        return this._requestService.makeGet<ApiResponseModel<number>>(this.getUrl('messages-count'));
    }

    getGeneralData(type: string): Observable<ApiResponseModel<KeyValueModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<KeyValueModel[]>>(this.getUrl(type));
    }

    getTranslatedValue(text: string, targetLanguage: string, sourceLanguage: string): Observable<ApiResponseModel<string>> {
        return this._requestService.makeGet<ApiResponseModel<string>>(
            this.getUrl('translate'),
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            { key: 'text', value: text },
            { key: 'targetLanguage', value: targetLanguage },
            { key: 'sourceLanguage', value: sourceLanguage }
        );
    }

    getLicenseModuleBundles(licenseId: number): Observable<ApiResponseModel<KeyValueModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<KeyValueModel[]>>(
            this.getUrl('license-module-bundles'),
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            { key: 'licenseId', value: licenseId.toString() }
        );
    }

    getLicenseHardwareOrders(licenseId: number): Observable<ApiResponseModel<KeyValueModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<KeyValueModel[]>>(
            this.getUrl('license-hardware-orders'),
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            { key: 'licenseId', value: licenseId.toString() }
        );
    }

    getLicenseHardwareBundles(licenseId: number): Observable<ApiResponseModel<KeyValueModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<KeyValueModel[]>>(
            this.getUrl('hardware-bundles'),
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            { key: 'licenseId', value: licenseId.toString() }
        );
    }

    getProductCurrency(productId: number): Observable<ApiResponseModel<string>> {
        return this._requestService.makeGet<ApiResponseModel<string>>(
            this.getUrl('product-currency'),
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            { key: 'productId', value: productId.toString() }
        );
    }

    getProductHostUrl(productId: number): Observable<ApiResponseModel<string>> {
        return this._requestService.makeGet<ApiResponseModel<string>>(
            this.getUrl('product-host'),
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            { key: 'productId', value: productId.toString() }
        );
    }
    getIsDomainUnique(domain: string, hostUrl: string): Observable<ApiResponseModel<boolean>> {
        return this._requestService.makeGet<ApiResponseModel<boolean>>(
            this.getUrl('domain-unique'),
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            { key: 'subDomain', value: domain },
            { key: 'hostUrl', value: hostUrl }
        );
    }

    getHelpTopics(): Observable<ApiResponseModel<HelpTopicListModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<HelpTopicListModel[]>>(
            this.getUrl('help-topics')
        );
    }
}
