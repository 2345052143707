import { Injectable } from '@angular/core';
import { Observable, Subject, debounceTime, distinctUntilChanged, tap } from 'rxjs';

import { SearchRequestModel } from '../../app/models/requests/search-request.model';
import { ColumnFilterModel } from '../models/column-filter.model';
import { ColumnSortModel } from '../models/column-sort.model';
import { Utils } from '../utils/tools/utils.tools';

@Injectable({
    providedIn: 'root'
})
export class SearchRequestService {
    private static readonly searchRequestDebounceTimeMs = 250;

    loadingStart$: Observable<void>;
    searchRequestChanged$: Observable<SearchRequestModel>;

    private readonly searchRequestLoadingStartSub = new Subject<void>();
    private readonly searchRequestChangedSub = new Subject<SearchRequestModel>();
    private readonly internalSearchRequestUpdateSub = new Subject<SearchRequestModel>();

    private currentSearchRequest = new SearchRequestModel();

    get current(): SearchRequestModel {
        return Utils.deepCopy(this.currentSearchRequest);
    }

    constructor() {
        this.loadingStart$ = this.searchRequestLoadingStartSub.asObservable();
        this.searchRequestChanged$ = this.searchRequestChangedSub.asObservable();

        this.internalSearchRequestUpdateSub
            .pipe(
                distinctUntilChanged(this.hasChanged),
                tap(() => {
                    // start loading now, before debounce time
                    this.searchRequestLoadingStartSub.next();
                }),
                debounceTime(SearchRequestService.searchRequestDebounceTimeMs)
            )
            .subscribe((searchRequest) => {
                this.searchRequestChangedSub.next(searchRequest);
            });
    }

    init(searchRequest: SearchRequestModel): void {
        this.currentSearchRequest = Utils.deepCopy(searchRequest);
        this.checkForUpdate();
    }

    forceReload(): void {
        this.searchRequestChangedSub.next(this.currentSearchRequest);
    }

    setFilters(filters: ColumnFilterModel[]): void {
        this.currentSearchRequest.filters = Utils.deepCopy(filters);
        this.checkForUpdate();
    }

    setSortings(sortings: ColumnSortModel[]): void {
        this.currentSearchRequest.sortings = Utils.deepCopy(sortings);
        this.checkForUpdate();
    }

    setSearchText(searchText?: string): void {
        this.currentSearchRequest.searchText = searchText;
        this.checkForUpdate();
    }

    setPaginatorOptions(pageIndex?: number, pageSize?: number): void {
        this.currentSearchRequest.pageIndex = pageIndex;
        this.currentSearchRequest.pageSize = pageSize;
        this.checkForUpdate();
    }

    private checkForUpdate(): void {
        this.internalSearchRequestUpdateSub.next(Utils.deepCopy(this.currentSearchRequest));
    }

    private hasChanged(previous: SearchRequestModel, current: SearchRequestModel): boolean {
        return JSON.stringify(previous) === JSON.stringify(current);
    }
}
