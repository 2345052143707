import { Injectable } from '@angular/core';

import { BaseStorage } from '../../abstractions/base.storage';
import { OptionalType } from '../../models/types/optional.type';
import { AuthenticatedUserModel } from '../../../app/models/authenticated-user.model';

@Injectable({
    providedIn: 'root'
})
export class SecurityStorage extends BaseStorage {
    private userInfoKey = 'userInfo';
    private loggedUserDbKeyKey = 'loggedUserDbKey';
    private get loggedUserKey(): OptionalType<string> {
        return this.get<string>(this.loggedUserDbKeyKey);
    }

    constructor() {
        super('security');
    }

    saveUserInfo(userInfo: AuthenticatedUserModel): void {
        this.saveLoggedUserKey(userInfo);
        this.save(userInfo, this.getUserKey(this.userInfoKey));
    }

    getUserInfo(): OptionalType<AuthenticatedUserModel> {
        return this.get<AuthenticatedUserModel>(this.getUserKey(this.userInfoKey));
    }

    eraseSecurityData(): void {
        this.remove(
            this.getUserKey(this.userInfoKey),
            this.loggedUserDbKeyKey
        );
    }

    private saveLoggedUserKey(userInfo: AuthenticatedUserModel): void {
        this.save(`${userInfo.id}`, this.loggedUserDbKeyKey);
    }

    private getUserKey(key: string): string {
        return `${key}_${this.loggedUserKey}`;
    }
}
