import { StoreConfigActions } from './store-config-actions';

/**
 * Store configuration.
 */
export class StoreConfig {
    /**
     * Base controller.
     * In standard use cases, it will be the controller's name.
     *  - i.e: For the controller UsersController one route /api/users/... The value "users" should be provided.
     * A full URL (absolute) is also accepted for special cases.
     * In this scenario, the base URL of the app will be ignored and this URL will be used instead.
     * Obs: It should start with "[protocol]://" where protocol can be "http", "https", "ftp", etc.
     *  - i.e: "https://external-server.com/api-address/zip-code"
     */
    baseController?: string;

    /**
     * Actions.
     * Allows to change the name of the actions, if they're not standard.
     */
    actions = new StoreConfigActions();

    constructor(init?: Partial<StoreConfig>) {
        Object.assign(this, init);
    }
}
