import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { StoreConfig } from '../../../core/models/store.config';
import { InvoiceModel } from '../../models/invoice.model';
import { InvoiceListModel } from '../../models/responses/invoice-list.model';
import { InvoiceEditModel } from '../../models/requests/invoice-edit.model';
import { ApiResponseModel } from '../../models/responses/api-response.model';
import { InvoicePaymentModel } from '../../models/responses/invoice-payment.model';

@Injectable({
    providedIn: 'root'
})
export class InvoicesStore extends BaseCrudStore<InvoiceModel, InvoiceListModel, InvoiceEditModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'invoices' }));
    }

    createPaymentIntentForInvoice(hash: string): Observable<ApiResponseModel<InvoicePaymentModel>> {
        return this._requestService.makePost<ApiResponseModel<InvoicePaymentModel>>(this.getUrl(`${hash}/payment-intent`));
    }

    confirmPayment(hash: string): Observable<ApiResponseModel<boolean>> {
        return this._requestService.makePost<ApiResponseModel<boolean>>(this.getUrl(`${hash}/confirm-payment`));
    }
}
