<mat-form-field [floatLabel]="hasFloatingLabel ? 'always' : 'auto'" class="w-full" [color]="color"
    [arcErrorTooltip]="_formControl" [subscriptSizing]="hasFixedSubscript ? 'fixed' : 'dynamic'">
    <mat-label *ngIf="!!label">{{ isLabelTranslated ? label : (label | transloco)}}</mat-label>
    <input matInput (input)="onInput()" (blur)="onBlur()" (change)="onInputChanged()" [attr.step]="inputStep"
        [placeholder]="(!placeholder || isPlaceholderTranslated) ? placeholder : (placeholder | transloco)"
        [formControl]="internalControl" [readonly]="isReadonly"/>
    <div matSuffix *ngIf="!!unit && isAllowed" class="text-on-app-light whitespace-pre">
        {{ isUnitTranslated ? unitText : (unitText | transloco) }}
    </div>
    <span class="!absolute right-0 top-1/2 -translate-y-1/2">
        <arc-no-permission-lock [requiredModule]="requiredModule" [requiredPermission]="requiredPermission"
            [requiredPermissionType]="requiredPermissionType"
            (notAllowed)="handleNotAllowed()"></arc-no-permission-lock>
    </span>
</mat-form-field>
