import { TransactionTypeEnum } from '../enums/transaction-type.enum';
import { BaseEntityModel } from '../../../core/abstractions/base-entity.model';

export class TransactionEditModel extends BaseEntityModel {
    valueDate!: Date;
    licenseId!: number;
    type!: TransactionTypeEnum;
    poolTime?: number;
    amount?: number;
    publicRemark?: string;
    internalRemark?: string;
    discountGiven?: number;
    licenseModuleBundleId?: number;
    licenseHardwareOrderId?: number;

    constructor(init?: Partial<TransactionEditModel>) {
        super();

        if (!!init) {
            Object.assign(this, init);
        }
    }
}
