import { Component } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { ModuleBundleModel } from '../../../../../models/module-bundle.model';
import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ModuleBundleEditModel } from '../../../../../models/requests/module-bundle-edit.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';
import { CustomValidators } from '../../../../../../core/utils/custom-validators';

@Component({
    selector: 'arc-module-bundle-base-data',
    templateUrl: './module-bundle-base-data.component.html',
    styleUrl: './module-bundle-base-data.component.scss'
})
export class ModuleBundleBaseDataComponent extends BaseEditSidebarItemComponent<ModuleBundleModel, ModuleBundleEditModel> {
    override formGroup = new FormGroup({
        productId: new ArcFormControl(0, Validators.required),
        key: new ArcFormControl('', [Validators.required, Validators.maxLength(10)]),
        title_de: new ArcFormControl('', Validators.required),
        title_en: new ArcFormControl<OptionalType<string>>(undefined),
        title_fr: new ArcFormControl<OptionalType<string>>(undefined),
        title_it: new ArcFormControl<OptionalType<string>>(undefined),
        minQuantity: new ArcFormControl(0, [Validators.required, CustomValidators.number({ min: 0, isInteger: true })]),
        maxQuantity: new ArcFormControl(0, [Validators.required, CustomValidators.number({ min: 0, isInteger: true })]),
        price: new ArcFormControl(0, [Validators.required, CustomValidators.number({ min: 0.0 })]),
        monthlyPrice: new ArcFormControl(0, [Validators.required, CustomValidators.number({ min: 0.0 })]),
        noBenefit: new ArcFormControl(false, Validators.required),
        usePosQuantity: new ArcFormControl(false, Validators.required)
    });

    GeneralDataTypeEnum = GeneralDataTypeEnum;

    onItemSet(): void {
        this.formGroup.patchValue(this.item);
    }

    override prepareSaveModel(itemCopy: ModuleBundleEditModel): ModuleBundleEditModel {
        return new ModuleBundleEditModel({ ...itemCopy, ...this.formGroup.value });
    }
}
