import * as dayjs from 'dayjs';
import 'dayjs/locale/de';
import * as localeData from 'dayjs/plugin/localeData';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import * as localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localeData);
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);

export default dayjs;
