import {
    Component,
    HostBinding,
    ViewChild,
    inject,
    AfterViewInit
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
    CdkDragDrop,
    moveItemInArray,
    transferArrayItem
} from '@angular/cdk/drag-drop';
import { MatTabGroup } from '@angular/material/tabs';

import { TableSettingsDialogModel } from './models/table-settings-dialog.model';
import { TableSettingsModel } from '../../../../core/models/table-settings.model';
import { AuthService } from '../../../../core/services/auth.service';

@Component({
    selector: 'arc-table-settings-dialog',
    templateUrl: './table-settings-dialog.component.html',
    styleUrls: ['./table-settings-dialog.component.scss']
})
export class TableSettingsDialogComponent implements AfterViewInit {
    @HostBinding('class') classes = 'flex flex-col h-full';
    @ViewChild('tabGroup') tabGroup!: MatTabGroup;

    isLoading = true;
    currentColumns: string[];
    availableColumns: string[];
    currentDetailWidgets: string[];
    availableDetailWidgets: string[];
    currentTab: number;
    readonly data: TableSettingsDialogModel = inject(MAT_DIALOG_DATA);

    private readonly dialogRef = inject(MatDialogRef);
    private readonly authService = inject(AuthService);

    constructor() {
        this.currentColumns = this.data.currentColumnOrder;
        this.availableColumns = this.getCurrentAvailableColumns();
        this.currentDetailWidgets = this.getCurrentDetailWidgets();
        this.availableDetailWidgets = this.getCurrentAvailableDetailWidgets();
        this.currentTab = 0;
    }

    ngAfterViewInit(): void {
        if (this.data.currentTab) {
            this.currentTab = this.data.currentTab;
        }
    }

    drop(event: CdkDragDrop<string[]>): void {
        if (event.previousContainer === event.container) {
            moveItemInArray(
                event.container.data,
                event.previousIndex,
                event.currentIndex
            );
        } else {
            transferArrayItem(
                event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex
            );
        }
    }

    resetColumnsToDefault(): void {
        this.currentColumns = [...this.data.defaultColumnOrder];
        this.availableColumns = this.getCurrentAvailableColumns();
    }

    resetDetailWidgetsToDefault(): void {
        this.currentDetailWidgets = [...this.data.defaultDetailWidgetOrder];
        this.availableDetailWidgets = this.getCurrentAvailableDetailWidgets();
    }

    save(): void {
        this.dialogRef.close(
            new TableSettingsModel({
                columnOrder: this.currentColumns,
                detailWidgetOrder: this.currentDetailWidgets
            })
        );
    }

    private getCurrentDetailWidgets(): string[] {
        const result: string[] = [];

        this.data.currentDetailWidgetOrder.forEach((k) => {
            const item = this.data.availableDetailWidgets[k];

            if (!!item && !item.requiredPermission) {
                result.push(k);
            }
        });

        return result;
    }

    private getCurrentAvailableColumns(): string[] {
        return Object.entries(this.data.availableColumns)
            .map(([name]) => name)
            .filter((name) => !this.currentColumns.includes(name));
    }

    private getCurrentAvailableDetailWidgets(): string[] {
        return Object.entries(this.data.availableDetailWidgets)
            .map(([name]) => name)
            .filter(
                (name) =>
                    !this.currentDetailWidgets.includes(name) &&
          !this.data.availableDetailWidgets[name].requiredPermission
            );
    }
}
