<div class="h-full">
    <div class="h-full w-full">
        <div *ngIf="isLoading" class="w-full h-full grid place-items-center">
            <mat-spinner [diameter]="32"></mat-spinner>
        </div>
        <arc-static-basic-table-list-view
            *ngIf="!!tableConfig && !isLoading"
            [config]="tableConfig"
            [shouldUseCompactStyle]="true"></arc-static-basic-table-list-view>
    </div>
</div>
