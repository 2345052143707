import { Component, Input, inject } from '@angular/core';

import { CustomerModel } from '../../../../app/models/customer.model';
import { DateService } from '../../../../core/services/date.service';

@Component({
    selector: 'arc-customer-data',
    templateUrl: './customer-data.component.html',
    styleUrls: ['./customer-data.component.scss']
})
export class CustomerDataComponent {
    @Input() model?: CustomerModel;

    readonly dateService = inject(DateService);

    /**
     * removes the protocol from the url like 'http://' or 'https://'
     */
    get websiteUrl(): string {
        const url = this.model?.contactUrl ?? '';
        return url.slice(url.indexOf('//') + 1);
    }
}
