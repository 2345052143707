<form [formGroup]="formGroup" (ngSubmit)="submitForm()" class="flex flex-col">
    <ng-container *ngFor="let item of items">
        <ng-container [ngSwitch]="item.field.type">
            <ng-container *ngSwitchCase="DynamicFormControlTypeEnum.Text">
                <arc-input class="mb-4" [control]="item.control" [placeholder]="item.field.label" type="text"
                    [label]="item.field.isLabelTranslated ? item.field.label : ''"
                    [labelKey]="!item.field.isLabelTranslated ? item.field.label : ''"></arc-input>
            </ng-container>
            <ng-container *ngSwitchCase="DynamicFormControlTypeEnum.Label">
                <div class="ml-4">
                    <span>{{ item.field.isLabelTranslated ? item.field.label : (item.field.label | transloco) }}</span>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="DynamicFormControlTypeEnum.Number">
                <arc-input class="mb-4" [control]="item.control" [placeholder]="item.field.label" type="number"
                    [step]="1" [label]="item.field.isLabelTranslated ? item.field.label : ''"
                    [labelKey]="!item.field.isLabelTranslated ? item.field.label : ''"></arc-input>
            </ng-container>
            <ng-container *ngSwitchCase="DynamicFormControlTypeEnum.Decimal">
                <arc-input class="mb-4" [control]="item.control" [placeholder]="item.field.label" type="number"
                    [step]="0.01" [label]="item.field.isLabelTranslated ? item.field.label : ''"
                    [labelKey]="!item.field.isLabelTranslated ? item.field.label : ''"></arc-input>
            </ng-container>
            <ng-container *ngSwitchCase="DynamicFormControlTypeEnum.Checkbox">
                <arc-checkbox [control]="item.control" [placeholder]="item.field.label" [label]="item.field.label"
                    [label]="item.field.isLabelTranslated ? item.field.label : ''"
                    [labelKey]="!item.field.isLabelTranslated ? item.field.label : ''"></arc-checkbox>
            </ng-container>
            <div class="mb-4" *ngSwitchCase="DynamicFormControlTypeEnum.Date">
                <ng-container>
                    <arc-datepicker [control]="item.control" [label]="item.field.label"
                        [label]="item.field.isLabelTranslated ? item.field.label : ''"
                        [labelKey]="!item.field.isLabelTranslated ? item.field.label : ''"></arc-datepicker>
                </ng-container>
            </div>
            <div class="mb-4" *ngSwitchCase="DynamicFormControlTypeEnum.DateRange">
                <ng-container>
                    <arc-date-range-picker [control]="asFormGroup(item.control).controls['FromDate']"
                        [controlEnd]="asFormGroup(item.control).controls['ToDate'] " [label]="item.field.label"
                        [label]="item.field.isLabelTranslated ? item.field.label : ''"
                        [labelKey]="!item.field.isLabelTranslated ? item.field.label : ''"></arc-date-range-picker>
                </ng-container>
            </div>
            <div class="mb-4" *ngSwitchCase="DynamicFormControlTypeEnum.ButtonToggle">
                <section>
                    <span *ngIf="!!item.field.label">{{ item.field.label }}</span>
                    <arc-button-toggle-group [control]="item.control | formControl" [items]="item.field.selectOptions!"></arc-button-toggle-group>
                </section>
            </div>
            <ng-container *ngSwitchCase="DynamicFormControlTypeEnum.Select">
                <arc-select class="mb-4" [control]="item.control" [options]="item.field.selectOptions!"
                    [isMultiselect]="item.field.isMultiselect"
                    [label]="item.field.isLabelTranslated ? item.field.label : ''"
                    [labelKey]="!item.field.isLabelTranslated ? item.field.label : ''"></arc-select>
            </ng-container>
            <ng-container
                *ngSwitchCase="DynamicFormControlTypeEnum.GeneralData && isDefinedGeneralDataType(item.field.generalDataType)">
                <arc-general-data-select class="mb-4" [formControl]="item.control  | formControl"
                    [isMultiselect]="item.field.isMultiselect" [generalDataType]="item.field.generalDataType!"
                    [label]="item.field.label"
                    [isLabelTranslated]="item.field.isLabelTranslated"></arc-general-data-select>
            </ng-container>
            <ng-container *ngSwitchCase="DynamicFormControlTypeEnum.Markdown">
                <arc-markdown-editor [formControl]="item.control | formControl">
                </arc-markdown-editor>
            </ng-container>
            <ng-container *ngSwitchDefault>
                <arc-input class="mb-4" [control]="item.control" [placeholder]="item.field.label" type="text"
                    [label]="item.field.isLabelTranslated ? item.field.label : ''"
                    [labelKey]="!item.field.isLabelTranslated ? item.field.label : ''"></arc-input>
            </ng-container>
        </ng-container>
    </ng-container>
</form>