<form [formGroup]="formGroup" class="flex flex-col gap-4">
    <arc-quick-search [config]="ComplexDataTypesEnum.User | arcQuickSearchConfig" [formControl]="formGroup.controls.userId"
        class="col-span-2" label="License.Edit.Users.LicenseHolder">
    </arc-quick-search>
    <arc-quick-search [config]="ComplexDataTypesEnum.Reseller | arcQuickSearchConfig" [formControl]="formGroup.controls.resellerId"
        class="col-span-2" label="License.Edit.Users.Reseller">
    </arc-quick-search>
    <arc-quick-search [config]="ComplexDataTypesEnum.User | arcQuickSearchConfig" [formControl]="formGroup.controls.recommendationUserId"
        class="col-span-2" label="License.Edit.Users.RecommendationUser">
    </arc-quick-search>
</form>