import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from '../views/security/login/login.component';
import { DefaultLayoutComponent } from '../../components/layouts/default-layout/default-layout.component';
import { HomeComponent } from '../views/home/home.component';
import { OnlyLoggedUsersGuard } from '../../core/services/guards/only-logged-users.guard';
import { RequestPasswordTokenComponent } from '../views/security/request-pwd-token/request-password-token.component';
import { ResetPasswordComponent } from '../views/security/reset-password/reset-password.component';
import { NoPaddingLayoutComponent } from '../../components/layouts/no-padding-layout/no-padding-layout.component';
import { storesRoutes } from './stores.routes';
import { settingsRoutes } from './settings.routes';
import { LoginLayoutComponent } from '../../components/layouts/login-layout/login-layout.component';
import { licensesRoutes } from './licences.routes';
import { supportRoutes } from './support.routes';
import { marketingRoutes } from './marketing.routes';
import { basicDataRoutes } from './basic-data.routes';
import { ReportsViewerComponent } from '../views/basic-data/report-templates/reports-viewer/reports-viewer.component';
import { ReportsDesignerComponent } from '../views/basic-data/report-templates/designer/reports-designer.component';
import { RegisterComponent } from '../views/security/register/register.component';
import { ContractViewComponent } from '../views/contract-view/contract-view.component';
import { HelpArticleViewComponent } from '../views/help-article-view/help-article-view.component';
import { DemoAvailableSoonComponent } from '../views/demo-available-soon/demo-available-soon.component';
import { ConfiguratorAvailableSoonComponent } from '../views/configurator-available-soon/configurator-available-soon.component';
import { redirectInvoicesGuard } from '../../core/services/guards/redirect-invoices.guard';
import { InvoicePayViewComponent } from '../views/invoice-pay-view/invoice-pay-view.component';

export const routes: Routes = [
    // Main redirect.
    { path: '', redirectTo: 'home', pathMatch: 'full' },

    // temporary: no layout
    { path: 'Demo', component: DemoAvailableSoonComponent },
    { path: 'License/NewSaasLicense', pathMatch: 'full', component: ConfiguratorAvailableSoonComponent },

    // proxy to redirect old invoices urls to portal api
    {
        path: 'Reports/Invoice.rdlx',
        pathMatch: 'full',
        canActivate: [redirectInvoicesGuard],

        // component doesn't matter here, window is closed or redirected before the component is loaded
        component: HomeComponent
    },

    // Full screen views without layout.
    {
        path: '',
        component: LoginLayoutComponent,
        children: [
            { path: 'login', component: LoginComponent },
            { path: 'register', component: RegisterComponent },
            {
                path: 'request-password-reset',
                component: RequestPasswordTokenComponent
            },
            { path: 'reset-password/:userId', component: ResetPasswordComponent }
        ]
    },

    // Default layout.
    {
        path: '',
        component: DefaultLayoutComponent,
        canActivate: [OnlyLoggedUsersGuard],
        children: [...storesRoutes, ...settingsRoutes, ...licensesRoutes, ...supportRoutes, ...marketingRoutes, ...basicDataRoutes]
    },

    // No padding layout.
    {
        path: '',
        component: NoPaddingLayoutComponent,
        canActivate: [OnlyLoggedUsersGuard],
        children: [
            { path: 'home', component: HomeComponent, data: { subMenu: 'home' } },
            {
                path: 'report-templates',
                children: [
                    { path: 'view/:id', component: ReportsViewerComponent, data: { subMenu: 'basic-data' } },
                    { path: 'edit/:id', component: ReportsDesignerComponent, data: { subMenu: 'basic-data' } }
                ]
            },
            {
                path: 'pos-report-templates',
                children: [
                    { path: 'view/:id', component: ReportsViewerComponent, data: { subMenu: 'basic-data' } },
                    { path: 'edit/:id', component: ReportsDesignerComponent, data: { subMenu: 'basic-data' } }
                ]
            }
        ]
    },
    { path: 'legal/:key', component: ContractViewComponent },
    { path: 'help-article/:id', component: HelpArticleViewComponent },
    { path: 'invoice/:hash', component: InvoicePayViewComponent },
    // TODO: 404 page
    { path: '**', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
