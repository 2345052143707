import { Component, inject } from '@angular/core';
import { first } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ContractEditModel } from '../../../../../models/requests/contract-edit.model';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { TranslationService } from '../../../../../../core/services/translation.service';
import { ContractModel } from '../../../../../models/contract.model';

@Component({
    selector: 'arc-contract-edit-base-data',
    templateUrl: './contract-edit-base-data.component.html',
    styleUrl: './contract-edit-base-data.component.scss'
})
export class ContractEditBaseDataComponent extends BaseEditSidebarItemComponent<ContractModel, ContractEditModel> {
    GeneralDataTypeEnum = GeneralDataTypeEnum;
    language = new FormControl<'en' | 'fr' | 'de' | 'it'>('de');

    override formGroup = new FormGroup({
        key: new ArcFormControl('', Validators.required),
        title_de: new ArcFormControl('', Validators.required),
        text_de: new ArcFormControl('', Validators.required),
        lastChangesSummary_de: new ArcFormControl('', Validators.required),
        title_en: new ArcFormControl<OptionalType<string>>(undefined),
        text_en: new ArcFormControl<OptionalType<string>>(undefined),
        lastChangesSummary_en: new ArcFormControl<OptionalType<string>>(undefined),
        title_fr: new ArcFormControl<OptionalType<string>>(undefined),
        text_fr: new ArcFormControl<OptionalType<string>>(undefined),
        lastChangesSummary_fr: new ArcFormControl<OptionalType<string>>(undefined),
        title_it: new ArcFormControl<OptionalType<string>>(undefined),
        text_it: new ArcFormControl<OptionalType<string>>(undefined),
        lastChangesSummary_it: new ArcFormControl<OptionalType<string>>(undefined)
    });

    get title(): FormControl {
        switch (this.language.value) {
            case 'en':
                return this.formGroup.controls.title_en;
            case 'fr':
                return this.formGroup.controls.title_fr;
            case 'de':
                return this.formGroup.controls.title_de;
            case 'it':
                return this.formGroup.controls.title_it;
            default:
                return this.formGroup.controls.title_de;
        }
    }

    get text(): FormControl {
        switch (this.language.value) {
            case 'en':
                return this.formGroup.controls.text_en;
            case 'fr':
                return this.formGroup.controls.text_fr;
            case 'de':
                return this.formGroup.controls.text_de;
            case 'it':
                return this.formGroup.controls.text_it;
            default:
                return this.formGroup.controls.text_de;
        }
    }

    get lastChangesSummary(): FormControl {
        switch (this.language.value) {
            case 'en':
                return this.formGroup.controls.lastChangesSummary_en;
            case 'fr':
                return this.formGroup.controls.lastChangesSummary_fr;
            case 'de':
                return this.formGroup.controls.lastChangesSummary_de;
            case 'it':
                return this.formGroup.controls.lastChangesSummary_it;
            default:
                return this.formGroup.controls.lastChangesSummary_de;
        }
    }

    readonly translationService = inject(TranslationService);

    languageItems = [
        { value: 'de', label: this.translationService.getText('General.LanguagesShort.De') },
        { value: 'en', label: this.translationService.getText('General.LanguagesShort.En') },
        { value: 'fr', label: this.translationService.getText('General.LanguagesShort.Fr') },
        { value: 'it', label: this.translationService.getText('General.LanguagesShort.It') }
    ];

    onItemSet(): void {
        this.formGroup.patchValue({ ...this.item });
    }

    override prepareSaveModel(itemCopy: ContractEditModel): ContractEditModel {
        return new ContractEditModel({ ...itemCopy, ...this.formGroup.value });
    }

    translate(): void {
        const controlsToTranslate = ['title', 'text', 'lastChangesSummary'];
        this.translationService.translateFormControls(this.formGroup, controlsToTranslate).pipe(first()).subscribe();
    }
}
