import { GridsterItem } from 'angular-gridster2';

import { WidgetTypeEnum } from './enums/widget-type.enum';

export class WidgetModel {
    /**
     * The unique id of the widget.
     */
    id = '';

    /**
     * The Type of Widget to be displayed.
     */
    type!: WidgetTypeEnum;

    /**
     * The default amount of dashboard rows taken by a newly added widget of this type.
     */
    defaultRows = 3;

    /**
     * The default amount of dashboard columns taken by a newly added widget of this type.
     */
    defaultColumns = 3;

    /**
     * The minimum permited dashboard rows used to display this widget type.
     */
    minRows = 2;

    /**
     * The minimum permited dashboard columns used to display this widget type.
     */
    minColumns = 2;

    /**
     * Defines if more than one instance of this widget type may be added to the dashboard.
     */
    allowMultipleInstances = false;

    /**
     * A string to be displayed as title of the widget.
     */
    title!: string;

    readonly gridsterItem!: GridsterItem;

    private localStartX!: number;
    private localStartY!: number;

    get defaultStartX(): number {
        return this.localStartX;
    }
    set defaultStartX(newX: number) {
        this.gridsterItem.x = newX;
        this.localStartX = newX;
    }

    get defaultStartY(): number {
        return this.localStartY;
    }
    set defaultStartY(newY: number) {
        this.gridsterItem.y = newY;
        this.localStartY = newY;
    }

    constructor(init: Partial<WidgetModel>, id?: string) {
        if (!!init) {
            Object.assign(this, init);
        }

        // new objects receive an id
        // and only when creating the gridster-item should be initialized
        if (!!id) {
            this.id = id;

            if (!init.gridsterItem) {
                this.gridsterItem = {
                    id: `gi-card-${this.id}`,
                    cols: this.defaultColumns,
                    rows: this.defaultRows,
                    minItemRows: this.minRows,
                    minItemCols: this.minColumns,
                    x: this.localStartX,
                    y: this.localStartY
                };
            }
        }
    }
}
