import { Component } from '@angular/core';

import { BaseCustomColumnComponent } from '../../../../../../components/dynamic-table/column-components/custom-column/base-custom-column.component';
import { HelpArticleListModel } from '../../../../../models/responses/help-article-list.model';

@Component({
    selector: 'arc-help-sub-topic-item',
    templateUrl: './help-sub-topic-item.component.html',
    styleUrl: './help-sub-topic-item.component.scss'
})
export class HelpSubTopicItemComponent extends BaseCustomColumnComponent<HelpArticleListModel> {}
