import { DictionaryType } from '../models/types/dictionary.type';
import { OptionalType } from '../models/types/optional.type';

export class CacheService {
    private data: DictionaryType<any> = {};

    get<T>(key: string): OptionalType<T> {
        return this.data[key];
    }

    set<T>(key: string, data: T): void {
        this.data[key] = data;
    }

    clear(key: string): void {
        this.data[key] = undefined;
    }
}
