import { Params } from '@angular/router';

export class MenuItemModel {
    path!: string;
    labelKey!: string;
    isDisabled!: boolean;
    favouriteId?: string;
    startingQueryParams?: Params;

    constructor(init?: Partial<MenuItemModel>) {
        if (!!init) {
            Object.assign(this, init);
        }
    }
}
