import { SelectOptionModel } from '../../models/select-option.model';

export class Time {
    /**
     * Generates a list of time options for a select.
     * @param minutesInterval
     */
    static generateSelectList(minutesInterval = 30): SelectOptionModel<Date>[] {
        const sections = 24 / (minutesInterval / 60);
        const startDate = new Date('1970-01-01');
        const result: SelectOptionModel<Date>[] = [{ label: '00:00', value: startDate }];

        for (let i = 1; i < sections; i++) {
            const currentMinutes = minutesInterval * i;
            const currDate = new Date(new Date(startDate.getTime() + currentMinutes * 60000));
            const hours = Math.floor(currentMinutes / 60).toString().padStart(2, '0');
            const minutes = ((currentMinutes / 60 - Math.floor(currentMinutes / 60)) * 60).toString().padStart(2, '0');

            result.push({
                label: `${hours}:${minutes}`,
                value: currDate
            });
        }

        return result;
    }
}
