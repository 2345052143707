import { ComponentType } from '@angular/cdk/portal';

import { ColumnTypeEnum } from '../enums/column-type.enum';
import { BaseColumnModel } from './base-column.model';
import { BaseCustomColumnComponent } from '../../column-components/custom-column/base-custom-column.component';

export class CustomColumnModel<T extends object> extends BaseColumnModel {
    customComponent!: ComponentType<BaseCustomColumnComponent<T>>;
    constructor(init?: Partial<CustomColumnModel<T>>) {
        super(ColumnTypeEnum.Custom);

        if (!!init) {
            Object.assign(this, init);
        }
    }
}
