import { Component, Input, OnChanges } from '@angular/core';

import { BaseButtonsBarComponent } from '../abstractions/base-buttons-bar.component';
import { Identifyable } from '../../core/abstractions/identifyable';

@Component({
    selector: 'arc-buttons-bar',
    templateUrl: './buttons-bar.component.html',
    styleUrls: ['./buttons-bar.component.scss']
})
export class ButtonsBarComponent<T extends Identifyable<TId>, TId> extends BaseButtonsBarComponent<T, TId> implements OnChanges {
    @Input() data: any;

    override ngOnChanges(): void {
        this.setupItems(this.data);
    }
}
