import { Component, inject } from '@angular/core';

import { BaseColumnComponent } from '../base-column.component';
import { DateColumnModel } from '../../models/column-types/date-column.model';
import { DateService } from '../../../../core/services/date.service';

@Component({
    selector: 'arc-date-column',
    templateUrl: './date-column.component.html',
    styleUrls: ['./date-column.component.scss']
})
export class DateColumnComponent<T extends object> extends BaseColumnComponent<T> {
    private readonly dateService = inject(DateService);

    get castedColumnModel(): DateColumnModel {
        return this.columnModel as DateColumnModel;
    }

    getDateValue(): string {
        return this.dateService.format(this.getItemValue(this.castedColumnModel.propertyName), this.castedColumnModel.format);
    }
}

