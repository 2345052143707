import { Component, inject } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { LicenseEditModel } from '../../../../../models/requests/license-edit.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { PaymentTypesEnum } from '../../../../../models/enums/payment-types.enum';
import { PaymentPeriodsEnum } from '../../../../../models/enums/payment-periods.enum';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { LicenseModel } from '../../../../../models/license.model';
import { TranslationService } from '../../../../../../core/services/translation.service';

@Component({
    selector: 'arc-license-payment',
    templateUrl: './license-payment.component.html',
    styleUrl: './license-payment.component.scss'
})
export class LicensePaymentComponent extends BaseEditSidebarItemComponent<LicenseModel, LicenseEditModel> {
    override formGroup = new FormGroup({
        paymentPeriod: new ArcFormControl(PaymentPeriodsEnum.Monthly, Validators.required),
        paymentType: new ArcFormControl(PaymentTypesEnum.Card, Validators.required),
        isDemo: new ArcFormControl(false, Validators.required),
        benefitCode: new ArcFormControl<OptionalType<string>>(undefined)
    });

    PaymentPeriods = PaymentPeriodsEnum;
    PaymentTypes = PaymentTypesEnum;

    readonly translationService = inject(TranslationService);

    paymentPeriods = [
        {
            value: PaymentPeriodsEnum.Monthly,
            label: this.translationService.getText('License.Edit.Payment.Monthly')
        },
        {
            value: PaymentPeriodsEnum.Annual,
            label: this.translationService.getText('License.Edit.Payment.Yearly')
        }
    ];

    paymentTypes = [
        {
            value: PaymentTypesEnum.Invoice,
            label: this.translationService.getText('License.Edit.Payment.Invoice')
        },
        {
            value: PaymentTypesEnum.Card,
            label: this.translationService.getText('License.Edit.Payment.Card')
        }
    ];

    onItemSet(): void {
        this.formGroup.patchValue(this.item);
    }

    override prepareSaveModel(itemCopy: LicenseEditModel): LicenseEditModel {
        return new LicenseEditModel({ ...itemCopy, ...this.formGroup.value });
    }
}
