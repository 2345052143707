<form (submit)="save()" [formGroup]="form">
    <h1 mat-dialog-title>{{ 'Components.SearchFilter.SaveFilter' | transloco }}</h1>
    <mat-dialog-content>
        <mat-form-field class="!mt-2">
            <mat-label>{{ 'Components.SearchFilter.FilterName' | transloco }}</mat-label>
            <input matInput cdkFocusInitial [formControl]="form.controls.name" />
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-stroked-button mat-dialog-close>{{ 'General.Actions.Cancel' | transloco }}</button>
        <button mat-flat-button type="submit" color="accent">{{ 'General.Actions.Save' | transloco }}</button>
    </mat-dialog-actions>
</form>