import { Directive, HostListener, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { Subscription } from 'rxjs';

import { FormControlErrorsService } from '../services/form-control-errors.service';
import { Utils } from '../utils/tools/utils.tools';
import { LayoutService } from '../services/layout.service';

@Directive({
    selector: '[arcErrorTooltip]'
})
export class ErrorTooltipDirective extends MatTooltip implements OnInit, OnDestroy {
    @Input('arcErrorTooltip') formControl?: AbstractControl;
    @Input() arcTooltipClass = 'arc-tooltip-warn';

    private lastErrors?: ValidationErrors | null;
    private statusChangesSub?: Subscription;

    private scrollSub?: Subscription;

    private readonly formControlErrorsService = inject(FormControlErrorsService);
    private readonly layoutService = inject(LayoutService);

    @HostListener('mouseover') mouseOver(): void {
        this.show();
    }

    @HostListener('mouseleave') mouseLeave(): void {
        this.hide();
    }

    ngOnInit(): void {
        this.tooltipClass = this.arcTooltipClass;

        this.statusChangesSub = this.formControl?.statusChanges.subscribe(status => {
            if (status === 'INVALID') {
                this.show();
            } else if (this._isTooltipVisible()) {
                this.hide();
            }
        });

        this.scrollSub = this.layoutService.anyScrollSubject.subscribe(() => {
            if (this._isTooltipVisible()) {
                this.hide();
            }
        });
    }

    /**
     * Only show the tooltip if the control is invalid and has been touched
     */
    override show(delay?: number | undefined, origin?: { x: number; y: number } | undefined): void {
        this.getMessage();
        if (this.isInvalidAndTouched()) {
            super.show(delay, origin);
        }
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
        this.statusChangesSub?.unsubscribe();
        this.scrollSub?.unsubscribe();
    }

    private getMessage(): void {
        if (Utils.areEqual(this.lastErrors, this.formControl?.errors)) {
            return;
        }

        this.lastErrors = this.formControl?.errors;
        this.message = this.formControlErrorsService.getErrorMessage(this.lastErrors) ?? '';
    }

    private isInvalidAndTouched(): boolean {
        return !!this.formControl?.invalid && !!this.formControl?.touched;
    }
}
