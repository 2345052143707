<arc-input *ngIf="columnModel.isEditable && !!control; else readonlyTpl" [control]="control | formControl" [action]="castedColumnModel.action" [actionIcon]="castedColumnModel.actionIcon" ></arc-input>
<ng-template #readonlyTpl>
    <span [class.line-clamp-2.break-words]="!castedColumnModel.isCompact" [class.truncate]="castedColumnModel.isCompact"
        [class.text-on-app-light]="!!castedColumnModel.suffixPropertyName">
        <span class="text-on-app" [class.text-sm]="castedColumnModel.isCompact"
            [class.font-bold]="isBold(castedColumnModel.propertyName)">
            {{ getItemValue(castedColumnModel.propertyName) }}
        </span>
        <span *ngIf="!!castedColumnModel.suffixPropertyName" class="ml-2 text-sm text-on-app-light"
            [class.text-xs]="castedColumnModel.isCompact">
            {{ getItemValue(castedColumnModel.suffixPropertyName) }}
        </span>
    </span>
</ng-template>