import { Component, ElementRef, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable, Subject, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';

import { BaseControlComponent } from '../base-control/base-control.component';
import { OptionalType } from '../../../core/models/types/optional.type';
import { KeyValueModel } from '../../../core/models/key-value.model';
import { Utils } from '../../../core/utils/tools/utils.tools';

@Component({
    selector: 'arc-chip-selector',
    templateUrl: './chip-selector.component.html',
    styleUrls: ['./chip-selector.component.scss']
})
export class ChipSelectorComponent extends BaseControlComponent {
    @ViewChild('chipInput') chipInput!: ElementRef<HTMLInputElement>;
    @HostBinding('class') classes = 'grow';

    @Input() optionDisplayFn!: (option: KeyValueModel) => string;
    @Input() searchFn!: (query: string) => Observable<KeyValueModel[]>;
    @Input() allowNewValues = false;
    @Input() selectedChips: KeyValueModel[] = [];
    @Input() separtorKeyCodes = [ENTER, COMMA];
    @Input() allowDuplicateValues = false;

    @Output() readonly optionsSelected = new EventEmitter<OptionalType<KeyValueModel[]>>();

    filteredChips: KeyValueModel[] = [];
    searchText = '';
    isLoading = false;

    protected readonly _debounceTimeMs = 250;
    private readonly internalSearchSubject = new Subject<string>();

    constructor() {
        super();
        this.internalSearchSubject
            .pipe(
                distinctUntilChanged((prev, curr) => Utils.areEqual(prev, curr)),
                tap(() => {
                    this.filteredChips = [];
                    this.isLoading = true;
                }),
                debounceTime(this._debounceTimeMs),
                switchMap(searchText => this.searchFn(searchText))
            )
            .subscribe(options => {
                this.filteredChips = options;
                this.isLoading = false;
            });
    }

    remove(el: KeyValueModel): void {
        this.selectedChips = this.selectedChips.filter(e => e.value !== el.value);
        this.control.setValue(this.selectedChips);
    }

    onOptionSelected(event: MatAutocompleteSelectedEvent): void {
        const optionToAdd = event.option.value as KeyValueModel;

        this.selected(optionToAdd);
    }

    selected(option: KeyValueModel): void {
        if (this.allowDuplicateValues || !this.selectedChips.some(chip => chip.value === option.value)) {
            this.selectedChips.push(option);
            this.control.setValue(this.selectedChips);
        }
        this.chipInput.nativeElement.value= '';
        this.searchText = '';
    }

    add(event: MatChipInputEvent): void {
        if (event.value && this.allowNewValues) {
            const option = new KeyValueModel({ key: '', value: event.value });
            this.selected(option);
        }
        this.chipInput.nativeElement.value= '';
        this.searchText = '';
    }

    onInput(): void {
        this.performSearch();
    }

    onFocus(): void {
        this.performSearch();
    }

    private performSearch(): void {
        this.internalSearchSubject.next(this.searchText);
    }
}
