import { HasRoleGuard } from '../../core/services/guards/has-role.guard';
import { UserRoles } from '../models/enums/user-roles.enum';
import { LeadsComponent } from '../views/marketing/leads/leads.component';
import { NewsletterComponent } from '../views/marketing/newsletter/newsletter.component';
import { SubscribersComponent } from '../views/marketing/subscribers/subscribers.component';
import { BlogComponent } from '../views/support/blog/blog.component';

export const marketingRoutes = [
    {
        path: 'marketing',
        data: {
            showInMainMenu: true,
            mainMenuIndex: 2,
            subMenu: 'marketing',
            title: 'Marketing.Marketing',
            requiredRole: UserRoles.Newsletter | UserRoles.Leads
        },
        children: [
            {
                path: 'newsletter',
                component: NewsletterComponent,
                canActivate: [HasRoleGuard],
                data: {
                    subMenu: 'marketing',
                    subMenuIndex: 1,
                    title: 'Marketing.Newsletter',
                    requiredRole: UserRoles.Newsletter
                }
            },
            {
                path: 'blog',
                component: BlogComponent,
                canActivate: [HasRoleGuard],
                data: {
                    subMenu: 'marketing',
                    subMenuIndex: 2,
                    title: 'Marketing.Blog',
                    requiredRole: UserRoles.BlogArticles
                }
            },
            {
                path: 'subscribers',
                component: SubscribersComponent,
                canActivate: [HasRoleGuard],
                data: {
                    subMenu: 'marketing',
                    subMenuIndex: 3,
                    title: 'Marketing.Subscribers',
                    requiredRole: UserRoles.Newsletter
                }
            },
            {
                path: 'leads',
                component: LeadsComponent,
                canActivate: [HasRoleGuard],
                data: {
                    subMenu: 'marketing',
                    subMenuIndex: 4,
                    title: 'Marketing.Leads',
                    requiredRole: UserRoles.Leads
                }
            }
        ]
    }
];
