import { Component, ViewChild, inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DynamicFormDialogConfig } from './models/dynamic-form-dialog.config';
import { DynamicFormComponent } from '../dynamic-form/dynamic-form.component';

@Component({
    selector: 'arc-dynamic-form-dialog',
    templateUrl: './dynamic-form-dialog.component.html',
    styleUrls: ['./dynamic-form-dialog.component.scss']
})
export class DynamicFormDialogComponent {
    @ViewChild('dynamicForm') dynamicForm!: DynamicFormComponent;

    readonly data: DynamicFormDialogConfig = inject(MAT_DIALOG_DATA);

    private readonly dialogRef = inject(MatDialogRef);

    onSave(): void {
        this.dynamicForm.submitForm();
    }

    onFormSubmitted(data: any): void {
        this.dialogRef.close(data);
    }
}
