import { Component, inject } from '@angular/core';
import { first } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

import { FeatureVoteListModel } from '../../../../../models/responses/feeature-vote.list.model';
import { FeaturesStore } from '../../../../../services/stores/features.store';
import { FeedbackVoteTextColumnModel } from '../../../../../../components/dynamic-table/models/column-types/feedback-vote-text-column.model';
import { BaseCustomColumnComponent } from '../../../../../../components/dynamic-table/column-components/custom-column/base-custom-column.component';
import { GeneralPromptDialogComponent } from '../../../../../../components/dialogs/general-prompt-dialog/general-prompt-dialog.component';

@Component({
    selector: 'arc-feedback-vote-text',
    templateUrl: './feedback-vote-text-column.component.html',
    styleUrl: './feedback-vote-text-column.component.scss'
})
export class FeedbackVoteTextColumnComponent extends BaseCustomColumnComponent<FeatureVoteListModel> {
    private featuresStore = inject(FeaturesStore);
    private readonly matDialog = inject(MatDialog);

    handleDeleteClick(): void {
        const dialogRef = this.matDialog.open(GeneralPromptDialogComponent, {
            data: { promptKey: 'General.Prompts.DeleteEntry' }
        });

        dialogRef.afterClosed().subscribe(isConfirmed => {
            if (isConfirmed) {
                this.featuresStore
                    .deleteFeatureVote(this.item.id)
                    .pipe(first())
                    .subscribe(() => {
                        if (!!this.castedColumnModel.refreshFn) {
                            this.castedColumnModel.refreshFn();
                        }
                    });
            }
        });
    }

    get castedColumnModel(): FeedbackVoteTextColumnModel {
        return this.columnModel as FeedbackVoteTextColumnModel;
    }
}
