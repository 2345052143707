import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { SecurityStorage } from '../storages/security.storage';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
    private readonly securityStorage = inject(SecurityStorage);

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const userInfo = this.securityStorage.getUserInfo();

        // do not add header for requests that do not go to our api server
        if (!!userInfo && request.url.startsWith(environment.baseUrl)) {
            const authRequest = request.clone({
                headers: request.headers.set('Authorization', `Bearer ${userInfo.token}`)
            });

            return next.handle(authRequest);
        }

        return next.handle(request);
    }
}
