import { HasRoleGuard } from '../../core/services/guards/has-role.guard';
import { UserRoles } from '../models/enums/user-roles.enum';
import { ApiIntegrationsComponent } from '../views/basic-data/api-integrations/api-integrations.component';
import { ContractsComponent } from '../views/basic-data/contracts/contracts.component';
import { HardwareBundlesComponent } from '../views/basic-data/hardware-bundles/hardware-bundles.component';
import { ModuleBundlesComponent } from '../views/basic-data/module-bundles/module-bundles.component';
import { PosReportTemplatesComponent } from '../views/basic-data/pos-report-templates/pos-report-templates.component';
import { ReportTemplatesComponent } from '../views/basic-data/report-templates/report-templates.component';
import { SystemLogComponent } from '../views/basic-data/system-log/system-log.component';
import { UsersComponent } from '../views/basic-data/users/users.component';

export const basicDataRoutes = [
    {
        path: 'basic-data',
        data: {
            showInMainMenu: true,
            subMenu: 'basic-data',
            mainMenuIndex: 3,
            title: 'BasicData.BasicData',
            requiredRole:
                UserRoles.Users |
                UserRoles.ReportTemplates |
                UserRoles.ModuleBundles |
                UserRoles.Admin |
                UserRoles.ApiIntegrationAdmin |
                UserRoles.ApiIntegrations
        },
        children: [
            {
                path: 'users',
                component: UsersComponent,
                canActivate: [HasRoleGuard],
                data: {
                    subMenu: 'basic-data',
                    subMenuIndex: 1,
                    title: 'BasicData.Users',
                    requiredRole: UserRoles.Users
                }
            },
            {
                path: 'module-bundles',
                component: ModuleBundlesComponent,
                canActivate: [HasRoleGuard],
                data: {
                    subMenu: 'basic-data',
                    subMenuIndex: 2,
                    title: 'BasicData.ModuleBundles',
                    requiredRole: UserRoles.ModuleBundles
                }
            },
            {
                path: 'hardware-bundles',
                component: HardwareBundlesComponent,
                canActivate: [HasRoleGuard],
                data: {
                    subMenu: 'basic-data',
                    subMenuIndex: 3,
                    title: 'BasicData.HardwareBundles',
                    requiredRole: UserRoles.HardwareBundles
                }
            },
            {
                path: 'report-templates',
                component: ReportTemplatesComponent,
                canActivate: [HasRoleGuard],
                data: {
                    subMenu: 'basic-data',
                    subMenuIndex: 4,
                    title: 'BasicData.Reporttemplates',
                    requiredRole: UserRoles.ReportTemplates
                }
            },
            {
                path: 'pos-report-templates',
                component: PosReportTemplatesComponent,
                canActivate: [HasRoleGuard],
                data: {
                    subMenu: 'basic-data',
                    subMenuIndex: 5,
                    title: 'BasicData.POS-Reporttemplates',
                    requiredRole: UserRoles.ReportTemplates
                }
            },
            {
                path: 'api-integrations',
                component: ApiIntegrationsComponent,
                canActivate: [HasRoleGuard],
                data: {
                    subMenu: 'basic-data',
                    subMenuIndex: 6,
                    title: 'BasicData.API-Integrations',
                    requiredRole: UserRoles.ApiIntegrations | UserRoles.ApiIntegrationAdmin
                }
            },
            {
                path: 'contracts',
                component: ContractsComponent,
                canActivate: [HasRoleGuard],
                data: { subMenu: 'basic-data', subMenuIndex: 7, title: 'BasicData.Contracts', requiredRole: UserRoles.Contracts }
            },
            {
                path: 'portal-logs',
                component: SystemLogComponent,
                canActivate: [HasRoleGuard],
                data: { subMenu: 'basic-data', subMenuIndex: 8, title: 'BasicData.SystemLog', requiredRole: UserRoles.Admin }
            }
        ]
    }
];
