import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { StoreConfig } from '../../../core/models/store.config';
import { BaseStore } from '../../../core/abstractions/base.store';
import { ApiResponseModel } from '../../models/responses/api-response.model';
import { HelpArticleListModel } from '../../models/responses/help-article-list.model';
import { KeyValueModel } from '../../../core/models/key-value.model';
import { HelpArticleModel } from '../../models/responses/help-article.model';

@Injectable({
    providedIn: 'root'
})
export class HelpStore extends BaseStore {
    constructor() {
        super(new StoreConfig({ baseController: 'portal' }));
    }

    getHelpArticles(searchText?: string, context?: string): Observable<ApiResponseModel<HelpArticleListModel[]>> {
        const url = this.getUrl('help-articles');

        return this._requestService.makeGet<ApiResponseModel<HelpArticleListModel[]>>(
            url,
            undefined,
            false,
            undefined,
            false,
            undefined,
            new KeyValueModel({ key: 'searchText', value: searchText }),
            new KeyValueModel({ key: 'context', value: context })
        );
    }

    getHelpArticle(articleId: string): Observable<ApiResponseModel<HelpArticleModel>> {
        const url = this.getUrl('help-articles');

        return this._requestService.makeGet<ApiResponseModel<HelpArticleModel>>(url, articleId);
    }
}
