<mat-card-header class="items-center mb-2">
    <mat-card-title>
        <span *ngIf="!!currentSavedFilter; else filterHeader">
            {{ 'Components.SearchFilter.AppliedFilter' | transloco }}: {{ currentSavedFilter.name }}
        </span>
        <ng-template #filterHeader>
            {{ 'Components.SearchFilter.Filter' | transloco }}
        </ng-template>
    </mat-card-title>

    <!-- saving / loading of filters -->
    <div class="ml-auto flex items-center gap-4">
        <button *ngIf="!!currentSavedFilter && !isSaving" mat-icon-button (click)="updateCurrentSavedFilter()"
            [disabled]="!hasUnsavedChanges || !isValid"
            [matTooltip]="'Components.SearchFilter.SaveFilter' | transloco">
            <mat-icon>save_as</mat-icon>
        </button>
        <mat-spinner *ngIf="isSaving" diameter="20"></mat-spinner>

        <button *ngIf="!!currentSavedFilter && !isDeleting" [disabled]="isDeleting" mat-icon-button
            (click)="deleteCurrentSavedFilter()" [matTooltip]="'Components.SearchFilter.DeleteSavedFilter' | transloco">
            <mat-icon>delete</mat-icon>
        </button>
        <mat-spinner *ngIf="isDeleting" diameter="20"></mat-spinner>

        <button mat-icon-button [matMenuTriggerFor]="savedfilterSelection" [disabled]="savedFilters.length === 0"
            [matTooltip]="'Components.SearchFilter.SavedFilters' | transloco">
            <mat-icon>topic</mat-icon>
        </button>
    </div>
</mat-card-header>

<mat-menu #savedfilterSelection="matMenu">
    <button *ngFor="let savedFilter of savedFilters" mat-menu-item (click)="applySavedFilter(savedFilter.id)">
        {{ savedFilter.name }}
    </button>
</mat-menu>

<mat-card-content>
    <div class="flex flex-col">
        <!-- readonly filters -->
        <div *ngIf="readOnlyFilterLabels.length > 0" class="gap-4">
            <mat-chip-listbox>
                <mat-chip-option *ngFor="let filter of readOnlyFilterLabels; let i = index">
                    {{filter}}
                    <button matChipRemove (click)="removeReadOnlyFilter(i)">
                        <mat-icon>cancel</mat-icon>
                    </button>
                </mat-chip-option>
            </mat-chip-listbox>
        </div>
        <!-- editable filters -->
        <div class="flex flex-col gap-2" *ngFor="let filter of currentFilters; let i = index">
            <mat-divider *ngIf="i !== 0" class="!mt-4 !mb-2"></mat-divider>
            <arc-filter-part [index]="i" [filterProperties]="selectableFilterItems">
            </arc-filter-part>
        </div>
    </div>

    <div class="mt-2 flex items-center gap-2">
        <button mat-button (click)="addFilter()" [disabled]="selectableFilterItems.length === 0">
            <mat-icon>add</mat-icon>
            <span>{{ 'Components.SearchFilter.AddFilter' | transloco }}</span>
        </button>

        <div class="grow"></div>

        <button mat-icon-button (click)="reset()" [matTooltip]="'General.Actions.Reset' | transloco">
            <mat-icon>replay</mat-icon>
        </button>
        <button mat-flat-button color="primary" [disabled]="!canApply" (click)="applyFilters()">
            <span class="flex gap-2">
                <mat-spinner *ngIf="isLoading" diameter="20"></mat-spinner>
                {{ 'Components.SearchFilter.ShowXResults' | transloco: { value: canApply ? resultCount : '' } }}
            </span>
        </button>
    </div>
</mat-card-content>