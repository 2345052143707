export class AddressModel {
    street!: string;
    streetNumber?: string;
    streetSupplement?: string;
    isPoBox?: boolean;
    poBox?: string;
    zip!: string;
    city!: string;
    countryIsoCode!: string;

    constructor(init?: Partial<AddressModel>) {
        if (!!init) {
            Object.assign(this, init);
        }
    }
}
