import { BaseColumnModel } from '../../../dynamic-table/models/column-types/base-column.model';
import { StackedColumnModel } from '../../../dynamic-table/models/column-types/stacked-column.model';
import { StringColumnModel } from '../../../dynamic-table/models/column-types/string-column.model';

export const userSelectionTableConfig: BaseColumnModel[] = [
    new StackedColumnModel({
        propertyName: 'displayName',
        propertyName2: 'id',
        columnTitleKey: 'User.List.DisplayName'
    }),
    new StringColumnModel({
        columnTitleKey: 'User.List.Email',
        propertyName: 'email',
        widthPixels: 250
    })
];
