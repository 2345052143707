import { Component, computed, inject, input } from '@angular/core';

import { LicenseModel } from '../../../../../models/license.model';
import { ActionButtonsService } from '../../../../../../core/services/action-buttons.service';

@Component({
    selector: 'arc-license-product-item',
    templateUrl: './license-product-item.component.html',
    styleUrl: './license-product-item.component.scss'
})
export class LicenseProductItemComponent {
    license = input.required<LicenseModel>();

    readonly licenseChangeProductContextAction = 'LicenseChangeProductContextAction';

    readonly actionButtonsService = inject(ActionButtonsService);

    canChangeProduct = computed(() => !this.actionButtonsService.isHidden(this.licenseChangeProductContextAction, this.license()));

    handleChangeProductContextClick(): void {
        if (this.actionButtonsService.isHidden(this.licenseChangeProductContextAction, this.license())) {
            return;
        }
        this.actionButtonsService.handleClick(this.licenseChangeProductContextAction, this.license());
    }
}
