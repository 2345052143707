/**
 * HTTP requests' content type.
 */
export enum ContentTypeEnum {
    /**
     * Empty.
     */
    Empty = '',
    /**
     * application/json.
     */
    ApplicationJson = 'application/json',
    /**
     * application/octet-stream
     */
    ApplicationOctetStream = 'application/octet-stream'
}
