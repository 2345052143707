import { Component, EventEmitter, Input, Output, ViewChild, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Subject, distinctUntilChanged, tap, debounceTime, switchMap, Observable, finalize } from 'rxjs';

import { BaseControlValueAccessor } from '../../../core/abstractions/base-control-value-accessor';
import { OptionalType } from '../../../core/models/types/optional.type';
import { Utils } from '../../../core/utils/tools/utils.tools';

@Component({
    selector: 'arc-autocomplete',
    templateUrl: './autocomplete.component.html',
    styleUrls: ['./autocomplete.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AutocompleteComponent),
            multi: true
        }
    ]
})
export class AutocompleteComponent<TOption> extends BaseControlValueAccessor<string> {
    @ViewChild(MatAutocomplete) autocomplete!: MatAutocomplete;

    @Input() searchFn!: (query: string) => Observable<TOption[]>;
    @Input() valueAccessor!: keyof TOption | ((option: OptionalType<TOption>) => string);
    @Input() optionDisplayAccessor!: keyof TOption | ((option: TOption) => string);
    @Input() valueDisplayAccessor?: keyof TOption | ((option: OptionalType<TOption>) => string);
    @Input() shouldSkipDistinctCheck = false;
    @Input() allowArbitraryValues = false;
    @Input() shouldPanelWidthFitContent = false;

    @Output() readonly inputChanged = new EventEmitter<string>();
    @Output() readonly blurred = new EventEmitter<void>();
    @Output() readonly optionSelected = new EventEmitter<OptionalType<TOption>>();

    isLoading = false;
    isValidInput = false;
    options: TOption[] = [];

    protected readonly _debounceTimeMs = 250;
    protected readonly _searchResultSize = 50;

    private readonly internalSearchSubject = new Subject<string>();

    constructor() {
        super();

        this.internalSearchSubject
            .pipe(
                distinctUntilChanged((prev, curr) => !this.shouldSkipDistinctCheck && Utils.areEqual(prev, curr)),
                tap(() => {
                    this.options = [];
                    this.isLoading = true;
                }),
                debounceTime(this._debounceTimeMs),
                switchMap(searchText =>
                    this.searchFn(searchText).pipe(
                        tap(options => {
                            this.options = options;
                        }),
                        finalize(() => {
                            this.isLoading = false;
                        })
                    )
                )
            )
            .subscribe();
    }

    onInput(): void {
        this.isValidInput = false;

        this.inputChanged.emit(this.internalControl.value);
        this.valueChanged(this.internalControl.value);
        this.performSearch();
    }

    onFocus(): void {
        this.performSearch();
    }

    onBlur(): void {
        this.blurred.emit();

        if (!this.isValidInput) {
            if (this.allowArbitraryValues) {
                this.valueChanged(this.internalControl.value);
            } else {
                this.internalControl.setValue(undefined);
                this.valueChanged(undefined);
            }
        }
    }

    valueDisplayFunction(option: OptionalType<TOption> | string): string {
        if (typeof option === 'string') {
            return option;
        }

        return this.getValueDisplay(option) ?? this.getValue(option);
    }

    onOptionSelected(event: MatAutocompleteSelectedEvent): void {
        const option = event.option.value as TOption;

        this.valueChanged(this.getValue(option));
        this.optionSelected.emit(option);

        this.isValidInput = true;
        this.options = [option];
    }

    getOptionDisplay(option: TOption): string {
        if (typeof this.optionDisplayAccessor === 'function') {
            return this.optionDisplayAccessor(option);
        }
        return !!option ? `${option[this.optionDisplayAccessor]}` : '';
    }

    private performSearch(): void {
        this.internalSearchSubject.next(this.internalControl.value);
    }

    private getValue(option: OptionalType<TOption>): string {
        if (typeof this.valueAccessor === 'function') {
            return this.valueAccessor(option);
        }
        return !!option ? `${option[this.valueAccessor]}` : '';
    }

    private getValueDisplay(option: OptionalType<TOption>): OptionalType<string> {
        if (!this.valueDisplayAccessor) {
            return undefined;
        }

        if (typeof this.valueDisplayAccessor === 'function') {
            return this.valueDisplayAccessor(option);
        }
        return !!option ? `${option[this.valueDisplayAccessor]}` : '';
    }
}
