export enum ModalVisibilityEnum {
    /**
     * Not visible in the modal.
     */
    None = 0,

    /**
     * Visible as a primary button.
     */
    Primary = 1,

    /**
     * Visible as a primary button and closes the modal after action is executed.
     */
    PrimaryCloseAfter = 2,

    /**
     * Visible as a secondary button.
     */
    Secondary = 3,

    /**
     * Visible as a secondary button and closes the modal after action is executed.
     */
    SecondaryCloseAfter = 4
}
