import { AfterViewInit, Component, Input, OnInit, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ChartData, ChartType } from 'chart.js';
import { Observable, map, of } from 'rxjs';

import { StatisticsWidgetModel } from '../../core/models/statistics-widget.model';
import { StatisticChartTypesEnum } from '../../core/models/enums/statistic-chart-types.enum';
import { WidgetTypeEnum } from '../../core/models/enums/widget-type.enum';
import { DashboardService } from '../../core/services/dashboard.service';
import { TranslationService } from '../../core/services/translation.service';
import { LayoutService } from '../../core/services/layout.service';
import { StatisticalPeriodsEnum } from '../../app/models/enums/statistical-periods.enum';
import { StatisticsWidgetOptionsDialogComponent } from './statistics-widget-options-dialog/statistics-widget-options-dialog.component';
import { StatisticParametersRequestModel } from '../../app/models/requests/statistic-parameters-request.model';
import { OptionalType } from '../../core/models/types/optional.type';
import { StatisticalValueResponseModel } from '../../app/models/responses/statistical-value-response.model';
import { StatisticsStore } from '../../app/services/stores/statistics.store';

@Component({
    selector: 'arc-statistics-widget',
    templateUrl: './statistics-widget.component.html',
    styleUrls: ['./statistics-widget.component.scss']
})
export class StatisticsWidgetComponent implements OnInit, AfterViewInit {
    @Input() widget!: StatisticsWidgetModel;

    chartData!: ChartData;

    periods: any[] = [];

    chartTypes: any[] = [];

    WidgetTypeEnum = WidgetTypeEnum;

    StatisticChartTypesEnum = StatisticChartTypesEnum;

    chartTypeIconMapper: Record<StatisticChartTypesEnum, string> = {
        [StatisticChartTypesEnum.Bar]: 'bar_chart',
        [StatisticChartTypesEnum.Line]: 'show_chart',
        [StatisticChartTypesEnum.Doughnut]: 'donut_large',
        [StatisticChartTypesEnum.Number]: '123'
    };

    chartTypeNumberSum?: number;
    chartTypeNumberChange = 0;
    isChartTypeNumberComparisonPositive = true;
    hasComparisonValues = false;

    isDashboardEditable = false;

    private readonly matDialog = inject(MatDialog);
    private readonly dashboardService = inject(DashboardService);
    private readonly statisticsStore = inject(StatisticsStore);
    private readonly translationService = inject(TranslationService);
    private readonly layoutService = inject(LayoutService);

    constructor() {
        this.initializeChartTypes();
        this.initializePeriods();
    }

    ngOnInit(): void {
        this.dashboardService.isDashboardEditable$.subscribe(isEditable => (this.isDashboardEditable = isEditable));

        this.layoutService.getThemeChangedObservable().subscribe(() => {
            this.updateChartData();
        });
    }

    ngAfterViewInit(): void {
        this.updateChartData();
    }

    handleClick($event: MouseEvent | TouchEvent): void {
        // the mouse/touch events need to be handled here, when targeting the input inside the widget
        // otherwise the input does not get focus and the focus goes to the gridster-item
        // making it impossible to type into the input
        $event.stopPropagation();
    }
    onPeriodSelected(period: StatisticalPeriodsEnum): void {
        this.widget.parameters.period = period;

        this.saveAndUpdateChartData();
    }

    onChartTypeSelected(chartType: StatisticChartTypesEnum): void {
        this.widget.parameters.chartType = chartType;

        this.saveAndUpdateChartData();
    }

    saveAndUpdateChartData(): void {
        this.updateChartData();
        this.dashboardService.saveCurrent();
    }

    getChartTypeString(chartType: StatisticChartTypesEnum): ChartType {
        switch (chartType) {
            case StatisticChartTypesEnum.Bar:
                return 'bar';
            case StatisticChartTypesEnum.Doughnut:
                return 'doughnut';
            case StatisticChartTypesEnum.Line:
                return 'line';
            default:
                return 'bar';
        }
    }

    openOptionsDialog(): void {
        const dialogRef = this.matDialog.open(StatisticsWidgetOptionsDialogComponent, {
            width: '400px',
            data: {
                widgetType: this.widget.type,
                parameters: { ...this.widget.parameters }
            },
            autoFocus: false
        });

        dialogRef.afterClosed().subscribe((parameters?: StatisticParametersRequestModel) => {
            if (!!parameters) {
                this.updateParameters(parameters);
            }
        });
    }

    updateParameters(parameters: StatisticParametersRequestModel): void {
        this.widget.parameters = parameters;

        this.saveAndUpdateChartData();
    }

    private updateChartData(): void {
        const style = getComputedStyle(document.body);

        this.getStatistics().subscribe(async satisticalValueDtoArray => {
            if (!satisticalValueDtoArray) {
                return;
            }

            const query = satisticalValueDtoArray.filter(dto => dto.group !== '==' && dto.group !== '??');
            const distinctDates = [
                ...new Set(
                    query.map(dto => {
                        const date = new Date(dto.date);

                        return date.toLocaleDateString();
                    })
                )
            ];

            const comparisonChartValues = satisticalValueDtoArray.filter(dto => dto.group === '==').map(dto => dto.value);
            this.hasComparisonValues = comparisonChartValues.length > 2;

            const budgetChartValues = satisticalValueDtoArray.filter(dto => dto.group === '??').map(dto => dto.value.toFixed(0));
            const hasBudgetValues = budgetChartValues.length > 2;

            const chartData: any = {
                labels: [],
                datasets: []
            };

            if (this.widget.parameters.chartType !== StatisticChartTypesEnum.Doughnut) {
                chartData.labels = distinctDates;
            }

            // ChartType Bar
            if (this.widget.parameters.chartType === StatisticChartTypesEnum.Bar) {
                const data = query.reduce((acc: { group: string; color: string; values: number[] }[], curr) => {
                    const existingDataset = acc.find(dataset => dataset.group === curr.group && dataset.color === curr.groupColor);
                    if (existingDataset) {
                        existingDataset.values.push(curr.value);
                    } else {
                        acc.push({
                            group: curr.group,
                            color: curr.groupColor,
                            values: [curr.value]
                        });
                    }
                    return acc;
                }, []);

                data.forEach(dataset => {
                    chartData.datasets.push({
                        label: dataset.group,
                        data: dataset.values,
                        borderColor: dataset.color,
                        backgroundColor: dataset.color
                    });
                });

                if (this.hasComparisonValues) {
                    const label = await this.translationService.getTextAsync('Widgets.Statistics.Filter.ComparisonPeriodChartLabel');
                    const comparisonValuesChartData = {
                        label: label,
                        type: 'line',
                        data: comparisonChartValues,
                        borderColor: style.getPropertyValue('--chart-comparison'),
                        backgroundColor: style.getPropertyValue('--chart-comparison')
                    };

                    chartData.datasets.push(comparisonValuesChartData);
                }

                if (hasBudgetValues) {
                    const label = await this.translationService.getTextAsync('Widgets.Statistics.Filter.BudgetValuesChartLabel');
                    const budgetValuesChartData = {
                        label: label,
                        type: 'line',
                        data: budgetChartValues,
                        borderColor: style.getPropertyValue('--chart-budget'),
                        backgroundColor: style.getPropertyValue('--chart-budget')
                    };

                    chartData.datasets.push(budgetValuesChartData);
                }
            }

            // ChartType Line
            if (this.widget.parameters.chartType === StatisticChartTypesEnum.Line) {
                const data = query.reduce((acc: { group: string; value: number }[], curr) => {
                    const dateString = new Date(curr.date).toLocaleDateString();

                    const existingDataset = acc.find(dataset => dataset.group === dateString);

                    const currentGroup = existingDataset !== undefined ? existingDataset : { group: dateString, value: 0 };

                    currentGroup.value += curr.value;

                    if (!existingDataset) {
                        acc.push(currentGroup);
                    }

                    return acc;
                }, []);

                chartData.datasets.push({
                    label: await this.translationService.getTextAsync('Widgets.Statistics.Total'),
                    data: data.map(d => d.value),
                    borderColor: style.getPropertyValue('--chart-line'),
                    backgroundColor: style.getPropertyValue('--chart-line')
                });
            }

            // ChartType Doughnut
            if (this.widget.parameters.chartType === StatisticChartTypesEnum.Doughnut) {
                const data = query.reduce((acc: { group: string; color: string; value: number }[], curr) => {
                    const existingDataset = acc.find(dataset => dataset.group === curr.group && dataset.color === curr.groupColor);
                    if (existingDataset) {
                        existingDataset.value += curr.value;
                    } else {
                        acc.push({
                            group: curr.group,
                            color: curr.groupColor,
                            value: curr.value
                        });
                    }
                    return acc;
                }, []);

                chartData.labels = data.map(d => d.group);
                chartData.datasets.push({
                    data: data.map(d => Math.abs(d.value)),
                    backgroundColor: data.map(d => d.color)
                });
            }

            // ChartType Number
            if (this.widget.parameters.chartType === StatisticChartTypesEnum.Number) {
                this.chartTypeNumberSum = query.reduce((accumulator, currentValue) => accumulator + currentValue.value, 0);

                if (this.hasComparisonValues) {
                    const comparisonSum = comparisonChartValues.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

                    // when the initial value is 0 we can not calculate a percentage difference,
                    // because it would imply dividing by zero, which is infinity.
                    // we handle this cases separately
                    if (comparisonSum === 0 && this.chartTypeNumberSum === 0) {
                        this.chartTypeNumberChange = 0;
                    } else if (comparisonSum === 0) {
                        this.chartTypeNumberChange = 100;
                    } else if (!(this.chartTypeNumberSum === 0 && comparisonSum === 0)) {
                        this.chartTypeNumberChange = ((this.chartTypeNumberSum - comparisonSum) / comparisonSum) * 100;
                    }

                    if (this.chartTypeNumberChange < 0) {
                        this.isChartTypeNumberComparisonPositive = false;
                    } else {
                        this.isChartTypeNumberComparisonPositive = true;
                    }
                }
            }

            this.chartData = chartData;
        });
    }

    private getStatistics(): Observable<OptionalType<StatisticalValueResponseModel[]>> {
        switch (this.widget.type) {
            case WidgetTypeEnum.LicensesByProduct:
                return this.statisticsStore.licensesByProduct(this.widget.parameters).pipe(map(r => r.value));
            case WidgetTypeEnum.TransactionsByReseller:
                return this.statisticsStore.transactionsByReseller(this.widget.parameters).pipe(map(r => r.value));
            default:
                return of(undefined);
        }
    }

    private async initializePeriods(): Promise<void> {
        for (const period in StatisticalPeriodsEnum) {
            if (isNaN(Number(period))) {
                const label = await this.translationService.getTextAsync(`Widgets.Statistics.StatisticalPeriods.${period}`);
                const val = StatisticalPeriodsEnum[period];
                const option = { value: val, label: label };

                this.periods.push(option);
            }
        }
    }

    private async initializeChartTypes(): Promise<void> {
        for (const chartType in StatisticChartTypesEnum) {
            if (isNaN(Number(chartType))) {
                const chartTypeEnum = chartType as StatisticChartTypesEnum;

                const label = await  this.translationService.getTextAsync(`Widgets.Statistics.StatisticChartTypes.${chartTypeEnum}`);
                const val = chartTypeEnum;
                const icon = this.chartTypeIconMapper[chartTypeEnum];
                const option = { value: val, label: label, icon: icon };

                this.chartTypes.push(option);
            }
        }
    }
}
