import { HTTP_INTERCEPTORS, HttpInterceptor } from '@angular/common/http';
import { InjectionToken } from '@angular/core';

import { AuthHttpInterceptor } from './auth.http-interceptor';
import { TimezoneHttpInterceptor } from './timezone.http-interceptor';
import { DateResponseHttpInterceptor } from './date-response.http-interceptor';
import { ResponseHandlerHttpInterceptor } from './response-handler.http-interceptor';
import { LanguageHttpInterceptor } from './language.http-interceptor';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const HttpInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS as InjectionToken<HttpInterceptor>, useClass: AuthHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS as InjectionToken<HttpInterceptor>, useClass: LanguageHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS as InjectionToken<HttpInterceptor>, useClass: ResponseHandlerHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS as InjectionToken<HttpInterceptor>, useClass: DateResponseHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS as InjectionToken<HttpInterceptor>, useClass: TimezoneHttpInterceptor, multi: true }
];
