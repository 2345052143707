import { Component, HostBinding, inject, signal } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import { BaseComponent } from '../../../../../../../components/abstractions/base.component';
import { ComplexDataTypesEnum } from '../../../../../../../core/models/complex-data-types.enum';
import { OptionalType } from '../../../../../../../core/models/types/optional.type';
import { ArcFormControl } from '../../../../../../../core/utils/arc-form-control';

@Component({
    selector: 'arc-create-ticket-dialog',
    templateUrl: './create-ticket-dialog.component.html',
    styleUrl: './create-ticket-dialog.component.scss'
})
export class CreateTicketDialogComponent extends BaseComponent {
    @HostBinding('class') classes = 'grow flex flex-col overflow-y-auto';

    ComplexDataTypesEnum = ComplexDataTypesEnum;

    isCreating = signal(false);
    formGroup = new FormGroup({
        licenseId: new ArcFormControl<OptionalType<string>>(undefined, Validators.required),
        escalated: new ArcFormControl(true, Validators.required)
    });

    private readonly matDialogRef = inject(MatDialogRef);

    create(): void {
        this.formGroup.markAllAsTouched();
        this.formGroup.updateValueAndValidity();

        if (this.formGroup.invalid) {
            return;
        }

        this.matDialogRef.close({ licenseId: this.formGroup.value.licenseId, escalated: this.formGroup.value.escalated });
    }
}
