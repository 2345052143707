import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseStore } from '../../abstractions/base.store';
import { StoreConfig } from '../../models/store.config';
import { BingApiResponseModel } from '../../models/bing-api-response.model';

@Injectable({
    providedIn: 'root'
})
export class BingApiStore extends BaseStore {
    private readonly baseUrl = 'https://dev.virtualearth.net/REST/v1/Autosuggest';

    constructor() {
        super(new StoreConfig());
    }

    getSuggestions(query: string, key: string, countryIsoCode: string, maxResults: number): Observable<BingApiResponseModel> {
        return this._requestService.makeGet<BingApiResponseModel>(
            this.baseUrl,
            undefined,
            true,
            // this request fails if content type is application/json
            undefined,
            false,
            undefined,
            { key: 'query', value: query },
            { key: 'maxResults', value: maxResults.toString() },
            { key: 'includeEntityTypes', value: 'Address' },
            { key: 'culture', value: 'de-CH' },
            { key: 'userRegion', value: countryIsoCode },
            { key: 'countryFilter', value: countryIsoCode },
            { key: 'key', value: key }
        );
    }
}
