import { Injectable, inject } from '@angular/core';
import Swal, { SweetAlertOptions, SweetAlertIcon } from 'sweetalert2';

import { TranslationService } from './translation.service';

@Injectable({
    providedIn: 'root'
})
export class AlertService {
    private readonly translationService = inject(TranslationService);

    /**
     * Displays an alert.
     * @param message Message to be displayed to the user.
     * @param [title] Message's title.
     * @param [isTranslated] Whether the message/title combo is translated or not.
     * @param [isHtml] Whether the content has HTML.
     */
    showAlert(message: string, title?: string, isTranslated = false, isHtml = false): void {
        const opt = this.generateOptions(message, 'warning', title ?? 'General.Alert.Warning', isTranslated, isHtml);
        Swal.fire(opt).then();
    }

    /**
     * Displays an information.
     * @param message Message to be displayed to the user.
     * @param [title] Message's title.
     * @param [isTranslated] Whether the message/title combo is translated or not.
     * @param [isHtml] Whether the content has HTML.
     */
    showInfo(message: string, title?: string, isTranslated = false, isHtml = false): void {
        const opt = this.generateOptions(message, 'info', title ?? 'General.Alert.Info', isTranslated, isHtml);
        Swal.fire(opt).then();
    }

    /**
     * Displays an error.
     * @param message Message to be displayed to the user.
     * @param [title] Message's title.
     * @param [isTranslated] Whether the message/title combo is translated or not.
     * @param [isHtml] Whether the content has HTML.
     */
    showError(message: string, title?: string, isTranslated = false, isHtml = false): void {
        const opt = this.generateOptions(message, 'error', title ?? 'General.Alert.Error', isTranslated, isHtml);
        Swal.fire(opt).then();
    }

    /**
     * Displays a success message.
     * @param message Message to be displayed to the user.
     * @param [title] Message's title.
     * @param [isTranslated] Whether the message/title combo is translated or not.
     * @param [callback] Callback executed if the user clicks on the button.
     * @param [isHtml] Whether the content has HTML.
     */
    showSuccess(message: string, title?: string, callback?: any, isTranslated = false, isHtml = false): void {
        const opt = this.generateOptions(message, 'success', title ?? 'General.Alert.Success', isTranslated, isHtml);
        Swal.fire(opt).then(() => {
            if (callback) {
                callback();
            }
        }).catch(() => {
        });
    }

    /**
     * Displays a message requesting a confirmation from the user.
     * @param message Message to be displayed to the user.
     * @param [title] Message's title.
     * @param [isTranslated] Whether the message/title combo is translated or not.
     * @param [callback] Callback executed if the user clicks on confirm.
     * @param [isHtml] Whether the content has HTML.
     */
    showConfirm(message: string, title?: string, callback?: any, isTranslated = false, isHtml = false): void {
        const opt = this.generateOptions(message, 'warning', title ?? 'General.Alert.Confirm', isTranslated, isHtml);
        opt.cancelButtonText = this.translationService.getText('General.Alert.CancelButtonText');
        opt.confirmButtonText = this.translationService.getText('General.Alert.ConfirmButtonText');
        opt.showCancelButton = true;
        opt.allowEscapeKey = false;
        opt.allowOutsideClick = false;

        Swal.fire(opt).then((result) => {
            if (!!result.value && !!callback) {
                callback();
            }
        }).catch(() => {
        });
    }

    private generateOptions(
        message: string, icon: SweetAlertIcon, defaultTitle: string, isTranslated: boolean, isHtml: boolean, title?: string
    ): SweetAlertOptions {
        const opt: SweetAlertOptions = { icon: icon };

        if (isTranslated || isHtml) {
            if (isHtml) {
                opt.html = message;
            } else {
                opt.text = message;
            }
        } else {
            opt.text = this.translationService.getText(message);
        }

        if (!title) {
            opt.title = this.translationService.getText(defaultTitle);
        } else {
            opt.title = this.translationService.getText(title);
        }

        return opt;
    }
}
