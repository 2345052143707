import { Component } from '@angular/core';

@Component({
    selector: 'arc-blank-layout',
    templateUrl: './blank-layout.component.html',
    styleUrls: ['./blank-layout.component.scss']
})
export class BlankLayoutComponent {

}
