import { Component, OnInit, OnDestroy, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { SecurityStorage } from '../../../../../core/services/storages/security.storage';
import { BaseAuthComponent } from '../../../../../components/abstractions/base-auth.component';
import { EnvironmentService } from '../../../../../core/services/environment.service';
import { TranslationService } from '../../../../../core/services/translation.service';
import { ActionButtonModel } from '../../../../../core/models/action-button.model';
import { ReportTemplateParametersService } from '../../../../services/stores/report-template-parameters.service';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare const GrapeCity: any;

@Component({
    selector: 'arc-reports-viewer',
    templateUrl: './reports-viewer.component.html',
    styleUrls: ['./reports-viewer.component.scss']
})
export class ReportsViewerComponent extends BaseAuthComponent implements OnInit, OnDestroy {
    actionButtons: ActionButtonModel[] = [
        new ActionButtonModel({
            text: 'General.Actions.Edit',
            isEnabled: true,
            icon: 'tune',
            clickFn: () => this.reportParametersService.openReportParametersDialog(this.reportId!, this.getParameters())
        }),
        new ActionButtonModel({
            text: 'General.Actions.Print',
            isEnabled: true,
            icon: 'print',
            clickFn: () => this.viewer.print()
        }),
        new ActionButtonModel({
            text: 'General.Actions.Export',
            isEnabled: true,
            icon: 'picture_as_pdf',
            clickFn: () => this.viewer.export('Pdf', undefined, true, {})
        })
    ];

    private viewer: any;
    private reportId?: string;
    private parameters: any[]  = [];

    private readonly activatedRoute = inject(ActivatedRoute);
    private readonly environmentService = inject(EnvironmentService);
    private readonly securityStorage = inject(SecurityStorage);
    private readonly translationService = inject(TranslationService);
    private readonly titleService = inject(Title);
    private readonly reportParametersService = inject(ReportTemplateParametersService);

    ngOnInit(): void {
        const userToken = this.securityStorage.getUserInfo()?.token;
        this.viewer = GrapeCity.ActiveReports.JSViewer.create({
            element: '#viewerPlaceHolder',
            availableExports: ['Pdf'],
            reportService: {
                url: this.environmentService.reportingUrl + '/api/reporting',
                securityToken: `Bearer ${userToken}`
            },
            title: {
                onUpdate: () => this.updateTitle()
            },
            settings: {
                zoomType: 'FitPage'
            }
        });

        this.viewer.sidebar.toggle(false);
        const queryParamSub = this.activatedRoute.queryParams.subscribe(p => {
            this.reportId = this.activatedRoute.snapshot.paramMap.get('id') || undefined;
            const strParams = p['params'];
            this.parameters = !!strParams ? JSON.parse(window.atob(strParams)) : [];

            this.updateTitle();
            this.viewer.openReport(this.reportId, this.parameters);
        });

        // should start in fullscreen layout
        this.viewer._viewer.toggleFullscreen();

        this.addSubscriptions(queryParamSub);
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
        this.viewer.destroy();
    }

    private updateTitle(): void {
        this.translationService.getTextAsync('Layout.Menu.Sales.Reports').then(txt =>
            this.titleService.setTitle(`Arcavis | ${txt}`)
        );
    }

    private getParameters(): Map<string, any> {
        const result = new Map<string, any>();

        for (const param of this.parameters) {
            result.set(param.name, param.values);
        }

        return result;
    }
}
