@if (isTableListView(config)) {
    <arc-table-list-view [config]="config" (selectionChanged)="tableSelectionChanged($event)">
    </arc-table-list-view>
} @else {
    <arc-card-list-view [config]="config">
    </arc-card-list-view>
}

@if (actionButtonsLength() > 0) {
    <arc-fab [items]="actionButtons()"></arc-fab>
}
