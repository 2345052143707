import { Component } from '@angular/core';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { LicenseModel } from '../../../../../models/license.model';
import { LicenseListModel } from '../../../../../models/responses/license-list.model';

@Component({
    selector: 'arc-license-details-widget',
    templateUrl: './license-details-widget.component.html',
    styleUrl: './license-details-widget.component.scss'
})
export class LicenseDetailsWidgetComponent extends BaseDetailWidget<LicenseListModel, LicenseModel> {}
